/**
* @Author: Ajay
* @Date:   08-Mar-2017 15:58 +05:30
* @Project: Ezyprocure
* @Filename: manage-suppliers.add.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-01-29T12:44:52+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ManageSupplierAddController', ManageSupplierAddController);

  ManageSupplierAddController.$inject = ['$scope', '$state', '$stateParams', 'Auth', 'ManageSupplierService', 'ImageUploadService', 'EzyCompanyService', 'TIME_CONSTANTS', 'GLOBAL_CONSTANTS', 'NotificationService', '$mdDialog', 'PdfUploadService', 'toastr', 'BankService', 'paginationConstants'];

  function ManageSupplierAddController($scope, $state, $stateParams, Auth, ManageSupplierService, ImageUploadService, EzyCompanyService, TIME_CONSTANTS, GLOBAL_CONSTANTS, NotificationService, $mdDialog, PdfUploadService, toastr, BankService, paginationConstants) {
    var vm = this;

    vm.supplier = {};
    vm.save = save;
    vm.saveAdhoc = saveAdhoc;
    vm.checkDeliveryConditions = checkDeliveryConditions;
    vm.checkPaymentTerms = checkPaymentTerms;
    vm.paymentTermChanged = paymentTermChanged;
    vm.setCompanyLogo = setCompanyLogo;
    vm.uploadLogo = uploadLogo;
    vm.deliveryConditions = {};
    vm.paymentTermsOptions = {};
    vm.paymentTerms = {};
    vm.paymentTerms.BANK_TRANSFER = {};
    vm.paymentTerms.PAYPAL = {};
    vm.paymentTerms.CHEQUE = {};
    vm.paymentTerms.OTHER_PAYMENT = {};
    vm.orderTimes = TIME_CONSTANTS.timeConstants;
    vm.userRole = Auth.getUserRole();
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.visibilityType = $stateParams.visibilityType ? $stateParams.visibilityType : 'ALLOCATED';
    vm.showKycUpload = showKycUpload;
    vm.getPdfFileBase64 = getPdfFileBase64;
    vm.supplierKycUpload = supplierKycUpload;
    vm.cancel = cancel;
    // To check supplier management state
    vm.mst = vm.visibilityType === 'ADHOC' ? 'adhoc-tools.adhoc-suppliers' : 'suppliers';
    vm.getAllBanks = getAllBanks;
    vm.selectedBankChanged = selectedBankChanged;
    vm.updateLeadTime = updateLeadTime;
    vm.supplier.leadTime = 0;
    vm.createIdentifier = createIdentifier;
    vm.customDutyTypes = GLOBAL_CONSTANTS.CUSTOM_DUTY_TYPE;

    /**
     * [showKycUpload opens modal to upload KYC file]
     */
    function showKycUpload(event, supplier){
      vm.selectedSupplier = angular.copy(supplier);
      $mdDialog.show({
        templateUrl:'app/common-modules/manage-suppliers/manage-suppliers.kyc-upload.html',
        controller:function(){
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    /**
     * [supplierKycUpload updates the KYC status]
     * @param {Object} data
     * @param {Object} params
     */
    function supplierKycUpload(data, supplierId){
      vm.cancel();
      var params = {supplierId: supplierId};
      ManageSupplierService.supplierKycUpload(data, params).then(function(response){
        vm.files = '';
        NotificationService.success({
          title: 'Supplier KYC update',
          message: 'KYC Updated successfully'
        });
        $state.go(vm.userRole.pretty + '.' + vm.mst, {
          reload: false
        });
      },function(error){
        NotificationService.error({
          title: 'Supplier KYC update',
          error: error
        });
      }
      );
    }

    /**
     * [createIdentifier Creates supplier identifier details]
     */
    function createIdentifier() {
      EzyCompanyService.createIdentifier(
        {ezpCompanyId:vm.supplierDetail.id,
         identifier:vm.ezyCompanyDetails.identifier,
         isoCode:vm.ezyCompanyDetails.isoCode,
         userRole:'ROLE_SUPPLIER',
         id:''}).then(function(response) {
      }, function(error) {
        NotificationService.simpleErrorToast({
          title: 'Admin Supplier Updation',
          message: error.data.message
        });
      });
    }

    /**
     * [getPdfFileBase64 converts the uploaded file into base64]
     * @param {Integer} supplierId
     */
    function getPdfFileBase64(supplierId) {
      var file = vm.files[0];
      if (!file) {
        toastr.error('Please upload a file!', 'File Required');
        return false;
      }
      PdfUploadService.getPdfFileBase64(file.lfFile, function(base64PDF) {
        vm.fileUploadData = {
          kycReport: base64PDF
        };
        vm.supplierKycUpload(vm.fileUploadData,supplierId);
      });
    }

    /**
    * [cancel to close the modal]
    */
    function cancel() {
      $mdDialog.cancel();
      $state.go(vm.userRole.pretty + '.' + vm.mst, {
        reload: false
      });
    }

    vm.getAllBanks();

    function save() {
      checkPaymentTerms();
      // Call Admin supplier service with supplier object as parameter
      ManageSupplierService.createAdminSupplier(vm.supplier).then(function(response) {
        $scope.$emit('ezyprocureApp:supplierUpdate', response);
        NotificationService.success({
          title: 'Admin Supplier',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        vm.supplierDetail = response.data;
        if(vm.userRole.pretty == 'admin' && vm.ezyCompanyDetails){
          vm.createIdentifier();
        }
        vm.showKycUpload(event,response.data);

      }, function(error) {
        NotificationService.error({
          title: 'Admin Supplier Creation',
          error: error
        });
      });
    }

    function showDuplicateDialog(ev, error) {
      var headerError = error.headers('X-ezyprocureApp-error') || '';
      var errorList = ['alertMessage.COMPANY_NAME_EXIST', 'alertMessage.COMPANY_REGISTRATION_NUMBER_EXIST'];
      if (errorList.includes(headerError)) {
        var confirm = $mdDialog.confirm()
          .title('A similar ADHOC supplier already exists in the system.')
          .textContent('Please go to \'Adhoc-Supplier Buyer Mapping\' to map')
          .ariaLabel('confirmation dialog')
          .targetEvent(ev)
          .ok('Take me!')
          .cancel('Cancel');

        $mdDialog.show(confirm).then(function () {
          $state.go('buyer.adhoc-tools.adhoc-supplier-buyer-mapping');
        }, function () {
        });
      }
    }

    function saveAdhoc(ev) {
      angular.forEach($scope.addSupplierForm.$error, function (field) {
        angular.forEach(field, function (errorField) {
          errorField.$setTouched();
        });
      });
      if ($scope.addSupplierForm.$invalid) {
        return;
      }
      vm.supplier.user.login = '0';
      checkPaymentTerms();
      // Call Admin supplier service with supplier object as parameter
      ManageSupplierService.createBuyerSupplier(vm.supplier,vm.visibilityType).then(function(response) {
        $scope.$emit('ezyprocureApp:supplierUpdate', response);
        NotificationService.success({
          title: 'Admin Supplier',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        $state.go(vm.userRole.pretty + '.' + vm.mst, {
          reload: false
        });
      }, function(error) {
        // if duplicate found, show dialog
        NotificationService.error({
          title: 'Admin Supplier Creation',
          error: error
        });
        showDuplicateDialog(ev, error);
      });
    }

    //Check for delivery conditions
    function checkDeliveryConditions() {
      if (!vm.deliveryConditions.today) {
        vm.supplier.orderTimeForToday = null;
      }
      if (!vm.deliveryConditions.friday) {
        vm.supplier.orderTimeForFriday = null;
      }
      if (!vm.deliveryConditions.saturday) {
        vm.supplier.orderTimeForSaturday = null;
      }
      if (!vm.deliveryConditions.minimum) {
        vm.supplier.minimumOrderForFreeDelivery = 0;
        vm.supplier.belowMinimumPurchaseCondition = null;
        vm.supplier.deliveryCharge = 0;
      }
    }

    //call when a payment term is selected or deselected
    function paymentTermChanged(paymentType, active, paymentTerm) {
      paymentTerm.active = active;
      if (paymentTerm.active === false && !paymentTerm.id) {
        vm.paymentTerms[paymentType] = {};
      }
    }

    //check payment terms
    function checkPaymentTerms() {
      vm.supplier.paymentTermsList = [];
      _.forEach(vm.paymentTerms, function(paymentTerm, key) {
        paymentTerm.paymentType = key;
        if (paymentTerm.active === true || paymentTerm.active === false) {
          vm.supplier.paymentTermsList.push(paymentTerm);
        }
      });
    }

    /**IMAGE UPLOAD**/

    function setCompanyLogo(flowFile) {
      ImageUploadService.getImageBase64(flowFile.file, function(base64Img) {
        vm.uploadLogo(base64Img);
      });
    }

    function uploadLogo(base64Img) {
      vm.uploadingInProgress = true;
      ImageUploadService.uploadImage(base64Img).
        then(function(responseData) {
          vm.supplier.companyLogo = responseData.url;
          $scope.addSupplierForm.$pristine = false;
          NotificationService.success({
            title: 'Supplier Company logo',
            message: 'alertMessage.UPLOADED_SUCCESSFULLY'
          });
          vm.uploadingInProgress = false;
        }, function(error) {
          vm.uploadingInProgress = false;
          NotificationService.error({
            title: 'Supplier Company logo Upload',
            error: error
          });
        });
    }

    function getAllBanks() {
      BankService.getAllBanks({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.banks = response.data;
      }, function(error) {
      });
    }

    function selectedBankChanged(bankId) {
      var banks = angular.copy(vm.banks);
      vm.paymentTerms.BANK_TRANSFER.bank = BankService.getSelectedBank(banks, bankId);
    }

    /**
    * [updateLeadTime update lead time value based on noSameDayDelivery condition]
    * @param  {[type]} noSameDayDelivery [no same day delivery condition for supplier]
    * @return {[type]}                   [description]
    */
    function updateLeadTime(noSameDayDelivery){
      if(noSameDayDelivery){
        vm.supplier.leadTime = 1;
      }
      else{
        vm.supplier.leadTime = 0;
      }
    }

  }
})();
