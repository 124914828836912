/**
 * @Author: Elsa
 * @Date:   28-Mar-2017 15:32 +05:30
 * @Filename: supplier.purchase-order.consolidated-details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-06-24T16:31:44+05:30
 */




(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierPurchaseOrderConsolidatedDetailsController', SupplierPurchaseOrderConsolidatedDetailsController);

  SupplierPurchaseOrderConsolidatedDetailsController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'toastr', 'PurchaseOrderService', 'UTCDateService', 'PDFExportService', 'EXPORT_CONSTANTS', 'CSVExportService', 'paginationConstants', 'NotificationService', 'GLOBAL_CONSTANTS', 'Auth'];

  function SupplierPurchaseOrderConsolidatedDetailsController($scope, $rootScope, $filter, $state, $stateParams, toastr, PurchaseOrderService, UTCDateService, PDFExportService, EXPORT_CONSTANTS, CSVExportService, paginationConstants, NotificationService, GLOBAL_CONSTANTS, Auth) {
    var vm = this;
    vm.init = init;
    vm.loadMetaData = loadMetaData;
    vm.loadAll = loadAll;
    vm.showMore = showMore;
    vm.getConsolidatedPurchaseOrderPDF = getConsolidatedPurchaseOrderPDF;
    vm.getConsolidatedPurchaseOrderCSVData = getConsolidatedPurchaseOrderCSVData;
    vm.ConsolidatedGoodsReceiptNoteHeader = EXPORT_CONSTANTS.CSV.ConsolidatedGoodsReceiptNoteHeader;
    vm.consolidatedPODetails = [];
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.hasMore = false;
    vm.pageDetails = "";
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');
    vm.showAll = showAll;
    
    function init(){
      if(vm.accessForPriceDisplay)
      vm.purchaseOrderHeader = EXPORT_CONSTANTS.CSV.purchaseOrderHeader;
      else {
        vm.purchaseOrderHeader = _.without(EXPORT_CONSTANTS.CSV.purchaseOrderHeader, 'unit_price' , 'amount_required', 'amount_supplier');
      }
    }

    /**
    * [sort to sort by PO date]
    */
   function sort() {
    var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
    return result;
  }

    /**
     * [loadAll Gets Purchase Order Details]
     */
    function loadMetaData() {
      PurchaseOrderService.getConsolidatedPOMetaData({
        poFromDate: !vm.purchaseOrderObject.poFromDate ? '' : vm.purchaseOrderObject.poFromDate,
        poToDate: !vm.purchaseOrderObject.poToDate ? '' : vm.purchaseOrderObject.poToDate,
        deliveryFromDate: !vm.purchaseOrderObject.deliveryFromDate ? '' : vm.purchaseOrderObject.deliveryFromDate,
        deliveryToDate: !vm.purchaseOrderObject.deliveryToDate ? '' : vm.purchaseOrderObject.deliveryToDate,
        reportType: "PO",
        page: vm.query.page - 1,
        size: vm.query.limit,
        buyerIdList: vm.purchaseOrderObject.buyerIdList.length != 0 ? vm.purchaseOrderObject.buyerIdList : '',
        poNumber: vm.purchaseOrderObject.poNumber,
        purchaseOrderStatus: vm.purchaseOrderObject.purchaseOrderStatus,
        invoiceStatus: vm.purchaseOrderObject.invoiceStatus,
        invoiceNumber: vm.purchaseOrderObject.invoiceNumber,
        businessOutletId: vm.purchaseOrderObject.businessOutletId != 0 ? vm.purchaseOrderObject.businessOutletId : '' ,
        businessOutletName: vm.purchaseOrderObject.businessOutletName,
        query: vm.purchaseOrderObject.query,
        supplier: vm.purchaseOrderObject.supplierId,
        itemGroup: vm.purchaseOrderObject.itemGroupId,
        debitNoteStatus: vm.purchaseOrderObject.debitNoteStatus,
        grnNumber: vm.purchaseOrderObject.grnNumber,
        buyerName: vm.purchaseOrderObject.buyerName,
        creditNoteStatus: vm.purchaseOrderObject.creditNoteStatus,
        supplierVisibilityType: vm.purchaseOrderObject.supplierVisibilityType,
        sort: sort(),
        xeroExported: vm.purchaseOrderObject.xeroExported,
        module : vm.purchaseOrderObject.module
      }).then(function(response){
        vm.consolidatedCurrency = response.data;
      },function(error){
        NotificationService.error({
          title: 'Fetching Consolidated PO Metadata',
          error: error
        });
      });
    }


    /**
    * [loadAll Gets Purchase Order Details]
    */
    function loadAll() {
      vm.purchaseOrderObject = PurchaseOrderService.getPurchaseOrderRequestParams();
      PurchaseOrderService.getConsolidatedPODetails({
        poFromDate: !vm.purchaseOrderObject.poFromDate ? '' : vm.purchaseOrderObject.poFromDate,
        poToDate: !vm.purchaseOrderObject.poToDate ? '' : vm.purchaseOrderObject.poToDate,
        deliveryFromDate: !vm.purchaseOrderObject.deliveryFromDate ? '' : vm.purchaseOrderObject.deliveryFromDate,
        deliveryToDate: !vm.purchaseOrderObject.deliveryToDate ? '' : vm.purchaseOrderObject.deliveryToDate,
        reportType: "PO",
        page: vm.query.page - 1,
        size: vm.query.limit,
        buyerIdList: vm.purchaseOrderObject.buyerIdList,
        poNumber: vm.purchaseOrderObject.poNumber,
        purchaseOrderStatus: vm.purchaseOrderObject.purchaseOrderStatus,
        invoiceStatus: vm.purchaseOrderObject.invoiceStatus,
        invoiceNumber: vm.purchaseOrderObject.invoiceNumber,
        businessOutletId: vm.purchaseOrderObject.businessOutletId,
        businessOutletName: vm.purchaseOrderObject.businessOutletName,
        query: vm.purchaseOrderObject.query,
        supplier: vm.purchaseOrderObject.supplierId,
        itemGroup: vm.purchaseOrderObject.itemGroupId,
        debitNoteStatus: vm.purchaseOrderObject.debitNoteStatus,
        grnNumber: vm.purchaseOrderObject.grnNumber,
        buyerName: vm.purchaseOrderObject.buyerName,
        creditNoteStatus: vm.purchaseOrderObject.creditNoteStatus,
        supplierVisibilityType: vm.purchaseOrderObject.supplierVisibilityType,
        sort: sort(),
        xeroExported: vm.purchaseOrderObject.xeroExported,
        module : vm.purchaseOrderObject.module
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.hasMore = _.gt(parseInt(vm.totalItems), (vm.query.page  * vm.query.limit));
        vm.consolidatedPODetails = vm.consolidatedPODetails.concat(response.data);
        vm.pageDetails = "Showing " + (vm.consolidatedPODetails.length === 0 ? "0" : "1") + " to " + $filter('number')(vm.consolidatedPODetails.length) + " of " + $filter('number')(vm.totalItems) + " entries";
      },function(error){
        NotificationService.error({
          title: 'Fetching Consolidated PR Details',
          error: error
        });
      });
    }

    vm.loadAll();
    vm.loadMetaData();

    function showMore() {
      vm.query.page += 1;
      vm.loadAll();
    }

    function downloadURI(uri, name)
    {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = name;
      link.href = uri;
      link.click();
    }

    function getConsolidatedPurchaseOrderPDF() {
      PDFExportService.getConsolidatedPurchaseOrderPDF({
        poFromDate: !vm.purchaseOrderObject.poFromDate ? '' : vm.purchaseOrderObject.poFromDate,
        poToDate: !vm.purchaseOrderObject.poToDate ? '' : vm.purchaseOrderObject.poToDate,
        deliveryFromDate: !vm.purchaseOrderObject.deliveryFromDate ? '' : vm.purchaseOrderObject.deliveryFromDate,
        deliveryToDate: !vm.purchaseOrderObject.deliveryToDate ? '' : vm.purchaseOrderObject.deliveryToDate,
        reportType: "PO",
        page: 0,
        size: vm.consolidatedPODetails.length,
        buyerIdList: vm.purchaseOrderObject.buyerIdList,
        businessOutletId: vm.purchaseOrderObject.businessOutletId,
        poNumber: vm.purchaseOrderObject.poNumber,
        purchaseOrderStatus: vm.purchaseOrderObject.purchaseOrderStatus,
        invoiceStatus: vm.purchaseOrderObject.invoiceStatus,
        invoiceNumber: vm.purchaseOrderObject.invoiceNumber,
        businessOutletName: vm.purchaseOrderObject.businessOutletName,
        query: vm.purchaseOrderObject.query,
        supplier: vm.purchaseOrderObject.supplierId,
        itemGroup: vm.purchaseOrderObject.itemGroupId,
        debitNoteStatus: vm.purchaseOrderObject.debitNoteStatus,
        grnNumber: vm.purchaseOrderObject.grnNumber,
        buyerName: vm.purchaseOrderObject.buyerName,
        creditNoteStatus: vm.purchaseOrderObject.creditNoteStatus,
        supplierVisibilityType: vm.purchaseOrderObject.supplierVisibilityType,
        sort: sort(),
        xeroExported: vm.purchaseOrderObject.xeroExported,
        module : vm.purchaseOrderObject.module
      })
      .then(function(response){
        downloadURI(response.data.message, "PDF_" + vm.purchaseOrderNumber);
      },function(error) {
        NotificationService.error({
          title: 'Consolidated PO PDF Export',
          error: error
        });
      });
    }

    function getConsolidatedPurchaseOrderCSVData() {
      return CSVExportService.getConsolidatedPurchaseOrderCSV(vm.consolidatedPODetails);
    }

    
    function showAll() {
      vm.query.limit = paginationConstants.maxLimit;
      vm.consolidatedPODetails = [];
      vm.loadAll();
    }

  }
})();
