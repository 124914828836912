/*
 * @Author:Neethu
 * @Date: 2323-08-23 14:24:00
 * @Project: Ezyprocure
 * @Filename: ezyap.supplier-information.controller.js
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapManageSupplierController', EzyapManageSupplierController);

  EzyapManageSupplierController.$inject = ['NotificationService', 'ManageSupplierInfoService', 'paginationConstants', '$mdDialog', '$filter', 'pagingParams', 'Auth'];

  function EzyapManageSupplierController(NotificationService, ManageSupplierInfoService, paginationConstants, $mdDialog, $filter, pagingParams, Auth) {

    var vm = this;
    vm.init = init;
    vm.getSupplierInformation = getSupplierInformation;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.showDeleteConfirmationModal = showDeleteConfirmationModal;
    vm.deleteSupplierInformationById = deleteSupplierInformationById;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.userRole = Auth.getUserRole();
    
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function init() {
      vm.getSupplierInformation();
    }

     /**
     * [get Supplier Information]
     */
     function getSupplierInformation() {
      ManageSupplierInfoService.getSupplierInformation({ 
        query: vm.searchText ? vm.searchText : "",
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort() }).
        then(function (response) {
          vm.supplierDetails = response.data;
          vm.totalItems = response.headers('X-Total-Count');
        }, function (error) {
          NotificationService.error({
            title: 'global.supplierInformation',
            error: error
          });
        });
    }

    /**
     * [delete the supplier information of the given id]
     * @param {String} supplierId [id of the supplier]
     */
    function deleteSupplierInformationById(supplierId) {
      ManageSupplierInfoService.deleteSupplierInformationById(supplierId).
        then(function (responseData) {
          vm.getSupplierInformation();
          NotificationService.success({
            title: 'global.supplierInformation',
            message: 'alertMessage.DELETED_SUCCESSFULLY'
          });
        }, function (error) {
          NotificationService.error({
            title: '',
            error: error
          });
        });
    }

    /**
     * [show confirmaton modal to delete supplier information]
     * @param {Integer} supplierinfoId [supplier information id]
     */
    function showDeleteConfirmationModal(supplierinfoId) {
      var confirm = $mdDialog.confirm()
        .title('Are you sure you want to delete the supplier details?')
        .targetEvent(event)
        .ok($filter('translate')('global.buttons.proceed'))
        .cancel($filter('translate')('global.buttons.cancel'));
      $mdDialog.show(confirm).then(function () {
        vm.deleteSupplierInformationById(supplierinfoId);
      }, function () { });
    }
  }
})();
