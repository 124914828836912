/**
* @Author: Atul
* @Date:   Thu Aug 17 2023 17:01:40 GMT+0800 (Singapore Standard Time)
* @Project: Ezyprocure
* @Filename: ezypayment.buyer.dashboard.controller.js
* @Last modified by:   Atul
* @Last modified time: Thu Aug 17 2023 17:01:40 GMT+0800 (Singapore Standard Time)
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BnplBuyerDashboardController', BnplBuyerDashboardController);

  BnplBuyerDashboardController.$inject = ["BnplBuyerDashboardService", "$rootScope", 'NotificationService', 'GLOBAL_CONSTANTS','BuyerDataService'];

  function BnplBuyerDashboardController(BnplBuyerDashboardService, $rootScope, NotificationService, GLOBAL_CONSTANTS,BuyerDataService) {

    var vm = this;
    vm.getBnplBuyerDashboardCount = getBnplBuyerDashboardCount;
    vm.showUobBanner = GLOBAL_CONSTANTS.SHOW_UOB_BANNER;
    vm.showEzysourceBanner = GLOBAL_CONSTANTS.SHOW_EZYSOURCE_BANNER;
    vm.getBnplBuyer=getBnplBuyer;
    vm.getBnplBuyerDashboardCountByBuyerId=getBnplBuyerDashboardCountByBuyerId;
    vm.brandIds=0;
    vm.getBnplBuyer();
    function getBnplBuyerDashboardCount() {
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      BnplBuyerDashboardService.getBnplBuyerDashboardCount(buyerId).then(function(response) {
        vm.buyerDashboardCountAll=response.data;
        vm.buyerDashboardCount = JSON.parse(JSON.stringify(vm.buyerDashboardCountAll));
        }, function(error) {
        NotificationService.error({
          title: 'BNPL Dashboard',
          error: error
        });
      });
    }
    function getBnplBuyer()
    {
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      BnplBuyerDashboardService.getBnplStatus(buyerId).then(function(response) {
        var brandBnplDetailsDTOs =response.data.brandBnplDetailsDTOs;
        vm.brandsWithTokenMapped=brandBnplDetailsDTOs;
       }, function(error) {
        NotificationService.error({
          title: 'BNPL Dashboard',
          error: error
        });
      });
    }
    function getBnplBuyerDashboardCountByBuyerId()
    {
      var brandFlag=vm.brandIds==0?false:true;
      var dd=brandFlag? _.find(vm.brandsWithTokenMapped, ['brandId', vm.brandIds]):vm.buyerDashboardCountAll;
      vm.buyerDashboardCount.creditAllocated=dd.creditAllocated;
      vm.buyerDashboardCount.creditUtilized=dd.creditUtilized;
      vm.buyerDashboardCount.creditBalance=dd.creditBalance;
      vm.buyerDashboardCount.loanPaid=dd.loanPaid;
    }
  }
})();
