/**
* @Author: Atul
* @Date:   07-July-2023 20:33 +05:30
* @Project: Ezyprocure
* @Filename: bnpl.state.js
* @Last modified by:   Atul
* @Last modified time:  07-July-2023 20:33 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig ($stateProvider) {

    $stateProvider.state('bnpl', {
      abstract: true,
      parent: 'app',
      url: '/bnpl',
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          $translatePartialLoader.addPart('brand');
          $translatePartialLoader.addPart('invoiceOCR');
          return $translate.refresh();
        }]

      }
    });
  }
})();
