/**
 * @Author: Ajay
 * @Date:   04-Apr-2018 10:55 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.items-mapped-from-supplier.controller.js
 * @Last modified by:   Vaibhavi
 * @Last modified time: 09-Jul-2018 15:18 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerItemsMappedFromSupplierController', BuyerItemsMappedFromSupplierController);

  BuyerItemsMappedFromSupplierController.$inject = ['$scope', '$rootScope', '$state', 'pagingParams', '$stateParams', 'BuyerItemsForApprovalService', 'paginationConstants', 'NotificationService'];

  function BuyerItemsMappedFromSupplierController($scope, $rootScope, $state, pagingParams, $stateParams, BuyerItemsForApprovalService, paginationConstants, NotificationService) {
    var vm = this;
    vm.loadAll = loadAll;
    vm.getItemBuyerMappingByItemId = getItemBuyerMappingByItemId;
    vm.updateItemsForApproval = updateItemsForApproval;
    vm.updateItemsBusinessMappingStatus= updateItemsBusinessMappingStatus;
    vm.searchItem = '';
    vm.itemListType = 'all';
    vm.checkAllItemsForApprovalBySupplier = checkAllItemsForApprovalBySupplier;
    vm.itemMappingList = [];

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptionsWithAll;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function getItemBuyerMappingByItemId() {
      if(vm.suppliers)
        return false;
      BuyerItemsForApprovalService.getItemBuyerMappingByItemId().then(function(response) {
        vm.suppliers = response.data;
      }, function(error) {
      });
    }

    //Get all items of the selected Supplier
    function loadAll() {
      vm.selectAll = false;
      vm.selectAllItems = false;
      BuyerItemsForApprovalService.getItemsForApprovalBySupplier(vm.supplierId, {
        page: vm.query.page - 1,
        size: vm.query.limit,
        query: vm.searchItem,
        view: vm.itemListType,
        sort: sort()
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.approvalRequiredItems = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Items Mapped from Supplier Fetching',
          error: error
        });
      });
    }

    //Select all items for approval in Items Mapped From Supplier page
    function checkAllItemsForApprovalBySupplier() {
      vm.selectAllItems ? vm.selectAllItems = true : vm.selectAllItems = false;
      angular.forEach(vm.approvalRequiredItems, function(approvalRequiredItem) {
        approvalRequiredItem.approvalRequired = vm.selectAllItems;
      });
      vm.updateItemsForApproval(vm.approvalRequiredItems);
    }

    function updateItemsForApproval(item) {
      vm.itemMappingList = [];
      if(item instanceof Array){
        vm.itemMappingList = item;
      }
      else {
        vm.itemMappingList =  Array.from([item]);
      }
      BuyerItemsForApprovalService.updateItemsForApproval(vm.itemMappingList).then(function(response) {
        NotificationService.success({
          title: 'Item For Approval',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
      }, function(error) {
        NotificationService.error({
          title: 'Item For Approval Updation',
          error: error
        });
      });
    }

    function updateItemsBusinessMappingStatus(itemId,approvalStatus){
      BuyerItemsForApprovalService.updateItemBusinessMappingApprovalStatus({
        itemId: itemId,
        purchaseRequestApprovalStatus: approvalStatus
      }).then(function(response){
        NotificationService.success({
          title: 'Item Business Mappings',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Item Rejection Failed',
          error: error
        });
      });
    }
  }
})();
