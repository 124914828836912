/*
 * @Author: Ajay 
 * @Date: 2019-02-11 11:37:07 
 * @Project: Ezyprocure
 * @Filename: ezyap.peppol-invoices-summary.service.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-10-24 10:40:07
 */

(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('ExtenalPeppolInvoicesService', ExtenalPeppolInvoicesService);

  ExtenalPeppolInvoicesService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function ExtenalPeppolInvoicesService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    
    var vm = this;
    // vm.peppolInvoiceStatus = [{ data: 'Message acknowledgement', value: 'AB' }, { data: 'Accepted', value: 'AP' }, { data: 'Rejected', value: 'RE' }, { data: 'In process', value: 'IP' }, { data: 'Under query', value: 'UQ' }, { data: 'Conditionally accepted', value: 'CA' }, { data: 'Paid', value: 'PD' }];
    
    var ExtenalPeppolInvoicesService = {
      sendInvoiceById: sendInvoiceById,
      sendAllInvoice: sendAllInvoice,
      updateExportFlag: updateExportFlag,
      updateInvoiceStatus: updateInvoiceStatus,
      getInvoiceStatus: getInvoiceStatus,
      getStatusReason: getStatusReason,
      getStatusAction: getStatusAction
    };
    return ExtenalPeppolInvoicesService;

    /**
     * [send invoice by invoice id]
     * @param {Integer} invoiceId [id of the invoice to be send]
     */
    function sendInvoiceById(invoiceId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/invoices/upload/' + invoiceId,
        method: "POST"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [sendAllInvoice sends all invoices]
     * @param {Integer} invoiceIdList [id of the invoice to be send]
     */
    function sendAllInvoice(invoiceIdList) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/invoices/upload-ap/bulk',
        method: "PUT",
        data: invoiceIdList
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

     /**
     * [updateExportFlag Updates the export flag]
     * @param {List} invoiceIdList [id of the invoice exported]
     */
    function updateExportFlag(invoiceIdList) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/invoices/export',
        method: "PUT",
        data: invoiceIdList
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getInvoiceStatus get invoice status list]
     * @param {Integer} id [id]
     * @param {string} invoiceStatus [invoice status ]
     * 
     */
  function getInvoiceStatus(id, invoiceStatus) {
    var deferred = $q.defer();
    $http({
      url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/invoices/' + id +'/invoice-response-status?statusCode=' + invoiceStatus,
      method: "GET"
    }).then(function (data) {
      deferred.resolve(data);
    }).catch(function (message) {
      deferred.reject(message);
    });
    return deferred.promise;
  }

  /**
     * [getStatusReason according to invoice status code get status reason list]
     * @param {Integer} id [id]
     * @param {string} invoiceStatus [invoice status ]
     * @param {string} invoiceStatusReason [invoice status reason ]
     * 
     * 
     */
  function getStatusReason(id, invoiceStatus, invoiceStatusReason) {
    var deferred = $q.defer();
    $http({
      url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/invoices/' + id +'/invoice-response-status-reason?statusCode=' + invoiceStatus + '&statusReasonCode=' + invoiceStatusReason,
      method: "GET"
    }).then(function (data) {
      deferred.resolve(data);
    }).catch(function (message) {
      deferred.reject(message);
    });
    return deferred.promise;
  }

  /**
     * [getStatusAction according to invoice status code,status reason get status action list]
     * @param {Integer} id [id]
     * @param {string} invoiceStatus [invoice status ]
     * @param {string} invoiceStatusReason [invoice status reason ]
     * @param {string} invoiceStatusAction [invoice status action ]
     * 
     * 
     * 
     */
  function getStatusAction(id, invoiceStatus, invoiceStatusReason, invoiceStatusAction) {
    var deferred = $q.defer();
    $http({
      url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/invoices/' + id +'/invoice-response-status-action?statusCode=' + invoiceStatus + '&statusReasonCode=' + invoiceStatusReason + '&statusActionCode=' + invoiceStatusAction,
      method: "GET"
    }).then(function (data) {
      deferred.resolve(data);
    }).catch(function (message) {
      deferred.reject(message);
    });
    return deferred.promise;
  }

    /**
     * [updateInvoiceStatus Updates invoice status]
     * @param {Integer} invoiceId [id of the invoice ]
     * @param { Array } data [data ]
     * 
     */
  function updateInvoiceStatus(id, data) {
    vm.data = data;
    var deferred = $q.defer();
    $http({
      url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/invoices/' + id +'/invoice-response',
      method: "PUT",
      data: vm.data,
    }).then(function (data) {
      deferred.resolve(data);
    }).catch(function (message) {
      deferred.reject(message);
    });
    return deferred.promise;
  }

  }

})();