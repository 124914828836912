/*
 * @Author: Neethu 
 * @Date: 2023-08-22
 * @Project: Ezyprocure
 * @Filename: ezyap.peppol-orders-summary.controller.js
 */
(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapPeppolOrdersSummaryController', EzyapPeppolOrdersSummaryController);

  EzyapPeppolOrdersSummaryController.$inject = ['$q', 'NotificationService', 'UTCDateService', 'GLOBAL_CONSTANTS', 'EzyapGetPosService', 'paginationConstants', 'pagingParams', 'EXPORT_CONSTANTS', 'CSVExportService', '$state', 'ExtenalPeppolOrdersService', 'Auth'];

  function EzyapPeppolOrdersSummaryController($q, NotificationService, UTCDateService, GLOBAL_CONSTANTS, EzyapGetPosService, paginationConstants, pagingParams, EXPORT_CONSTANTS, CSVExportService, $state, ExtenalPeppolOrdersService, Auth) {

    var vm = this;
    vm.init = init;
    vm.getAllPos = getAllPos;
    vm.init = init;
    vm.todaysDate = new Date();
    vm.type = $state.params.type;
    vm.peppolTransmissionStatus = !vm.type ? GLOBAL_CONSTANTS.PEPPOL_TRANSMISSION_STATUSES: ['RECEIVED', 'SENT', 'FAILED'];
    vm.filter={};
    vm.filter.peppolTransmissionStatus= vm.peppolTransmissionStatus[0];
    vm.filter.fromDate = vm.todaysDate;
    vm.filter.toDate = vm.todaysDate;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.sort = sort;
    vm.selectAllPos = selectAllPos;
    vm.sendAllPos = sendAllPos;
    vm.purchaseOrderCSVHeader = EXPORT_CONSTANTS.CSV.peppolPurchaseOrderCSVHeader;
    vm.getPosCSVData = getPosCSVData;
    vm.getAllSelectedPos=getAllSelectedPos; 
    vm.downloadAllPos=downloadAllPos;
    vm.userRole = Auth.getUserRole();
    vm.updateExportFlag = updateExportFlag;
    vm.getPoIdList = getPoIdList;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function init() {
      vm.getAllPos();
    }

    /**
    * [selectAllPos select all Pending Process Orders]
    */
    function selectAllPos() {
      vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
      _.map(vm.poDetails, function(po) {
        po.selected = vm.selectAll;
      });
    }

    /**
     * [getAllSelectedPos Get all selected pos]
     */
    function getAllSelectedPos(){
      var selectedPos = _.filter(vm.poDetails, function(po) {
        return po.selected === true;
      });
     return selectedPos;
    }
    /**
     * [getSelectedJobs Returns selected Jobs Ids]
     */
    function getselectedPos() {
     var selectedPos = vm.getAllSelectedPos();
     var selectedPoIds =_.map(selectedPos, _.property('id'));
      return selectedPoIds;
    }

    /**
     * [getPosCSVData Downloads CSV]
     */
    function getPosCSVData() {
      var deferred = $q.defer();
      vm.selectedPos = vm.getAllSelectedPos();
      if (vm.poDetails.length == 0) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      else if (vm.selectedPos.length > 0) {
        return CSVExportService.getPeppolPoCSV(vm.selectedPos);
      } else {
        return CSVExportService.getPeppolPoCSV(vm.poDetails);
      }
    }

    /**
     * [send pos which are selected]
     */
    function sendAllPos() {
      var selectedPoIds = getselectedPos();
      if (selectedPoIds.length < 1) {
        NotificationService.simpleErrorToast({
          title: 'global.menu.entities.invoice',
          message: 'global.pleaseSelectedOneFromTheList'
        });
      } else {
        ExtenalPeppolOrdersService.sendAllPo(selectedPoIds)
        .then(function(response){
          vm.getAllPos();
          NotificationService.success({
            title: 'global.sendPurchaseOrder',
            message: 'alertMessage.PURCHASE_ORDER_SEND_SUCCESSFULLY'
          });
        },function(error){
          NotificationService.error({
            title: 'global.sendPurchaseOrder',
            error: error
          });
        });
      }
    }

    /**
     * [get all pos]
     */
    function getAllPos() {
      vm.selectAll=false;
      EzyapGetPosService.getAllPos({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: vm.sort(),
        startDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        endDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        transmissionStatus: !vm.filter.peppolTransmissionStatus ? '' : vm.filter.peppolTransmissionStatus
      }).
        then(function (response) {
          vm.poDetails = response.data;
          vm.totalItems = response.headers('X-Total-Count');
        }, function (error) {
          NotificationService.error({
            title: 'global.menu.entities.invoice',
            error: error
          });
        });
    } 

    /**
     * [updateExportFlag Update the export flag]
     * @param {Idlist} invoiceIdList 
     */
    function updateExportFlag(invoiceIdList){
      ExtenalPeppolOrdersService.updateExportFlag(invoiceIdList)
        .then(function(response){
        },function(error){
      });
    }

    function getPoIdList(selectedPos){
      var invoiceIdList = _.map(selectedPos, _.property('id'));
     return invoiceIdList;
    }

    /**
     * 
     * @param {String} uri [uploaded url]
     * @param {String} fileName [the name of the file downloaded file]
     */
    function downloadAllPos(fileName) {
      vm.selectedPos=vm.getAllSelectedPos();
      if(vm.selectedPos.length>0){
        vm.pos=vm.selectedPos;
        var selectedPoIds = getselectedPos();
        vm.updateExportFlag(selectedPoIds);
        vm.getAllPos();
      }
      else if(vm.poDetails.length>0){
        vm.pos=vm.poDetails;
        var selectedPoIds = getselectedPos();
        vm.updateExportFlag(selectedPoIds);
        vm.getAllPos();
      }
      else{
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });   
      }
      _.forEach(vm.pos,function(pos){
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.download = 'po details_'+pos.id;
        window.open(pos.uploadedUrl,'_blank');
      })
    }
  }
})();
