/**
* @Author: Allan
* @Date:   21-August-2018 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.buyer.dashboard.state.js
* @Last modified by:   Allan
* @Last modified time: 28-August-2018 19:03 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('bnpl.buyer.dashboard', {
      parent: 'bnpl.buyer',
      url: '/dashboard',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/bnpl-modules/buyer-modules/dashboard/bnpl.buyer.dashboard.html',
          controller: 'BnplBuyerDashboardController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('buyerDashboard');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForBnplPayment')){
          
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    })
  }
})();
