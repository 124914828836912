/*
 * @Author: Abhay
 * @Date: 2020-12-18 11:47:49
 * @Filename: ezypayment.admin.nfrnds-account-report-statement.controller.js
 * @Last Modified by: Abhay
 * @Last Modified time: 2020-12-18 11:33:03
 */


(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .controller('ManageBnplEditController', ManageBnplEditController);

    ManageBnplEditController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', 'toastr', 'pagingParams', 'UTCDateService', 'NotificationService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'BuyerBusinessService', 'ManageBuyerService', 'EzypaymentAdminStatementOfAccountService', 'BrandManagementService', 'ManageSupplierService', '$window', 'EXPORT_CONSTANTS', 'CSVExportService', 'EzypaymentAdminManageMerchantDetailsService', '$stateParams','ManageBnplService', 'Auth'];

    function ManageBnplEditController($scope, $rootScope, $q, $filter, $state, toastr, pagingParams, UTCDateService, NotificationService, GLOBAL_CONSTANTS, paginationConstants, BuyerBusinessService, ManageBuyerService, EzypaymentAdminStatementOfAccountService, BrandManagementService, ManageSupplierService, $window, EXPORT_CONSTANTS, CSVExportService, EzypaymentAdminManageMerchantDetailsService, $stateParams, ManageBnplService,Auth) {
        var vm = this;
        vm.bnplId = $stateParams.id;
console.log(this.bnplId);
   

    }
})();