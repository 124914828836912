/*
 * @Author: Ajay 
 * @Date: 2019-02-11 11:38:41 
 * @Project: Ezyprocure
 * @Filename: ezyap.buyer.create-invoice.service.js
 * @Last Modified by: Neethu
 * @Last Modified time: 2023-09-20 12:02:53
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('ezyap.buyer.create-invoice', {
        parent: 'ezyap.buyer',
        url: '/create-invoice',
        data: {
          authorities: ['ROLE_BUYER']
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/ezyap-tab-templates/create-invoice/ezyap.create-invoice.html',
            controller: 'EzyapCreateAdhocInvoiceController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return true;
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('invoice');
            $translatePartialLoader.addPart('purchaseOrder');
            $translatePartialLoader.addPart('items');
            return $translate.refresh();
          }],
          hasModuleAccess: ['Auth', '$q', '$state', function (Auth, $q, $state) {
            if (Auth.hasPermission('accessForPEPPOL') || Auth.hasPermission('accessForOnlyEzyAP')) {
              return true;
            } else {
              return $q.reject('Unauthorized');
            }
          }]
        }
      })
      .state('ezyap.buyer.eInvoice', {
        parent: 'ezyap.buyer',
        url: '/eInvoice/:id',
        data: {
          authorities: ['ROLE_BUYER']
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/ezyap-tab-templates/create-invoice/ezyap.create-invoice.html',
            controller: 'EzyapCreateAdhocInvoiceController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return true;
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('invoice');
            $translatePartialLoader.addPart('purchaseOrder');
            $translatePartialLoader.addPart('items');
            return $translate.refresh();
          }],
          hasModuleAccess: ['Auth', '$q', '$state', function (Auth, $q, $state) {
            if (Auth.hasPermission('accessForPEPPOL') || Auth.hasPermission('accessForOnlyEzyAP')) {
              return true;
            } else {
              return $q.reject('Unauthorized');
            }
          }]
        }
      })
  }
})();