/**
 * @Author: Ajay
 * @Date:   23-Feb-2018 16:35 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.purchase-approval-request.details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-11-21T11:18:12+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerItemsApprovalRequestDetailsController', BuyerItemsApprovalRequestDetailsController);

  BuyerItemsApprovalRequestDetailsController.$inject = ['$scope', '$state', '$stateParams', '$window', 'toastr', 'BuyerPurchaseApprovalRequestService', 'BuyerDataService', 'CartService', 'PromotionService', 'paginationConstants', 'NotificationService','$mdDialog', '$mdMedia', 'Auth', 'PDFExportService','GLOBAL_CONSTANTS','$timeout', '$q'];

  function BuyerItemsApprovalRequestDetailsController($scope, $state, $stateParams, $window, toastr, BuyerPurchaseApprovalRequestService, BuyerDataService, CartService, PromotionService, paginationConstants, NotificationService,$mdDialog, $mdMedia, Auth, PDFExportService,GLOBAL_CONSTANTS, $timeout, $q) {
    var vm = this;
    vm.init = init;
    vm.loadAll = loadAll;
    vm.refreshApprovalStatus = refreshApprovalStatus;
    vm.getSupplierList = getSupplierList;
    vm.getBusinessOutletList = getBusinessOutletList;
    vm.getPurchaserList = getPurchaserList;
    vm.approvePurchaseApprovalRequest = approvePurchaseApprovalRequest;
    vm.rejectPurchaseApprovalRequest = rejectPurchaseApprovalRequest;
    vm.approvalStatus = $stateParams.approvalStatus;
    vm.approvalRequestNumber = $stateParams.approvalRequestNumber;
    vm.getCurrency = getCurrency;
    vm.deletePurchaseApprovalRequestbyId = deletePurchaseApprovalRequestbyId;
    vm.editDetails = editDetails;
    vm.editQuantityDetails = editQuantityDetails;
    vm.editQtyDate = false;
    vm.editRemark=false;
    vm.approvalId = 0;
    vm.cancel=cancel;
    vm.showRejectModal=showRejectModal;
    vm.showDeleteModal=showDeleteModal;
    vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.id;
    vm.buyerInfo = BuyerDataService.getBuyerProfile().buyerUser;
    vm.accessControls = BuyerDataService.getBuyerProfile().accessDTO;
    vm.updatePurchaseApprovalRequestDetails = updatePurchaseApprovalRequestDetails;
    vm.todaysDate = new Date();
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.isMobile = $mdMedia('xs');
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');
    vm.getAllApprovalListById = getAllApprovalListById;
    vm.calculateTotalAmount = calculateTotalAmount;
    vm.haveAccessForWhatsappPo = Auth.hasPermission('accessForWhatsappPo');
    vm.getWhatsappShareDetails = getWhatsappShareDetails;
    vm.showWhatsappConfirmList = showWhatsappConfirmList;
    vm.getIndividualPurchaseOrderPDF = getIndividualPurchaseOrderPDF;
    vm.shareByWhatsapp = shareByWhatsapp;
    vm.redirectAfterShare = redirectAfterShare;
    vm.validateMOQ = validateMOQ;
    vm.validateDeliveryCondition = validateDeliveryCondition;
    vm.setDeliveryCondition = setDeliveryCondition;
    vm.itemId = [];
    vm.visibilityType = undefined;
    vm.navigateToBuyNow = navigateToBuyNow;
    vm.showAlternateSupplierData = showAlternateSupplierData
    vm.showAlternateSupplierPopup = showAlternateSupplierPopup;
    vm.confirmAlternateSupplierTitle = 'Alternate Suppliers';
    vm.chooseAlternateSupplier = chooseAlternateSupplier;
    vm.changeAlternateSupplier = changeAlternateSupplier;
    vm.changeSupplierbtn = true;
    vm.choosebtnclick = false;
    vm.cancelPopUp = cancelPopUp;
    vm.deleteAttachment = deleteAttachment;
    vm.editDescriptionPopup = editDescriptionPopup;
    vm.uploadModal = uploadModal;
    vm.uploadAttachment = uploadAttachment;
    // negative buying
    vm.accessForNegativeBuyingBuyerView = Auth.hasPermission('accessForNegativeBuyingBuyerView');
    vm.isNegativeBuying = isNegativeBuying;
    vm.approvalCatch = [];
    vm.pendingCatch = [];
    vm.rejectedCatch = [];
    vm.deliveryDateValidationCheck = deliveryDateValidationCheck;
    vm.prnoPresentArray = [];
    vm.prnoPresentArrayElements = '';
    vm.getSupplierHolidays = getSupplierHolidays;
    vm.lastselectededitindex = -1;
    vm.updatePurchaseApprovalRequestDetailssave = updatePurchaseApprovalRequestDetailssave;
    vm.disableDays = disabledays;
    vm.isSupplier = isSupplier;
    vm.myPrApprovalStatus = false;
    function isNegativeBuying(quantity) {
      return vm.accessForNegativeBuyingBuyerView && (Math.sign(quantity) == -1 || Math.sign(quantity) == 0);
    }

    function navigateToBuyNow() {
      var purchaseOrderType = '';
      if (Auth.hasPermission('accessForGlModule')) {
        if (vm.itemApprovalRequests && vm.itemApprovalRequests.length > 0) {
          var firstItem = vm.itemApprovalRequests[0];
          if (firstItem.glCodeMappings && firstItem.glCodeMappings.purchaseOrderTypes) {
            purchaseOrderType = firstItem.glCodeMappings.purchaseOrderTypes.id;
          }
        }
      }

      $state.go('buyer.buy-now', {
        approvalRequestNumber: vm.approvalRequestNumber,
        businessOutletId: vm.businessOutletList[0].id,
        supplierId: vm.supplierList.length == 1 ?  vm.supplierList[0].id : 0,
        fromApproval: 'fromApproval',
        visibilityType: vm.visibilityType,
        PurchaseOrderType:  purchaseOrderType
      });
    }

    // To refresh ApprovalStatus if user hits old url*
    function refreshApprovalStatus(newApprovalStatus) {
      if(vm.approvalStatus === 'myPr'){
        vm.myPrApprovalStatus = true;
        vm.disableApprovalActionbtns = true;
      }else{
        vm.myPrApprovalStatus = false;
      }
      newApprovalStatus = newApprovalStatus.toLowerCase();
      if (newApprovalStatus !== vm.approvalStatus && !vm.myPrApprovalStatus) {
        vm.approvalStatus = newApprovalStatus;
        $state.go('buyer.purchase-approval-request.details', {
          approvalStatus: vm.approvalStatus,
          approvalRequestNumber: vm.approvalRequestNumber
        }, {
          reload: false
        });
      }
      if(vm.myPrApprovalStatus){ 
        vm.approvalStatus = 'pending';
        $state.go('buyer.purchase-approval-request.details', {
          approvalStatus: 'myPr',
          approvalRequestNumber: vm.approvalRequestNumber
        }, {
          reload: false
        });
      }
    }

    /*
    *[uploadModal ---- file upload popup]
    */
    function uploadModal(){
        $mdDialog.show({
          templateUrl: 'app/buyer-modules/cart-checkout/buyer.cart-upload-attachment.html',
          controller: function() {
            return vm;
          },
          controllerAs: 'vm',
          targetEvent: event,
          clickOutsideToClose: true
        });
      }

      /*
    *[uploadAttachment --- uploaded selected files to s3 and get the link]
    */
    function uploadAttachment(){
        var base64Array = [];
        var files = vm.files;
        $mdDialog.cancel();
        files.forEach( function(each){
          var deferred = $q.defer();
          CartService.getBase64(each.lfFile, function(base64file) {
            base64Array.push ( {
              extension :  each.lfFile.name.split('.').pop(),
              base64String :  base64file,
              fileName : each.lfFile.name.split('.').shift()
            });
            deferred.resolve(base64file);
          });
          files.push(deferred.promise);
        });
        $q.all(files).then(function(){
          CartService.fileUpload(base64Array).then(function(response){
            NotificationService.success({
              title: 'UPLOAD ATTACHMENT',
              message: 'alertMessage.UPLOADED_SUCCESSFULLY'
            });
            if(!vm.urls) {
                vm.urls = [];
            }
            angular.forEach(response.data.urls, function(url) {
                vm.urls.push(url);
            });
          });
        });
      }

    function deleteAttachment(index) {
        vm.urls.splice(index,1);
    }
    function getBusinessOutletList(itemApprovalRequests) {
      var outletList;
      //Pick all outlet array property from list of itemApprovalRequests
      var result1 = _.map(itemApprovalRequests, _.property('buyerBusiness'));
      outletList = _.uniqBy(result1, 'id');
      return outletList;
    }

    function getSupplierList(itemApprovalRequests) {
      var supplierList;
      //Pick all supplier array property from list of itemApprovalRequests
      var result1 = _.map(itemApprovalRequests, _.property('items.supplier'));
      supplierList = _.uniqBy(result1, 'id');
      return supplierList;
    }
    /**
     *
     * @param {returns name of purchasers from itemApprovalRequests} itemApprovalRequests
     */
    function getPurchaserList(itemApprovalRequests) {
      var purchaserList = [];
      var result1 = [];
      //Pick all purchaser array property from list of itemApprovalRequests
     _.map(itemApprovalRequests, function(itemRequest){
      result1.push(itemRequest.buyerUser.user.firstName +' '+ itemRequest.buyerUser.user.lastName);
      purchaserList = _.uniq(result1);
    })
      return purchaserList;
    }

    function init() {
      vm.loadAll();
      vm.getAllApprovalListById();
    }

    //Get Item Approval Request details by approval Request Number
    function loadAll() {
      BuyerPurchaseApprovalRequestService.getPurchaseApprovalRequest(vm.approvalRequestNumber, {
        page: vm.query.page - 1,
        size: vm.query.limit
      }).then(function(response) {
        vm.businessOutletList = vm.getBusinessOutletList(response.data);
        vm.visibilityType = response.data[0].items.supplier.visibilityType;
        vm.supplierList = vm.getSupplierList(response.data);
        vm.purchasers = vm.getPurchaserList(response.data);
        vm.itemApprovalRequests = response.data;
        orderItemsById(vm.itemApprovalRequests);
        vm.orderDescription = vm.itemApprovalRequests[0].orderDescription;
        if(vm.itemApprovalRequests[0].attachmentLink != null)
           vm.urls = vm.itemApprovalRequests[0].attachmentLink.split(',');
        //vm.refreshApprovalStatus(response.data[0].approvalStatus);
        vm.supplierId = vm.itemApprovalRequests[0].supplierId;
        if(vm.itemApprovalRequests[0].adhocStatus !== "ADHOC_PO_OFF_BOARD"){
          vm.groupedItemApprovalRequests = groupBySupplier(vm.itemApprovalRequests);
        }
        else{
          vm.groupedItemApprovalRequests = vm.itemApprovalRequests;
        }
      }, function(error) {
      });
    }

    vm.total=total;
    vm.totalTemp = 0;
    function total(quantity,price,freeItem,moqPrice,moq){
      if(freeItem !== true && !moqPrice){
        vm.totalTemp = quantity*price;
      }else if(!freeItem && moqPrice ==true){
        vm.totalTemp = (quantity/moq)*price;
      }else{
        vm.totalTemp=0;
      }
      return vm.totalTemp;
    }

    //calculate the total amount
    function calculateTotalAmount(purchaseApprovalRequestDTO){
      vm.subTotal =0;
      _.forEach(purchaseApprovalRequestDTO,function(purchaseApprovalRequestItem){
        if(purchaseApprovalRequestItem.freeItem !== true && !purchaseApprovalRequestItem.items.uom.moqPrice){
          vm.tempTotal = _.round(purchaseApprovalRequestItem.quantity*purchaseApprovalRequestItem.price,2);
        }else if(!purchaseApprovalRequestItem.freeItem && purchaseApprovalRequestItem.items.uom.moqPrice ==true){
          vm.tempTotal = _.round((purchaseApprovalRequestItem.quantity/purchaseApprovalRequestItem.items.moq)*purchaseApprovalRequestItem.price,2);
        }else{
          vm.tempTotal=0;
        }
        vm.subTotal = _.round(vm.subTotal + vm.tempTotal,2);
      });

      return vm.subTotal;
    }

    function getCurrency(itemApprovalRequests) {
      for(var i = 0; i < itemApprovalRequests.length; i++){
        var product = itemApprovalRequests[i];
        if (!product.freeItem)
        {
          vm.currencytodisplay = ' '+ product.items.currency;
        }
      }
      return vm.currencytodisplay;
    }

    function groupBySupplier(xs) {
      return xs.reduce(function(rv, x) {
        (rv[x['items']['supplier']['companyName']] = rv[x['items']['supplier']['companyName']] || []).push(x);
        return rv;
      }, {});
    }

    //Approve Item Approval Request to Accepted/Rejected by approval Request Number
    function approvePurchaseApprovalRequest(approvalRequestNumber) {
      var idList = [];
      _.forEach(vm.approverList, function(itm) {
        idList = idList.concat(itm.approvalRequestIds)
      });

      // API Call to check the delivery condition
      BuyerPurchaseApprovalRequestService.getDeliveryConditionsForPRApproval(approvalRequestNumber).then(function(response) {
        if(response.data){
            var now         = new Date();
            var hours       = now.getHours();
            var minutes     = now.getMinutes();
            var timetoCheck = ('0000' + (hours * 100 + minutes)).slice(-4);
            var timeapprove = (response.data[0].buyerDeliveryConditions!=null) ? response.data[0].buyerDeliveryConditions.orderTimeForToday : response.data[0].supplierDeliveryCondition.orderTimeForToday;

            vm.validTomorrowDeliveryDateCheckArray = [];
            if(timeapprove){
            _.map(vm.itemApprovalRequests, function(itemRequest){
                var localDeliveryDate = moment(itemRequest.deliveryDate).local(true).format("YYYY-MM-DD HH:mm:ss");
                var localTodaysDate = moment(vm.todaysDate).local(true).format("YYYY-MM-DD HH:mm:ss");
                if(moment(localDeliveryDate,"YYYY-MM-DD").diff(moment(localTodaysDate,"YYYY-MM-DD"),'days') <= 1){
                    vm.validTomorrowDeliveryDateCheckArray.push(itemRequest.deliveryDate);
                }
            });
            }
            if((timeapprove && timetoCheck > timeapprove) && vm.validTomorrowDeliveryDateCheckArray.length > 0){
                NotificationService.error({
                    title: 'Item Cannot approve after '+timeapprove,
                    error: {
                        data:{
                            approvalerror:"cannot approve"
                        }
                    }
                  });
            }
            else{
                BuyerPurchaseApprovalRequestService.validateApprovalRequest({ 'itemApprovalRequestNos': [approvalRequestNumber]}).then(function (response) {
                    var resData = _.uniq(Object.values(response.data));
                    if (resData.length == 1 && resData[0] == null) {
                        approvePurchaseApprovalRequestByapprovalRequestNumber(approvalRequestNumber, idList);
                    }
                    else if (resData.length >= 1 && !_.includes(resData, null)) {
                    NotificationService.simpleErrorToast({
                        title: 'global.purchaseApprovalRequest',
                        message: 'alertMessage.SELECTED_APPROVAL_REQUESTS_APPROVED_ALREADY'
                    });
                    vm.cancel();
                    //return false;
                    }
                    else {
                        if (_.includes(resData, null)) {
                        approvePurchaseApprovalRequestByapprovalRequestNumber(approvalRequestNumber, idList);
                        }
                        else{
                            NotificationService.simpleErrorToast({
                                title: 'global.purchaseApprovalRequest',
                                message: 'alertMessage.SELECTED_APPROVAL_REQUESTS_APPROVED_ALREADY'
                            });
                            vm.cancel();
                           // return false;
                        }
                    }
                });

            }
        }
        else{

            BuyerPurchaseApprovalRequestService.validateApprovalRequest({ 'itemApprovalRequestNos': [approvalRequestNumber]}).then(function (response) {
                var resData = _.uniq(Object.values(response.data));
                if (resData.length == 1 && resData[0] == null) {
                    approvePurchaseApprovalRequestByapprovalRequestNumber(approvalRequestNumber, idList);
                }
                else if (resData.length >= 1 && !_.includes(resData, null)) {
                    NotificationService.simpleErrorToast({
                        title: 'global.purchaseApprovalRequest',
                        message: 'alertMessage.SELECTED_APPROVAL_REQUESTS_APPROVED_ALREADY'
                    });
                    vm.cancel();
                    //return false;
                    }
                    else {
                        if (_.includes(resData, null)) {
                        approvePurchaseApprovalRequestByapprovalRequestNumber(approvalRequestNumber, idList);
                        }
                        else{
                            NotificationService.simpleErrorToast({
                                title: 'global.purchaseApprovalRequest',
                                message: 'alertMessage.SELECTED_APPROVAL_REQUESTS_APPROVED_ALREADY'
                            });
                            vm.cancel();
                           // return false;
                        }
                    }
            })
        }
      }, function(error) {
        NotificationService.error({
            title: 'Item Approval Request Approving',
            error: error
          });
      });

    }

    // create separate function for approval

    function approvePurchaseApprovalRequestByapprovalRequestNumber(approvalRequestNumber, idList){
        BuyerPurchaseApprovalRequestService.approvePurchaseApprovalRequest(approvalRequestNumber, idList).then(function(response) {
            NotificationService.success({
              title: 'Item Approval Request',
              message: 'Approved successfully'
            });
            // if supplier is adhoc then get details to be shared on whatsapp or else redirect back to pending purchase order for approval
            if(vm.itemApprovalRequests[0].items.supplier.visibilityType == 'ADHOC'){
              vm.getWhatsappShareDetails(response.data)
            } else{
              $state.go('buyer.purchase-approval-request',{
                'approvalStatus': 'pending'
              },
              {reload: true});
            }
          }, function(error) {
            NotificationService.error({
              title: 'Item Approval Request Approving',
              error: error
            });
          });
    }

    //Reject Item Approval Request to Accepted/Rejected by approval Request Number
    function rejectPurchaseApprovalRequest(approvalRequestNumber,prRemark) {
      BuyerPurchaseApprovalRequestService.rejectPurchaseApprovalRequest(approvalRequestNumber,{'prRemark':prRemark}).then(function(response) {
        NotificationService.success({
          title: 'Item Approval Request',
          message: 'alertMessage.REJECTED_SUCCESSFULLY'
        });
        vm.cancel();
        $state.go('buyer.purchase-approval-request', {reload: false});
      }, function(error) {
        NotificationService.error({
          title: 'Item Approval Request Rejection',
          error: error
        });
      });
    }

    function deletePurchaseApprovalRequestbyId(approvalId, itemId, createdDate,prRemark) {
     getPromotionItemsForBuyerByPromotionItemId(itemId, createdDate);
     BuyerPurchaseApprovalRequestService.deletePurchaseApprovalRequestbyId(approvalId,{'prRemark':prRemark}).
      then(function(response) {
        NotificationService.success({
          title: 'Purchase Approval',
          message: 'Removed successfully from purchase approval!'
        });
        vm.cancel();
        vm.loadAll();
        if(vm.promotions !== null && vm.promotions !== "")
        {
          deleteCorrespondingFreeItem();
        }
        $window.location.reload();
      }, function(error) {
        NotificationService.error({
          title: 'Purchase Approval',
          error: error
        });
      });
    }

    function editDetails(approvalId,supplierData,form)
    {
      vm.editQtyDate = true;
      vm.editRemark=true;
      vm.approvalId = approvalId;
      vm.getSupplierHolidays(supplierData.id, supplierData); 
      form.$submitted =  true;
    }

    function getPromotionItemsForBuyerByPromotionItemId(itemId, createdDate) {
      vm.searchText = '';
      PromotionService.getPromotionItemsForBuyerByPromotionItemId(itemId, {
        page: vm.query.page - 1,
        size: vm.query.limit,
        searchTerm: vm.searchText,
        date: createdDate
      }).then(function(response) {
        vm.promotions = response.data;
      }, function(error) {
      });
    }

    //Show Modal for entering the reason for Reject PR
    function showRejectModal(approvalRequestNumber) {
      vm.approvalNumber=approvalRequestNumber;
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/purchase-approval-request/details/buyer.purchase-approval-request-reject-tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }


    //Show Modal for entering the reason for deleting item from PR
    function showDeleteModal(id,itemId,createdDate) {
      vm.id=id;
      vm.itemId=itemId;
      vm.createdDate=createdDate;
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/purchase-approval-request/details/buyer.purchase-approval-delete.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }


    function cancel() {
      $mdDialog.cancel();
   }

    function deleteCorrespondingFreeItem() {
      var approvalDetails = angular.copy(vm.itemApprovalRequests);
      var approvalId = 0;
      _.forEach(approvalDetails, function(approvalDetail) {
        if (vm.promotions[0].freeItem.id === approvalDetail.items.id && approvalDetail.freeItem === true) {
          approvalId = approvalDetail.id;
        }
      });
      if(approvalId !== 0)
      {
        BuyerPurchaseApprovalRequestService.deletePurchaseApprovalRequestbyId(approvalId,{'prRemark':prRemark}).
        then(function(response) {
          NotificationService.success({
            title: 'Purchase Approval',
            message: 'Removed successfully from purchase approval!'
          });
        }, function(error) {
        });
      }
    }

    function editQuantityDetails(approvalId, itemId, createdDate, quantity, deliveryDate) {
      vm.itemId.push(itemId);
      vm.deliveryDateChanged = true;
      PromotionService.getPromotionItemsForBuyerByPromotionItemId(itemId, {
        page: vm.query.page - 1,
        size: vm.query.limit,
        searchTerm: vm.searchText,
        date: createdDate
      }).then(function(response) {
        vm.promotions = response.data;
        freeItemEdit(quantity, deliveryDate);
      }, function(error) {
      });
    }

    function freeItemEdit(quantity, deliveryDate)
    {
      for(var i=0;i<vm.itemApprovalRequests.length;i++){
        if(Array.isArray(vm.promotions) && vm.promotions.length > 0)
        {
          if (vm.promotions[0].freeItem.id === vm.itemApprovalRequests[i].items.id && vm.itemApprovalRequests[i].freeItem === true) {
            if(vm.promotions[0].promotionItem.id === vm.itemApprovalRequests[i-1].items.id)
            {
              var qtyValue = Math.floor((quantity / vm.promotions[0].applicablePromotionQuantity));
              if(qtyValue < 1 && isNaN(qtyValue))
              {
                vm.itemApprovalRequests[i].quantity = 0;
              }
              else
              {
                vm.itemApprovalRequests[i].quantity = qtyValue * vm.promotions[0].freeQuantity;
              }
              vm.itemApprovalRequests[i].deliveryDate = deliveryDate;
            }
          }
        }
      }
    }

    // validate delivery conditions
    function validateDeliveryCondition(){
       _.forEach(_.uniq(vm.itemId), function(itemId){
          _.forEach(vm.itemApprovalRequests, function(req){
            if(req.items.id == itemId){
              vm.setDeliveryCondition(req);  
              vm.itemListQuery = req.buyerBusiness.id; 
              if((CartService.checkLeadTime(req)) && (CartService.checkOrderedTime(req, vm.itemListQuery))) {
                vm.isValidate = true;
              }
              else{
                vm.isValidate = false;
                return false;
              }
            }
          });
        });
       return vm.isValidate;
    }

    // set default delivery condition
    function setDeliveryCondition(req){
      req.items.supplier.deliveryCondition = {
        orderTimeForToday: req.items.supplier.orderTimeForToday,
        orderTimeForFriday: req.items.supplier.orderTimeForFriday,
        orderTimeForSaturday: req.items.supplier.orderTimeForSaturday,
        noDeliveryOnHolidays: req.items.supplier.noDeliveryOnHolidays,
        noDeliveryOnSaturday: req.items.supplier.noDeliveryOnSaturday,
        noDeliveryOnSunday: req.items.supplier.noDeliveryOnSunday,
        noDeliveryOnMonday: req.items.supplier.noDeliveryOnMonday,
        minimumOrderForFreeDelivery: req.items.supplier.minimumOrderForFreeDelivery,
        deliveryCharge: req.items.supplier.deliveryCharge,
        belowMinimumPurchaseCondition: req.items.supplier.belowMinimumPurchaseCondition,
        noSameDayDelivery: req.items.supplier.noSameDayDelivery,
        leadTime: req.items.supplier.leadTime,
        noDeliveryOnTuesday: req.items.supplier.noDeliveryOnTuesday,
        noDeliveryOnWednesday: req.items.supplier.noDeliveryOnWednesday,
        noDeliveryOnThursday: req.items.supplier.noDeliveryOnThursday,
        noDeliveryOnFriday: req.items.supplier.noDeliveryOnFriday
      }
    }

    function updatePurchaseApprovalRequestDetailssave() {
      vm.itemApprovalRequests[vm.lastselectededitindex]['orderDescription'] = vm.orderDescription;
      $mdDialog.cancel();
    }

    function updatePurchaseApprovalRequestDetails(){
      if(!vm.itemApprovalRequests[0].attachmentLink && vm.urls && vm.urls.length > 0) {
        vm.itemApprovalRequests[0].attachmentLink = vm.urls.toString();
      } else if(vm.itemApprovalRequests[0].attachmentLink && vm.urls && (vm.itemApprovalRequests[0].attachmentLink.toString() !== vm.urls.toString())) {
        vm.itemApprovalRequests[0].attachmentLink = vm.urls.toString();
      }
      if(vm.urls && vm.urls.length === 0) {
        vm.itemApprovalRequests[0].attachmentLink = null;
      }
      var itemApprovalRequests = {
        itemApprovalRequests : vm.itemApprovalRequests
      };
      if(vm.deliveryDateChanged){
        if(!vm.validateDeliveryCondition()){
          return false;
        }
      }
      BuyerPurchaseApprovalRequestService.updatePurchaseApprovalRequestDetails(itemApprovalRequests).then(function(response){
        NotificationService.success({
          title: 'Item Approval Request Details',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        $mdDialog.cancel();
        $window.location.reload();
      }, function(error) {
        NotificationService.error({
          title: 'Item Approval Request Details Updation',
          error: error
        });
      });
    }

    //get approver list
    function getAllApprovalListById(){
      vm.disableApprovalActionbtns = false;
      BuyerPurchaseApprovalRequestService.getAllApprovalListById(vm.approvalRequestNumber)
      .then(function(response) {
          vm.approverList = response.data;
          vm.approverList.forEach(function(approvalStatus){

            //  check wheather logged in user is an approver anfd if he approved
            if(approvalStatus.itemApprovalStatus == GLOBAL_CONSTANTS.approvalStatus.approved && vm.buyerId === approvalStatus.approvalNeeded){
                vm.disableApprovalActionbtns = true;
            }

            if(approvalStatus.itemApprovalStatus == GLOBAL_CONSTANTS.approvalStatus.approved){
                vm.approvalCatch.push(approvalStatus.itemApprovalStatus)
            }
            else if(approvalStatus.itemApprovalStatus == GLOBAL_CONSTANTS.approvalStatus.pending){
                vm.pendingCatch.push(approvalStatus.itemApprovalStatus)
            }
            else if(approvalStatus.itemApprovalStatus == GLOBAL_CONSTANTS.approvalStatus.rejected){
                vm.rejectedCatch.push(approvalStatus.itemApprovalStatus)
            }
          })
          if( vm.approverList.length == vm.approvalCatch.length){
              vm.refreshApprovalStatus(vm.approvalCatch[0]);
          }
          else if(vm.approverList.length == vm.pendingCatch.length){
            vm.refreshApprovalStatus(vm.pendingCatch[0]);
          }
          else if(vm.approverList.length == vm.rejectedCatch.length){
            vm.refreshApprovalStatus(vm.rejectedCatch[0]);
          }
          else if(vm.pendingCatch.length > 0 && vm.rejectedCatch.length > 0){
            vm.refreshApprovalStatus(vm.rejectedCatch[0]);
          }
        else if( vm.pendingCatch.length > 0 && vm.approvalCatch.length > 0){
            vm.refreshApprovalStatus(vm.pendingCatch[0]);
        }
        else if( vm.rejectedCatch.length > 0 && vm.approvalCatch.length > 0){
            vm.refreshApprovalStatus(vm.rejectedCatch[0]);
        }
        }, function(error) {
      });
     }

     /**
     * [getWhatsappShareDetails get PO details for whatsapp share]
     * @param  {[type]} data [description]
     * @return {[type]}      [description]
     */
     function getWhatsappShareDetails(data){
       vm.approvalStatus = $stateParams.approvalStatus;
       vm.purchaseOrderList = data.data;
       if(vm.purchaseOrderList){
         if(vm.purchaseOrderList[0].processOrder.items.supplier.visibilityType == 'ADHOC'){
           vm.adhocSupplierList = _.uniqBy(_.map(vm.purchaseOrderList, function(adhocPOItems){
             return {
               'supplier': adhocPOItems.processOrder.items.supplier.companyName,
               'poNumber': adhocPOItems.purchaseOrderNumber
             }
           }), 'supplier');
           if(vm.haveAccessForWhatsappPo && vm.purchaseOrderList) {
             vm.showWhatsappConfirmList();
           }
           else {
             $state.go('buyer.purchase-approval-request',{
               'approvalStatus': 'pending'
             },
             {reload: false});
           }
         }
         if(vm.purchaseOrderList[0].processOrder.items.supplier.visibilityType == 'ALLOCATED'){
             $state.go('buyer.purchase-approval-request',{
               'approvalStatus': 'pending'
             },
             {reload: false});
         }
       }
       $state.go('buyer.purchase-approval-request',{
          'approvalStatus': 'pending'
        },
        {reload: false});
     }


     /**
     * [showWhatsappConfirmList modal for supplier to whom PO details is to be shared]
     * @param  {[type]} event [descriptiomodal event]
     */
     function showWhatsappConfirmList(event) {
       $mdDialog.show({
         templateUrl: 'app/core/common-templates/whatsapp-pdf-share.tmpl.html',
         controller: function() {
           return vm;
         },
         controllerAs: 'vm',
         targetEvent: event,
         clickOutsideToClose: false
       });
     }

     /**
     * [getIndividualPurchaseOrderPDF get the pdf for individual purchase order details]
     * @param  {[type]} poNumber [po number]
     */
     function getIndividualPurchaseOrderPDF(poNumber) {
       PDFExportService.getPurchaseOrderPDF(poNumber).then(function(response){
         vm.shareByWhatsapp(response.data.message, poNumber);
       },function(error) {
         NotificationService.error({
           title: 'Purchase Order PDF Export',
           error: error
         });
       });
     }

     /**
     * [shareByWhatsapp share PO details via whatsapp]
     * @param  {[type]} pdfLink  [pdf link]
     * @param  {[type]} poNumber [po number]
     */
     function shareByWhatsapp(pdfLink, poNumber){
       var itemNames1 = _.map(vm.purchaseOrderList, function(adhocPOItems){
         if(adhocPOItems.purchaseOrderNumber === poNumber){
           vm.deliveryDate =  moment(adhocPOItems.processOrder.deliveryDate).format("DD-MM-YYYY");
           return adhocPOItems.buyerAcceptedQuantity+' X ('+adhocPOItems.processOrder.items.uom.name+') '+ adhocPOItems.processOrder.items.itemName +'%0D%0ADelivery Date: '+vm.deliveryDate+'%0D%0A';
         }
       });
       var items =_.join(itemNames1,'%0D%0A');
       vm.purchaseDate =  moment(vm.todaysDate).format("DD-MM-YYYY");

       if(vm.isMobile){
         var whatsappShareUrlMobile= "whatsapp://send?text=*PURCHASE ORDER*%0D%0A%0D%0ABranch Name: "+
         (vm.purchaseOrderList[0].processOrder.buyerBusiness.businessOutletName? vm.purchaseOrderList[0].processOrder.buyerBusiness.businessOutletName: "NA") +
         "%0D%0ADelivery Address: " +
         "%0D%0A"+(vm.purchaseOrderList[0].processOrder.buyerBusiness.businessOutletName? vm.purchaseOrderList[0].processOrder.buyerBusiness.businessOutletName: "NA") +
         (vm.purchaseOrderList[0].processOrder.buyerBusiness.shippingAddress?(", "+ vm.purchaseOrderList[0].processOrder.buyerBusiness.shippingAddress) : "")+
         "%0D%0A"+(vm.purchaseOrderList[0].processOrder.buyerBusiness.city? vm.purchaseOrderList[0].processOrder.buyerBusiness.city: "")+
         (vm.purchaseOrderList[0].processOrder.buyerBusiness.postalCode? (", " + vm.purchaseOrderList[0].processOrder.buyerBusiness.postalCode): "")+
         "%0D%0APO Date: "+ vm.purchaseDate +
         "%0D%0APO Number: "+ poNumber +
         "%0D%0A%0D%0AProducts:%0D%0A"+items+'%0D%0A'+pdfLink;
         var mobileLink = document.createElement("a");
         document.body.appendChild(mobileLink);
         mobileLink.download = "name";
         mobileLink.href = whatsappShareUrlMobile;
         mobileLink.click();
       }

       else{
         window.open("https://web.whatsapp.com/send?text=*PURCHASE ORDER*%0D%0A%0D%0ABranch Name: "+
         (vm.purchaseOrderList[0].processOrder.buyerBusiness.businessOutletName? vm.purchaseOrderList[0].processOrder.buyerBusiness.businessOutletName: "NA") +
         "%0D%0ADelivery Address: " +
         "%0D%0A"+(vm.purchaseOrderList[0].processOrder.buyerBusiness.businessOutletName? vm.purchaseOrderList[0].processOrder.buyerBusiness.businessOutletName: "NA")+
         (vm.purchaseOrderList[0].processOrder.buyerBusiness.shippingAddress? (", "+ vm.purchaseOrderList[0].processOrder.buyerBusiness.shippingAddress) : "")+
         "%0D%0A"+(vm.purchaseOrderList[0].processOrder.buyerBusiness.city? vm.purchaseOrderList[0].processOrder.buyerBusiness.city: "")+
         (vm.purchaseOrderList[0].processOrder.buyerBusiness.postalCode? (", " + vm.purchaseOrderList[0].processOrder.buyerBusiness.postalCode): "")+
         "%0D%0APO Date: "+ vm.purchaseDate +
         "%0D%0APO Number: "+ poNumber +
         "%0D%0A%0D%0AProducts:%0D%0A"+items+'%0D%0A'+pdfLink);
       }
     }

     /**
     * [redirectAfterShare redirect to dashboard page after sharing PO via whatsapp]
     */
     function redirectAfterShare(){
       $mdDialog.cancel();
       $state.go('buyer.purchase-approval-request',{
         'approvalStatus': 'pending'
       },
       {reload: false});
     }

     /**
     * [validateMOQ Validates the MOQ with Multiplier]
     */
      function validateMOQ(multiplierFactor, quantity, item, index){
        if(item.uom.weightRequired){
          if(!multiplierFactor){
            item.multiplierFactor = 0.001
          }
          var splitQty = quantity.toString().split(".")[1];
          if(splitQty){
            var len = splitQty.length || 0;
            if(len > 3){
              $scope.itemApprovalRequestForm['quantity_'+index].$setTouched();
              $scope.itemApprovalRequestForm['quantity_'+index].$setValidity("max-precision", false);
              return ;
            }
          }
        }
        else {
          if(!multiplierFactor){
            item.multiplierFactor = 1;
          }
          var splitQty = quantity.toString().split(".")[1];
          if(splitQty){
            var len = splitQty.length || 0;
            if(len >= 3){
              $scope.itemApprovalRequestForm['quantity_'+index].$setTouched();
              $scope.itemApprovalRequestForm['quantity_'+index].$setValidity("max-precision", false);
              return ;
            }
          }
        }
        if(multiplierFactor > 0 && quantity){
          if (((quantity*1000).toFixed(2) % (multiplierFactor*1000).toFixed(2)) != 0) {
            $scope.itemApprovalRequestForm['quantity_'+index].$setTouched();
            $scope.itemApprovalRequestForm['quantity_'+index].$setValidity("moq", false);
          }else{
            $scope.itemApprovalRequestForm['quantity_'+index].$setValidity("moq", true);
          }
        }else
          $scope.itemApprovalRequestForm['quantity_'+index].$setValidity("moq", true);
      }

    /**
    * show alternate supplier pop up
    */
   function showAlternateSupplierPopup(itemsId,outletId,supplierId,itemApprovalRequestId){
       //  Show alternate supplier pop up
      vm.choosebtnclick = false;
      vm.itemApprovalRequestId = itemApprovalRequestId;
      vm.alternateSupplierItemData = [];
      vm.alternateSupplierItemParams = {
           "itemsId":itemsId,
           "outletId":outletId,
           "supplierId":supplierId,
           "itemApprovalRequestId":itemApprovalRequestId
       }
       BuyerPurchaseApprovalRequestService.getAlternateSupplierView(vm.alternateSupplierItemParams).then(function(response) {
        vm.alternateSupplierItemData = response.data;
        vm.showAlternateSupplierData();
       }, function(error) {
        NotificationService.error({
            error: error,
          })
      });
   }

   // showing alternate supplier popup
   function showAlternateSupplierData(event) {
    vm.changeSupplierbtn  = true;
    $mdDialog.show({
      templateUrl: 'app/core/common-templates/alternate-supplier-view.html',
      controller: function() {
        return vm
      },
      controllerAs: 'vm',
      targetEvent: event,
      clickOutsideToClose: true
    });
  }

  function chooseAlternateSupplier(alternateItemId, alternateSupplierId, itemId, supplierId){
    vm.choosebtnclick = true;
    vm.changeSupplierbtn = false;
    vm.changeSupplierDTO = {
        "alternateItemId" : alternateItemId,
        "alternateSupplierId" : alternateSupplierId,
        "approvalRequestId" : vm.itemApprovalRequestId,
        "itemId" : itemId,
        "supplierId" : supplierId
    }
    BuyerPurchaseApprovalRequestService.chooseAlternateSupplierView(vm.changeSupplierDTO).then(function(response) {
        vm.chooseAlternateSupplierItemData = response.data;
        vm.changeSupplierDTO.alternatePrice = vm.chooseAlternateSupplierItemData.alternatePrice;
       }, function(error) {
        vm.chooseAlternateSupplierItemData = [];
        NotificationService.error({
            error: error,
          })
      });
  }

  function changeAlternateSupplier(){
    BuyerPurchaseApprovalRequestService.changeAlternateSupplier(vm.changeSupplierDTO).then(function(response) {
        vm.changeAlternateSupplierItemData = response.data;
        $mdDialog.hide();
        vm.init();
       }, function(error) {
           NotificationService.error({
            error: error,
          })
      });
  }

  function cancelPopUp(){
    $mdDialog.hide();
  }

  function editDescriptionPopup(index,orderDetail) {
    vm.lastselectededitindex = index;
    vm.orderDescription = orderDetail;
    $mdDialog.show({
      templateUrl: 'app/core/common-templates/po-order-description.html',
      controller: function () {
        return vm;
      },
      controllerAs: 'vm',
      targetEvent: event,
      clickOutsideToClose: false
    });
  }

  //   sorting items for same order on cart
    function orderItemsById(itemsList) {
      if (Array.isArray(itemsList)) {
        return itemsList.sort(function (a, b) {
          return a.id - b.id;
        });
      }
      else {
        return [];
      }
    }

    // Delivery Date Check, if invalid date will set the previous date

    function deliveryDateValidationCheck(itemsID,oldDeliveryDate){
        if(!vm.validateDeliveryCondition()){
            vm.deliveryDateBeforeChange = new Date(oldDeliveryDate);
            var index = vm.itemApprovalRequests.findIndex(function(el) {
                return el.items.id == itemsID;
            });
            vm.itemApprovalRequests[index].deliveryDate = vm.deliveryDateBeforeChange;
            return false;
          }
    }

    function getSupplierHolidays(supplierId, supplier){
        CartService.getAllSupplierCalendarsHoliday(supplierId).then(function(response){         
          vm.holidaysList = response.data;
          supplier.holidaylist = vm.holidaysList;           
      })   
    }

    function orderItemsByApprovalItems(itemsList) {
      if (Array.isArray(itemsList)) {
        return itemsList.sort(function (a, b) {
          return a.approvalLevel - b.approvalLevel;
        });
      }
      else {
        return [];
      }
    }
	
    function isSupplier(itemToBuy) {
      if (itemToBuy.items || itemToBuy.supplier) {
        var itemSupplier = itemToBuy.items ? (itemToBuy.items.supplier ? itemToBuy.items.supplier : null) : (itemToBuy.supplier ? itemToBuy.supplier : null);
        return itemSupplier;
      }
    }

    function disabledays(date) {
      var itemSupplier, noDeliveryDays = [], deliveryCond;
      const day = date.getDay();
      _.forEach(vm.itemApprovalRequests, function (req) {
        itemSupplier = isSupplier(req);
        deliveryCond = req.buyerDeliveryConditions ? req.buyerDeliveryConditions.deliveryConditions : itemSupplier; // get specific delivery condition, otherwise get supplier default conditions

      });
      if (deliveryCond.noDeliveryOnSaturday) noDeliveryDays.push(6);
      if (deliveryCond.noDeliveryOnSunday) noDeliveryDays.push(0);
      if (deliveryCond.noDeliveryOnMonday) noDeliveryDays.push(1);
      if (deliveryCond.noDeliveryOnTuesday) noDeliveryDays.push(2);
      if (deliveryCond.noDeliveryOnWednesday) noDeliveryDays.push(3);
      if (deliveryCond.noDeliveryOnThursday) noDeliveryDays.push(4);
      if (deliveryCond.noDeliveryOnFriday) noDeliveryDays.push(5);
      return !_.includes(noDeliveryDays, date.getDay());
    }   

   }
})();
