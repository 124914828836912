/**
 * @Author: Ajay
 * @Date:   15-May-2018 13:04 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.manage-erp-item.edit.controller.js
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2020-12-31 12:58:51
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ManageERPItemEditController', ManageERPItemEditController);

  ManageERPItemEditController.$inject = ['$scope', '$q', '$filter', '$state', '$stateParams', 'ImageUploadService', 'ItemBuyerMappingService', 'UomService', 'ItemGroupService', 'paginationConstants', 'NotificationService', 'GLOBAL_CONSTANTS', 'BuyerDataService','$mdDialog'];

  function ManageERPItemEditController($scope, $q, $filter, $state, $stateParams, ImageUploadService, ItemBuyerMappingService, UomService, ItemGroupService, paginationConstants, NotificationService, GLOBAL_CONSTANTS, BuyerDataService,$mdDialog) {
    var vm = this;

    vm.itemBuyerMappingId = $stateParams.itemBuyerMappingId;
    vm.init = init;
    vm.loadItemGroups = loadItemGroups;
    vm.getItemBuyerMapping = getItemBuyerMapping;
    vm.save = save;
    vm.getParentItem = getParentItem;
    vm.accessForBarcodeSearch = BuyerDataService.getBuyerProfile().accessDTO.accessForBarcodeSearch;
    vm.itemFilter = itemFilter;
    vm.getSubCategoryList = getSubCategoryList;
    vm.subCategorySearch = {};
    vm.selectedSubCategoryId = undefined;
    vm.batchManagement = 'NO';
    vm.subCategoryList = [];
    vm.clearSearchTerm = clearSearchTerm;
    vm.setCategoryId = setCategoryId;
    vm.accessForBuyerItemCodeFeatures = BuyerDataService.getBuyerProfile().accessDTO.accessForBuyerItemCodeFeatures;
    vm.defaultSupplierSelectedCheckbox = defaultSupplierSelectedCheckbox;
    vm.defaultSupplierCheckPopUp = defaultSupplierCheckPopUp;
    vm.changeDefaultSupplier = changeDefaultSupplier;
    vm.cancelPopUp = cancelPopUp;
    vm.loadUoms = loadUoms;
    
    function init() {
      vm.getSubCategoryList();
      vm.getItemBuyerMapping();
      vm.loadItemGroups();
      vm.getParentItem();
      vm.loadUoms();
    }

    function clearSearchTerm() {
      vm.subCategorySearch.name = "";
    }

    function getSubCategoryList() {
      ItemBuyerMappingService.getSubCategoryList().then(function (response) {
        vm.subCategoryList = response.data;
      }, function (error) {

      })
    }

    function setCategoryId() {
      if (vm.itemBuyerMappingObj.subCategoryId) {
        vm.itemBuyerMappingObj.categoryId = vm.subCategoryList.find(function (subcategory) {
          return subcategory.id == vm.itemBuyerMappingObj.subCategoryId;
        }).category.id;
      }
    }

    function save() {
      if(vm.accessForBarcodeSearch){
        vm.itemBuyerMappingObj.parentItem = vm.itemBuyerMappingObj.parentItem ? vm.itemBuyerMappingObj.parentItem : vm.searchText;
        if(vm.itemBuyerMappingObj.parentItem == '' && vm.searchText == ''){
          vm.itemBuyerMappingObj.parentItem = null;
        }
      }
      if (vm.accessForBuyerItemCodeFeatures && vm.itemBuyerMappingObj.defaultSupplier && !vm.itemBuyerMappingObj.buyerItemCode) {
        NotificationService.simpleErrorToast({
          title: 'ERP Item Updation',
          message: 'alertMessage.BUYER_ITEM_CODE_REQUIRED'
        });
        return;
      }
      ItemBuyerMappingService.updateItemBuyerMapping(vm.itemBuyerMappingObj).then(function(response) {
        $scope.$emit('ezyprocureApp:buyerItemUpdate', response);
        NotificationService.success({
          title: 'Item',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        $state.go('buyer.manage-erp-item', {
          reload: false
        });
      }, function(error) {
        NotificationService.error({
          title: 'ERP Item Updation',
          error: error
        });
      });
    }

    function itemFilter(){
      return vm.Items = vm.searchText ? $filter('filter')(vm.parentItems, vm.searchText) : vm.parentItems;
    }

    function getParentItem(){
      var params  = {
        buyerId : BuyerDataService.getBuyerProfile().buyerUser.buyer.id
      };
      ItemBuyerMappingService.getParentItems(params).then(function(response){
        vm.parentItems = response.data;
      },
      function(error){
        NotificationService.error({
          title: 'Parent Item Listing',
          error: error
        });
      });
    }

    function getItemBuyerMapping() {
      ItemBuyerMappingService.getItemBuyerMapping(vm.itemBuyerMappingId).
      then(function(response) {
        vm.itemBuyerMappingObj = response;
        vm.allowEditBuyerUom = checkAllowEdit('buyerUom');
        vm.allowEditInventoryUom = checkAllowEdit('inventoryUom');
        vm.itemBuyerMappingObj.subCategoryId = response.subCategoryId ? response.subCategoryId : undefined;
        !vm.itemBuyerMappingObj.buyerItemGroup ? vm.itemBuyerMappingObj.buyerItemGroup = {id : vm.itemBuyerMappingObj.items.itemGroup.id} : vm.itemBuyerMappingObj.buyerItemGroup = {id : vm.itemBuyerMappingObj.buyerItemGroup.id};
      }, function(error) {
      });
    }
    function checkAllowEdit(type) {
      var item = vm.itemBuyerMappingObj;
      if (!item.invCreated) {
        return true;
      }
      if (item.invCreated) {
        if (item.buyerUom === null && item.inventoryUom === null) {
          return true;
        } else if (type === 'buyerUom' && item.buyerUom === null) {
          return true;
        } else if (type === 'inventoryUom' && item.inventoryUom === null) {
          return true;
        }
      }

      return false;
    }

    function loadItemGroups() {
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }


    function defaultSupplierSelectedCheckbox(itemBuyerMapping,$index){
        vm.mappingId =  itemBuyerMapping.id;
        vm.buyerItemCodeData = itemBuyerMapping.buyerItemCode;
        vm.changeSupplierDTO = {
            buyerItemCode: vm.buyerItemCodeData,
            defaultSupplier: itemBuyerMapping.defaultSupplier ,
        }
        vm.defaultSupplierCheckPopUp()
    }

    function defaultSupplierCheckPopUp(event){

        vm.changeSupplierbtn  = true;
        $mdDialog.show({
        templateUrl: 'app/core/common-templates/default-supplier-view.tmpl.html',
        controller: function() {
            return vm
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
        });
    }

    function changeDefaultSupplier(){
        ItemBuyerMappingService.changeDefaultSupplier(vm.changeSupplierDTO,vm.mappingId).then(function(response) {
            vm.changeDefaultSupplierItemData = response.data;
            $mdDialog.hide();
            vm.init();
        }, function(error) {
            NotificationService.error({
                error: error,
            })
        });
    }

    function cancelPopUp(){
        $mdDialog.hide();
        vm.init();
    }

    function loadUoms() {
      UomService.getAllUoms({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.uoms = response.data;
      }, function(error) {
      });
    }

  }
})();
