/**
* @Author: Allan
* @Date:   20-June-2018 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.buyer.due-payment-summary.controller.js
* @Last modified by:   Allan
* @Last modified time: 10-September-2018 14:45 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentDuePaymentSummaryController', EzypaymentDuePaymentSummaryController);

  EzypaymentDuePaymentSummaryController.$inject = ['$mdDialog', '$state', 'DuePaymentInvoicesService', '$filter', '$timeout', 'BuyerOCRService', 'BuyerBuyNowService', 'BuyerBusinessUserMapService', 'paginationConstants', 'pagingParams', 'UTCDateService', 'NotificationService', 'toastr', 'BrandManagementService', 'Auth', 'GLOBAL_CONSTANTS', '$stateParams', 'CardsService', 'CustomPaymentsService'];

  function EzypaymentDuePaymentSummaryController($mdDialog, $state, DuePaymentInvoicesService, $filter, $timeout, BuyerOCRService, BuyerBuyNowService, BuyerBusinessUserMapService, paginationConstants, pagingParams, UTCDateService, NotificationService, toastr, BrandManagementService, Auth, GLOBAL_CONSTANTS, $stateParams, CardsService, CustomPaymentsService) {

    var vm = this;
    var popup = this;
    vm.init = init;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.filter = {};
    vm.purposeCode = null;
    vm.getPurposes = getPurposes;
    vm.isCustom = $stateParams.isCustom == 'custom';
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.selectAllRows = false;
    vm.checkAllRows = checkAllRows;
    vm.confirmAndPay = confirmAndPay;
    vm.externalPayments = externalPayments;
    vm.checkPaymentModel = checkPaymentModel;
    popup.checkPaymentModel = checkPaymentModel;
    vm.getAllAllocatedSuppliersForBusiness = getAllAllocatedSuppliersForBusiness;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.getInvoices = getInvoices;
    vm.getDuePaymentSummary = getDuePaymentSummary;
    vm.getSelectedPOs = getSelectedPOs;
    vm.cardTokenByBrands = cardTokenByBrands;
    vm.getSelectedPOIds = getSelectedPOIds;
    vm.getBrandsWithTokenMapped = getBrandsWithTokenMapped;
    vm.accessForOcr = Auth.hasPermission("accessForOcr");
    vm.invoiceTypes = GLOBAL_CONSTANTS.EZYPAYMENT_INVOICE_TYPES;
    vm.paymentMethods = GLOBAL_CONSTANTS.PAYMENT_METHODS;
    vm.getExternalInvoices = getExternalInvoices;
    vm.checkAllExternalRows = checkAllExternalRows;
    vm.selectAllExternalRows = false;
    vm.getInternalInvoices = getInternalInvoices;
    vm.todaysDate = new Date();
    vm.exQuery = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    }
    vm.limitOptions = paginationConstants.limitOptionsWithoutAll;
    vm.removeExternalInvoice=removeExternalInvoice;
    vm.openExternalInvoice=openExternalInvoice;
    vm.cancel = cancel;
    popup.cancel = cancel;
    popup.purposes = [];
    vm.confirmRemoveExternalInvoiceModal=confirmRemoveExternalInvoiceModal;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.filter.paymentMethod = vm.paymentMethods[0].value;
    // 2FA changes
    vm.otp = undefined;
    vm.isOtpValid = true;
    vm.counterMilliSeconds = undefined;
    vm.counterSeconds = undefined;
    vm.counterMinutes = undefined;
    vm.validateOTP = validateOTP;
    vm.resendOTP = resendOTP;

    function init() {
      vm.getPurposes();
      vm.getInternalInvoices();
      vm.getExternalInvoices();
    }

    vm.init();

    // timer for otp resend
    function counter() {
      vm.stopped = $timeout(function () {
        vm.counterMilliSeconds -= 1000;
        if (vm.counterMilliSeconds) {
          var seconds = vm.counterMilliSeconds / 1000;
          vm.counterMinutes = Math.floor(seconds / 60);
          if(vm.counterMinutes > 0) {
            vm.counterSeconds = seconds - (vm.counterMinutes * 60);
          }
          else {
            vm.counterSeconds = seconds;
          }
          counter();
        }
        else {
          vm.counterSeconds = 0;
          vm.counterMinutes = 0;
          stopCounter();
        }
      }, 1000);
    };

    function stopCounter(){
      vm.isOtpValid = false;
      $timeout.cancel(vm.stopped);
    }

    function showOtpPrompt() {
      // clear otp
      vm.otp = undefined;
      vm.isOtpValid = true;
      vm.counterSeconds = 0;
      vm.counterMinutes = 0;
      vm.counterMilliSeconds = GLOBAL_CONSTANTS.OTP_EXPIRY_MINUTES * 60 * 1000;
      counter();
      // show prompt
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/otp-templates/otp-capture.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function validateOTP() {
      if (!vm.otp) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }

      if (vm.otp && (vm.otp.length < 5 || vm.otp.length > 8)) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }
      externalPayments(vm.otp);
    }

    function resendOTP() {
      externalPayments();
    }

    function selectAll(dropdown) {
      switch (dropdown) {
        case 'SUPPLIERS':
        vm.filter.supplierIds = _.map(vm.supplierList, _.property('id'));
        break;
        case 'OUTLETS':
        vm.filter.businessOutletIds = _.map(vm.businessOutlets, _.property('id'));
        break;
        case 'BRANDS':
        vm.filter.brandIds = _.map(vm.brandsWithTokenMapped, _.property('id'));
        break;
      }
    }

    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'SUPPLIERS':
        vm.filter.supplierIds = [];
        break;
        case 'OUTLETS':
        vm.filter.businessOutletIds = [];
        break;
        case 'BRANDS':
        vm.filter.brandIds = [];
        break;
      }
    }

    function checkAllRows() {
      vm.selectAllRows ? vm.selectAllRows = true : vm.selectAllRows = false;
      angular.forEach(vm.invoices, function(invoice) {
        invoice.selected = vm.selectAllRows;
      });
    }

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function getSelectedPOs(poItems) {
      var selectedPOs = _.filter(poItems, function(poItem) {
        return poItem.selected === true;
      });
      return selectedPOs;
    }

    function getSelectedPOIds(selectedPOs) {
      var purchaseOrderIdList = (vm.filter.invoiceType === 'INTERNAL') ? _.map(selectedPOs, _.property('purchaseOrder.id')) : _.map(selectedPOs, _.property('id'));
      return purchaseOrderIdList;
    }

    function getAllAllocatedSuppliersForBusiness() {
      var params = {
      businessId: !vm.filter.businessOutletIds?'':vm.filter.businessOutletIds,
      brandIds: !vm.filter.brandIds?'': vm.filter.brandIds,
      };
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function(response){
        vm.supplierList = response.data;
      },function(error){
        NotificationService.error({
          title: 'Fetching Allocated Suppliers',
          error: error
        });
      });
    }

    function getAllBuyerBusinessOutlets() {
      BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser({
        brandIds: !vm.filter.brandIds ? [] : vm.filter.brandIds
      }).then(function(response){
        vm.businessOutlets = response;
        vm.filter.supplierIds=[];
      },function(error){
        NotificationService.error({
          title: 'Buyer Business Outlets',
          error: error
        });
      });
    }

    function getInternalInvoices() {
      BuyerOCRService.getAllPurchaseOrders({
        page: vm.query.page - 1,
        size: vm.query.limit,
        query: !vm.filter.poNumber ? '': vm.filter.poNumber,
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        ocrInvoiceStatus: !vm.accessForOcr ? '' : 'ACCEPTED',
        ocrAcceptanceStatus: !vm.accessForOcr ? '' : 'ACCEPTED',
        invoiceStatus: !vm.accessForOcr ? 'PROCESSED' : '',
        geoExported: '',
        businessOutletIds: !vm.filter.businessOutletIds ? '' : vm.filter.businessOutletIds,
        brandIds: !vm.filter.brandIds ? '' : String(vm.filter.brandIds),
        supplierId: !vm.filter.supplierIds ? '' : String(vm.filter.supplierIds),
        paymentStatus: "'UNPAID','FAILED','VOID','CANCELLED'",
        kycStatus: 'SUCCESSFUL',
        module: 'payment',
        paymentMethod: vm.filter.paymentMethod,
        sort: sort(),
      }).then(function(response){
        vm.invoices = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      },function(error) {
        NotificationService.error({
          title: 'Fetching Invoices',
          error: error
        });
      });
    }

    /**
     * [confirmAndPay Show the modal to enter the remarks]
     * @param {*} event
     */
    function confirmAndPay(event){
      var selectedPOs = (vm.filter.invoiceType === 'INTERNAL') ? vm.getSelectedPOs(vm.invoices) : vm.getSelectedPOs(vm.externalInvoices);
      var selectedPOIds = vm.getSelectedPOIds(selectedPOs);
      if(selectedPOIds.length <= 0){
        NotificationService.simpleErrorToast({
          title: 'global.fetchingInvoiceDetails',
          message: 'global.pleaseSelectInvoiceToPay'
        });
        return false;
      }
      $mdDialog.show({
        templateUrl: 'app/common-modules/ezypayment/ezypayment.payment.confirm-pay.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function cardTokenByBrands() {
            var selectedPOs = (vm.filter.invoiceType === 'INTERNAL') ? vm.getSelectedPOs(vm.invoices) : vm.getSelectedPOs(vm.externalInvoices);
            var validData = [];
            angular.forEach(selectedPOs, function(invoice) {
             validData.push( (vm.filter.invoiceType === 'INTERNAL') ? invoice.purchaseOrder.processOrder.buyerBusiness.brand.id : invoice.brand.id);
            });
            popup.selectedPOs = selectedPOs;
        CardsService.getAllCards({
            brandIds: validData,
            page: 0,
            size: paginationConstants.maxLimit,
            isCustom: vm.isCustom
          }).then(function(response){
            CustomPaymentsService.getPurposes().then(function (res) {
                popup.purposes = res.data;
                popup.tokenCards = response.data;
                $mdDialog.show({
                    templateUrl: 'app/ezypayment-modules/buyer-modules/common/popup-selecting.tmpl.html',
                    controller: function() {
                      return popup;
                    },
                    controllerAs: 'popup',
                    targetEvent: event,
                    clickOutsideToClose: true
                });
              }, function (error) {
                NotificationService.error({
                  title: 'Purposes',
                  error: error
                });
              });
          },function(error) {
            NotificationService.error({
              title: 'Fetching Token By Cards',
              error: error
            });
          });
    }
    function getPurposes() {
        CustomPaymentsService.getPurposes().then(function (res) {
            vm.purposes = res.data;
          }, function (error) {
            NotificationService.error({
              title: 'Purposes',
              error: error
            });
          });
    }
    /**
     * [checkPaymentModel Decides whether to goto details page or show popup]
     */
    function checkPaymentModel() {
        if(vm.isCustom){
            if(!vm.purposeCode) {
                toastr.error('purpose code not selected!', 'Validation');
                return;
            }
            var selectedPOs = (vm.filter.invoiceType === 'INTERNAL') ? vm.getSelectedPOs(vm.invoices) : vm.getSelectedPOs(vm.externalInvoices);
            var brandIds = [];
            if(vm.filter.invoiceType === 'INTERNAL'){
                selectedPOs.forEach(function (elem) {
                    brandIds.push(elem.purchaseOrder.processOrder.buyerBusiness.brand.id)
                })
            } else {
                selectedPOs.forEach(function (elem) {
                    brandIds.push(elem.brand.id)
                })
            }
            CardsService.getAllCards({
                brandIds: brandIds,
                page: 0,
                size: paginationConstants.maxLimit,
                isCustom: vm.isCustom
              }).then(function(response){
                vm.tokenCards = response.data;
                var selectedPOs = (vm.filter.invoiceType === 'INTERNAL') ? vm.getSelectedPOs(vm.invoices) : vm.getSelectedPOs(vm.externalInvoices);
                var validData = [];
                angular.forEach(selectedPOs, function(invoice) {
                 validData.push({
                  brandId: (vm.filter.invoiceType === 'INTERNAL') ? invoice.purchaseOrder.processOrder.buyerBusiness.brand.id : invoice.brand.id,
                  supplierId: (vm.filter.invoiceType === 'INTERNAL') ? invoice.purchaseOrder.processOrder.items.supplier.id : invoice.supplier.id,
                  purposeId: vm.purposeCode,
                  tokenId: _.filter(vm.tokenCards, {
                    'brandId': vm.filter.invoiceType === 'INTERNAL' ? invoice.purchaseOrder.processOrder.buyerBusiness.brand.id : invoice.brand.id
                  })[0].token
                 });
                });
                DuePaymentInvoicesService.customPaymentValidate(validData).then(function(response){
                    vm.filter.paymentMethod === 'EZYPAY' ? vm.getDuePaymentSummary() : confirmAndPay();
                  },function(error) {
                    NotificationService.error({
                      title: 'global.invoiceValidation',
                      error: error
                    });
                  });
              },function(error) {
                NotificationService.error({
                  title: 'Fetching Token By Cards',
                  error: error
                });
              });

        } else {
            vm.filter.paymentMethod === 'EZYPAY' ? vm.getDuePaymentSummary() : confirmAndPay();
        }
    }
    /**
     * [getDuePaymentSummary Redirects to the payment details page]
     */
    function getDuePaymentSummary(){
      var selectedPOs = (vm.filter.invoiceType === 'INTERNAL') ? vm.getSelectedPOs(vm.invoices) : vm.getSelectedPOs(vm.externalInvoices);
      var brandIds = [];
      if(vm.filter.invoiceType === 'INTERNAL'){
        selectedPOs.forEach(function (elem) {
            brandIds.push(elem.purchaseOrder.processOrder.buyerBusiness.brandId)
        })
       } else {
        selectedPOs.forEach(function (elem) {
          brandIds.push(elem.brand.id)
        })
       }
      var selectedPOIds = vm.getSelectedPOIds(selectedPOs);
      if(selectedPOIds.length > 0){
        var purposeObj = _.filter(vm.purposes, function(code) {
            return code.id === vm.purposeCode;
          })[0];
        DuePaymentInvoicesService.setSelectedPOIds(selectedPOIds);
        DuePaymentInvoicesService.setInvoiceType(vm.filter.invoiceType);
        DuePaymentInvoicesService.setIsCustom(vm.isCustom);
        DuePaymentInvoicesService.setPurpose(popup.purposeCode);
        DuePaymentInvoicesService.setPurposeObj(purposeObj);
        DuePaymentInvoicesService.setCard(popup.brandTokens);
        DuePaymentInvoicesService.setBrandIds(brandIds);
        DuePaymentInvoicesService.setSelectedPO(selectedPOs);
        $state.go('ezypayment.buyer.due-payment-summary.details');
      } else {
        toastr.error('Please select invoices to pay.', 'Fetching Invoice Details');
      }
    }

    function cancel () {
       $mdDialog.cancel();
    }

    /**
     * [externalPayments Pays external payment]
     */
    function externalPayments(otp) {
      var selectedPOs = (vm.filter.invoiceType === 'INTERNAL') ? vm.getSelectedPOs(vm.invoices) : vm.getSelectedPOs(vm.externalInvoices);
      var selectedPOIds = vm.getSelectedPOIds(selectedPOs);
      if(selectedPOIds.length > 0){
        vm.externalPaymentDetails ={
          purchaseOrderIdList :vm.filter.invoiceType === 'INTERNAL' ? selectedPOIds : [],
          remarks:vm.remarks,
          invoiceIdList: vm.filter.invoiceType === 'INTERNAL' ? []: selectedPOIds,
          paymentMethod: vm.filter.paymentMethod
        }
        DuePaymentInvoicesService.externalConfirmPayment(vm.externalPaymentDetails, otp)
        .then(function(response){
          if(response.headers('authorization-otp') == 'OTP_VERIFICATION_REQUIRED') {
            showOtpPrompt();
          }
          else if(GLOBAL_CONSTANTS.OTP_EXCEPTIONS.includes(response.headers('authorization-otp'))) {
            toastr.error('Invalid OTP', 'AUTHENTICATION FAILED');
          }
          else {
            NotificationService.success({
              title: 'global.paymentForExternalInvoices',
              message: 'Successfully made payments'
            });
            $mdDialog.hide();
            vm.cancel();
            vm.init();
          }
        },function(error) {
          NotificationService.error({
            title: 'global.fetchingInvoiceDetails',
            error: error
          });
          vm.cancel();
        });

      } else {
        NotificationService.simpleErrorToast({
          title: 'global.fetchingInvoiceDetails',
          message: 'global.pleaseSelectInvoiceToPay'
        });
      }
    }

    function getBrandsWithTokenMapped(){
      BrandManagementService.getBrandsWithTokenMapped().then(function(response){
        vm.brandsWithTokenMapped = response.data;
        vm.filter.businessOutletIds=[];
        vm.filter.supplierIds=[];
      },function(error){
        NotificationService.error({
          title: 'Brands with Token Mapped',
          error: error
        });
      });
    }

    function getExternalInvoices(){
      DuePaymentInvoicesService.getExternalInvoices({
        page: vm.exQuery.page - 1,
        size: vm.exQuery.limit,
        poNumber: !vm.filter.poNumber ? '': vm.filter.poNumber,
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        brandIds: !vm.filter.brandIds ? '' : String(vm.filter.brandIds),
        supplierId: !vm.filter.supplierIds ? '' : String(vm.filter.supplierIds),
        paymentStatus: "'UNPAID','FAILED','VOID','CANCELLED'",
        kycStatus: 'SUCCESSFUL',
        paymentMethod: vm.filter.paymentMethod,
        sort: sort(),
      }).then(function(response){
        vm.externalInvoices = response.data;
        vm.externalTotalItems = response.headers('X-Total-Count');
      },function(error) {
        NotificationService.error({
          title: 'Fetching External Invoices',
          error: error
        });
      });
    }

    function checkAllExternalRows(){
      vm.selectAllExternalRows ? vm.selectAllExternalRows = true : vm.selectAllExternalRows = false;
      angular.forEach(vm.externalInvoices, function(invoice) {
        invoice.selected = vm.selectAllExternalRows;
      });
    }

    function getInvoices(){
      (vm.filter.invoiceType === 'INTERNAL') ? getInternalInvoices() : getExternalInvoices();
    }

    /**
     * removeExternalInvoice removes the external invoice by updating the payment status to 'DELETED'
     * @param {invoice}
     */
    function removeExternalInvoice(invoiceId){
      vm.cancel();
      DuePaymentInvoicesService.removeExternalInvoice(invoiceId).then(function(response) {
        NotificationService.success({
          title: 'External Invoice',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.filter.fromDate='';
        vm.filter.toDate='';
        vm.getExternalInvoices();
      }, function(error) {
        NotificationService.error({
          title: 'External Invoice',
          error: error
        });
      });
    }

    /**
     * openExternalInvoice allows to preview the existing invoice details and also to replace the invoice into s3
     * @param {event,invoice}
     */
    function openExternalInvoice(event,invoice){
      $mdDialog.show({
        templateUrl: 'app/ezypayment-modules/buyer-modules/common/external-invoice-details-view.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    /**
    * [confirmRemoveExternalInvoiceModal showConfirm mdDialog]
    * @param  {[Object]} event   [event]
    * @param  {[Id]} invoiceId [invoice id]
    */
   function confirmRemoveExternalInvoiceModal(event, invoiceId) {
    var confirm = $mdDialog.confirm()
    .title($filter('translate')('alertMessage.REMOVE_INVOICE_CONFIRMATION'))
    .targetEvent(event)
    .ok('Delete')
    .cancel('Cancel');

    $mdDialog.show(confirm).then(function() {
      removeExternalInvoice(invoiceId);
    }, function() {});
  }

    /**
     * cancel allows close the pop-up
     */
    function cancel() {
      $mdDialog.cancel();
    }

  }
})();
