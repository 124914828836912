/**
* @Author: JayalekshmiR
* @Date:   2018-09-17T13:23:29+05:30
* @Filename: ezypayment.buyer.payee.add.controller.js
 * @Last modified by:   JayalekshmiR
 * @Last modified time: 2018-09-17T15:41:20+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('AddPayeeController', AddPayeeController);

  AddPayeeController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', 'toastr','pagingParams', 'NotificationService', 'paginationConstants', 'BuyerPayeeService', 'Auth', 'GLOBAL_CONSTANTS', '$stateParams', 'BankService', 'BuyerDataService', 'BrandManagementService', 'EZYAP_CONSTANTS'];

  function AddPayeeController($scope, $rootScope, $q, $filter, $state, toastr, pagingParams, NotificationService, paginationConstants, BuyerPayeeService, Auth, GLOBAL_CONSTANTS, $stateParams, BankService, BuyerDataService, BrandManagementService, EZYAP_CONSTANTS) {

    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = true;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.addPayee = addPayee;
    vm.loadBrands = loadBrands;
    vm.sort = sort;
    vm.supplier = {};
    vm.paymentTerms = {};
    vm.isCustom = BuyerPayeeService.getIsCustom();
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.paymentTerms.BANK_TRANSFER = {};
    vm.userRole = Auth.getUserRole();
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.visibilityType = $stateParams.visibilityType ? $stateParams.visibilityType : 'ALLOCATED';
    vm.getAllBanks = getAllBanks;
    vm.selectedBankChanged = selectedBankChanged;
    vm.paymentModels = GLOBAL_CONSTANTS.PAYMENT_MODELS;
    vm.customDutyTypes = GLOBAL_CONSTANTS.CUSTOM_DUTY_TYPE;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.countryCodes = EZYAP_CONSTANTS.ezyap.countryCodes;
    vm.countryCodeChanged = countryCodeChanged;
    vm.selectedCountry = 'Singapore';
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [loadBrands loads all brands]
    */
    function loadBrands(){
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      if(vm.brands){
        return false;
      }
      BrandManagementService.getAllBrandsByBuyerId({
        page: 0,
        size: paginationConstants.maxLimit,
        sort: vm.sort(),
        query: '',
        buyerId : buyerId
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.brands = response.data;
      },function(error){
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

    function selectAll(dropdown) {
      switch (dropdown) {
        case 'BRANDS':
        vm.supplier.brandId = _.map(vm.brands, _.property('id'));
        break;
      }
    }

    function deselectAll(dropdown) {
        switch (dropdown) {
          case 'BRANDS':
          vm.supplier.brandId = [];
        break;
      }
    }

    function addPayee() {
      if (vm.isWrongRegNumber) {
        return;
      }
      vm.supplier.paymentTermsList = [{
        'accountName':vm.supplier.accountName ,
        'accountNumber':vm.supplier.accountNumber ,
        'bank': {
          'bankCode': vm.supplierData.bank.bankCode,
          'bankName': vm.supplierData.bank.bankName,
          'id':vm.supplierData.bank.id,
          'swiftCode': vm.supplierData.bank.swiftCode
        },
        'paymentType':"BANK_TRANSFER",
        'active': true,
        'brand': {
          'id': null
        },
        'brandList':vm.supplier.brandId,
        'customDutyType':vm.supplier.customDutyType,
        'countryCode':vm.supplier.countryCode
      }];
      BuyerPayeeService.addPayee(vm.supplier)
      .then(function (response) {
        NotificationService.success({
          title: 'global.navbar.addPayee',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        $state.go('ezypayment.buyer.payee', {
          reload: false
        });
      }, function (error) {
        vm.translatedError = error.data;
        NotificationService.error({
          title: 'Cannot Add Payee',
          error: error
        });
      });
    }


    function getAllBanks() {
      BankService.getAllBanks({
        page: 0,
        size: paginationConstants.maxLimit,
        query: '',
        countryCode: vm.supplier.countryCode
      }).then(function(response) {
        vm.banks = response.data;
        // debugger;
      }, function(error) {
      });
    }

    function selectedBankChanged(bankId) {
      var banks = angular.copy(vm.banks);
      vm.supplierData.bank = BankService.getSelectedBank(banks, bankId);
    }

    function countryCodeChanged() {
      const countryCode = vm.supplier.countryCode;

      const country = _.find(vm.countryCodes, function(data) {
        return data.countryCode === countryCode;
      });
      if (country) {
        vm.selectedCountry = country.countryName;
      } else {
        console.log("Country not found");
      }
    }
  }
})();
