/**
* @Author: Ajay
* @Date:   20-Mar-2017 12:11 +05:30
* @Project: Ezyprocure
* @Filename: supplier.price-update-history.service.js
* @Last modified by:   hareesh
* @Last modified time:16-Aug-2017 20:34 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierPriceUpdateHistoryService', SupplierPriceUpdateHistoryService);

  SupplierPriceUpdateHistoryService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function SupplierPriceUpdateHistoryService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var supplierPriceUpdateHistoryService = {
      getTopBuyers : getTopBuyers,
      getTopPurchasedItems : getTopPurchasedItems,
      getPriceUpdateMovement : getPriceUpdateMovement,
      getAuditTransactionData : getAuditTransactionData,
      rePostTransactionData : rePostTransactionData,
    };
    return supplierPriceUpdateHistoryService;

     function getTopBuyers(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'top-buyers-details',
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    function getTopPurchasedItems(params) {
          var deferred = $q.defer();
          $http({
            url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'top-purchased',
            method: "GET",
            params:params,
          }).then(function(data) {
            deferred.resolve(data);
          }).catch(function(message) {
            deferred.reject(message);
          });
          return deferred.promise;
        }
    function getPriceUpdateMovement(params) {
          var deferred = $q.defer();
          $http({
            url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'price-update-movement',
            method: "GET",
            params:params,
          }).then(function(data) {
            deferred.resolve(data);
          }).catch(function(message) {
            deferred.reject(message);
          });
          return deferred.promise;
        }
    function getAuditTransactionData(params) {
          var deferred = $q.defer();
          $http({
            url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'audit-transaction-data',
            method: "GET",
            params:params,
          }).then(function(data) {
            deferred.resolve(data);
          }).catch(function(message) {
            deferred.reject(message);
          });
          return deferred.promise;
        }
    function rePostTransactionData(params) {
          var deferred = $q.defer();
          $http({
            url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.SATS + 'repost',
            method: "GET",
            params:params,
          }).then(function(data) {
            deferred.resolve(data);
          }).catch(function(message) {
            deferred.reject(message);
          });
          return deferred.promise;
        }
  }
})();
