/*
 * @Author: Neethu 
 * @Date: 2023-08-17
 * @Project: Ezyprocure
 * @Filename: ezyap.manage-supplier-details.service.js
 */

(function () {
    'use strict';
  
    angular.module('ezyprocureApp').factory('ManageSupplierDetailsService', ManageSupplierDetailsService);
  
    ManageSupplierDetailsService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  
    function ManageSupplierDetailsService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {
  
      var ManageSupplierDetailsService = {
        addDeliveryDetails: addDeliveryDetails,
        getDeliveryDetails: getDeliveryDetails,
        getDeliveryDetailsById: getDeliveryDetailsById,
        deleteDeliveryDetailsById: deleteDeliveryDetailsById,
        editDeliveryDetails: editDeliveryDetails
      };
      return ManageSupplierDetailsService;
   
      /**
       * [add supplier information]
       * @param {Object} data [supplier information data]
       */
      function addDeliveryDetails(data) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/supplier-delivery-details',
          method: "POST",
          data: data
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      /**
       * [edit supplier information]
       * @param {Object} data [supplier information data]
       */
      function editDeliveryDetails(data) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/supplier-delivery-details',
          method: "PUT",
          data: data
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      /**
       * [get supplier information]
       * @param {String} params [search text] 
       */
      function getDeliveryDetails(params) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/supplier-delivery-details',
          method: "GET",
          params: params
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      /**
       * [get individual delivery information by id]
       * @param {Integer} id [delivery information id]
       */
      function getDeliveryDetailsById(id) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/supplier-delivery-details/' + id,
          method: "GET"
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      /**
       * [delete supplier information by id ]
       * @param {Integer} id [supplier information id]
       */
      function deleteDeliveryDetailsById(id) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/supplier-delivery-details/' + id,
          method: "DELETE",
          params: id
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
    }
  })();