/**
* @Author: hareesh
* @Date:   10-Aug-2017 05:54 +05:30
* @Project: Ezyprocure
* @Filename: buyer.top-suppliers.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:58 +05:30
*/



(function() {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('BuyerTopSuppliersController', BuyerTopSuppliersController);

  BuyerTopSuppliersController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog', 'pagingParams', 'BuyerDataService', 'UTCDateService', 'BuyerPriceUpdateHistoryService','BusinessReportService', 'BuyerSupplierMappingService', 'ItemGroupService', 'paginationConstants', 'BuyerBuyNowService', 'CSVExportService', 'EXPORT_CONSTANTS', 'BuyerBusinessUserMapService','MainItemGroupService', 'NotificationService', 'GLOBAL_CONSTANTS'];

  function BuyerTopSuppliersController($scope, $rootScope, $q, $filter, $state, $stateParams, toastr, $mdDialog, pagingParams, BuyerDataService, UTCDateService, BuyerPriceUpdateHistoryService, BusinessReportService, BuyerSupplierMappingService,ItemGroupService, paginationConstants, BuyerBuyNowService, CSVExportService, EXPORT_CONSTANTS, BuyerBusinessUserMapService, MainItemGroupService, NotificationService, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.getAllAllocatedSuppliersForBusiness = getAllAllocatedSuppliersForBusiness;
    vm.loadItemGroups = loadItemGroups;
    vm.loadAll = loadAll;
    vm.supplier = '';
    vm.pieChart = pieChart;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.filter = {};
    vm.loadMainItemGroups = loadMainItemGroups;
    vm.getAllGroupedItemGroups = getAllGroupedItemGroups;
    vm.mainItemGroupSelectionChanged = mainItemGroupSelectionChanged;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.view=false;
    vm.options={};
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };

    vm.limitOptions = paginationConstants.limitOptions;
    //Header
    vm.topSuppliersHeader = EXPORT_CONSTANTS.CSV.TopSuppliersReport;
    //Function Declaration
    vm.getTopSuppliersCSVData = getTopSuppliersCSVData;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    //filter reset
    vm.resetFilters = resetFilters;
    vm.barOptions = {};
    vm.showbarChart = false;
    vm.cancel = cancel;
    vm.resetCharts = resetCharts;
    //Getting CSV DATA
    function getTopSuppliersCSVData() {
      var deferred = $q.defer();
      if (!vm.topSupplierItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getTopSuppliersCSV(vm.topSupplierItems,vm.fromDate,vm.toDate);
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
    */
    function getAllBuyerBusinessOutlets() {
      if (vm.businessOutlets)
        return false;
      vm.businessOutlets = null;
      BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser().
        then(function(response) {
          vm.businessOutlets = response;
          //if only one business outlet set it as default
          if (vm.businessOutlets.length === 1) {
            vm.businessId = vm.businessOutlets[0].id;
          }
        }, function(error) {
          NotificationService.error({
            title:"Failed to load business outlets!",
            message:error
          });
        });
    }

    /**
    * [getAllAllocatedSuppliersForBusiness Get all allocated suppliers of buyer business]
    */
    function getAllAllocatedSuppliersForBusiness(){
      if(vm.supplierList)
        return false;
      var params = {'businessId': ''};
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function(response){
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
      },function(error){
        NotificationService.error({
          title:"Failed to load suppliers!",
          message:error
        });
      });
    }
    /**
    *[loadItemGroups loads all the item group]
    */
    function loadItemGroups() {
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
        NotificationService.error({
          title:"Failed to load item groups!",
          message:error
        });
      });
    }

    /**
    *pieChart views a pie chart representing the top suppliers and respective percentage
    */
    function pieChart(){
      vm.options={
        chart: {
          type: 'pieChart',
          height: 300,
          x: function(d){return d.key;},
          y: function(d){return d.value;},
          showLabels: false,
          duration: 500,
          labelThreshold: 0.01,
          labelSunbeamLayout: true,
          legend: {
            margin: {
              top: 5,
              right: 35,
              bottom: 5,
              left: 0
            }
           } ,
           pie: {
             dispatch: {
               elementClick: function (e, d3) {
                 handlePieClick(e);
               }
             }
           } 
        }
      };
      vm.data=[];

      vm.topSupplierItems.forEach(function(element){
        var item={};
        item.key=element.supplier;
        item.value=element.percentage;
        item.supplierId = element.supplierId;
        item.supplier = element.supplier;
        vm.data.push(item);
      });

    }

    /**
    * [loadAll loads all top suppliers]
    */
    function loadAll() {
      vm.duplicateTopPurchasesByItemGroup = [];
      BusinessReportService.getTopSuppliers({
        fromDate:  !vm.fromDate ? '' : UTCDateService.utcFromDate(vm.fromDate),
        toDate: !vm.toDate ? '' : UTCDateService.utcToDate(vm.toDate),
        buyerBusinessIdList: !vm.filter.buyerBusinessIdList ? [] : vm.filter.buyerBusinessIdList ,
        supplierIdList: !vm.filter.supplierIdList ? []: vm.filter.supplierIdList,
        mainItemGroupIdList: !vm.filter.mainItemGroupIdList ? []: vm.filter.mainItemGroupIdList,
        itemGroupIdList: !vm.filter.itemGroupIdList ? [] :vm.filter.itemGroupIdList,
        query: ''
      },
      {
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort()
      }).then(function(response) {
        vm.topSupplierItems = response.data.topSuppliers;
        vm.topPurchasesByItemGroup = response.data.topPurchasesByItemGroup;
        vm.duplicateTopPurchasesByItemGroup = angular.copy(vm.topPurchasesByItemGroup);
        vm.totalItems = response.headers('X-Total-Count');
        vm.pieChart = pieChart();
        vm.barChart = barChart();
        vm.showbarChart = false;
        vm.supplierSel = '';
      }, function(error) {
        NotificationService.error({
          title:"Failed to retrieve the top suppliers report!",
          message:error
        });
      });
    }
    /**
    * [loadMainItemGroups Gets all Main Item Groups]
    */
    function loadMainItemGroups() {
      if (vm.mainItemGroups) {
        return false;
      }
      MainItemGroupService.getAllMainItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.mainItemGroups = response.data;
      }, function(error) {
        NotificationService.error({
          title:"Failed to load main item groups!",
          message:error
        });
      });
    }
    /**
    * [mainItemGroupChanged changes the status of mainItemGroup and empties the existing itemGroupList]
    */
    function mainItemGroupSelectionChanged() {
      vm.filter.mainItemGroupChanged = true;
      vm.filter.itemGroupIdList = [];
    }
    /**
    * [selectAll Selects all from the list]
    * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups, itemGroups ]
    */
    function selectAll(dropdown) {
      switch (dropdown) {
      case 'OUTLETS':
        vm.filter.buyerBusinessIdList = _.map(vm.businessOutlets, _.property('id'));
        break;
      case 'SUPPLIERS':
        vm.filter.supplierIdList = _.map(vm.supplierList, _.property('id'));
        break;
      case 'MAIN_ITEMGROUPS':
        vm.mainItemGroupSelectionChanged();
        vm.filter.mainItemGroupIdList = _.map(vm.mainItemGroups, _.property('id'));
        break;
      case 'ITEMGROUPS':
        vm.filter.itemGroupIdList = _.map(_.flatten(_.without(_.map(vm.itemGroups, _.property('itemGroupDTOList')), undefined, null)), _.property('itemGroupId'));
        break;
      }
    }
    /**
    * [deselectAll Deselect all from the list]
    * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups, itemGroups ]
    */
    function deselectAll(dropdown) {
      switch (dropdown) {
      case 'OUTLETS':
        vm.filter.buyerBusinessIdList = [];
        break;
      case 'SUPPLIERS':
        vm.filter.supplierIdList = [];
        break;
      case 'MAIN_ITEMGROUPS':
        vm.filter.mainItemGroupIdList = [];
        vm.mainItemGroupSelectionChanged();
        break;
      case 'ITEMGROUPS':
        vm.filter.itemGroupIdList = [];
        break;

      }
    }
    /**
    * [getAllGroupedItemGroups Gets all ItemGroups Grouped by Main Item Groups]
    */
    function getAllGroupedItemGroups(mainItemGroupIdList) {
      if (vm.itemGroups && !vm.filter.mainItemGroupChanged){
        return false;
      }
      vm.filter.mainItemGroupChanged = false;
      ItemGroupService.getAllGroupedItemGroups(mainItemGroupIdList, {
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
        NotificationService.error({
          title:"Failed to load item groups grouped by main item group!",
          message:error
        });
      });
    }
    // filter reset
    function resetFilters() {
        vm.fromDate = vm.todaysDate;
        vm.toDate = vm.todaysDate;
        vm.filter.buyerBusinessIdList = '';
        vm.filter.supplierIdList = '';
        vm.topSupplierItems = null;
    }

    function handleBarClick(e) {
      loadSupplierByItemGroupsId(e.data);            
      $scope.$apply();
    }

    function cancel() {
      vm.showbarChart = !vm.showbarChart;
      if (vm.showbarChart === true) {
        vm.showbarChart = false;
      }
    }

    function barChart() {
      vm.barOptions = {
        chart: {
          type: 'discreteBarChart',
          height: 400,
          margin: {
            "top": 20,
            "right": 20,
            "bottom": 50,  // Increased bottom margin for long labels
            "left": 80  // Increased left margin for long labels
          },
          x: function (d) { return d.label; },
          y: function (d) { return d.value + (1e-10); },
          showValues: true,
          valueFormat: function (d) {
            return d3.format(',.2f')(d);
          },
          duration: 500,
          xAxis: {
            axisLabel: 'Item Groups'
          },
          yAxis: {
            axisLabel: 'Amount',
            axisLabelDistance: -10,
            showMaxMin: true,
            staggerLabels: true,
            wrapLabels: true,
            rotateYLabel: false,
            rotateLabels: 45,
            tickFormat: function(d) {
              if (d >= 1000000) {
                return d3.format('.2s')(d); // Format with suffix if value is above one million
            } else {
                return d3.format(',.2f')(d); // Format as a regular number with two decimal places
            }
          } 
          },
          discretebar: {
            dispatch: {
              elementClick: function (e) {
                handleBarClick(e);
              }
            }
          }
        },
        title: {   
          enable: true,
          text: vm.supplierSel===undefined||vm.supplierSel===''? "Top Item Groups":vm.supplierSel+' - '+"Top Item Groups"
        }
      };

      vm.barData = [];
      var item = {
        key: "Top Item Groups",
        values: []
      };

      vm.topPurchasesByItemGroup.forEach(function (element) {
        var valueItem = {
          label: element.itemGroupName,
          value: element.amount,
          id: element.itemGroupId
        };
        item.values.push(valueItem);
      });
      vm.barData.push(item);      
    }

    
    /**
    * [loadAll loads all top suppliers itemgroups vs amount]
    */
    function loadSupplierByItemGroupsId(d) {
      BusinessReportService.loadSupplierByItemGroupsId({
        fromDate:  !vm.fromDate ? '' : UTCDateService.utcFromDate(vm.fromDate),
        toDate: !vm.toDate ? '' : UTCDateService.utcToDate(vm.toDate),
        buyerBusinessIdList: !vm.filter.buyerBusinessIdList ? [] : vm.filter.buyerBusinessIdList,
        supplierIdList: !vm.filter.supplierIdList ? []: vm.filter.supplierIdList,
        mainItemGroupIdList: !vm.filter.mainItemGroupIdList ? [] : vm.filter.mainItemGroupIdList,
        itemGroupIdList: !d ? [] : [d.id],
        query: ''
      },
        {
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort()
        }).then(function (response) {
          vm.topSuppliersByItemGroupId = response.data;
          vm.topSuppliersByItemGroupIdBarChart = topSuppliersByItemGroupIdBarChart(d);
        }, function (error) {
          NotificationService.error({
            title: "Failed to retrieve the top suppliers report!",
            message: error
          });
        });
    }

    function topSuppliersByItemGroupIdBarChart(e) {
      vm.suppliersBarOptions = {
        chart: {
          type: 'multiBarHorizontalChart',
          height: 300,
          margin: {
            top: 20,
            right: 100,
            bottom: 50,
            left: 200
          },
          x: function (d) { return d.label; },
          y: function (d) { return d.value; },
          showControls: false,
          showValues: true,
          duration: 500,
          xAxis: {
            showMaxMin: false,
            margin: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 100
            },
            width: 100,
            axisLabelDistance: 10,
            tickFormat: function (d) {
              if (typeof d === 'string' && d.length > 30) {
                return d.substring(0, 30) + '...'; // Truncate and add ellipsis for strings longer than 30 characters
              } else {
                return d;
              }
            } ,
            tickValues: function (d) {
              return _.map(d[0].values, function(f) {
                return f.label;
              }); // Ensures that the full label is used in tooltips
            }
          },
          yAxis: {
            axisLabel: 'Amount',
            staggerLabels: false,
            tickFormat: function (d) {
              if (d >= 1000000) {
                return d3.format('.2s')(d); // Format with suffix if value is above one million
              } else {
                return d3.format(',.2f')(d); // Format as a regular number with two decimal places
              }
            }
          },
          tooltip: {
            contentGenerator: function (d) {
              // This controls the content of the tooltip
              var color = d.color || d3.scale.category10()(d.index);

              // Use string concatenation instead of template literals
              var tooltipContent = '<div style="display: flex; align-items: center; margin: 5px;">' +
                '<span style="width: 14px; height: 14px; background-color: ' + color + '; display: inline-block;"></span>' +
                '<span style="margin-left: 10px;">' + d.data.label + '</span>' +
                '<span style="font-size: 12px; color: #333; margin-left: 10px;">' +
                '<b>' + d3.format(',.2f')(d.data.value) + '</b>' +
                '</span>' +
                '</div>';
              return tooltipContent;
            }
          },
          multibar: {
            width: 960,
            height: 500,
            forceY: [0],
            stacked: false,
            showValues: false,
            valuePadding: 60,
            groupSpacing: 0.1,
            barColor: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf"]  // Add colors here

          },
        },
        title: {
          enable: true,
          text: e.label
        }
      };

      vm.suppliersBarData = [];
      var item = {
        key: "Top Suppliers",
        values: []
      };

      vm.topSuppliersByItemGroupId.forEach(function (element) {
        var valueItem = {
          label: element.supplierName,
          value: element.amount
        };
        item.values.push(valueItem);
      });
      vm.suppliersBarData.push(item);
      vm.showbarChart = true;
    }

    function handlePieClick(e) {
      vm.supplierSel = '';
      vm.showbarChart = false;
      loadItemGroupsBySupplierId(e.data.supplierId);            
      vm.supplierSel = e.data.supplier;            
      $scope.$apply();
    }

    /**
    * [loadAll loads all top suppliers itemgroups vs amount]
    */
    function loadItemGroupsBySupplierId(d) {
      BusinessReportService.loadItemGroupsBySupplierId({
        fromDate:  !vm.fromDate ? '' : UTCDateService.utcFromDate(vm.fromDate),
        toDate: !vm.toDate ? '' : UTCDateService.utcToDate(vm.toDate),
        buyerBusinessIdList: !vm.filter.buyerBusinessIdList ? [] : vm.filter.buyerBusinessIdList,
        supplierIdList: !d ? [] : [d],
        mainItemGroupIdList: !vm.filter.mainItemGroupIdList ? [] : vm.filter.mainItemGroupIdList,
        itemGroupIdList: !vm.filter.itemGroupIdList ? [] : vm.filter.itemGroupIdList,
        query: ''
      },
        {
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort()
        }).then(function (response) {
          vm.topPurchasesByItemGroup = response.data;
          vm.barChart = barChart();
        }, function (error) {
          NotificationService.error({
            title: "Failed to retrieve the top suppliers report!",
            message: error
          });
        });
    }

    function resetCharts(){
      vm.showbarChart = false;
      vm.supplierSel = '';
      vm.topPurchasesByItemGroup = vm.duplicateTopPurchasesByItemGroup;
      vm.pieChart = pieChart();
      vm.barChart = barChart();      
    }

  }

}) ();
