/*
 * @Author: Neethu S 
 * @Date: 2023-08-22
 * @Project: Ezyprocure
 * @Filename: ezyap.peppol-orders-summary.details.controller.js
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapOrdersDetailsController', EzyapOrdersDetailsController);

  EzyapOrdersDetailsController.$inject = ['NotificationService', '$stateParams', 'ExtenalPeppolOrdersService', 'EzyapGetPosService', 'PDFExportService', 'CreateAdhocInvoiceService', '$state', '$filter', 'Auth'];

  function EzyapOrdersDetailsController(NotificationService, $stateParams, ExtenalPeppolOrdersService, EzyapGetPosService, PDFExportService, CreateAdhocInvoiceService, $state, $filter, Auth) {

    var vm = this;
    vm.poId = $stateParams.id;
    vm.init = init;
    vm.getPoDetailsById = getPoDetailsById;
    vm.sendPoById = sendPoById;
    vm.downloadURI = downloadURI;
    vm.userRole = Auth.getUserRole();
    vm.updateExportFlag = updateExportFlag;
    vm.getPoSummaryPDF = getPoSummaryPDF;
    vm.getUomFromItem = getUomFromItem;
    vm.createEinvoice = createEinvoice;

    vm.filter={};

    vm.init();
    function init() {
      vm.getPoDetailsById();
    }

    /** 
     * [get individual invoice details by invoice id]
     */
    function getPoDetailsById() {
      EzyapGetPosService.getPoDetailsById(vm.poId).
        then(function (response) {
          vm.poDetails = response.data;
          vm.poDetails.purchaseOrder.requestedDeliveryStartDate = $filter('date')(vm.poDetails.purchaseOrder.requestedDeliveryStartDate, "dd/MM/yyyy", 'UTC');
          vm.poDetails.purchaseOrder.requestedDeliveryEndDate = $filter('date')(vm.poDetails.purchaseOrder.requestedDeliveryEndDate, "dd/MM/yyyy", 'UTC');
          vm.poDetails.purchaseOrder.poIssueDate = $filter('date')(vm.poDetails.purchaseOrder.poIssueDate, "dd/MM/yyyy", 'UTC');
          vm.poDetails.purchaseOrder.poValidityDate = $filter('date')(vm.poDetails.purchaseOrder.poValidityDate, "dd/MM/yyyy", 'UTC');
        }, function (error) {
          NotificationService.error({
            title: '',
            error: error
          });
        });
    }

    function getUomFromItem(item) {
      return !!item.uom ? item.uom : (!!item.unitCode ? item.unitCode.name : null);
    }

    /**
     * [send invoice by invoice id]
     */
    function sendPoById() {
      ExtenalPeppolOrdersService.sendPoById(vm.poId).
        then(function (response) {
          vm.getPoDetailsById();
          NotificationService.success({
            title: 'global.sendPurchaseOrder',
            message: 'alertMessage.PURCHASE_ORDER_SEND_SUCCESSFULLY'
          })
        }, function (error) {
          NotificationService.error({
            title: 'global.sendPurchaseOrder',
            error: error
          });
        });
    }

    function getPoSummaryPDF() {
      PDFExportService.getPoSummaryPDF(vm.poId).then(function(response){
        vm.downloadURI(response.data.message, "PDF_" + vm.poId);
      },function(error) {
        NotificationService.error({
          title: 'Individual PDF Export',
          error: error
        });
      });
    }

    /**
     * [updateExportFlag Updates the export flag]
     * @param {Id} poId 
     */
    function updateExportFlag(poId){
      ExtenalPeppolOrdersService.updateExportFlag(poId)
        .then(function(response){
        },function(error){
      });
    }

    /**
     * 
     * @param {String} uri [uploaded url]
     * @param {String} fileName [the name of the file downloaded file]
     */
    function downloadURI(uri, fileName) {
      updateExportFlag([vm.poId]);
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = fileName;
      window.open(uri,'_blank');
    }

    /**
     *  [createEinvoice create eInvoice from po]
     */
    function createEinvoice() {
      $state.go('ezyap.buyer.eInvoice', {
          poData: vm.poDetails,
        
       });
    }
    

  }
})();
