/*
 * @Author: Neethu 
 * @Date: 2023-08-17 10:14:39 
 * @Project: Ezyprocure
 * @Filename: ezyap.buyer.manage-supplier-details.state.js
 */

(function () {
    'use strict';
  
    angular
      .module('ezyprocureApp')
      .config(stateConfig);
  
    stateConfig.$inject = ['$stateProvider'];
  
    function stateConfig($stateProvider) {
      $stateProvider
      .state('ezyap.buyer.manage-supplier-details', {
        parent: 'ezyap.buyer',
        url: '/manage-supplier-details',
        data: {
          authorities: ['ROLE_BUYER']
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/ezyap-tab-templates/manage-supplier-details/ezyap.manage-supplier-details.html',
            controller: 'EzyapSupplierDetailsController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,desc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort)};
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }],
          hasModuleAccess: ['Auth', '$q', '$state', function (Auth, $q, $state) {
            if (Auth.hasPermission('accessForPEPPOL') || Auth.hasPermission('accessForOnlyEzyAP')) {
              return true;
            } else {
              return $q.reject('Unauthorized');
            }
          }]
        }
      })
        .state('ezyap.buyer.manage-supplier-details.add', {
          parent: 'ezyap.buyer.manage-supplier-details',
          url: '/manage-supplier-details/add',
          data: {
            authorities: ['ROLE_BUYER']
          },
          views: {
            'content@': {
              templateUrl: 'app/common-modules/ezyap-tab-templates/manage-supplier-details/add/ezyap.manage-supplier-details.add.html',
              controller: 'EzyapAddSupplierDetailsController',
              controllerAs: 'vm'
            }
          },
          resolve: {
            pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
              return true;
            }],
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('global');
              return $translate.refresh();
            }],
            hasModuleAccess: ['Auth', '$q', '$state', function (Auth, $q, $state) {
              if (Auth.hasPermission('accessForPEPPOL') || Auth.hasPermission('accessForOnlyEzyAP')) {
                return true;
              } else {
                return $q.reject('Unauthorized');
              }
            }]
          }
        })
        .state('ezyap.buyer.manage-supplier-details.edit', {
          parent: 'ezyap.buyer.manage-supplier-details',
          url: '/manage-supplier-details/edit/:id',
          data: {
            authorities: ['ROLE_BUYER']
          },
          views: {
            'content@': {
              templateUrl: 'app/common-modules/ezyap-tab-templates/manage-supplier-details/edit/ezyap.manage-supplier-details.edit.html',
              controller: 'EzyapEditSupplierDetailsController',
              controllerAs: 'vm'
            }
          },
          resolve: {
            pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
              return true;
            }],
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('global');
              return $translate.refresh();
            }],hasModuleAccess: ['Auth', '$q', '$state', function (Auth, $q, $state) {
              if (Auth.hasPermission('accessForPEPPOL') || Auth.hasPermission('accessForOnlyEzyAP')) {
                return true;
              } else {
                return $q.reject('Unauthorized');
              }
            }]
          }
        })
    }
  })();
  