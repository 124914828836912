/*
 * @Author: Ajay 
 * @Date: 2019-02-11 11:37:01 
 * @Project: Ezyprocure
 * @Filename: ezyap.supplier.peppol-invoices-summary.state.js
 * @Last Modified by: Neethu S
 * @Last Modified time: 2023-09-06 
 */
(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('ezyap.supplier.peppol-invoices-summary', {
        parent: 'ezyap.supplier',
        url: '/peppol-invoice-summary',
        data: {
          authorities: ['ROLE_SUPPLIER']
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/ezyap-tab-templates/PEPPOL-invoices-summary/ezyap.peppol-invoices-summary.html',
            controller: 'EzyapPeppolInvoicesSummaryController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,desc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort)
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('invoice');
            $translatePartialLoader.addPart('purchaseOrder');
            $translatePartialLoader.addPart('items');
            return $translate.refresh();
          }]
        }
      })
      .state('ezyap.supplier.received-peppol-invoices-summary', {
        parent: 'ezyap.supplier',
        url: '/received-peppol-invoice-summary',
        data: {
          authorities: ['ROLE_SUPPLIER']
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/ezyap-tab-templates/PEPPOL-invoices-summary/ezyap.peppol-invoices-summary.html',
            controller: 'EzyapPeppolInvoicesSummaryController',
            controllerAs: 'vm'
          }
        },
        params: {
          type:'RECEIVED',
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,desc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort)
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      })
      .state('ezyap.supplier.peppol-invoices-summary.details', {
        parent: 'ezyap.supplier.peppol-invoices-summary',
        url: '/details/:id',
        data: {
          authorities: ['ROLE_SUPPLIER']
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/ezyap-tab-templates/PEPPOL-invoices-summary/details/ezyap.peppol-invoices-summary.details.html',
            controller: 'EzyapInvoicesDetailsController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return true;
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      })
  }
})();
