/**
 * @Author: Mufsir
 * @Date:   08-Dec-2016 19:49 +05:30
 * @Filename: auth.service.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-11-05T10:57:42+05:30
 */



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .factory('Auth', Auth);

  Auth.$inject = ['$rootScope', '$state', '$location', '$localStorage', '$q', '$translate', 'toastr', 'Principal', 'AuthServerProvider', 'Account', 'Password', 'PasswordResetInit', 'PasswordResetFinish', 'RoleBasedRoutingService', 'BuyerAccountService', 'SupplierAccountService', 'BuyerDataService', 'SupplierDataService', 'ROLE_CONSTANTS', 'MobileUserService', '$mdDialog', '$http', 'API_CONSTANTS', '$window', 'Redirect'];

  function Auth($rootScope, $state, $location, $localStorage, $q, $translate, toastr, Principal, AuthServerProvider, Account, Password, PasswordResetInit, PasswordResetFinish, RoleBasedRoutingService, BuyerAccountService, SupplierAccountService, BuyerDataService, SupplierDataService, ROLE_CONSTANTS, MobileUserService, $mdDialog, $http, API_CONSTANTS, $window, Redirect) {
    var service = {
      authorize: authorize,
      changePassword: changePassword,
      getPreviousState: getPreviousState,
      login: login,
      logout: logout,
      logoutBothPlatform: logoutBothPlatform,
      loginWithToken: loginWithToken,
      resetPasswordFinish: resetPasswordFinish,
      resetPasswordInit: resetPasswordInit,
      resetPreviousState: resetPreviousState,
      storePreviousState: storePreviousState,
      redirectToPreviousState: redirectToPreviousState,
      roleBasedRoute: roleBasedRoute,
      getUserRole: getUserRole,
      hasPermission: hasPermission,
      clearTokenAndRedirectToLogin: clearTokenAndRedirectToLogin,
      redirectToCbPlatform: redirectToCbPlatform,
      redirectToPlanPage: redirectToPlanPage,
      activateAccount: activateAccount,
      activateEzyprocureAccount:activateEzyprocureAccount,
      checkCbHealth: checkCbHealth,
      clearToken: clearToken,
      getXborderActivate:getXborderActivate,
      requestOTP: requestOTP,
      authenticateWithOTP:authenticateWithOTP,
      verifyUserValid: verifyUserValid
    };

    return service;

    function authenticateWithOTP(otp, module) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'otp/' + module,
        method: "POST",
        params: {},
        data: parseInt(otp)
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function requestOTP(module) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'otp/' + module,
        method: "GET",
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getXborderActivate(){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'xborder/activated',
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function authorize(force) {
      var authReturn = Principal.identity(force).then(authThen);

      return authReturn;

      function authThen() {
        var isAuthenticated = Principal.isAuthenticated();

        if (isAuthenticated) {
            if(!Principal.isUserVerifiedCB()) {
                verifyUserValid();
                Principal.setUserVerifiedCB(true);
            } else {
              checkIfUserPlanValid();
            }
          var buyerInfo = JSON.parse($localStorage["buyer-profile-info"] || "{}");
          var supplierInfo = JSON.parse($localStorage["supplier-profile-info"] || "{}");
          var buyerRole = (buyerInfo && buyerInfo.buyerUser && buyerInfo.buyerUser.userRoles ?
            buyerInfo.buyerUser.userRoles.name : null);
          var supplierRole = (supplierInfo && supplierInfo.supplierUser && supplierInfo.supplierUser.userRoles ?
            supplierInfo.supplierUser.userRoles.name : null);
          // check for restricted url access
          if(isAccessDeniedURL()) {
            toastr.error('Restricted URL!', 'Authorization Failed');
            clearTokenAndRedirectToLogin();
            return;
          }
          if ($rootScope.toState.data.authorities
            && $rootScope.toState.data.authorities.length > 0
            && $rootScope.toState.data.authorities.indexOf('ROLE_ADMIN') === -1) {
            // Principal.setHasCbPlatform(false);
            // if (Principal.isCBEnabled()) {
            //   checkCbHealth().then(function () {
            //     getCbUser(buyerRole, supplierRole);
            //   });
            // }
          }
          $rootScope.$broadcast('authenticationSuccess');
        }
        // an authenticated user can't access to login and landing pages, should go to previous state
        // From mobile app if the user closes the app and relaunches, it will go to landing page
        // If the user is authenticated and has a previous state, then he will automatically be redirected to previous state
        if (isAuthenticated && $rootScope.toState.parent === 'account' &&
          ($rootScope.toState.name === 'login' ||
            $rootScope.toState.name === 'landing' ||
            $rootScope.toState.name === 'init')) {
          if ($rootScope.fromState.name !== 'autologin' && $rootScope.fromState.name !== 'activating') {
            redirectToPreviousState();
          }
        }

        if ($rootScope.toState.data.authorities && $rootScope.toState.data.authorities.length > 0 && !Principal.hasAnyAuthority($rootScope.toState.data.authorities)) {
          if (isAuthenticated) {
            // user is signed in but not authorized for desired state
            $state.go('accessdenied');
          }
          else {
            // user is not authenticated. stow the state they wanted before you
            // send them to the login service, so you can return them when you're done
            storePreviousState($rootScope.toState.name, $rootScope.toStateParams);

            // now, send them to the signin state so they can log in
            roleBasedRoute();
          }
        }
      }
    }

    function verifyUserValid() {
      var buyerInfo = JSON.parse($localStorage["buyer-profile-info"] || "{}");
      var supplierInfo = JSON.parse($localStorage["supplier-profile-info"] || "{}");
      var buyerRole = (buyerInfo && buyerInfo.buyerUser && buyerInfo.buyerUser.userRoles ?
        buyerInfo.buyerUser.userRoles.name : null);
      var supplierRole = (supplierInfo && supplierInfo.supplierUser && supplierInfo.supplierUser.userRoles ?
        supplierInfo.supplierUser.userRoles.name : null);
      if ($rootScope.toState.data.authorities
        && $rootScope.toState.data.authorities.length > 0
        && $rootScope.toState.data.authorities.indexOf('ROLE_ADMIN') === -1) {
        Principal.setHasCbPlatform(false);
        if (Principal.isCBEnabled()) {
          checkCbHealth().then(function () {
            getCbUser(buyerRole, supplierRole);
          });
        }
      }
    }

    function getCbUser(buyerRole, supplierRole) {
      var authJWT = AuthServerProvider.getToken();
      var decAuthJWT = jwt_decode(authJWT);
      var role = decAuthJWT.auth;
      if (role == 'ROLE_SUPPLIER') {
        var isUserVerifiedKYc = Principal.isUserVerifiedKYC();
        if (isUserVerifiedKYc == false) {
          $state.go((supplierRole !== null ? 'supplier' : 'buyer') + '.contactadmin', { from: 'kyc' });
          return;
        }
        verifyUserPlans(buyerRole, supplierRole);
      } else if (role == 'ROLE_BUYER') {
        var isUserVerifiedKYc = Principal.isUserVerifiedKYC();
        if (isUserVerifiedKYc == false) {
          $state.go((supplierRole !== null ? 'supplier' : 'buyer') + '.contactadmin', { from: 'kyc' });
          return;
        }
        verifyUserPlans(buyerRole, supplierRole);
      }
    }

    /**
     * [redirectToPreviousState description]
     * If User should be redirect to a stored previous state, if available
     * If not, redirect to his roleBasedRoute
     */
    function redirectToPreviousState() {
      if (Principal.isAuthenticated()) {
        $rootScope.$broadcast('authenticationSuccess');
        var previousState = getPreviousState();
        if (previousState) {
          resetPreviousState();
          $state.go(previousState.name, previousState.params);
        } else {
          roleBasedRoute();
        }
      } else {
        $rootScope.$broadcast('authenticationFailed');
        $state.go("login");
      }
    }

    function changePassword(newPassword, callback) {
      var cb = callback || angular.noop;
      var cbAuthToken = AuthServerProvider.getCbToken();

      return Password.save(newPassword, function () {
        if (Principal.isCBEnabled()) {
          $http.post(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + 'account/password', newPassword, {
            headers: { Authorization: 'Bearer ' + cbAuthToken }
          });
        }
        return cb();
      }, function (err) {
        return cb(err);
      }).$promise;
    }


    function login(credentials, callback) {
      var cb = callback || angular.noop;
      var deferred = $q.defer();

      // call login service
      AuthServerProvider.login(credentials)
        .then(loginThen)
        .catch(function (err) {
          this.clearTokenAndRedirectToLogin();
          deferred.reject(err);
          return cb(err);
        }.bind(this));

      function loginThen(data) {
        Principal.identity(true).then(function (account) {
          // After the login the language will be changed to
          // the language selected by the user during his registration
          if (account !== null) {
            $translate.use(account.langKey.toLowerCase()).then(function () {
              $translate.refresh();
            });
          }
          deferred.resolve(data);
        });
        return cb();
      }
      return deferred.promise;
    }

    function loginWithToken(jwt, rememberMe) {
      return AuthServerProvider.loginWithToken(jwt, rememberMe);
    }

    /**
    * [logout method for logout using API]
    * @return {[type]} [description]
    */
    function logout() {
      $rootScope.$broadcast('authenticationFailed');
      AuthServerProvider.logout().then(function (response) {
        sessionStorage.clear();
        var deviceToken = MobileUserService.getDeviceToken();
        if (deviceToken !== null && Principal.isAuthenticated()) {
          AuthServerProvider.clearPushToken(deviceToken);
        } else {
          AuthServerProvider.clearStorageData();
        }
        Principal.authenticate(null);
        $state.go('login');
      }, function (error) {
      });
    }

    /**
    * [logout method for logout using API]
    * @return {[type]} [description]
    */
    function logoutBothPlatform() {
      $rootScope.$broadcast('authenticationFailed');
      return AuthServerProvider.logout().then(function (response) {
        sessionStorage.clear();
        var deviceToken = MobileUserService.getDeviceToken();
        if (deviceToken !== null && Principal.isAuthenticated()) {
          AuthServerProvider.clearPushToken(deviceToken);
        } else {
          AuthServerProvider.clearStorageData();
        }
        Principal.authenticate(null);
        return Promise.resolve();
      }, function (error) {
      });
    }

    function clearTokenAndRedirectToLogin() {
      $rootScope.$broadcast('authenticationFailed');
      var deviceToken = MobileUserService.getDeviceToken();
      if (deviceToken !== null && Principal.isAuthenticated()) {
        AuthServerProvider.clearPushToken(deviceToken);
      } else {
        AuthServerProvider.clearStorageData();
      }
      Principal.authenticate(null);
      $state.go('login');
    }

    function resetPasswordFinish(keyAndPassword, callback) {
      var cb = callback || angular.noop;

      return PasswordResetFinish.save(keyAndPassword, function () {
        return cb();
      }, function (err) {
        return cb(err);
      }).$promise;
    }


    function resetPasswordInit(mail, callback) {
      var cb = callback || angular.noop;

      return PasswordResetInit.save(mail, function () {
        return cb();
      }, function (err) {
        return cb(err);
      }).$promise;
    }

    // check for restricted url access on all domains
    function isAccessDeniedURL() {
      var hostMapping = Redirect.getHostMapping();
      if (hostMapping && !(getUserRole().pretty == "admin" && hostMapping["allowAdmin"])) {
        if (!hasPermission(hostMapping["accessFlag"])) {
          return true;
        }
        if ($rootScope.toState.parent !== 'account') {
          var toEzyAP = $rootScope.toState.parent && $rootScope.toState.parent.indexOf(hostMapping["url"]);
          if (toEzyAP == undefined || toEzyAP == -1) {
            return true;
          }
        }
      }
      return false;
    }

    function roleBasedRoute() {
      //Retrieve auth token
      var authJWT = AuthServerProvider.getToken();
      if (authJWT) {
        $rootScope.$broadcast('authenticationSuccess');
        var decAuthJWT = jwt_decode(authJWT);
        var redirectUrl = $rootScope.destinationUrl || Redirect.getHostMapping();
        if (decAuthJWT.auth === "ROLE_BUYER") {
          var buyer = 'buyer/';
          BuyerAccountService.getBuyerProfile().
            then(function (response) {
              if (response) {
                BuyerDataService.setBuyerProfile(response);
                // redirect to role based route
                if(redirectUrl) {
                  if($rootScope.destinationUrl){
                    $window.location.href = API_CONSTANTS.ezyProcureBase + '#/' + $location.$$search.destinationUrl;
                  } else {
                    $state.go(redirectUrl["url"] + '.buyer.dashboard');
                  }
                }
                else {
                  $state.go(RoleBasedRoutingService.getRoleBasedRoute(decAuthJWT.auth));
                }
              }
            });
          if (Principal.isCBEnabled()) {
            checkCbHealth().then(function () {
              BuyerAccountService.getCbBuyerProfile().
                then(function (response) {
                  if (response) {
                    Principal.setHasCbPlatform(true);
                    BuyerDataService.setCbBuyerProfile(response);
                  }
                });
            });
          }
        } else if (decAuthJWT.auth === "ROLE_SUPPLIER") {
          var supplier = 'supplier/'
          SupplierAccountService.getSupplierProfile().
            then(function (response) {
              if (response) {
                SupplierDataService.setSupplierProfile(response);
                if(redirectUrl) {
                  if($rootScope.destinationUrl){
                    $window.location.href = API_CONSTANTS.ezyProcureBase + '#/' + $location.$$search.destinationUrl;
                  } else {
                    $state.go(redirectUrl["url"] + '.supplier.dashboard');
                  }
                }
                else {
                  $state.go(RoleBasedRoutingService.getRoleBasedRoute(decAuthJWT.auth));
                }
              }
            });
          if (Principal.isCBEnabled()) {
            checkCbHealth().then(function () {
              SupplierAccountService.getCbSupplierProfile().
                then(function (response) {
                  if (response) {
                    Principal.setHasCbPlatform(true);
                    SupplierDataService.setCbSupplierProfile(response);
                  }
                });
            });
          }
        } else {
          // if admin
          $state.go(RoleBasedRoutingService.getRoleBasedRoute(decAuthJWT.auth));
        }
      }
      else {
        $rootScope.$broadcast('authenticationFailed');
        clearTokenAndRedirectToLogin();
      }
    }


    function getPreviousState() {
      var previousState = $localStorage.previousState;
      return previousState;
    }

    function resetPreviousState() {
      delete $localStorage.previousState;
    }

    //store previous state
    function storePreviousState(previousStateName, previousStateParams) {
      var previousState = { "name": previousStateName, "params": previousStateParams };
      $localStorage.previousState = previousState;
    }

    //Retuns the role of the logged in user
    function getUserRole() {
      var authJWT = AuthServerProvider.getToken();
      if (authJWT) {
        var decAuthJWT = jwt_decode(authJWT);
        var roleDetails = {};
        roleDetails.ugly = decAuthJWT.auth;
        roleDetails.pretty = ROLE_CONSTANTS.roleNames[decAuthJWT.auth];
        return roleDetails;
      }
    }

    function hasPermission(accessKey) {
      var userRole = getUserRole().pretty;
      var accessControls = {};
      if (userRole === 'buyer')
        accessControls = BuyerDataService.getBuyerProfile().accessDTO;
      else if (userRole === 'supplier') {
        accessControls = SupplierDataService.getSupplierProfile().accessDTO;
      }
      if (accessControls[accessKey] !== true) return false;
      else return true;
    }

    function fetchuserPlans() {
      var authJWT = AuthServerProvider.getToken();
      if (authJWT != undefined) {
        var decAuthJWT = jwt_decode(authJWT);
        var userRole = decAuthJWT.auth === 'ROLE_BUYER' ? 'buyer' : 'supplier';
        return getCompanyId(userRole).then(function (companyId) {
          var deferred = $q.defer();
          var cbAuthToken = AuthServerProvider.getCbToken();
          $http.get(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + 'user-plans/verify/' +
            companyId + '/' + (decAuthJWT.auth == 'ROLE_SUPPLIER' ? 'supplier' : decAuthJWT.auth == 'ROLE_BUYER' ? 'buyer' : ''),
            {
              headers: { Authorization: 'Bearer ' + cbAuthToken }
            })
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (message) {
              Principal.setHasCbPlatform(false);
              deferred.reject(message);
            });
          return deferred.promise;
        });
      }
    }

    function getCompanyId(userRole) {
      if (userRole === 'buyer') {
        return BuyerAccountService.getCbBuyerProfile()
          .then(function (buyerInfo) {
            return buyerInfo && buyerInfo.buyerUser && buyerInfo.buyerUser.buyer.id ? buyerInfo.buyerUser.buyer.id : null;
          });
      } else if (userRole === 'supplier') {
        return SupplierAccountService.getCbSupplierProfile()
          .then(function (supplierInfo) {
            return supplierInfo && supplierInfo.supplier && supplierInfo.supplier.id ? supplierInfo.supplier.id : null;
          });
      }
    }

    function verifyUserPlans(buyerRole, supplierRole) {
      return fetchuserPlans().then(function (response) {
        Principal.setUserHaveValidPlan(response.doesHaveDomestic);
        if (response.doesHaveDomestic == false) {
          if (response.doesHaveCrossBorder == true) {
            $mdDialog.show({
              templateUrl: 'app/layouts/cb-components/cb-platform-redirect-modal.html',
              controller: function () {
                return service;
              },
              controllerAs: 'vm',
              clickOutsideToClose: true
            });
          }
        }
        if (response.doesHaveDomestic == false &&
          response.domesticStatus &&
          response.domesticStatus.planType === 'transaction' &&
          response.domesticStatus.paymentFailure) {
          var amount = response.domesticStatus.paymentAmount;
          if (buyerRole && buyerRole == 'ADMIN_BUYER') {
            $state.go('buyer.addcard', {
              transactionFailed: true,
              transactionAmount: amount,
              transactioncurrencyCode: response.domesticStatus.currencyCode,
              transactionPaymentId: response.domesticStatus.paymentId
            });
          } else if (supplierRole && supplierRole == 'ADMIN_SUPPLIER') {
            $state.go('supplier.addcard', {
              transactionFailed: true,
              transactionAmount: amount,
              transactioncurrencyCode: response.domesticStatus.currencyCode,
              transactionPaymentId: response.domesticStatus.paymentId
            });
          }
        }
        if (response.doesHaveDomestic == false) {
          if (buyerRole == 'ADMIN_BUYER' || supplierRole == 'ADMIN_SUPPLIER') {
            if ($rootScope.toState.name !== 'addcard') {
              if (buyerRole == 'ADMIN_BUYER') {
                $state.go('buyer.subscription');
              } else if (supplierRole == 'ADMIN_SUPPLIER') {
                $state.go('supplier.subscription');
              }
            }
          } else {
            if ($rootScope.toState.data.authorities.indexOf('ROLE_SUPPLIER') > -1) {
              $state.go('supplier.contactadmin');
            } else if ($rootScope.toState.data.authorities.indexOf('ROLE_BUYER') > -1) {
              $state.go('buyer.contactadmin');
            }
          }
        }
      });
    }

    function redirectToCbPlatform() {
      checkCbHealth().then(function() {
        var token = AuthServerProvider.getCbToken();
        var domToken = AuthServerProvider.getToken();
        if (token) {
          $window.location.href = API_CONSTANTS.cbBase + '#/autologin/save/' + token + '/' + domToken;
        } else {
          $window.location.href = API_CONSTANTS.cbBase;
        }
      });
    }

    function redirectToPlanPage() {
      $mdDialog.cancel();
      var buyerRole = ($localStorage["buyer-profile-info"] ? JSON.parse($localStorage["buyer-profile-info"]).buyerUser.userRoles.name : null);
      var supplierRole = ($localStorage["supplier-profile-info"] ? JSON.parse($localStorage["supplier-profile-info"]).supplierUser.userRoles.name : null);
      if (buyerRole == 'ADMIN_BUYER') {
        $state.go('buyer.subscription');
      } else if (supplierRole == 'ADMIN_SUPPLIER') {
        $state.go('supplier.subscription');
      }
    }

    function activateAccount(key) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + 'activate?key=' + key)
        .then(function (data) {
          Principal.authenticate(true);
          activateDomAccount(key);
          deferred.resolve(data.data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    function activateEzyprocureAccount(key) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'activate?key=' + key)
        .then(function (data) {
          Principal.authenticate(true);
          activateDomAccount(key);
          deferred.resolve(data.data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    function activateDomAccount(key) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'activate?key=' + key)
        .then(function (data) {
          Principal.authenticate(true);
          deferred.resolve(data.data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    function checkCbHealth() {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + 'health', {
        responseType: 'blob'
      })
        .then(function (data) {
          Principal.setHasCbPlatform(true);
          deferred.resolve(data);
        }).catch(function (message) {
          Principal.setHasCbPlatform(false);
          deferred.reject(message);
        });
      return deferred.promise;
    }

    function clearToken() {
      $rootScope.$broadcast('authenticationFailed');
      var deviceToken = MobileUserService.getDeviceToken();
      if (deviceToken !== null && Principal.isAuthenticated()) {
        AuthServerProvider.clearPushToken(deviceToken);
      } else {
        AuthServerProvider.clearStorageData();
      }
      Principal.authenticate(null);
    }


    function checkIfUserPlanValid() {
      var buyerInfo = JSON.parse($localStorage["buyer-profile-info"] || "{}");
      var supplierInfo = JSON.parse($localStorage["supplier-profile-info"] || "{}");
      var buyerRole = (buyerInfo && buyerInfo.buyerUser && buyerInfo.buyerUser.userRoles ?
        buyerInfo.buyerUser.userRoles.name : null);
      var supplierRole = (supplierInfo && supplierInfo.supplierUser && supplierInfo.supplierUser.userRoles ?
        supplierInfo.supplierUser.userRoles.name : null);

      if(Principal.isCBEnabled() && Principal.hasCbPlatform()) {
        if(Principal.isUserVerifiedKYC() === false) {
          if ($rootScope.toState.name !== 'buyer.contactadmin'
            && $rootScope.toState.name !== 'supplier.contactadmin') {
            var authJWT = AuthServerProvider.getToken();
            var decAuthJWT = jwt_decode(authJWT);
            var role = decAuthJWT.auth;
            if (role == 'ROLE_SUPPLIER') {
              var isUserVerifiedKYc = Principal.isUserVerifiedKYC();
              if (isUserVerifiedKYc == false) {
                $state.go((supplierRole !== null ? 'supplier' : 'buyer') + '.contactadmin', { from: 'kyc' });
                return;
              }
            } else if (role == 'ROLE_BUYER') {
              var isUserVerifiedKYc = Principal.isUserVerifiedKYC();
              if (isUserVerifiedKYc == false) {
                $state.go((supplierRole !== null ? 'supplier' : 'buyer') + '.contactadmin', { from: 'kyc' });
                return;
              }
            }
          }
        }
        if(Principal.isUserHaveValidPlan() === false) {
          if ($rootScope.toState.name !== 'buyer.addcard'
          && $rootScope.toState.name !== 'supplier.addcard'
          && $rootScope.toState.name !== 'buyer.listcards'
          && $rootScope.toState.name !== 'supplier.listcards'
          && $rootScope.toState.name !== 'buyer.subscription'
          && $rootScope.toState.name !== 'supplier.subscription') {
            if (buyerRole == 'ADMIN_BUYER') {
              $state.go('buyer.subscription');
            } else if (supplierRole == 'ADMIN_SUPPLIER') {
              $state.go('supplier.subscription');
            }
          }
        }
      }
    }
  }
})();
