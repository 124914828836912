/**
* @Author: Ajay
* @Date:   01-Mar-2017 12:49 +05:30
* @Project: Ezyprocure
* @Filename: buyer.navbar.controller.js
 * @Last modified by:   Atul
 * @Last modified time: Mon Jul 10 2023 15:43:21 GMT+0530 (India Standard Time)
*/



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('BuyerNavbarController', BuyerNavbarController);

  BuyerNavbarController.$inject = ['$rootScope', 'toastr', '$mdMedia', '$mdDialog', '$location', '$window', '$stateParams', '$timeout', '$state', 'ReleaseNotesMasterService', 'WhatsNewService', 'Auth', 'BuyerAccountService', 'BuyerDataService', 'CartService', '$localStorage', 'UserRedirectService', 'NotificationService', 'API_CONSTANTS', 'Principal', 'AuthServerProvider', '$cookies', 'GLOBAL_CONSTANTS','$scope','BnplBuyerDashboardService'];

  function BuyerNavbarController($rootScope, toastr, $mdMedia, $mdDialog, $location, $window, $stateParams, $timeout, $state, ReleaseNotesMasterService, WhatsNewService, Auth, BuyerAccountService, BuyerDataService, CartService, $localStorage, UserRedirectService, NotificationService, API_CONSTANTS, Principal, AuthServerProvider, $cookies, GLOBAL_CONSTANTS,$scope,BnplBuyerDashboardService) {
   
    var vm = this;

    vm.init = init;
    vm.getBuyerProfile = getBuyerProfile;
    vm.getCartDetails = getCartDetails;
    vm.resolveAccessForAuditLog = resolveAccessForAuditLog;
    vm.logout = logout;
    vm.cartCount = 0;
    $rootScope.back = back;
    vm.getEzysourcingTempToken = getEzysourcingTempToken;
    vm.redirectToEzypayment = redirectToEzypayment;
    vm.redirectTobnpl=redirectTobnpl;
    vm.userInfo = JSON.parse($localStorage["buyer-profile-info"] || "{}");
    vm.redirectToEzyap = redirectToEzyap;
    vm.redirectToEzyInventory = redirectToEzyInventory;
    vm.redirectToAlexWorld = redirectToAlexWorld;
    vm.isMobile = $mdMedia('xs');
    vm.userRole = null;
    vm.checkPlan = checkPlan;
    vm.submitRequest = submitRequest;
    vm.hasCbPlatform = hasCbPlatform;
    vm.authenticateAndRedirect = authenticateAndRedirect;
    vm.requestOTP = requestOTP;
    vm.cancel = cancel;
    vm.activateBNPL=activateBNPL;
    vm.bnplBtnIsDisabled=true;
    vm.bnplStatusName='Activate BNPL';
    vm.bnplTerms=false;
    // 2FA changes
    vm.otp = undefined;
    vm.isOtpValid = true;
    vm.counterMilliSeconds = undefined;
    vm.counterSeconds = undefined;
    vm.counterMinutes = undefined;
    vm.validateOTP = validateOTP;
    vm.resendOTP = resendOTP;
    // whats new dialog
    vm.whatsNew = {};
    vm.hideDialog = hideDialog;
    vm.cancelDialog = cancelDialog;
    vm.confirmBnpl=confirmBnpl;
    vm.getBnplBuyerProfile=getBnplBuyerProfile;
    vm.showSubscriptionRenewalPopup = showSubscriptionRenewalPopup;
    function init() {
      vm.accessForEzyinventory = BuyerDataService.getBuyerProfile().accessDTO.accessForEzyinventory;
      vm.accessForManageAdhoc = BuyerDataService.getBuyerProfile().accessDTO.accessForManageAdhoc;
      vm.getBuyerProfile();
      vm.getCartDetails();
      vm.getBnplBuyerProfile();
      vm.userRole = (vm.userInfo.buyerUser.userRoles ? vm.userInfo.buyerUser.userRoles.name : null);
      // dialog display check
      checkWhatsNewStatus();
      // license expiration check
      const licenseExpirationDataString = sessionStorage.getItem('licenseExpirationData');

      if (licenseExpirationDataString) {
        console.log(licenseExpirationDataString);
        const licenseExpirationData = JSON.parse(licenseExpirationDataString);
        console.log(licenseExpirationData);
        if (Object.keys(licenseExpirationData).length > 0) {
          console.log('License expiration data is available:', licenseExpirationData);
          vm.showSubscriptionRenewalPopup(licenseExpirationDataString);
        } else {
          console.log('License expiration data is an empty object.');
        }
      } else {
        console.log('License expiration data is not available.');
      }

    }

    function submitRequest() {
        $mdDialog.show({
            templateUrl: 'app/core/common-templates/submit-request-hubspot.html',
            controller: function () {
                return vm;
              },
              controllerAs: 'vm',
              targetEvent: event,
            clickOutsideToClose: true
          });
    }
// BNPL Code //
function getBnplBuyerProfile()
{
   var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
  BnplBuyerDashboardService.getBnplStatus(buyerId).then(function (response) {
   if(response.status==200){
    $scope.bnplBuyerDetail=response.data;
    localStorage.setItem('bnplBuyerDetail',JSON.stringify($scope.bnplBuyerDetail));
    bnplProcess($scope.bnplBuyerDetail);
   }
  }, function (error) {
    $scope.bnplStatusName='Activate BNPL';
    $scope.bnplStatus="NOT_ACTIVATED";
    $scope.bnplButtonCheck($scope.bnplStatus)
  });
}

function bnplProcess(bnplBuyerDetail)
{
  
  $scope.bnplStatus=bnplBuyerDetail.bnplStatus;
  $scope.bnplStatusName=bnplBuyerDetail.bnplStatusName;
  $scope.bnplButtonCheck($scope.bnplStatus)
}
$scope.bnplStatusName='BNPL Status';

$scope.bnplButtonCheck=function(bnplStatus)
{
  
  // PENDING, ACTIVATED, REJECTED, SUSPENDED, NOT_ACTIVATED, UNAUTHORISED
  switch (bnplStatus) {
    
    case "NOT_ACTIVATED":
      vm.bnplBtnIsDisabled=false;
    break;
    case "PENDING":
      vm.bnplBtnIsDisabled=true;
    break;
    case "APPROVED":
      vm.bnplBtnIsDisabled=false;
    break;
    case "ACTIVATED":
      vm.bnplBtnIsDisabled=false;
    break;
    case "UNAUTHORISED":
      vm.bnplBtnIsDisabled=true;
    break;
    case "REJECTED":
      vm.bnplBtnIsDisabled=false;
    break;
    case "SUSPENDED":
      vm.bnplBtnIsDisabled=false;
    break;
    default:
      break;
  }

}

// status is comming BNPL (activated and suspended)
// PENDING, ACTIVATED, REJECTED, SUSPENDED, NOT_ACTIVATED, UNAUTHORISED
  $scope.bnblStatusChange= function ()
  {
   localStorage.setItem("bnplStatus",$scope.bnplStatus)
  switch ($scope.bnplStatus) {
    case 'PENDING':
    break;
    case 'REJECTED':
      vm.activateBNPL();
    break;
    case 'SUSPENDED':
       vm.redirectTobnpl();
    break;
    case 'NOT_ACTIVATED':
      vm.activateBNPL();
    break;
    case 'ACTIVATED':
      vm.redirectTobnpl();
    break;
    case 'APPROVED':
      vm.redirectTobnpl();
    break;
    case 'UNAUTHORISED':
      
    break;
    default:
      break;
  }
  }
function confirmBnpl()
{

  BnplBuyerDashboardService.activateBNPL({
    name: vm.user.name,
    email: vm.user.email,
    contactNumber: vm.user.contactNo,
    bnplStatus:"PENDING"
  }).then(function (response) {
   
   if(response.status==201)
   {
    $mdDialog.cancel();
    vm.getBnplBuyerProfile()
  }
  }, function (error) {
  });
}
  function redirectTobnpl() {
    $state.go('bnpl.buyer.dashboard', {
      reload: false
    });
  }
  vm.user = {
   name:"",// Your Name
   email:"",// Your Email
   contactNo:""// Your contact no.
  }


    function activateBNPL() {
      $mdDialog.show({
          templateUrl: 'app/core/common-templates/activateBNPL.html',
          controller: function () {
              return vm;
            },
            controllerAs: 'vm',
            targetEvent: event,
          clickOutsideToClose: true
        });
  }

  function cancel() 
  {
     $mdDialog.cancel();
  }
  
 
    function getBuyerProfile() {
      BuyerAccountService.getBuyerProfile().
        then(function (response) {
          if (response) {
            vm.buyerProfile = response;
            vm.buyerAccess = vm.buyerProfile.accessDTO;
            $rootScope.$broadcast('buyer-profile-update', vm.buyerProfile);
            BuyerDataService.setBuyerProfile(vm.buyerProfile);
          }
        }, function (error) {
        });
        if(Principal.isCBEnabled()) {
          Auth.checkCbHealth().then(function() {
            BuyerAccountService.getCbBuyerProfile().
              then(function (response) {
                if (response) {
                  BuyerDataService.setCbBuyerProfile(response);
                }
              }, function (error) {
              });
          });
        }
    }
    function getCartDetails() {
      CartService.getCartDetails({
        page: 0,
        size: 100000
      }).then(function (response) {
        vm.cartCount = response.cartCount ? response.cartCount : 0;
      }, function (error) {
      });
    }

    $rootScope.$on('refresh-cart', function () {
      vm.getCartDetails();
    });

    $rootScope.$on('refresh-cart-count', function (event, count) {
      vm.cartCount = count;
    });

    function logout() {
      if (Principal.isCBEnabled() && Principal.hasCbPlatform()) {
        Auth.checkCbHealth().then(function() {
          Auth.logoutBothPlatform()
            .then(function () {
              $window.location.href = API_CONSTANTS.cbBase + '#/autologin/delete/null/'
            });
        }).catch(function() {
          Auth.logout();
        });
      } else {
        Auth.logout();
      }
    }

    function back() {
      $window.history.back();
    }

    function popWhatsNewDialog() {
      $mdDialog.show({
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        templateUrl: 'app/common-modules/whats-new/whats-new-tmpl.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false
      }).then(function () {
        WhatsNewService.updateHasReadValue(true);
      }, function () {
        WhatsNewService.updateHasReadValue(false);
      });
    }

    function hideDialog() {
      $mdDialog.hide();
    }

    function cancelDialog() {
      $mdDialog.cancel();
    }

    function checkWhatsNewStatus() {
      ReleaseNotesMasterService.getActiveReleaseNotes().then(function(response) {
        vm.whatsNew = response.data[0];
        if(Array.isArray(response.data) && WhatsNewService.getHasReadValue(response.data[0].releaseVersion) && (response.data[0].noticeFor=="FOR_BUYERS"||response.data[0].noticeFor=="FOR_ALL")) {
          popWhatsNewDialog();
        }
      }, function(error) {

      });
    }

    /**
     * function getEzysourcingTempToken gets temporary token for ezySourcing redirection where the temporary token contains the user details which has to be passed through the url
     */
    function getEzysourcingTempToken() {
      UserRedirectService.getEzysourcingTempToken(vm.userInfo.buyerUser.user.id).then(function (response) {
        vm.tempToken = response.id_token;
        UserRedirectService.redirectToEzysourcing(vm.tempToken);
      }, function (error) {
        NotificationService.error({
          title: 'ezysource',
          error: error
        });
      });
    }

    /**
     * redirection done in controller (to avoid broken UI instead of using ui-sref in html)
     */
    function redirectToEzypayment() {
      $state.go('ezypayment.buyer.dashboard', {
        reload: false
      });
    }

    function requestOTP() {
      Auth.requestOTP('EZYPAYMENT_OTP').then(function (response) {
        if(response.data.status == 'OTP_SENT') {
          showOtpPrompt();
        }
      },
      function(error) {
        toastr.error('OTP Request Failed', 'Error');
      });
    }

    function authenticateAndRedirect(otp) {
      Auth.authenticateWithOTP(otp, 'EZYPAYMENT_OTP').then(function (response) {
        $mdDialog.hide();
        redirectToEzypayment();
      },
      function(error) {
        toastr.error('Invalid OTP', 'AUTHENTICATION FAILED');
      });
    }

    // timer for otp resend
    function counter() {
      vm.stopped = $timeout(function () {
        vm.counterMilliSeconds -= 1000;
        if (vm.counterMilliSeconds) {
          var seconds = vm.counterMilliSeconds / 1000;
          vm.counterMinutes = Math.floor(seconds / 60);
          if(vm.counterMinutes > 0) {
            vm.counterSeconds = seconds - (vm.counterMinutes * 60);
          }
          else {
            vm.counterSeconds = seconds;
          }
          counter();
        }
        else {
          vm.counterSeconds = 0;
          vm.counterMinutes = 0;
          stopCounter();
        }
      }, 1000);
    };

    function stopCounter(){
      vm.isOtpValid = false;
      $timeout.cancel(vm.stopped);
    }

    function showOtpPrompt() {
      // clear otp
      vm.otp = undefined;
      vm.isOtpValid = true;
      vm.counterSeconds = 0;
      vm.counterMinutes = 0;
      vm.counterMilliSeconds = GLOBAL_CONSTANTS.OTP_EXPIRY_MINUTES * 60 * 1000;
      counter();
      // show prompt
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/otp-templates/otp-capture.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function validateOTP() {
      if (!vm.otp) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }

      if (vm.otp && (vm.otp.length < 5 || vm.otp.length > 8)) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }
      authenticateAndRedirect(vm.otp);
    }

    function resendOTP() {
      requestOTP();
    }

    /**
     * [redirectToEzyap Redirects to buyer dashboard]
     */
    function redirectToEzyap() {
      $state.go('ezyap.buyer.dashboard', {
        reload: false
      });
    }

    /**
     * @desc redirect to ezyinventory
     */
    function redirectToEzyInventory() {
      var authToken = $localStorage.authenticationToken;
      window.open(API_CONSTANTS.ezyinventoryDomainName + "redirect?authtoken=" + authToken);
    }

    function resolveAccessForAuditLog() {
      return BuyerDataService.isAdminBuyer() || !Auth.hasPermission("accessForDisableAuditLogNonAdminUsers")
    }

    function hasCbPlatform() {
      return Principal.hasCbPlatform() && Principal.isCBEnabled();
    }

    function checkPlan() {
      return ((Principal.isUserHaveValidPlan() && Principal.isUserVerifiedKYC()) || !Principal.hasCbPlatform()) || !Principal.isCBEnabled();
    }

    vm.redirectToCbPlatform = function () {
      Auth.checkCbHealth().then(function() {
        var token = AuthServerProvider.getCbToken();
        var domToken = AuthServerProvider.getToken();
        if (token) {
          $window.location.href = API_CONSTANTS.cbBase + '#/autologin/save/' + token + '/' + domToken;
        } else {
          $window.location.href = API_CONSTANTS.cbBase;
        }
      }).catch(function() {
        toastr.error('Something went wrong');
      });
    }

    vm.redirectToCbPayPlatform = function () {
        Auth.checkCbHealth().then(function() {
          UserRedirectService.sync().then(function () {
              var token = AuthServerProvider.getCbToken();
              var domToken = AuthServerProvider.getToken();
              if (token) {
                $window.location.href = API_CONSTANTS.cbBase + '#/autologin/save/' + token + '/' + domToken + '?destinationUrl=ezypayment/buyer/dashboard';
              } else {
                $window.location.href = API_CONSTANTS.cbBase;
              }
          }).catch(function (data) {
            toastr.error('Something went wrong');
          })
        }).catch(function() {
          toastr.error('Something went wrong');
        });
      }
    function redirectToAlexWorld() {
      $state.go('buyer.alex-world');
    }

    function showSubscriptionRenewalPopup(licenseExpirationDataString) {
      console.log(licenseExpirationDataString);
      $mdDialog.show({
        templateUrl: 'app/common-modules/web-reminder/web-reminder.html',
        controller: 'WebReminderController',
        controllerAs: 'vm',
        escapeToClose: false,
        clickOutsideToClose: false,
        resolve: {
          data: function () {
            return JSON.parse(licenseExpirationDataString);
          }
        }
      });
    }
  }
})();
