/*
 * @Author: lovin 
 * @Date: 2019-08-27 19:15:49 
 * @Filename: ezypayment.admin.account-details.add.controller.js
 * @Last Modified by: lovin
 * @Last Modified time: 2019-11-01 19:17:41
 */

(function () {
    'use strict';
  
    angular
      .module('ezyprocureApp')
      .config(stateConfig);
  
    stateConfig.$inject = ['$stateProvider'];
  
    function stateConfig($stateProvider) {
      $stateProvider
        .state('bnpl.buyer.account-details', {
          parent: 'bnpl.buyer',
          url: '/account-details',
          data: {
            authorities: ['ROLE_BUYER']
          },
          views: {
            'content@': {
              templateUrl: 'app/common-modules/bnpl/account-details/bnpl.admin.account-details.html',
              controller: 'BnplAccountDetailsController',
              controllerAs: 'vm'
            }
          },
          params: {
            page: {
              value: '1',
              squash: true
            },
            sort: {
              value: 'id,asc',
              squash: true
            },
            search: null
          },
          resolve: {
            pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
              return {
                page: PaginationUtil.parsePage($stateParams.page),
                sort: $stateParams.sort,
                predicate: PaginationUtil.parsePredicate($stateParams.sort),
                ascending: PaginationUtil.parseAscending($stateParams.sort),
                search: $stateParams.search
              };
            }],
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('global');
              $translatePartialLoader.addPart('paymentTerms');
              return $translate.refresh();
            }]
          }
        })
        .state('bnpl.buyer.account-details.add', {
            parent: 'bnpl.buyer.account-details',
            url: '/account-details-add',
            data: {
              authorities: ['ROLE_BUYER']
            },
            views: {
              'content@': {
                templateUrl: 'app/common-modules/bnpl/account-details/add/bnpl.admin.account-details.add.html',
                controller: 'BnplAccountDetailsAddController',
                controllerAs: 'vm'
              }
            },
            resolve: {
              translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('global');
                $translatePartialLoader.addPart('buyerBusiness');
                $translatePartialLoader.addPart('bank');
                $translatePartialLoader.addPart('paymentTerms');
                return $translate.refresh();
              }]
            }
          })
          .state('bnpl.buyer.account-details.edit', {
            parent: 'bnpl.buyer.account-details',
            url: '/edit/:brandId',
            data: {
              authorities: ['ROLE_BUYER']
            },
            views: {
              'content@': {
                templateUrl: 'app/common-modules/bnpl/account-details/edit/bnpl.admin.account-details.edit.html',
                controller: 'BnplAccountDetailsEditController',
                controllerAs: 'vm'
              }
            },
            resolve: {
              translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('global');
                $translatePartialLoader.addPart('buyerBusiness');
                $translatePartialLoader.addPart('bank');
                $translatePartialLoader.addPart('paymentTerms');
                return $translate.refresh();
              }]
            }
          });
    }
  
  })();
  