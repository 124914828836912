/*
 * @Author: Ajay 
 * @Date: 2019-02-21 14:52:01 
 * @Project: Ezyprocure
 * @Filename: ezyap.supplier.state.js
 * @Last Modified by: Ajay
 * @Last Modified time: 2019-02-21 14:52:23
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('ezyap.supplier', {
      parent: 'app',
      url: '/ezyap/supplier',
      data: {
        authorities: ['ROLE_SUPPLIER']
      },
      views: {
        'navbar@': {
          templateUrl: 'app/ezyap-modules/supplier-modules/common/ezyap.supplier.navbar.html',
          controller: 'EzyapSupplierNavbarController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth', '$q', '$state', function (Auth, $q, $state) {
          if (Auth.hasPermission('accessForPEPPOL') || Auth.hasPermission('accessForOnlyEzyAP')) {
            return true;
          } else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    }).state('ezyap.supplier.send-email', {
      parent: 'ezyap.supplier',
      url: '/send-email',
      data: {
        authorities: ['ROLE_SUPPLIER']
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/email/supplier.email.html',
          controller: 'SupplierEmailController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          return $translate.refresh();
        }]
      }
    });
  }
})();
