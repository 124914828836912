/*
 * @Author: Neethu 
 * @Date:  2023-08-23
 * @Project: Ezyprocure
 * @Filename: ezyap.manage-delivery-details.add.controller.js 
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapAddSupplierDetailsController', EzyapAddSupplierDetailsController);

  EzyapAddSupplierDetailsController.$inject = ['NotificationService', 'CsvUploadService', 'UtilService', 'ManageSupplierDetailsService', '$state', '$filter', 'ManageSupplierInfoService', 'EZYAP_CONSTANTS', 'Auth'];

  function EzyapAddSupplierDetailsController(NotificationService, CsvUploadService, UtilService, ManageSupplierDetailsService, $state, $filter, ManageSupplierInfoService, EZYAP_CONSTANTS, Auth) {

    var vm = this;
    vm.addDeliveryDetails = addDeliveryDetails;
    vm.getSupplierInformation = getSupplierInformation;
    vm.countryCodes = EZYAP_CONSTANTS.ezyap.countryCodes;
    vm.userRole = Auth.getUserRole();

    /**
     * [add supplier information]
     */
    function addDeliveryDetails() {
      ManageSupplierDetailsService.addDeliveryDetails({
        address: {
          additionalStreetName: vm.deliveryDetails.addressAdditionalStreetName ? vm.deliveryDetails.addressAdditionalStreetName : '',
          cityName: vm.deliveryDetails.addressCityName ? vm.deliveryDetails.addressCityName : '',
          companyId: vm.deliveryDetails.addressCompanyId ? vm.deliveryDetails.addressCompanyId : '',
          contactEmail: vm.deliveryDetails.addressContactEmail ? vm.deliveryDetails.addressContactEmail : '',
          contactTelephone: vm.deliveryDetails.addressContactTelephone ? vm.deliveryDetails.addressContactTelephone : '',
          contactName: vm.deliveryDetails.addressContactName ? vm.deliveryDetails.addressContactName : '',
          countryCode: vm.deliveryDetails.addressCountryCode,
          organisationName: vm.deliveryDetails.addressOrganisationName,
          postalZone: vm.deliveryDetails.addressPostalZone ? vm.deliveryDetails.addressPostalZone : '',
          streetName: vm.deliveryDetails.addressStreetName ? vm.deliveryDetails.addressStreetName : ''
        }, supplierInformation: {
          id: vm.supplierInfoId
        }
      }).
        then(function (responseData) {
          $state.go('ezyap.' + vm.userRole.pretty + '.manage-supplier-details');
          NotificationService.success({
            title: 'global.supplierDeliveryDetails',
            message: 'alertMessage.ADDED_SUCCESSFULLY'
          });
        }, function (error) {
          NotificationService.error({
            title: 'global.supplierDeliveryDetails',
            error: error
          });
        });
    }

    /**
    * get all supplier information
    */
    function getSupplierInformation() {
      if (vm.supplierDetails) {
        return false;
      }
      ManageSupplierInfoService.getSupplierInformation({ query: "" }).
        then(function (response) {
          vm.supplierDetails = response.data;
        }, function (error) {
          NotificationService.error({
            title: '',
            error: error
          });
        });
    }
  }
})();
