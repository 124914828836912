/**
* @Author: Atul
* @Date:   Tue Jul 11 2023 15:45:16 GMT+0530 (India Standard Time)
* @Project: Ezyprocure
* @Filename: bnpl.buyer.navbar.controller.js
* @Last modified by:   Atul
* @Last modified time: Tue Jul 11 2023 15:45:16 GMT+0530 (India Standard Time)
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BNPLBuyerNavbarController', BNPLBuyerNavbarController);

  BNPLBuyerNavbarController.$inject = ['$rootScope', '$window', 'Auth', 'BuyerAccountService', 'BuyerDataService','$state','UserRedirectService','AuthServerProvider','API_CONSTANTS','toastr'];

  function BNPLBuyerNavbarController ($rootScope, $window, Auth, BuyerAccountService, BuyerDataService,$state,UserRedirectService,AuthServerProvider,API_CONSTANTS,toastr) {
    var vm = this;

    vm.init = init;
    vm.getBuyerProfile = getBuyerProfile;
    vm.logout = logout;
    $rootScope.back = back;
    vm.bnplStatus=localStorage.getItem('bnplStatus');
    vm.redirectToAXSPayBillsFine = redirectToAXSPayBillsFine;
    function init() {
      vm.getBuyerProfile();
    }

    function getBuyerProfile () {
      BuyerAccountService.getBuyerProfile().
      then(function(response) {
        if (response) {
          vm.buyerProfile = response;
          vm.buyerAccess = vm.buyerProfile.accessDTO;
          BuyerDataService.setBuyerProfile(vm.buyerProfile);
        }
      }, function(error) {
      });
    }

    function logout() {
      Auth.logout();
    }

    function back() {
      $window.history.back();
    }

    function redirectToAXSPayBillsFine(){
        $state.go('ezypayment.buyer.axs-pay-bills', {
            reload: false
          });
    }

    vm.redirectToCbPayPlatform = function () {
      Auth.checkCbHealth().then(function() {
        UserRedirectService.sync().then(function () {
            var token = AuthServerProvider.getCbToken();
            var domToken = AuthServerProvider.getToken();
            if (token) {
              $window.location.href = API_CONSTANTS.cbBase + '#/autologin/save/' + token + '/' + domToken + '?destinationUrl=ezypayment/buyer/dashboard';
            } else {
              $window.location.href = API_CONSTANTS.cbBase;
            }
        }).catch(function (data) {
          toastr.error('Something went wrong');
        })
      }).catch(function() {
        toastr.error('Something went wrong');
      });
    }

  }
})();
