/*
 * @Author: Jayalekshmi
 * @Date: 2018-07-23 11:47:49
 * @Filename: ezypayment.admin.statement-of-account-report.contoller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-09-19 11:33:03
 */


(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentAdminAxsBillsFinesTransactionReportController', EzypaymentAdminAxsBillsFinesTransactionReportController);

  EzypaymentAdminAxsBillsFinesTransactionReportController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$mdDialog', 'toastr', 'EzypaymentAdminAXSPaymentsService', 'UTCDateService', 'NotificationService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'BuyerBusinessService', 'ManageBuyerService', 'EzypaymentAdminStatementOfAccountService', 'BrandManagementService', 'ManageSupplierService', '$window', 'EXPORT_CONSTANTS', 'CSVExportService', 'EzypaymentAdminManageMerchantDetailsService', '$stateParams', 'Auth'];

  function EzypaymentAdminAxsBillsFinesTransactionReportController($scope, $rootScope, $q, $filter, $state, $mdDialog, toastr, EzypaymentAdminAXSPaymentsService, UTCDateService, NotificationService, GLOBAL_CONSTANTS, paginationConstants, BuyerBusinessService, ManageBuyerService, EzypaymentAdminStatementOfAccountService, BrandManagementService, ManageSupplierService, $window, EXPORT_CONSTANTS, CSVExportService, EzypaymentAdminManageMerchantDetailsService, $stateParams, Auth) {

    var vm = this;
    vm.predicate = "id";
    vm.reverse = "asc";
    vm.todaysDate = new Date();
    vm.previousDate = new Date(vm.todaysDate.getFullYear(), vm.todaysDate.getMonth(), vm.todaysDate.getDate() - 1, 23, 59, 59, 999);
    vm.loadAll = loadAll;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
    vm.axsPaymentIndividualHeader = EXPORT_CONSTANTS.CSV.axsPaymentIndividualHeader;
    vm.axsPaymentConsolidatedHeader = EXPORT_CONSTANTS.CSV.axsPaymentConsolidatedHeader;
    vm.accessSetSuppiers = [];
    vm.getAllBuyersBusinessOutlets = getAllBuyersBusinessOutlets;
    vm.loadDropdowns = loadDropdowns;
    vm.getAllBuyersMappedToPayment = getAllBuyersMappedToPayment;
    vm.getAllSuppliersMappedToPayment = getAllSuppliersMappedToPayment;
    vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
    vm.filter = {};
    vm.datePeriod = {
      fromDate: vm.todaysDate,
      toDate: vm.todaysDate
    };
    vm.paymentDate = vm.previousDate;
    vm.selectAllInvoices = selectAllInvoices;
    vm.getSettlementCsv = getSettlementCsv;
    vm.getAllCountryCurrencies = getAllCountryCurrencies;
    vm.chooseCurrency = $stateParams.currency || 'SGD';
    vm.calculateSum = calculateSum;
    vm.getAllMerchantList = getAllMerchantList;
    vm.getViewDetails = getViewDetails;
    vm.closeDialog = closeDialog;
    vm.transactionDetails = [];
    vm.getIndividualCsv = getIndividualCsv;
    vm.getConsolidatedCsv = getConsolidatedCsv;
    vm.getAccessSetSuppliers = getAccessSetSuppliers;
    vm.generateGiro = generateGiro;
    vm.readGiroResponseFiles = readGiroResponseFiles;
    vm.downloadEodFiles = downloadEodFiles;
    vm.getUniqValue = getUniqValue;
    vm.getUniqData = getUniqData;
    vm.openDateSelection = openDateSelection;
    vm.userRole = Auth.getUserRole().ugly;
    init();

    function init() {
      getAllCountryCurrencies();
    }

    function getUniqValue(list) {
      return _.uniqBy(list, function (e) {
        return e;
      });
    }

    function getUniqData(list){
        var answer = (list.toString()).split(',').filter(function(item, pos,self) {
           return self.indexOf(item) == pos;
        });
       return answer;
    }


    function generateGiro(uploadType) {
      vm.supplierIdList = _.map(_.filter(vm.axsPayBillFineTransactions, _.matches({ 'selected': true })), _.property('supplierId'));
      if (!vm.supplierIdList || vm.supplierIdList.length < 1) {
        toastr.error('Please select atleast one transaction', 'No transaction Selected');
        return false;
      }
      vm.brandIdList = _.map(_.filter(vm.axsPayBillFineTransactions, _.matches({ 'selected': true })), _.property('brandIds'));

      vm.referenceIdList = _.map(_.filter(vm.axsPayBillFineTransactions, _.matches({ 'selected': true })), _.property('transactionId'));
      var supplierIdList = {
        brandIdList: !vm.brandIdList ? [] : String(getUniqData(vm.brandIdList)),
        currency: !vm.filter.currency ? '' : vm.filter.currency,
        supplierId: !vm.supplierIdList ? [] :String(getUniqData(vm.supplierIdList)),
        fromDate: !vm.filter.fromDate ? UTCDateService.utcFromDate(vm.todaysDate) : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? UTCDateService.utcToDate(vm.todaysDate) : UTCDateService.utcToDate(vm.filter.toDate),
        uploadHostToHost: uploadType,
        fast: vm.fast
      }
      EzypaymentAdminAXSPaymentsService.createGiroFile(supplierIdList)
        .then(function (response) {
          if (response) {
            _.each(response, function (url) {
              $window.open(url);
            });
          }
          NotificationService.success({
            title: 'Generate GIRO file',
            message: 'GIRO file is successfully generated'
          });
          vm.loadAll();
        }, function (error) {
          if (error.status == 503) {
            NotificationService.error({
              title: 'File upload failed into UOB server',
              error: error
            });
          }
          else {
            NotificationService.error({
              title: 'Could not generate GIRO',
              error: error
            });
          }
        });
    }

    function readGiroResponseFiles() {
      EzypaymentAdminAXSPaymentsService.readGiroResponseFiles()
        .then(function (response) {
          NotificationService.success({
            title: 'global.buttons.readGiroResponseFiles',
            message: 'alertMessage.COMPLETED_SUCCESSFULLY'
          });
        }, function (error) {
          NotificationService.error({
            title: 'global.buttons.readGiroResponseFiles',
            error: error
          });
        });
    }

    function getAccessSetSuppliers() {
      if (vm.accessSetSuppiers.length) { return; }
      ManageSupplierService.getConsolidatedGiroAccessSetSuppliers({
        page: 0,
        size: paginationConstants.maxLimit,
        search: '',
        visibilityType: 'ALLOCATED',
        sort: 'id,asc'
      }).then(function (response) {
        vm.accessSetSuppiers = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'Supplier Listing',
          error: error
        });
      });
    }

    function getIndividualCsv() {
      return getCsvData(true);
    }

    function getConsolidatedCsv() {
      return getCsvData(false);
    }

    function getViewDetails(transaction, ev) {
      var params = {
        paymentIds: String([transaction.paymentIds])
      };
      // vm.paymentIdList = _.map(_.filter(vm.axsPayBillFineTransactions, _.matches({ 'selected': true })), _.property('paymentIds'));
      EzypaymentAdminAXSPaymentsService.getTransactionViewDetails(params)
        .then(
          function (response) {
            vm.transactionDetails = response.data;
            openDialog(ev);
          },
          function (error) {
            toastr.error("Internal Server Error");
          });
    }

    function openDialog(ev) {
      $mdDialog.show({
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        templateUrl: 'app/ezypayment-modules/admin-modules/axs-payments-master/axs-pay-bills-fines/ezypayment.admin.axs-bills-fines-transaction-report-detail.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
      }).then(function (answer) {

      }, function () {

      });
    }

    function closeDialog() {
      $mdDialog.hide();
    }

    function openDateSelection(event) {
      $mdDialog.show({
        templateUrl: 'app/ezypayment-modules/admin-modules/axs-payments-master/axs-pay-bills-fines/ezypayment.admin.axs-bills-fines-transaction-report-date-selection-modal.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false
      });
    }

    function getSettlementCsv() {
      var params = {};
      vm.supplierIdList = _.map(vm.axsPayBillFineTransactions, _.property('supplierId'));
    //   if (!vm.supplierIdList || vm.supplierIdList.length < 1) {
    //     toastr.error('Invalid list', 'No records found');
    //     return false;
    //   }
      vm.brandIdList = _.map(vm.axsPayBillFineTransactions, _.property('brandId'));
      vm.paymentIdList = _.map(vm.axsPayBillFineTransactions, _.property('paymentIds'));
      if(vm.filter.listView === 'Individual') {
        params = {
          paymentIds: !vm.paymentIdList ? [] : String(vm.paymentIdList),
          reportType: !vm.filter.listView ? '': vm.filter.listView,
        };
      }
      else {
        params = {
          supplierIds: !vm.supplierIdList ? [] : String(vm.supplierIdList),
          brandIds: !vm.brandIdList ? [] : String(vm.brandIdList),
          paymentIds: !vm.paymentIdList ? [] : String(vm.paymentIdList),
          fromDate: !vm.filter.fromDate ? UTCDateService.utcFromDate(vm.todaysDate) : UTCDateService.utcFromDate(vm.filter.fromDate),
          toDate: !vm.filter.toDate ? UTCDateService.utcToDate(vm.todaysDate) : UTCDateService.utcToDate(vm.filter.toDate),
          reportType: !vm.filter.listView ? '': vm.filter.listView,
        };
      }
      var dateParams = {
        fileDate: UTCDateService.utcFromDate(vm.paymentDate),
      }
      EzypaymentAdminAXSPaymentsService.getSettlementFile(dateParams)
        .then(function (response) {
          if (response.data) {
            vm.downloadInterval = setInterval(processUrls, 300, response.data);
          }
          closeDialog();
          toastr.success("Successfully generated settlement file")
          vm.loadAll();
        }, function (error) {
          closeDialog();
          if (error.status == 503) {
            NotificationService.error({
              title: 'File upload failed into server',
              error: error
            });
          }
          else {
            NotificationService.error({
              title: 'Could not generate settlement file',
              error: error
            });
          }
        });

    }

    function downloadEodFiles(urlString) {
      if(urlString === 'NA' || !urlString) { return; }
      var urls = urlString.split(',');
      vm.downloadInterval = setInterval(processUrls, 300, urls);
    }

    function processUrls(urls) {
      var url = urls.pop();
      download(url);
      if (urls.length === 0) {
        clearInterval(vm.downloadInterval);
      }
    }

    function download(url) {
      var a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', undefined);
      a.setAttribute('target', '_blank');
      a.click();
    }

    /**
     * [calculateSum shows the sum of key in List]
     */
     function calculateSum(statementOfAccountReportsList, key){
       var sum=_.sumBy(statementOfAccountReportsList, key)
         return sum;
     }

    /**
    * [selectAllInvoices select all Pending Process Orders]
    */
    function selectAllInvoices() {
      vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
      _.map(vm.axsPayBillFineTransactions, function(statementOfAccountReport) {
        statementOfAccountReport.selected = vm.selectAll;
      });
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
     * [getAllBuyers Gets all buyers mapped with the payment model]
     */
    function getAllBuyersMappedToPayment(){
      if(vm.buyers){ return false}
      ManageBuyerService.getAllAdminBuyersWithUserDetails({
        page: 0,
        size: paginationConstants.maxLimit,
        search: '',
        sort: sort(),
        isAdminDashboard:vm.userRole==='ROLE_ADMIN' && $rootScope.redirectionApiFromDashboard==true?true:false
      }).then(function(response) {
        vm.buyers = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Buyer Listing',
          error: error
        });
      });
    }

    function getAllMerchantList() {
      if(!vm.filter.gatewayType) {
        return false;
      };
      EzypaymentAdminStatementOfAccountService.getAllMerchantList({
        gateWayType: vm.filter.gatewayType
      }).then(function(response) {
        vm.merchantIdList = [];
        if(Array.isArray(response.data)) {
          response.data.forEach(function(merchantId) {
            vm.merchantIdList.push({"merchantId" : merchantId});
          });
        }
      }, function(error) {
        NotificationService.error({
          title: 'MID Listing',
          error: error
        });
      });
    }

    function getAllSuppliersMappedToPayment(){
      if(vm.suppliers){return false}
      ManageSupplierService.getAllAdminSuppliersWithUserDetails({
        page: 0,
        size: paginationConstants.maxLimit,
        search: '',
        visibilityType: 'ALLOCATED',
        sort: sort(),
        isAdminDashboard:vm.userRole==='ROLE_ADMIN' && $rootScope.redirectionApiFromDashboard==true?true:false,
        filter:vm.userRole==='ROLE_ADMIN' && $rootScope.redirectionApiFromDashboard==true?$rootScope.dashboardFilter:"All"
      }).then(function(response){
        vm.suppliers = response.data;
      },function(error){
        NotificationService.error({
          title: 'Supplier Listing',
          error: error
        });
      });
    }

    /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
      case 'BUYERS':
        vm.filter.buyerIdList = _.map(vm.buyers, _.property('id'));
        break;
      case 'BRANDS':
        vm.filter.brandIdList = _.map(vm.brands, _.property('id'));
        break;
      case 'SUPPLIERS':
        vm.filter.supplierIdList = _.map(vm.accessSetSuppiers, _.property('id'));
        break;
      }
    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
      case 'BUYERS':
        vm.filter.buyerIdList = [];
        break;
      case 'BRANDS':
        vm.filter.brandIdList = [];
        break;
      case 'SUPPLIERS':
        vm.filter.supplierIdList = [];
        break;
      }
    }

    /**
    * [getAllBuyersBusinessOutlets gets all businessoutlets for selected buyers]
    * @param {List} buyerIdList
    */
    function getAllBuyersBusinessOutlets(buyerIdList) {
      if (!buyerIdList || buyerIdList.length === 0) return false;
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        groupBy: 'buyer'
      };
      BuyerBusinessService.getAllBuyersBusinessOutlets(buyerIdList, params).then(function(response) {
        vm.buyerBusinessOutlets = response.data;
        vm.filter.businessOutletIdList = [];
      }, function(error) {
        NotificationService.error({
          title: 'Business Outlet Listing',
          error: error
        });
      });
    }
    /**
     * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
     * @param {Array} buyerIdList
     */
    function getAllBrandsOfBuyerList(buyerIdList){
      if (!buyerIdList || buyerIdList.length === 0) return false;
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        query:'',
        buyerId: buyerIdList,
        sort: sort()
      };

      BrandManagementService.getAllBrandsOfBuyerList(params)
      .then(function(response){
        vm.brands = response;
      },function(error){
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

    /**
    * [loadDropdowns The function executes when the dropdown closes]
    */
    function loadDropdowns(){
      vm.getAllBuyersBusinessOutlets(vm.filter.buyerIdList);
      vm.getAllBrandsOfBuyerList(vm.filter.buyerIdList)
    }

    /**
    * [loadAll Load All statement of account report]
    * @param  {[string]} search [search text]
    */
    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      EzypaymentAdminAXSPaymentsService.getAxsBillFinesReport({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText,
        currency: vm.filter.currency,
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        invoiceNumber: !vm.filter.invoiceNumber ? '': vm.filter.invoiceNumber,
        buyerIdList: !vm.filter.buyerIdList ? []: vm.filter.buyerIdList,
        supplierIdList: !vm.filter.supplierIdList || vm.filter.listView === 'Individual' ? []: vm.filter.supplierIdList,
        brandIdList: !vm.filter.brandIdList ? []: vm.filter.brandIdList,
        businessOutletIdList: !vm.filter.businessOutletIdList ? []: vm.filter.businessOutletIdList,
        //If dates are selected, they are converted to UTC format
        reportName: !vm.filter.listView ? '': vm.filter.listView,
        fromDate: !vm.filter.fromDate ? UTCDateService.utcFromDate(vm.todaysDate) : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? UTCDateService.utcToDate(vm.todaysDate) : UTCDateService.utcToDate(vm.filter.toDate),
        paymentGiroStatus: (vm.filter.giroStatus === 'ALL') ? '' : vm.filter.giroStatus,
        accountType:(vm.accountType==='ALL')?'':vm.accountType,
       issuer:(vm.issuer==='ALL')?'':vm.issuer,
       merchantId: vm.filter.listView === 'Individual' ? vm.filter.merchantId : "",
       gateWay: vm.filter.listView === 'Individual' ? vm.filter.gatewayType : ""
       }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.axsPayBillFineTransactions = response.data;
      },function(error){
        NotificationService.error({
          title: 'Statement of Account Report',
          error: error
        });
      });
    }

    function getCsvData(isIndividualCsv) {
      var deferred = $q.defer();
      EzypaymentAdminAXSPaymentsService.getAxsBillFinesReport({
        page: 0,
        size: paginationConstants.maxLimit,
        sort: sort(),
        query: '',
        currency: vm.filter.currency,
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        invoiceNumber: !vm.filter.invoiceNumber ? '' : vm.filter.invoiceNumber,
        buyerIdList: !vm.filter.buyerIdList ? [] : vm.filter.buyerIdList,
        supplierIdList: !vm.filter.supplierIdList || vm.filter.listView === 'Individual' ? [] : vm.filter.supplierIdList,
        brandIdList: !vm.filter.brandIdList ? [] : vm.filter.brandIdList,
        businessOutletIdList: !vm.filter.businessOutletIdList ? [] : vm.filter.businessOutletIdList,
        //If dates are selected, they are converted to UTC format
        reportName: !vm.filter.listView ? '' : vm.filter.listView,
        fromDate: !vm.filter.fromDate ? UTCDateService.utcFromDate(vm.todaysDate) : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? UTCDateService.utcToDate(vm.todaysDate) : UTCDateService.utcToDate(vm.filter.toDate),
        paymentGiroStatus: (vm.filter.giroStatus === 'ALL') ? '' : vm.filter.giroStatus,
        accountType: (vm.accountType === 'ALL') ? '' : vm.accountType,
        issuer: (vm.issuer === 'ALL') ? '' : vm.issuer,
        merchantId: vm.filter.listView === 'Individual' ? vm.filter.merchantId : "",
        gateWay: vm.filter.listView === 'Individual' ? vm.filter.gatewayType : ""
      }).then(function (response) {
        deferred.resolve(isIndividualCsv ? CSVExportService.getIndividualAxsCsv(response.data) : CSVExportService.getConsolidatedAxsCsv(response.data));
      }, function (error) {
        NotificationService.error({
          title: 'Statement of Account Report',
          error: error
        });
        deferred.reject([]);
      });
      return deferred.promise;
    }

    /**
     * [getAllCountryCurrencies gets all country currencies]
     */
    function getAllCountryCurrencies(){
      EzypaymentAdminManageMerchantDetailsService.getAllCountryCurrencies().then(function(response){
        vm.currencies = response.data;
        if(vm.chooseCurrency == "USD"){
          vm.filter.currency = "USD";
        }
        else{
        vm.filter.currency=vm.currencies[0].currency;
      }
      vm.loadAll();
      },function(error){
      });
    }
  }
})();
