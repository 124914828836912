/**
* @Author: Ajay
* @Date:   24-Feb-2017 12:40 +05:30
* @Project: Ezyprocure
* @Filename: supplier.item.edit.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 05-Apr-2018 14:38 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierItemEditController', SupplierItemEditController);

  SupplierItemEditController.$inject = ['$scope', '$state', '$stateParams', 'ImageUploadService', 'SupplierItemService', 'UomService', 'ItemGroupService', 'paginationConstants', 'NotificationService', 'GLOBAL_CONSTANTS'];

  function SupplierItemEditController($scope, $state, $stateParams, ImageUploadService, SupplierItemService, UomService, ItemGroupService, paginationConstants, NotificationService, GLOBAL_CONSTANTS) {
    var vm = this;

    vm.itemId = $stateParams.itemId;
    vm.init = init;
    vm.loadUoms = loadUoms;
    vm.loadItemGroups = loadItemGroups;
    vm.getItemData = getItemData;
    vm.save = save;
    vm.setCompanyLogo = setCompanyLogo;
    vm.uploadLogo = uploadLogo;
    vm.validateMOQ = validateMOQ;
    vm.currencies = GLOBAL_CONSTANTS.CURRENCIES;
    vm.checkItemCodeExists=checkItemCodeExists;
    function save() {
      SupplierItemService.updateSupplierItem(vm.itemDTOObj).then(function(response) {
        $scope.$emit('ezyprocureApp:supplierItemUpdate', response);
        NotificationService.success({
          title: 'Item',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        $state.go('supplier.items', {
          reload: false
        });
      }, function(error) {
        NotificationService.error({
          title: 'Item Updation',
          error: error
        });
      });
    }

    function getItemData() {
      SupplierItemService.getSupplierItem(vm.itemId).
      then(function(response) {
        if (response) {
          response.price = response.price ? parseFloat(response.price.toFixed(2)) : response.price;
          vm.itemDTOObj = response;
        }
      }, function(error) {
      });
    }

    /**IMAGE UPLOAD**/

    function setCompanyLogo(flowFile) {
      ImageUploadService.getImageBase64(flowFile.file, function(base64Img) {
        vm.uploadLogo(base64Img);
      });
    }

    function uploadLogo(base64Img) {
      vm.uploadingInProgress = true;
      ImageUploadService.uploadImage(base64Img).
      then(function(responseData) {
        vm.itemDTOObj.image = responseData.url;
        $scope.editItemForm.$pristine = false;
        NotificationService.success({
          title: 'Item Image uploaded successfully',
          message: 'Please update to save changes!'
        });
        vm.uploadingInProgress = false;
      }, function(error) {
        vm.uploadingInProgress = false;
        NotificationService.error({
          title: 'Item Image Upload',
          error: error
        });
      });
    }

    function loadUoms() {
      UomService.getAllUoms({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.uoms = response.data;
      }, function(error) {
      });
    }

    function loadItemGroups() {
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }

    function init() {
      vm.getItemData();
      vm.loadUoms();
      vm.loadItemGroups();
    }

    /**
     * [validateMOQ Validates the MOQ with Multiplier]
     */
    function validateMOQ(){
      if(vm.itemDTOObj.multiplierFactor > 0){
        if (((vm.itemDTOObj.moq*100) % (vm.itemDTOObj.multiplierFactor*100))/100 != 0) {
          $scope.editItemForm.moq.$setTouched();
          $scope.editItemForm.moq.$setValidity("moq", false);
        }else{
         $scope.editItemForm.moq.$setValidity("moq", true);
        }
      }
    }
    function checkItemCodeExists(itemCode, id) {
      if (!itemCode) return; 
      SupplierItemService.checkItemCodeExists(itemCode, id)
        .then(function(response) {
          if (response.data) {
            $scope.editItemForm.itemCode.$setValidity('itemCodeExists', false);
          } else {
            $scope.editItemForm.itemCode.$setValidity('itemCodeExists', true);
          }
        })
        .catch(function(error) {
          console.error('Error checking item code existence:', error);
        });
    };
    
  }
})();
