/**
 * @Author: Ajay
 * @Date:   03-Mar-2017 15:12 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.dashboard.controller.js
 * @Last modified by:   Allan
 * @Last modified time: 10-Jan-2019 15:04 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerDashboardController', BuyerDashboardController);

  BuyerDashboardController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$filter', '$mdDialog', 'BuyerDashboardService', 'GLOBAL_CONSTANTS', 'NotificationService', '$mdMedia', 'Auth', 'SatsManagementService','BuyerDataService', 'SubscriptionPlan'];

  function BuyerDashboardController($rootScope, $scope, $state, $stateParams, $filter, $mdDialog, BuyerDashboardService, GLOBAL_CONSTANTS, NotificationService, $mdMedia, Auth, SatsManagementService, BuyerDataService, SubscriptionPlan) {

    var vm = this;
    vm.tabIndex = $stateParams.tabIndex ? $stateParams.tabIndex : 0;
    vm.refreshInterval = GLOBAL_CONSTANTS.DASHBOARD_REFRESH_INTERVAL;
    vm.getBuyerDashboardCount = getBuyerDashboardCount;
    vm.getBuyerDashboardApprovals = getBuyerDashboardApprovals;
    vm.uobBizPlusUrl = GLOBAL_CONSTANTS.UOB_BIZPLUS_URL;
    vm.showEzysourceBanner = GLOBAL_CONSTANTS.SHOW_EZYSOURCE_BANNER;
    vm.showUobBanner = GLOBAL_CONSTANTS.SHOW_UOB_BANNER;
    vm.isMobile = $mdMedia('xs');
    vm.accessForPromotion = Auth.hasPermission('accessForPromotion');
    vm.accessForSATS = Auth.hasPermission('accessForSATS');
    vm.accessForStockTransfer = Auth.hasPermission('accessForStockTransfer');
    vm.getBuyerDashboardPendingJobsCount = getBuyerDashboardPendingJobsCount;
    //Dashboard counter refresh timeout
    vm.incrementInterval = incrementInterval;
    vm.initializeIntervalCount = initializeIntervalCount;
    vm.getDashboardCount = getDashboardCount;
    vm.refreshDashboardCounts = refreshDashboardCounts;
    vm.refreshTimeout = GLOBAL_CONSTANTS.DASHBOARD_REFRESH_TIMEOUT;
    vm.initialRefreshInterval = GLOBAL_CONSTANTS.DASHBOARD_REFRESH_INTERVAL;
    vm.refreshIntervalIncrement = GLOBAL_CONSTANTS.DASHBOARD_INTERVAL_INCREMENT;
    vm.pauseRefreshFlag = false;
    vm.accessForEzyinventory = BuyerDataService.getBuyerProfile().accessDTO.accessForEzyinventory;
    vm.initialiseCarousal = initialiseCarousal;
    vm.showSubscriptionLicenceRenewalAlert = showSubscriptionLicenceRenewalAlert;
    /**
     * [getBuyerDashboardPendingJobsCount gets the count of Processed jobs count]
     */
    function getBuyerDashboardPendingJobsCount(){
      SatsManagementService.getPendingJobsDashboardCount()
      .then(function(response){
        vm.buyerPendingJobsCount = response.data.count;
      },function(error){
        NotificationService.error({
          title: 'Buyer Dashboard',
          error: error
        });
      });
    }

    /** increment refresh interval on successfull refresh */
    function incrementInterval() {
      if(vm.refreshInterval == vm.initialRefreshInterval) {
        vm.refreshInterval += vm.refreshIntervalIncrement;
      }
      else if(vm.refreshInterval < vm.refreshTimeout) {
        vm.refreshInterval += vm.refreshIntervalIncrement;
      }
      else if (vm.refreshInterval >= vm.refreshTimeout) {
        vm.pauseRefreshFlag = true;
      }
    }

    function initialiseCarousal(){
      var myCarousel = document.querySelector('#myCarousel');
      var carousel = $(myCarousel).carousel();
    }
    /** force refresh counts */
    function refreshDashboardCounts() {
      getDashboardCount();
      initializeIntervalCount();
      initialiseCarousal();
    }

    function initializeIntervalCount() {
      vm.refreshInterval = vm.initialRefreshInterval;
      vm.pauseRefreshFlag = false;
    }

    /** get dashboard count */
    function getDashboardCount() {
      if(vm.tabIndex == 0 && vm.buyerDashboardCount===undefined){
        getBuyerDashboardCount();
        getCarousalImg();
      }
      if(vm.tabIndex == 1 && vm.buyerApprovals===undefined){
        getBuyerDashboardApprovals();
      }
    }

    function getCarousalImg(){
      BuyerDashboardService.getCarousalDetails()
      .then(function(response){
        vm.carousalImgList = response.data;
      },function(error){
        NotificationService.error({
          title: 'Buyer Dashboard',
          error: error
        });
      });
    }
    function getBuyerDashboardCount() {
      BuyerDashboardService.getBuyerDashboardCount().then(function(response) {
        vm.buyerDashboardCount = response.data;        
        vm.pendingApprovalsCount = response.data.pendingApprovalsCount;
        $rootScope.$broadcast('refresh-cart-count',response.data.cartCount);
      }, function(error) {
        NotificationService.error({
          title: 'Buyer Dashboard',
          error: error
        });
      });
    }

    function getBuyerDashboardApprovals() {
      BuyerDashboardService.getBuyerDashboardApprovals().then(function(response) {
        vm.pendingApprovalsCount = response.data.pendingApprovalsCount;
        vm.buyerApprovals = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Buyer Dashboard Approvals',
          error: error
        });
      });
    }

    getDashboardCount();

    initializeIntervalCount();
    initialiseCarousal();
    showSubscriptionLicenceRenewalAlert();
    
    $rootScope.$on('language-update', function(event, key) {
      vm.currentLangKey = key;
    });
    $rootScope.$on('buyer-profile-update', function(event, key) {
      vm.currentLangKey = key.buyerUser.user.langKey;
    });

    function showSubscriptionLicenceRenewalAlert() {
      const showPopup = sessionStorage.getItem('showExpirationPopup');
      const expirationTimestamp = sessionStorage.getItem('expirationPopupTimestamp');
      const todaysDate = new Date();
      var countOutlets = 0;
      // Check if more than 24 hours have passed since the last login
      const twentyFourHoursInMillis = 24 * 60 * 60 * 1000;
      const currentTime = new Date().getTime();

      if (showPopup === "true" || (!expirationTimestamp || (currentTime - expirationTimestamp) > twentyFourHoursInMillis)) {
        var startTime = new Date();
        var buyerId;
        var intervalId = setInterval(function () {
          buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;

          // Check if 10 seconds have passed, and if so, clear the interval
          if (buyerId || new Date() - startTime >= 10000) {
            clearInterval(intervalId);

            if (!buyerId) {
              console.error('Unable to retrieve buyerId. Some properties are undefined.');
              return;
            }

            var userRole = Auth.getUserRole().ugly;
            var userType;
            if (userRole === 'ROLE_BUYER') {
              userType = 'buyer';
            }
            SubscriptionPlan.fetchSubscriptionLicenceRenewalAlert(buyerId, userType).then(function (response) {
              // Find the subscription with the least number of days remaining              
              // Calculate the date today
              const today = new Date();

              // Function to calculate the difference in days
              function daysBetween(date1, date2) {
                // Create Date objects from the input dates
                date1 = new Date(date1);
                date2 = new Date(date2);
            
                // Set both dates to midnight (00:00:00) to ignore time
                date1.setHours(0, 0, 0, 0);
                date2.setHours(0, 0, 0, 0);
            
                const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
                return Math.round((date2 - date1) / oneDay);
              }

              // Initialize variables to keep track of the subscription with the least days remaining
              var minDaysSubscription = null;
              var minDaysRemaining = Infinity;
              var minDaysIndices = [];
              // Iterate through subscriptions to find the one with the least days remaining
              for (var index = 0; index < response.data.subscriptions.length; index++) {
                var subscription = response.data.subscriptions[index];
                if (subscription.subscriptionEndDate) {
                  const endDate = new Date(subscription.subscriptionEndDate);
                  const daysRemaining = daysBetween(today, endDate);

                  if (daysRemaining < minDaysRemaining) {
                    minDaysRemaining = daysRemaining;
                    minDaysIndices = [index]; // Reset indices array to only include this new minimum
                  } else if (daysRemaining === minDaysRemaining) {
                    minDaysRemaining = daysRemaining;
                    minDaysIndices.push(index); // Add this index to the array
                  }
                 var date1 = new Date(subscription.subscriptionEndDate);
                  // Check if date1 is on or before today's date
                  if (date1.getTime() <= todaysDate.getTime()) {
                    countOutlets += 1;
                  }
                }
              }
              // Retrieve subscriptions with the minimum days remaining
              var minDaysSubscriptions = _.map(minDaysIndices, function (index) {
                return response.data.subscriptions[index];
              })
              vm.valid_to = minDaysSubscriptions[0].subscriptionEndDate;
              if (minDaysSubscriptions && minDaysRemaining <= 45) {
                var popupData = {
                  "valid_to": vm.valid_to,
                  "minDaysRemaining": minDaysRemaining,
                  "minDaysSubscriptions": minDaysSubscriptions,
                  "expiringSubscriptions": response.data.subscriptions,
                  "subscriptionExpiringIn45Days": _.filter(response.data.subscriptions,function(subscription) {
                    if (!subscription.subscriptionEndDate) {
                      return false; // Skip if subscriptionEndDate is null or undefined
                    }
                    todaysDate.setHours(0, 0, 0, 0);
                    
                    var expiringDate = new Date(subscription.subscriptionEndDate); 
                    expiringDate.setHours(0, 0, 0, 0);
                
                    var diffTime = expiringDate - todaysDate;
                    var diffDays = diffTime / (1000 * 60 * 60 * 24);
                
                    return diffDays <= 45; // Return true if expiring in 45 days or less
                  })
                }
                $mdDialog.show({
                  templateUrl: 'app/common-modules/web-reminder/web-reminder.html',
                  controller: 'WebReminderController',
                  controllerAs: 'vm',
                  escapeToClose: false,
                  clickOutsideToClose: false,
                  resolve: {
                    data: function () {
                      return popupData;
                    }
                  }
                });
                if(minDaysRemaining > 1 && countOutlets !== response.data.subscriptions.length){
                  sessionStorage.removeItem('licenseExpirationData');
                }
                console.log("Subscription with the least days remaining:", minDaysSubscription);
                console.log("Days remaining:", minDaysRemaining);
              } else {
                console.log('No valid subscription found.');
                console.log(response.data.errorMessage)
              }
            }, function (error) {
             console.log("fetching subscription details failed")
            });
            // Update the timestamp to the current time
            const licenseExpirationDataString = sessionStorage.getItem('licenseExpirationData');
            const licenseExpirationData = JSON.parse(licenseExpirationDataString);
            if (licenseExpirationData && Object.keys(licenseExpirationData).length > 0) {
              sessionStorage.setItem('showExpirationPopup', 'true');
              }else{
              sessionStorage.setItem('expirationPopupTimestamp', currentTime);
              sessionStorage.setItem('showExpirationPopup', 'false');
              }
          }
        }, 2000);
      }
    }

  }
})();
