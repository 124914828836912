/**
* @Author: Allan
* @Project: Ezyprocure
* @Filename: ezypayment.admin.manage-merchant-details.controller.js
* @Last modified by:   Allan
* @Last modified time: 2018-10-31T16:59:23+05:30
*/


(function(){
  'use strict';
  angular
  .module('ezyprocureApp')
  .controller('EzypaymentAdminManageMerchantDetailsController', EzypaymentAdminManageMerchantDetailsController);

  EzypaymentAdminManageMerchantDetailsController.$inject = ['$stateParams' ,'$scope', 'paginationConstants', 'pagingParams', 'NotificationService', 'ManageSupplierService', 'ManageBuyerService', 'BrandManagementService', '$mdDialog', 'GLOBAL_CONSTANTS', 'EzypaymentAdminManageMerchantDetailsService']

  function EzypaymentAdminManageMerchantDetailsController($stateParams ,$scope, paginationConstants, pagingParams, NotificationService, ManageSupplierService, ManageBuyerService, BrandManagementService, $mdDialog, GLOBAL_CONSTANTS, EzypaymentAdminManageMerchantDetailsService){

    var vm = this;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.supplierMerchantDetails = [];
    vm.merchantPassword = GLOBAL_CONSTANTS.MERCHANT_PASSWORD;
    vm.isEditable = true;
    vm.cybersourceMerchantDetails = [];
    vm.showEnterCodeModal = showEnterCodeModal;
    vm.closeEnterCodeModal = closeEnterCodeModal;
    vm.getAllSupplierMerchantIdDetail = getAllSupplierMerchantIdDetail;
    vm.init = init;
    vm.loadAll = loadAll;
    vm.enableUpdate = enableUpdate;
    vm.getCybersourceMerchantDetails = getCybersourceMerchantDetails;
    vm.setToDefault = setToDefault;
    vm.unmap = unmap;

    vm.init();

    function init(){
      vm.loadAll();
      vm.getAllSupplierMerchantIdDetail();
    }

    function unmap(id) {
      BrandManagementService.unmapCyberSourceDetail({
        id: id
      }).then(function(response){
        getCybersourceMerchantDetails();
        NotificationService.success({
          title: 'Unmap Merchant Detail',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
      },
      function(error) {
        NotificationService.error({
          title: 'Unmap Merchant Detail',
          error: error
        });
      });
    }

    function getCybersourceMerchantDetails() {
      if(vm.user === 'BUYER'||vm.user === 'SUPPLIER') {
        BrandManagementService.getCybersourceMerchantDetails({
          page: vm.query.page - 1,
          size: vm.query.limit,
          query: vm.midSearchText || '',
          type: vm.user
        }).then(function(response){
          vm.totalItemsCs = response.headers('X-Total-Count');
          vm.cybersourceMerchantDetails = response.data;
        },
        function(error) {
          NotificationService.error({
            title: 'Cybersource Merchant Details',
            error: error
          });
        });
      }
    }

    function setToDefault(user) {
      vm.query = {
        limit: 20,
        page: 1
      };
      vm.predicate = pagingParams.predicate;
      vm.reverse = pagingParams.ascending;
      vm.searchText = "";
      vm.midSearchText = "";
      vm.isEditable = true;
      vm.passcode = undefined;
      vm.user = user;
      if(vm.user === 'BUYER'|| vm.user === 'SUPPLIER') {
        vm.getCybersourceMerchantDetails();
      }else{
        if(vm.user === 'BUYER MERCHANT DETAILS'){
          vm.loadAll();
        }else if(vm.user === 'SUPPLIER MERCHANT DETAILS'){
          vm.getAllSupplierMerchantIdDetail();
        }
      }
    }

    /**
    * [loadAll loads all brands]
    */
    function loadAll(searchText){
      if(!searchText){
        vm.searchText = '';
      }
      BrandManagementService.getAllBrands({
        page: vm.query.page - 1,
        size: vm.query.limit,
        query: vm.searchText
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.brands = response.data;
      });
    }

    /**
     * [getAllSupplierMerchantIdDetail Gets all list of SupplierMerchantIdDetails]
     */
    function getAllSupplierMerchantIdDetail(){
      EzypaymentAdminManageMerchantDetailsService.getAllSupplierMerchantIdDetail({
        page: vm.query.page - 1,
        size: vm.query.limit,
        query: vm.searchText
      }).then(function(response){
        vm.totalItemsSm = response.headers('X-Total-Count');
        vm.supplierMerchantDetails = response.data;
      });
    }

    function enableUpdate(passcode){

      if(vm.merchantPassword === passcode){
        NotificationService.success({
          title: 'Manage Merchant Details',
          message: 'alertMessage.MERCHANT_EDITING_ENABLED'
        });
        vm.isEditable = false;
        closeEnterCodeModal();
      } else {
        NotificationService.simpleErrorToast({
          title: "Manage Merchant Details",
          message: 'alertMessage.MERCHANT_CODE_INCORRECT'
        });
        vm.isEditable = true;
      }

    }

    function showEnterCodeModal(event){
      $mdDialog.show({
        templateUrl: 'app/ezypayment-modules/admin-modules/manage-merchant-details/templates/ezypayment.admin.manage-merchant-details-passcode.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false
      });
    }

    function closeEnterCodeModal(){
      vm.passcode = '';
      $mdDialog.cancel();
    }
  }
})();
