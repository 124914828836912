/*
 * @Author: Neethu S 
 * @Date: 2023-08-17 
 * @Project: Ezyprocure
 * @Filename: ezyap.manage-supplier-details.edit.controller.js
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapEditSupplierDetailsController', EzyapEditSupplierDetailsController);

  EzyapEditSupplierDetailsController.$inject = ['NotificationService', 'CsvUploadService', 'UtilService', 'UploadInvoiceService', 'ManageSupplierInfoService', 'S3_URL_CONSTANTS', 'toastr', '$state', '$filter', 'ManageSupplierDetailsService', '$stateParams', 'EZYAP_CONSTANTS', 'Auth'];

  function EzyapEditSupplierDetailsController(NotificationService, CsvUploadService, UtilService, UploadInvoiceService, ManageSupplierInfoService, S3_URL_CONSTANTS, toastr, $state, $filter, ManageSupplierDetailsService, $stateParams, EZYAP_CONSTANTS, Auth) {

    var vm = this;
    vm.getDeliveryDetailsById = getDeliveryDetailsById;
    vm.editDeliveryDetails = editDeliveryDetails;
    vm.deliveryDetailsId = $stateParams.id;
    vm.getSupplierInformation = getSupplierInformation;
    vm.countryCodes = EZYAP_CONSTANTS.ezyap.countryCodes;
    vm.init = init;
    vm.userRole = Auth.getUserRole();

    function init() {
      vm.getDeliveryDetailsById();
      vm.getSupplierInformation();
    }

    /**
     * [edit supplier information]
     */
    function editDeliveryDetails() {
      ManageSupplierDetailsService.editDeliveryDetails({
        address: {
          additionalStreetName: vm.deliveryDetails.address.additionalStreetName ? vm.deliveryDetails.address.additionalStreetName : '',
          cityName: vm.deliveryDetails.address.cityName ? vm.deliveryDetails.address.cityName : '',
          companyId: vm.deliveryDetails.address.companyId ? vm.deliveryDetails.address.companyId : '',
          contactEmail: vm.deliveryDetails.address.contactEmail ? vm.deliveryDetails.address.contactEmail : '',
          contactTelephone: vm.deliveryDetails.address.contactTelephone ? vm.deliveryDetails.address.contactTelephone : '',
          contactName: vm.deliveryDetails.address.contactName ? vm.deliveryDetails.address.contactName : '',
          countryCode: vm.deliveryDetails.address.countryCode,
          organisationName: vm.deliveryDetails.address.organisationName,
          postalZone: vm.deliveryDetails.address.postalZone ? vm.deliveryDetails.address.postalZone : '',
          streetName: vm.deliveryDetails.address.streetName ? vm.deliveryDetails.address.streetName : '',
          id: vm.deliveryDetails.address.id,
        }, supplierInformation: { id: vm.supplierInfoId }, id: vm.deliveryDetailsId,
        createdDate: vm.deliveryDetails.createdDate

      }).
        then(function (responseData) {
          $state.go('ezyap.' + vm.userRole.pretty + '.manage-supplier-details');
          NotificationService.success({
            title: 'Supplier Delivery Details',
            message: 'alertMessage.UPDATED_SUCCESSFULLY'
          });
        }, function (error) {
          NotificationService.error({
            title: 'Supplier Delivery Details',
            error: error
          });
        });
    }

    /**
    * get all supplier information
    */
    function getSupplierInformation() {
      if (vm.supplierDetails) {
        return false;
      }
      ManageSupplierInfoService.getSupplierInformation({ query: "" }).
        then(function (response) {
          vm.supplierDetails = response.data;
        }, function (error) {
          NotificationService.error({
            title: '',
            error: error
          });
        });
    }

    /**
     * [get delivery details by id]
     * @param {Integer} deliveryDetailsId [Delivery details id]
     */
    function getDeliveryDetailsById() {
      ManageSupplierDetailsService.getDeliveryDetailsById(vm.deliveryDetailsId).
        then(function (response) {
          vm.deliveryDetails = response.data;
        }, function (error) {
          NotificationService.error({
            title: 'Supplier Delivery datails',
            error: error
          });
        });
    }
  }
})();
