/*
 * @Author: Ajay 
 * @Date: 2019-02-11 11:37:07 
 * @Project: Ezyprocure
 * @Filename: ezyap.buyer.external-peppol-invoices.service.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-10-24 10:36:11
 */


(function () {
    'use strict';
  
    angular.module('ezyprocureApp').factory( 'EzyapGetPosService', EzyapGetPosService);
  
    EzyapGetPosService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  
    function EzyapGetPosService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {
  
      var EzyapGetPosService = {
        getAllPos: getAllPos,
        getPoDetailsById: getPoDetailsById
      };
      return EzyapGetPosService;
  
      /**
       * [getAllPos Gets all invoices]
       * @param {*} params 
       */
      function getAllPos(params) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/purchase-orders',
          method: "GET",
          params: params
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      /**
       * [getPoDetailsById Gets purchase order details by id]
       * @param {*} id 
       */
      function getPoDetailsById(id) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/purchase-orders/' + id,
          method: "GET"
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
    }
  })();