/**
 * @Author: Jayalekshmi
 * @Date:   2018-07-12 13:00:30
 * @Filename: buyer.transaction-summary-report.state.js
 * @Last modified by:   Allan
 * @Last modified time: 2018-08-27T11:11:44+05:30
 */



(function() {
    'use strict';

    angular
    .module('ezyprocureApp')
    .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
      $stateProvider
      .state('bnpl.buyer.transaction-summary-report', {
        parent: 'bnpl.buyer',
        url: '/transaction-summary-report',
        data: {
          authorities: ['ROLE_BUYER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/bnpl/transaction-summary-report/transaction-summary-report.html',
            controller: 'TransactionSummaryReportController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,desc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('buyer');
            $translatePartialLoader.addPart('supplier');
            $translatePartialLoader.addPart('user-management');
            $translatePartialLoader.addPart('purchaseOrder');
            $translatePartialLoader.addPart('invoiceOCR');
            $translatePartialLoader.addPart('brand');
            $translatePartialLoader.addPart('debitNoteStatus');
            $translatePartialLoader.addPart('paymentStatus');
            return $translate.refresh();
          }]
        }
      })
      .state('bnpl.buyer.transaction-summary-report.purchase-order-details', {
        parent: 'bnpl.buyer.transaction-summary-report',
        url: '/po-details/:poNumber',

        data: {
          authorities: ['ROLE_BUYER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/bnpl/transaction-summary-report/details/transaction-summary-report.details.html',
            controller: 'TransactionPurchaseOrderDetailsController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('buyer');
            $translatePartialLoader.addPart('supplier');
            $translatePartialLoader.addPart('purchaseOrder');
            $translatePartialLoader.addPart('items');
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      })
      .state('bnpl.buyer.transaction-summary-report.credit-note-details', {
        parent: 'bnpl.buyer.transaction-summary-report',
        url: '/creditnote/:crNumber',

        data: {
          authorities: ['ROLE_BUYER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/bnpl/transaction-summary-report/details/transaction-summary-report.details.html',
            controller: 'TransactionPurchaseOrderDetailsController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('buyer');
            $translatePartialLoader.addPart('supplier');
            $translatePartialLoader.addPart('items');
            $translatePartialLoader.addPart('purchaseOrder');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('invoiceOCR');
            return $translate.refresh();
          }]
        }
      })
      .state('bnpl.buyer.transaction-summary-report.details', {
        parent: 'bnpl.buyer',
        url: '/details/:transactionSummaryReport',

        data: {
          authorities: ['ROLE_BUYER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/bnpl/transaction-summary-report/details/transaction-summary-report.details.html',
            controller: 'TransactionSummaryReportDetailsController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('buyer');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('items');
            return $translate.refresh();
          }]
        }
      });
    }

  })();
