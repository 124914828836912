/**
* @Author: Anju
* @Date:   23-May-2024 11:40 +05:30
* @Project: Ezyprocure
* @Filename: admin.subscription-management-report.service.js
*/

(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('SubscriptionManagementReportService', SubscriptionManagementReportService);

  SubscriptionManagementReportService.$inject = ['$http', '$q', 'API_CONSTANTS'];

  function SubscriptionManagementReportService($http, $q, API_CONSTANTS) {

    var subscriptionManagementReportService = {
      getAllSubscriptionManagementReport: getAllSubscriptionManagementReport,
      updateBuyerSubscriptionManagementReport:updateBuyerSubscriptionManagementReport,
      updateSupplierSubscriptionManagementReport:updateSupplierSubscriptionManagementReport,
      addSubscriptionManagementReportViaCsvUpload:addSubscriptionManagementReportViaCsvUpload
    };

    /**
     * [getAllSubscriptionManagementReport]
     * @return {[List]}[getAllSubscriptionManagementReport]
     */
    function getAllSubscriptionManagementReport(params) {
      console.log(params);
      var deffered = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + 'admin/billing/report',
        method: 'GET',
        params: params
      }).then(function (data) {
        deffered.resolve(data);
      }).catch(function (message) {
        deffered.reject(message);
      });
      return deffered.promise;
    }

    function updateBuyerSubscriptionManagementReport(urlParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + 'buyer-business/subscription',
        method: "PUT",
        data: urlParams
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function updateSupplierSubscriptionManagementReport(urlParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + 'supplier/subscription',
        method: "PUT",
        data: urlParams
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * addSubscriptionmanagementReportViaExcelUpload
    * @param {file}
   */
    function addSubscriptionManagementReportViaCsvUpload(file) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + 'admin/billing/import',
        method: "POST",
        data: file
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    return subscriptionManagementReportService;
  }
})();
