/*
 * @Author: Neethu S 
 * @Date: 2023-08-17
 * @Project: Ezyprocure
 * @Filename: ezyap.manage-supplier-details.controller.js
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapSupplierDetailsController', EzyapSupplierDetailsController);

  EzyapSupplierDetailsController.$inject = ['NotificationService', 'ManageSupplierDetailsService', 'paginationConstants', '$mdDialog', '$filter', 'pagingParams', 'Auth'];

  function EzyapSupplierDetailsController(NotificationService, ManageSupplierDetailsService, paginationConstants, $mdDialog, $filter, pagingParams, Auth) {

    var vm = this;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.getDeliveryDetails = getDeliveryDetails;
    vm.init = init;
    vm.deleteDeliveryDetailsById = deleteDeliveryDetailsById;
    vm.showDeleteConfirmationModal = showDeleteConfirmationModal;
    vm.userRole = Auth.getUserRole();

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function init() {
      vm.getDeliveryDetails();
    }

    /**
    * [get Supplier Information]
    */
    function getDeliveryDetails() {
      ManageSupplierDetailsService.getDeliveryDetails({
        query: vm.searchText ? vm.searchText : "",
        page: vm.query.page - 1,
        supplierInformationId: vm.supplierInformationId,
        size: vm.query.limit,
        sort: sort(),
      }).then(function (response) {
        vm.deliveryDetails = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      }, function (error) {
        NotificationService.error({
          title: 'Delivery Details',
          error: error
        });
      });
    }

    /**
     * [delete the supplier information of the given id]
     * @param {String} supplierId [id of the supplier]
     */
    function deleteDeliveryDetailsById(supplierId) {
      ManageSupplierDetailsService.deleteDeliveryDetailsById(supplierId).
        then(function (responseData) {
          vm.getDeliveryDetails();
          NotificationService.success({
            title: 'Supplier Delivery Details',
            message: 'alertMessage.DELETED_SUCCESSFULLY'
          });
        }, function (error) {
          NotificationService.error({
            title: 'Supplier Delivery Details',
            error: error
          });
        });
    }

    /**
     * [show confirmaton modal to delete supplier information]
     * @param {Integer} supplierinfoId [supplier information id]
     */
    function showDeleteConfirmationModal(supplierinfoId) {
      var confirm = $mdDialog.confirm()
        .title('Are you sure you want to delete the supplier delivery details?')
        .targetEvent(event)
        .ok($filter('translate')('global.buttons.proceed'))
        .cancel($filter('translate')('global.buttons.cancel'));
      $mdDialog.show(confirm).then(function () {
        vm.deleteDeliveryDetailsById(supplierinfoId);
      }, function () { });
    }
  }
})();