/**
* @Author: Mufsir
* @Date:   17-Jan-2017 17:22 +05:30
* @Project: Ezyprocure
* @Filename: buyer-supplier-mapping.service.js
* @Last modified by:   Mufsir
* @Last modified time: 05-Jun-2017 18:21 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerSupplierMappingService', BuyerSupplierMappingService);

  BuyerSupplierMappingService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function BuyerSupplierMappingService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var buyerSupplierMappingService = {
      getAllBuyerSupplierMappings: getAllBuyerSupplierMappings,
      getAllBuyerMappedSuppliers: getAllBuyerMappedSuppliers,
      getAllBuyerMappedSupplierList: getAllBuyerMappedSupplierList,
      getAllBuyerMappedSupplierListWithParam:getAllBuyerMappedSupplierListWithParam,
      getAllSupplierMappedBuyers: getAllSupplierMappedBuyers,
      getAllSupplierMappedBuyerList: getAllSupplierMappedBuyerList,
      isMapped: isMapped,
      createBSMapping: createBSMapping,
      deleteBSMapping: deleteBSMapping,
      mapBuyerToSuppliersCSV: mapBuyerToSuppliersCSV,
      mapSupplierToBuyersCSV: mapSupplierToBuyersCSV,
      updateSupplierIdentificationCode: updateSupplierIdentificationCode,
      getAllMappedSupplierBuyers: getAllMappedSupplierBuyers,
      getAllBuyerMappedAdhocSuppliers: getAllBuyerMappedAdhocSuppliers,
      updateBuyerIdentificationCode: updateBuyerIdentificationCode,
      mapContractedSuppliers: mapContractedSuppliers,
      getAllSupplierWithSatsAccess: getAllSupplierWithSatsAccess,
      getAllEzyapBuyerSuppliersMappedList: getAllEzyapBuyerSuppliersMappedList
    };

    return buyerSupplierMappingService;

    /* --  Admin Buyer Supplier Map CRUD operations  -- */

    /**
     * [getAllBuyerSupplierMappings BuyerSupplierMappings]
     * @return {[List]}         [BuyerSupplierMappings]
     */
    function getAllBuyerSupplierMappings(urlParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer-supplier-mappings/mappings',
        method: "GET",
        params: urlParams,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getAllBuyerMappedSuppliers BuyerMappedSuppliers]
     * @param  {[Integer]} buyerId [buyerId]
     * @return {[List]}         [BuyerMappedSuppliers]
     */
    function getAllBuyerMappedSuppliers(buyerId, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'map/buyer/'+buyerId+'/suppliers',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getAllSupplierWithSatsAccess Gets all suppliers wihe SATS Access]
     */
    function getAllSupplierWithSatsAccess(){
      var deferred = $q.defer();
      $http({
      url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.SUPPLIER_RESOURCE+'sats',
      method: "GET"
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


    function getAllBuyerMappedAdhocSuppliers(buyerId, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'map/buyer/'+buyerId+'/suppliers',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getAllBuyerMappedSupplierList Get All Buyer Mapped Suppliers Custom DTO with email]
     * @param  {[Integer]} buyerId [buyerId]
     * @return {[List]}         [BuyerMappedSuppliers with email]
     */
    function getAllBuyerMappedSupplierList(buyerId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'map/buyer/'+buyerId+'/supplier-list',
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

        /**
         * [getAllBuyerMappedSupplierList Get All Buyer and brand Mapped Suppliers ]
         * @param  {[Integer]} buyerId [buyerId]
         * @param  {[Integer]} brandId [brandId]
         */
        function getAllBuyerMappedSupplierListWithParam(buyerId,params) {
          var deferred = $q.defer();
          $http({
            url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'map/buyer/'+buyerId+'/supplier-list',
            method: "GET",
            params: params,
          }).then(function(data) {
            deferred.resolve(data);
          }).catch(function(message) {
            deferred.reject(message);
          });
          return deferred.promise;
        }

    /**
     * [getAllSupplierMappedBuyers Get All Supplier Mapped Buyers]
     * @param  {[Integer]} supplierId [supplierId]
     * @return {[List]}            [SupplierMappedBuyers]
     */
    function getAllSupplierMappedBuyers(supplierId, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'map/supplier/'+supplierId+'/buyers',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getAllSupplierMappedBuyerList Get All Supplier Mapped Buyers Custom DTO with email]
     * @param  {[Integer]} supplierId [supplierId]
     * @return {[List]}            [SupplierMappedBuyerList with email]
     */
    function getAllSupplierMappedBuyerList(supplierId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'map/supplier/'+supplierId+'/buyer-list',
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [isMapped Check Buyer and Supplier is Mapped or not]
     * @param  {[Object]}  mapping [mappingObj]
     * @return {Boolean}         [Check Buyer and Supplier is Mapped or not]
     */
    function isMapped(mapping) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer-supplier-mappings/', {params: mapping}).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [createBSMapping Create a new buyer-supplier mapping]
     * @param  {[Object]} mapping [mappingObj]
     * @return {[Object]}         [Success or failure response]
     */
    function createBSMapping(mapping) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer-supplier-mappings/' + mapping.buyerId + '/' + mapping.supplierId,
        method: "POST",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [deleteBSMapping Soft Delete existing buyer-supplier mapping]
     * @param  {[Object]} mapping [mappingObj]
     * @return {[Object]}         [Success or failure response]
     */
    function deleteBSMapping(mapping) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer-supplier-mappings/', {params: mapping}).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [mapBuyerToSuppliersCSV Map Buyer To Suppliers using CSV]
     * @param  {[Object]} csvMapping [csvMappingDTO]
     * @return {[Object]}            [Success or failure response]
     */
    function mapBuyerToSuppliersCSV(csvMapping) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'map/buyer/upload-suppliers', csvMapping).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [mapSupplierToBuyersCSV Map Supplier To Buyers using CSV]
     * @param  {[Object]} csvMapping [csvMappingDTO]
     * @return {[Object]}            [Success or failure response]
     */
    function mapSupplierToBuyersCSV(csvMapping) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'map/supplier/upload-buyers', csvMapping).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function updateSupplierIdentificationCode(mapping) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer-supplier-mappings/supplier-identification-code', mapping).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

     function getAllMappedSupplierBuyers(params) {
          var deferred = $q.defer();
          $http({
            url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyerSupplier-mappings/mappings',
            method: "GET",
            params: params,
          }).then(function(data) {
            deferred.resolve(data);
          }).catch(function(message) {
            deferred.reject(message);
          });
          return deferred.promise;
        }
        /**
         * updateBuyerIdentificationCode
         * @param {[object]} mapping
         * @returns {[object]}
         */
        function updateBuyerIdentificationCode(mapping) {
          var deferred = $q.defer();
          $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer-supplier-mappings/buyer-identification-code', mapping).then(function(data) {
            deferred.resolve(data.data);
          }).catch(function(message) {
            deferred.reject(message);
          });
          return deferred.promise;
        }

      function mapContractedSuppliers(supplierList) {
        var deferred = $q.defer();
        $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer-supplier-mappings/map-contracted-suppliers', supplierList).then(function(data) {
          deferred.resolve(data.data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

       /**
     * [getAllEzyapBuyerSuppliersMappedList Get All Buyer / Suppliers Custom DTO with email]
     * @param  {[Integer]} buyerId [buyerId]
     * @return {[List]}         [BuyerMappedSuppliers with email]
     */
    function getAllEzyapBuyerSuppliersMappedList() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/supplier-buyer-information',
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
