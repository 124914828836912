/**
* @Author: Anju
* @Date:   23-May-2024 11:40 +05:30
* @Project: Ezyprocure
* @Filename: admin.subscription-management-report.state.js
*/

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('admin.subscription-management-report', {
        parent: 'admin',
        url: '/subscription-management-reports',
        data: {
          authorities: ['ROLE_ADMIN'],
          pageTitle: 'ezyprocureApp.buyer.subscriptionManagement.detail.title'
        },
        views: {
          'content@': {
            templateUrl: 'app/admin-modules/subscription-management-report/admin.subscription-management.html',
            controller: 'SubscriptionManagementReportsController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,asc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('buyer');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('brandBuyerUserMapping');
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      });
  }

})();
