/*
 * @Author:Neethu
 * @Date: 2323-08-23 14:24:00
 * @Project: Ezyprocure
 * @Filename: ezyap.edit-supplier-information.controller.js
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapEditSupplierInfoController', EzyapEditSupplierInfoController);

    EzyapEditSupplierInfoController.$inject = ['$state', 'NotificationService', 'ManageSupplierInfoService', '$stateParams', 'GLOBAL_CONSTANTS', 'EZYAP_CONSTANTS', 'Auth'];

  function EzyapEditSupplierInfoController($state, NotificationService, ManageSupplierInfoService, $stateParams, GLOBAL_CONSTANTS, EZYAP_CONSTANTS, Auth) {

    var vm = this;
    vm.editSupplierInformation = editSupplierInformation;
    vm.supplierId = $stateParams.id;
    vm.getSupplierInformationById = getSupplierInformationById;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.init = init;
    vm.getAppendedValues = getAppendedValues;
    vm.countryCodes = EZYAP_CONSTANTS.ezyap.countryCodes;
    vm.userRole = Auth.getUserRole();

    function init() {
      vm.getSupplierInformationById(vm.supplierId);
    }

    /**
     * [edit supplier information]
     */
    function editSupplierInformation() {
      ManageSupplierInfoService.editSupplierInformation({
        addressAdditionalStreetName: vm.supplierDetails.addressAdditionalStreetName?vm.supplierDetails.addressAdditionalStreetName:'',
        addressCityName: vm.supplierDetails.addressCityName?vm.supplierDetails.addressCityName:'',
        addressCompanyId: vm.supplierDetails.addressCompanyId?vm.supplierDetails.addressCompanyId:'',
        addressContactEmail: vm.supplierDetails.addressContactEmail?vm.supplierDetails.addressContactEmail:'',
        addressContactTelephone: vm.supplierDetails.addressContactTelephone?vm.supplierDetails.addressContactTelephone:'',
        addressContactName: vm.supplierDetails.addressContactName?vm.supplierDetails.addressContactName:'',
        addressCountryCode: vm.supplierDetails.addressCountryCode,
        addressOrganisationName: vm.supplierDetails.addressOrganisationName,
        addressPostalZone: vm.supplierDetails.addressPostalZone?vm.supplierDetails.addressPostalZone:'',
        addressStreetName: vm.supplierDetails.addressStreetName?vm.supplierDetails.addressStreetName:'',
        isoCode: vm.supplierDetails.isoCode,
        receiverKey: vm.supplierDetails.receiverKey,
        id: vm.supplierId,
        addressId: vm.supplierDetails.addressId
      }).
        then(function (responseData) {
          $state.go('ezyap.'+vm.userRole.pretty+'.supplier-info');
          NotificationService.success({
            title: 'global.supplierInformation',
            message: 'alertMessage.UPDATED_SUCCESSFULLY'
          });
        }, function (error) {
          NotificationService.error({
            title: 'global.supplierInformation',
            error: error
          });
        });
    }

    /**
     * [get supplier information by id]
     * @param {Integer} supplierId [id of the supplier]
     */
    function getSupplierInformationById(supplierId) {
      ManageSupplierInfoService.getSupplierInformationById(supplierId).
        then(function (response) {
          vm.supplierDetails = response.data;
          vm.isoCode = getAppendedValues();
        }, function (error) {
          NotificationService.error({
            title: 'global.supplierInformation',
            error: error
          });
        });
    }

    /**
     * [getAppendedValues Returns concantinated values]
     */
    function getAppendedValues(){
      return vm.supplierDetails.isoCode + ' : ' + vm.supplierDetails.receiverKey;
    }
  }
})();
