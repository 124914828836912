/**
 * @Author: Ajay
 * @Date:   03-Mar-2017 15:12 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.dashboard.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 09-Nov-2017 15:04 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierDashboardController', SupplierDashboardController);

  SupplierDashboardController.$inject = ['$scope', '$state', '$stateParams', '$filter', '$mdDialog', 'SupplierDashboardService', 'GLOBAL_CONSTANTS', 'NotificationService', 'SupplierDataService', 'SatsManagementService','BuyerDashboardService', 'Auth', 'SubscriptionPlan'];

  function SupplierDashboardController ($scope, $state, $stateParams, $filter, $mdDialog, SupplierDashboardService, GLOBAL_CONSTANTS, NotificationService, SupplierDataService, SatsManagementService,BuyerDashboardService,Auth,SubscriptionPlan) {

    var vm = this;
    vm.getSupplierDashboardCount = getSupplierDashboardCount;
    vm.tabIndex = $stateParams.tabIndex ? $stateParams.tabIndex : 0;
    vm.refreshInterval = GLOBAL_CONSTANTS.DASHBOARD_REFRESH_INTERVAL;
    vm.uobBizPlusUrl = GLOBAL_CONSTANTS.UOB_BIZPLUS_URL;
    vm.showEzysourceBanner = GLOBAL_CONSTANTS.SHOW_EZYSOURCE_BANNER;
    vm.showUobBanner = GLOBAL_CONSTANTS.SHOW_UOB_BANNER;
    vm.accessDTO = SupplierDataService.getSupplierProfile().accessDTO;
    vm.getSupplierDashboardPendingJobsCount = getSupplierDashboardPendingJobsCount;
    //Dashboard counter refresh timeout
    vm.incrementInterval = incrementInterval;
    vm.initializeIntervalCount = initializeIntervalCount;
    vm.refreshDashboardCounts = refreshDashboardCounts;
    vm.refreshTimeout = GLOBAL_CONSTANTS.DASHBOARD_REFRESH_TIMEOUT;
    vm.initialRefreshInterval = GLOBAL_CONSTANTS.DASHBOARD_REFRESH_INTERVAL;
    vm.refreshIntervalIncrement = GLOBAL_CONSTANTS.DASHBOARD_INTERVAL_INCREMENT;
    vm.pauseRefreshFlag = false;
    vm.accessForEzyinventory = SupplierDataService.getSupplierProfile().accessDTO.accessForEzyinventory;
    vm.initialiseCarousal = initialiseCarousal;
    vm.showSubscriptionLicenceRenewalAlert = showSubscriptionLicenceRenewalAlert;

    /**
     * [getSupplierDashboardPendingJobsCount gets the count of Released jobs count]
     */
    function getSupplierDashboardPendingJobsCount(){
      SatsManagementService.getPendingJobsDashboardCount()
      .then(function(response){
        vm.supplierPendingJobsCount = response.data.count;
      },function(error){
        NotificationService.error({
          title: 'Supplier Dashboard',
          error: error
        });
      });
    }
    
    function initialiseCarousal(){
      var myCarousel = document.querySelector('#myCarousel');
      var carousel = $(myCarousel).carousel();
    }

    /** force refresh counts */
    function refreshDashboardCounts() {
      getSupplierDashboardCount();
      initializeIntervalCount();
      initialiseCarousal();
    }

    /** increment refresh interval on successfull refresh */
    function incrementInterval() {
      if(vm.refreshInterval == vm.initialRefreshInterval) {
        vm.refreshInterval += vm.refreshIntervalIncrement;
      }
      else if(vm.refreshInterval < vm.refreshTimeout) {
        vm.refreshInterval += vm.refreshIntervalIncrement;
      }
      else if (vm.refreshInterval >= vm.refreshTimeout) {
        vm.pauseRefreshFlag = true;
      }
    }

    function initializeIntervalCount() {
      vm.refreshInterval = vm.initialRefreshInterval;
      vm.pauseRefreshFlag = false;
    }

    function getSupplierDashboardCount() {
      SupplierDashboardService.getSupplierDashboardCount().then(function(response){
        vm.supplierDashboardCount = response.data;
      },function(error){
        NotificationService.error({
          title: 'Supplier Dashboard',
          error: error
        });
      });
      getCarousalImg();
    }

    function getCarousalImg(){
      BuyerDashboardService.getCarousalDetails()
      .then(function(response){
        vm.carousalImgList = response.data;
      },function(error){
        NotificationService.error({
          title: 'Supplier Dashboard',
          error: error
        });
      });
    }

    function showSubscriptionLicenceRenewalAlert() {
      const showPopup = sessionStorage.getItem('showExpirationPopup');
      const expirationTimestamp = sessionStorage.getItem('expirationPopupTimestamp');

      // Check if more than 24 hours have passed since the last login
      const twentyFourHoursInMillis = 24 * 60 * 60 * 1000;
      const currentTime = new Date().getTime();

      if (showPopup === "true" || (!expirationTimestamp || (currentTime - expirationTimestamp) > twentyFourHoursInMillis)) {
        var startTime = new Date();
        var supplierId;
        var intervalId = setInterval(function () {
          supplierId = SupplierDataService.getSupplierProfile().supplier.id;

          // Check if 10 seconds have passed, and if so, clear the interval
          if (supplierId || new Date() - startTime >= 10000) {
            clearInterval(intervalId);

            if (!supplierId) {
              console.error('Unable to retrieve supplierId. Some properties are undefined.');
              return;
            }

            var userRole = Auth.getUserRole().ugly;
            var userType;
            if (userRole === 'ROLE_SUPPLIER') {
              userType = 'supplier';
            }
            SubscriptionPlan.fetchSubscriptionLicenceRenewalAlert(supplierId, userType).then(function (response) {
              // Find the subscription with the least number of days remaining              
              // Calculate the date today
              const today = new Date();

              // Function to calculate the difference in days
              function daysBetween(date1, date2) {
                // Create Date objects from the input dates
                date1 = new Date(date1);
                date2 = new Date(date2);
            
                // Set both dates to midnight (00:00:00) to ignore time
                date1.setHours(0, 0, 0, 0);
                date2.setHours(0, 0, 0, 0);
            
                const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
                return Math.round((date2 - date1) / oneDay);
              }

              // Initialize variables to keep track of the subscription with the least days remaining
              var minDaysSubscription = null;
              var minDaysRemaining = Infinity;
              var minDaysIndices = [];
              // Iterate through subscriptions to find the one with the least days remaining
              for (var index = 0; index < response.data.subscriptions.length; index++) {
                var subscription = response.data.subscriptions[index];
                if (subscription.subscriptionEndDate) {
                  const endDate = new Date(subscription.subscriptionEndDate);
                  const daysRemaining = daysBetween(today, endDate);

                  if (daysRemaining < minDaysRemaining) {
                    minDaysRemaining = daysRemaining;
                    minDaysIndices = [index]; // Reset indices array to only include this new minimum
                  } else if (daysRemaining === minDaysRemaining) {
                    minDaysRemaining = daysRemaining;
                    minDaysIndices.push(index); // Add this index to the array
                  }
                }
              }
              // Retrieve subscriptions with the minimum days remaining
              var minDaysSubscriptions = _.map(minDaysIndices,function (index) {
                return response.data.subscriptions[index];
              })
              vm.valid_to = minDaysSubscriptions.length !== 0?minDaysSubscriptions[0].subscriptionEndDate:null;
              if (minDaysSubscriptions && minDaysRemaining <= 45) {
                var popupData = {
                  "valid_to": vm.valid_to,
                  "minDaysRemaining": minDaysRemaining,
                  "minDaysSubscriptions": minDaysSubscriptions,
                  "expiringSubscriptions": response.data.subscriptions
                }
                $mdDialog.show({
                  templateUrl: 'app/common-modules/web-reminder/web-reminder.html',
                  controller: 'WebReminderController',
                  controllerAs: 'vm',
                  escapeToClose: false,
                  clickOutsideToClose: false,
                  resolve: {
                    data: function () {
                      return popupData;
                    }
                  }
                });
                if(minDaysRemaining > 1){
                  sessionStorage.removeItem('licenseExpirationData');
                }
                console.log("Subscription with the least days remaining:", minDaysSubscription);
                console.log("Days remaining:", minDaysRemaining);
              } else {
                console.log('No valid subscription found.');
                console.log(response.data.errorMessage)
              }
            }, function (error) {
             console.log("fetching subscription details failed")
            });
            // Update the timestamp to the current time
            const licenseExpirationDataString = sessionStorage.getItem('licenseExpirationData');
            const licenseExpirationData = JSON.parse(licenseExpirationDataString);
            if (licenseExpirationData && Object.keys(licenseExpirationData).length > 0) {
            sessionStorage.setItem('showExpirationPopup', 'true');
            }else{
            sessionStorage.setItem('expirationPopupTimestamp', currentTime);
            sessionStorage.setItem('showExpirationPopup', 'false');
            }
          }
        }, 2000);
      }
    }

    getSupplierDashboardCount();

    initializeIntervalCount();
    initialiseCarousal();
    showSubscriptionLicenceRenewalAlert();

  }
})();
