/**
* @Author: Mufsir
* @Date:   17-Jan-2017 17:22 +05:30
* @Project: Ezyprocure
* @Filename: buyer-supplier-mapping.service.js
* @Last modified by:   Mufsir
* @Last modified time: 05-Jun-2017 18:21 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('ManageBnplService', ManageBnplService);

  ManageBnplService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function ManageBnplService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var ManageBnplService = {
      getAllBnplUser:getAllBnplUser,
      confirmBnpl:confirmBnpl
    };

    return ManageBnplService;

    /**
     * [getAllBnplUser ManageBNPL]
     * @return {[List]}         [getAllBnplUser]
     */
    

    function getAllBnplUser(params) {
      var deffered = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' +'bnpl/admin/list',
        method: 'GET',
        params: params
      }).then(function(data){
        deffered.resolve(data);
      }).catch(function(message){
        deffered.reject(message);
      });
      return deffered.promise;
    }

    function confirmBnpl(payload)
    {
        var deferred = $q.defer();
        $http.post(
          API_CONSTANTS.serviceBase  + 'api/' + API_CONSTANTS.baseVersion + 'bnpl/admin/activate',payload
        ).then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
    }

    
 
 

    

   

   

   

    

    

    
     
  }
})();
