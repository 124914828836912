/*
 * @Author: Neethu 
 * @Date: 2023-08-22  
 * @Project: Ezyprocure
 * @Filename: ezyap.peppol-orders-summary.service.js
 */

(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('ExtenalPeppolOrdersService', ExtenalPeppolOrdersService);

  ExtenalPeppolOrdersService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function ExtenalPeppolOrdersService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var ExtenalPeppolOrdersService = {
      sendPoById: sendPoById,
      sendAllPo: sendAllPo,
      updateExportFlag: updateExportFlag,
      // createeInvoice: createeInvoice
    };
    return ExtenalPeppolOrdersService;

    /**
     * [send po by po id]
     * @param {Integer} poId [id of the purchase order to be send]
     */
    function sendPoById(poId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/purchase-orders/upload/' + poId,
        method: "POST"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [sendAllPo sends all pos]
     * @param {Integer} poIdList [id of the po to be send]
     */
    function sendAllPo(poIdList) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/purchase-orders/upload-ap/bulk',
        method: "PUT",
        data: poIdList
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

     /**
     * [updateExportFlag Updates the export flag]
     * @param {List} poIdList [id of the invoice exported]
     */
    function updateExportFlag(poIdList) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/purchase-orders/export',
        method: "PUT",
        data: poIdList
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }

})();