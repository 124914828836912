/*
 * @Author: Ajay 
 * @Date: 2019-02-12 08:24:47 
 * @Project: Ezyprocure
 * @Filename: ezyap.peppol-invoices-summary.details.controller.js
 * @Last Modified by: Lovin
 * @Last Modified time: 2020-01-29 16:27:02
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapInvoicesDetailsController', EzyapInvoicesDetailsController);

  EzyapInvoicesDetailsController.$inject = ['NotificationService', '$stateParams', 'ExtenalPeppolInvoicesService', 'EzyapGetInvoicesService', 'PDFExportService', '$filter', 'Auth'];

  function EzyapInvoicesDetailsController(NotificationService, $stateParams, ExtenalPeppolInvoicesService, EzyapGetInvoicesService, PDFExportService, $filter, Auth) {

    var vm = this;
    vm.invoiceId = $stateParams.id;
    vm.init = init;
    vm.getInvoiceDetailsById = getInvoiceDetailsById;
    vm.sendInvoiceById = sendInvoiceById;
    vm.downloadURI = downloadURI;
    vm.userRole = Auth.getUserRole();
    vm.updateExportFlag = updateExportFlag;
    vm.getInvoiceSummaryPDF = getInvoiceSummaryPDF;
    vm.getUomFromItem = getUomFromItem;
    vm.statusUpdate = statusUpdate;
    vm.getStatusCode = getStatusCode;
    vm.getStatusClarificationReason = getStatusClarificationReason;
    vm.getStatusAction = getStatusAction;
    // vm.peppolInvoiceStatus = [{ data: 'Message acknowledgement', value: 'AB' }, { data: 'Accepted', value: 'AP' }, { data: 'Rejected', value: 'RE' }, { data: 'In process', value: 'IP' }, { data: 'Under query', value: 'UQ' }, { data: 'Conditionally accepted', value: 'CA' }, { data: 'Paid', value: 'PD' }];
    vm.filter={};
    // vm.filter.peppolInvoiceStatus = vm.peppolInvoiceStatus[0];
    // vm.statusClarificationReason = [{ data: 'No Issue', value: 'NON' }, { data: 'References incorrect', value: 'REF' }, { data: 'Legal information incorrect', value: 'LEG'}, { data: 'Receiver unknown', value: 'REC'}, { data: 'Item quality insufficient', value: 'QUA'}, { data: 'Delivery issues', value: 'DEL'}, 
    //                                 { data: 'Prices incorrect', value: 'PRI'}, { data: 'Quantity incorrect', value: 'QTY'}, { data: 'Items incorrect', value: 'ITM'}, { data: 'Payment terms incorrect', value: 'PAY'}, { data: 'Not recognized', value: 'UNR'}, { data: 'Finance incorrect', value: 'FIN'}, { data: 'Partially Paid', value: 'PPD'},
    //                                 { data: 'Other', value: 'OTH'}];
    // vm.filter.statusClarificationReason = vm.statusClarificationReason[0];    
    
    // vm.statusAction = [{statusActionMeaning: 'No action required', statusActionCode:'NOA'}, {statusActionMeaning: 'Provide information', statusActionCode:'PIN'}, {statusActionMeaning: 'Issue new invoice', statusActionCode:'NIN'}, {statusActionMeaning: 'Credit the amount', statusActionCode:'CNA'}, , {statusActionMeaning: 'Other', statusActionCode:'OTH'}]
    // vm.filter.statusAction = vm.statusAction[0];
    
    var selectedStatus, selectedReason, selectedActions;

    vm.init();
    function init() {
      vm.getInvoiceDetailsById();
    }

    /**
     * [get individual invoice details by invoice id]
     */
    function getInvoiceDetailsById() {
      EzyapGetInvoicesService.getInvoiceDetailsById(vm.invoiceId).
        then(function (response) {
          vm.invoiceDetails = response.data;
          vm.invoiceDetails.invoice.invoiceDueDate = $filter('date')(vm.invoiceDetails.invoice.invoiceDueDate, "dd/MM/yyyy", 'UTC');
          vm.invoiceDetails.invoice.invoiceIssueDate = $filter('date')(vm.invoiceDetails.invoice.invoiceIssueDate, "dd/MM/yyyy", 'UTC');
          vm.getStatusCode();
          vm.getStatusClarificationReason();
          vm.getStatusAction();
        }, function (error) {
          NotificationService.error({
            title: '',
            error: error
          });
        });
    }

    function getUomFromItem(item) {
      return !!item.uom ? item.uom : (!!item.unitCode ? item.unitCode.name : null);
    }

    /**
     * [send invoice by invoice id]
     */
    function sendInvoiceById() {
      ExtenalPeppolInvoicesService.sendInvoiceById(vm.invoiceId).
        then(function (response) {
          vm.getInvoiceDetailsById();
          NotificationService.success({
            title: 'global.sendInvoice',
            message: 'alertMessage.INVOICE_SEND_SUCCESSFULLY'
          })
        }, function (error) {
          NotificationService.error({
            title: 'global.sendInvoice',
            error: error
          });
        });
    }

    function getInvoiceSummaryPDF() {
      PDFExportService.getInvoiceSummaryPDF(vm.invoiceId).then(function(response){
        vm.downloadURI(response.data.message, "PDF_" + vm.invoiceId);
      },function(error) {
        NotificationService.error({
          title: 'Individual PDF Export',
          error: error
        });
      });
    }

    /**
     * [updateExportFlag Updates the export flag]
     * @param {Id} invoiceId 
     */
    function updateExportFlag(invoiceId){
      ExtenalPeppolInvoicesService.updateExportFlag(invoiceId)
        .then(function(response){
        },function(error){
      });
    }

    /**
     * 
     * @param {String} uri [uploaded url]
     * @param {String} fileName [the name of the file downloaded file]
     */
    function downloadURI(uri, fileName) {
      updateExportFlag([vm.invoiceId]);
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = fileName;
      window.open(uri,'_blank');
    }

    /**
     *  [get invoice status list]
     */
    function getStatusCode() {
      ExtenalPeppolInvoicesService.getInvoiceStatus(vm.invoiceDetails.invoice.id, vm.invoiceDetails.invoice.peppolInvoiceStatus).
        then(function (response) {
          vm.peppolInvoiceStatus = response.data;
          vm.selectedStatusObject = _.find(vm.peppolInvoiceStatus, function(invoiceStatus) { return invoiceStatus.selected === true; });
          vm.selectedStatus = vm.selectedStatusObject.statusCode;
        }, function (error) {
          NotificationService.error({
            title: '',
            error: error
          });
        });
    }

     /**
     *  [get invoice status clarification reason]
     * @param {String} selectedStatus [status code selected]
     */
     function getStatusClarificationReason() {

      ExtenalPeppolInvoicesService.getStatusReason(vm.invoiceDetails.invoice.id, vm.selectedStatus).
        then(function (response) {
          vm.statusClarificationReason = response.data;
          vm.selectedReasonObject = _.find(vm.statusClarificationReason, function(invoiceReason) { return invoiceReason.selected === true; });
          vm.selectedReason = vm.selectedReasonObject.statusReasonCode;
        }, function (error) {
          NotificationService.error({
            title: '',
            error: error
          });
        });
    }

    /**
     *  [get invoice status  action]
     * @param {String} selectedReason [reason code selected]
     */
    function getStatusAction() {
      ExtenalPeppolInvoicesService.getStatusAction(vm.invoiceDetails.invoice.id, vm.selectedStatus, vm.selectedReason).
        then(function (response) {
            vm.statusAction = response.data;
            vm.selectedActions = _.map(_.filter(vm.statusAction, {'selected': true}), function(item){
              return item.statusActionCode;
            });
          
        }, function (error) {
          NotificationService.error({
            title: '',
            error: error
          });
        });
    }

    /**
     *  [ send invoice response status ]
     * @param {Integer} id [id]
     */
    function statusUpdate() {
      var data = {
        
        "invoiceStatus": vm.selectedStatus,
        "statusReason": vm.selectedReason,
        "statusActions": vm.selectedActions
      }
      ExtenalPeppolInvoicesService.updateInvoiceStatus(vm.invoiceDetails.invoice.id, data).
        then(function (response) {
          NotificationService.success({
            title: 'global.buttons.statusUpdate',
            message: 'alertMessage.INVOICE_RESPONSE_SENT_SUCCESSFULLY'
          }) 
        }, function (error) {
          NotificationService.error({
            title: '',
            error: error
          });
        });
    }
  }
})();
