/**
* @Author: Jayalekshmi R
* @Date:   2018-06-11T15:32:51+05:30
* @Project: Ezyprocure
* @Filename: buyer.brands.service.js
 * @Last modified by:   Sneha
 * @Last modified time: 2018-09-19T09:20:39+05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BrandManagementService', BrandManagementService);

  BrandManagementService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function BrandManagementService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var brandManagementService = {
      getAllBrands: getAllBrands,
      getAllBrandsByBuyerId:getAllBrandsByBuyerId,
      createBrand: createBrand,
      getBrandById : getBrandById,
      updateBrand :updateBrand,
      deleteBrand: deleteBrand,
      updateBusinessServiceRate: updateBusinessServiceRate,
      getAllBrandsOfBuyerList: getAllBrandsOfBuyerList,
      getBrandsWithTokenMapped: getBrandsWithTokenMapped,
      getSelectedBrands: getSelectedBrands,
      getBrandIsoCodeAndIdentifier: getBrandIsoCodeAndIdentifier,
      createBrandIsoCodeAndIdentifier: createBrandIsoCodeAndIdentifier,
      editBrandIsoCodeAndIdentifier: editBrandIsoCodeAndIdentifier,
      deleteBrandIsoCodeAndIdentifier:deleteBrandIsoCodeAndIdentifier,
      getCybersourceMerchantDetails: getCybersourceMerchantDetails,
      submitCyberSourceForm: submitCyberSourceForm,
      updateCyberSourceForm: updateCyberSourceForm,
      fetchCyberSourceDetails: fetchCyberSourceDetails,
      unmapCyberSourceDetail: unmapCyberSourceDetail,
      getBrandsOnlyBnpl:getBrandsOnlyBnpl
    };
    return brandManagementService;

    function getCybersourceMerchantDetails(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cybersource/merchant-details/view',
        method: "GET",
        params: params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function submitCyberSourceForm(data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + 'cybersource/merchant-details/save',
        method: "POST",
        data: data
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function updateCyberSourceForm(data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + 'cybersource/merchant-details/update',
        method: "PUT",
        data: data
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function fetchCyberSourceDetails(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cybersource-merchant-details/' + params.id,
        method: "GET"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function unmapCyberSourceDetail(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cybersource-merchant-details/' + params.id,
        method: "DELETE"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // get brands iso code and identifier
    function getBrandIsoCodeAndIdentifier(params){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/ezy-brand-details',
        method: "GET",
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise; 
    }

    // Create iso code and identifier
    function createBrandIsoCodeAndIdentifier(details){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/ezy-brand-details',
        method: "POST",
        data: details
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // delete iso code and identifier
    function deleteBrandIsoCodeAndIdentifier(id){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/ezy-brand-details/'+ id,
        method: "DELETE",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //change iso code and identifier
    function editBrandIsoCodeAndIdentifier(details){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/ezy-brand-details' ,
        method: "PUT",
        data: details
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [getAllBrands Gets all brands]
    * @param {Object} params
    */
    function getAllBrands(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRANDS,
        method: "GET",
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [getAllBrandsByBuyerId Gets all brands]
    * @param {Object} params
    */
    function getAllBrandsByBuyerId(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRANDS + 'buyer',
        method: "GET",
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [Creates a new brand.]
    * @param {Object} brands
    */
    function createBrand(brands) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRANDS, brands).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [getBrandById - Gets one specific brand ]
    * @param {Integer} brandId
    */
    function getBrandById(brandId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRANDS + brandId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [updateBrand - Updates a brand]
    * @param {Object} brand
    */
    function updateBrand(brand) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRANDS, brand).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [deleteBrand - Deletes a brand]
    * @param {Integer} brandId
    */
    function deleteBrand(brandId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRANDS + brandId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [updateBrand - Updates brands businessServiceRate]
    * @param {Object} brand
    */
    function updateBusinessServiceRate(brand) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRANDS+ 'business-service-rate/', brand).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [updateBrand - Updates brands businessServiceRate]
    * @param {Object} brand
    */
   function getAllBrandsOfBuyerList(buyerList) {
    var deferred = $q.defer();
    $http({
      url:API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRANDS+ 'buyer-list',
      method: 'GET',
      params: buyerList
    }).then(function(data) {
      deferred.resolve(data.data);
    }).catch(function(message) {
      deferred.reject(message);
    });
    return deferred.promise;
  }


    function getBrandsWithTokenMapped(params) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRANDS + 'buyer-with-token-mapped').then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


    function getBrandsOnlyBnpl(params) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BNPL + 'brands').then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
     /**
    * [updateBrand - Updates brands businessServiceRate]
    * @param {Object} brand
    */
   function getAllBrandsOfBuyerList(buyerList) {
    var deferred = $q.defer();
    $http({
      url:API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRANDS+ 'buyer-list',
      method: 'GET',
      params: buyerList
    }).then(function(data) {
      deferred.resolve(data.data);
    }).catch(function(message) {
      deferred.reject(message);
    });
    return deferred.promise;
  }

      /**
      * [getSelectedBrands get brandName and id from array of selected brandIds]
      * @return {[type]} [description]
      */
      function getSelectedBrands(selectedBrandIds, brandList){
        var selected = {};
        //get brand objects from the selected brandIds
        selected =  _.flatMap(selectedBrandIds, function(brandId){
          return _.filter(brandList, ['id', brandId]);
        });

        // create new array of brands with only brandNames and properties
        var selectedList = _.map(selected,  function(brand){
          return {
            'id' : brand.id,
            'name': brand.name
          }
        });
        return selectedList;
      }

  }
})();
