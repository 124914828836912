/*
 * @Author: Jayalekshmi 
 * @Date: 2019-06-14 12:16:08 
 * @Project: Ezyprocure
 * @Filename: supplier.delivery-conditions.edit.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-06-14 12:16:58
 */



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('SupplierEditDeliveryConditionController', SupplierEditDeliveryConditionController);
  
    SupplierEditDeliveryConditionController.$inject = ['$scope', '$state', '$stateParams', 'TIME_CONSTANTS', 'SupplierDeliveryConditionService', 'UomService', 'ItemGroupService', 'paginationConstants', 'NotificationService', 'GLOBAL_CONSTANTS'];
  
    function SupplierEditDeliveryConditionController($scope, $state, $stateParams, TIME_CONSTANTS, SupplierDeliveryConditionService, UomService, ItemGroupService, paginationConstants, NotificationService, GLOBAL_CONSTANTS) {
      var vm = this;
  
      vm.deliverConditionId = $stateParams.deliverConditionId;
      vm.init = init;
      vm.getdeliveryConditionDataById = getdeliveryConditionDataById;
      vm.save = save;
      vm.populateDeliveryConditions = populateDeliveryConditions;
      vm.checkDeliveryConditions = checkDeliveryConditions;
      vm.deliveryConditions = {};
      vm.orderTimes = TIME_CONSTANTS.timeConstants;
      vm.updateLeadTime = updateLeadTime;

      /**
       * [save Updates the delivery condition details]
       */
      function save() {
        SupplierDeliveryConditionService.updateSupplierDeliveryCondition(vm.deliveryConditionsObj).then(function(response) {
          NotificationService.success({
            title: 'Delivery Condition',
            message: 'alertMessage.UPDATED_SUCCESSFULLY'
          });
          $state.go('supplier.tools.managedeliveryconditions', {
            reload: false
          });
        }, function(error) {
          NotificationService.error({
            title: 'Delivery Condition Updation',
            error: error
          });
        });
      }
  
      /**
       * [getdeliveryConditionDataById Gets all delivery condtion details by id]
       */
      function getdeliveryConditionDataById() {
        SupplierDeliveryConditionService.getSupplierDeliveryConditionById(vm.deliverConditionId).
        then(function(response) {
          vm.deliveryConditionsObj = response;
          vm.populateDeliveryConditions();
        }, function(error) {
        });
      }
  
      function init() {
        vm.getdeliveryConditionDataById();
      }

      /**
       * [checkDeliveryConditions Checks the delivery conditions]
       */
      function checkDeliveryConditions() {
        if (!vm.deliveryConditions.today) {
          vm.deliveryConditionsObj.orderTimeForToday = null;
        }
        if (!vm.deliveryConditions.friday) {
          vm.deliveryConditionsObj.orderTimeForFriday = null;
        }
        if (!vm.deliveryConditions.saturday) {
          vm.deliveryConditionsObj.orderTimeForSaturday = null;
        }
        if (!vm.deliveryConditions.minimum) {
          vm.deliveryConditionsObj.minimumOrderForFreeDelivery = 0;
          vm.deliveryConditionsObj.belowMinimumPurchaseCondition = null;
          vm.deliveryConditionsObj.deliveryCharge = 0;
        }
      }
      
          /**
    * [updateLeadTime update lead time value based on noSameDayDelivery condition]
    * @param  {[type]} noSameDayDelivery [no same day delivery condition for supplier]
    * @return {[type]}                   [description]
    */
    function updateLeadTime(noSameDayDelivery){
      if(noSameDayDelivery){
        vm.deliveryConditionsObj.leadTime = 1;
      }
      else{
        vm.deliveryConditionsObj.leadTime = 0;
      }
    }

      /**
       * [populateDeliveryConditions Populates the delivery conditions]
       */
      function populateDeliveryConditions() {
        if(vm.deliveryConditionsObj.orderTimeForToday) {
          vm.deliveryConditions.today = true;
        }
        if(vm.deliveryConditionsObj.orderTimeForFriday) {
          vm.deliveryConditions.friday = true;
        }
        if(vm.deliveryConditionsObj.orderTimeForSaturday) {
          vm.deliveryConditions.saturday = true;
        }
        if(vm.deliveryConditionsObj.minimumOrderForFreeDelivery) {
          vm.deliveryConditions.minimum = true;
        }
      }
    }
  })();
  