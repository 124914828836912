/**
* @Author: Abhay
* @Date:   01-02-2022 12:15 +05:30
* @Project: Ezyprocure
* @Filename: truncate.filter.js
 * @Last modified by:   Abhay
 * @Last modified time: 01-02-2022 12:15 +05:30
*/



(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .filter('unique', unique);
function unique(){
  return function(input, key) {
    var unique = {};
    var uniqueList = [];
    for(var i = 0; i < input.length; i++){
        if(typeof unique[input[i][key]] == "undefined"){
            unique[input[i][key]] = "";
            uniqueList.push(input[i]);
        }
    }
    return uniqueList;
};
}
        
})();
