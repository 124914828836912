/**
* @Author: Mufsir
* @Date:   24-Jan-2017 12:05 +05:30
* @Project: Ezyprocure
* @Filename: admin.user-access.controller.js
 * @Last modified by:   Atul
 * @Last modified time: Tue Jul 11 2023 09:31:10 GMT+0530 (India Standard Time)
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('AdminUserAccessController', AdminUserAccessController);

  AdminUserAccessController.$inject = ['$scope', '$filter', 'pagingParams', '$mdDialog', 'UserAccessService', 'paginationConstants', 'NotificationService', 'GLOBAL_CONSTANTS'];

  function AdminUserAccessController($scope, $filter, pagingParams, $mdDialog, UserAccessService, paginationConstants, NotificationService, GLOBAL_CONSTANTS) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.sort = sort;
    vm.loadAll = loadAll;
    vm.showAccessModal = showAccessModal;
    vm.updateUserAccess = updateUserAccess;
    vm.cancel = cancel;
    vm.searchText = '';

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    // Load All admin user's access data
    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      UserAccessService.getAllUsers({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        login: vm.searchText,
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.users = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'User Access Listing',
          error: error
        });
      });
    }

    /** filter access control data */
    function filterAccessControl() {
      vm.accessControlData.forEach(function(category) {
        if(category.value && category.value.length > 0) {
          category.value = category.value.filter(function (access) {
            return (access.authorization == 'BOTH' || vm.selectedUserAccess.userRoles.name == access.authorization);
          });
        }
      });
    }

    //Show modal to update user's access
    function showAccessModal(event, index, userAccess) {
      UserAccessService.getUserAccess(userAccess.userId).then(function(response) {
        vm.selectedUserAccess = response;
        vm.accessControlData = GLOBAL_CONSTANTS.accessControlData;
        _.map(vm.accessControlData, function(each){
          _.map(each.value, function(value){
            value.model = vm.selectedUserAccess[value.accessControl];
            value.serchText = $filter('translate')(value.translation);
          });
        });
        // filterAccessControl();
        $mdDialog.show({
          templateUrl: 'app/admin-modules/user-access/admin.user-access.details.tmpl.html',
          controller: function() {
            return vm;
          },
          controllerAs: 'vm',
          targetEvent: event,
          clickOutsideToClose: true
        });
      }, function(error) {
        NotificationService.error({
          title: 'User Access Details',
          error: error
        });
      });
    }

    //Update a admin user's access data
    function updateUserAccess(userId, key, value) {
      var userAccessData = {
        "userId": userId,
        "key": key,
        "value": value
      };
      UserAccessService.updateUserAccess(userAccessData)
      .then(function(response) {
        vm.selectedUserAccess[key] = response.value;
      }, function(error) {
        NotificationService.error({
          title: 'User Access Updation',
          error: error
        });
        vm.selectedUserAccess[key] = error.data.value;
      });
    }

    function cancel() {

      $mdDialog.cancel();
    }

    vm.loadAll();

  }
})();
