/**
* @Author: Mufsir
* @Date:   19-May-2017 15:34 +05:30
* @Project: Ezyprocure
 * @Last modified by:   sneha
 * @Last modified time: 2019-11-29T12:04:04+05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('PDFExportService', PDFExportService);

  PDFExportService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS', 'GLOBAL_CONSTANTS'];

  function PDFExportService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS, GLOBAL_CONSTANTS) {

    var service = {
      getPurchaseOrderPDF: getPurchaseOrderPDF,
      getConsolidatedPurchaseOrderPDF: getConsolidatedPurchaseOrderPDF,
      getInvoicePDF: getInvoicePDF,
      getConsolidatedInvoicePDF: getConsolidatedInvoicePDF,
      getGoodReceiptNotePDF: getGoodReceiptNotePDF,
      getCreditNotePDF: getCreditNotePDF,
      getAdHocCreditNotePDF: getAdHocCreditNotePDF,
      getGoodsReturnNoticePDF: getGoodsReturnNoticePDF,
      printStockReportsPDF: printStockReportsPDF,
      printMassDOPDF: printMassDOPDF,
      printMassPOPDF:printMassPOPDF,
      printPDF : printPDF,
      getInvoiceSummaryPDF:getInvoiceSummaryPDF,
      getPoSummaryPDF:getPoSummaryPDF,
      getDeliveryOrderPDF : getDeliveryOrderPDF,
      getConsolidatedGrnPDF: getConsolidatedGrnPDF
      // getPDF: getPDF
    };

    return service;

//
// function getPDF() {
//    var title = "Invoice";
//    // if (this.invoiceDetails && this.invoiceDetails.xeroResponse.Invoices[0].InvoiceNumber){
//    //   title = this.invoiceDetails.xeroResponse.Invoices[0].InvoiceNumber;
//    // }
//    // let printContents, popupWin;
//    var printContents = document.getElementById('exportInvoiceReport').innerHTML;
//    var popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
//    popupWin.document.open();
//    popupWin.document.write(`
//      <html>
//        <head>
//          <style>
//
//       table{
//         border: 1px solid black;
//       }
//          </style>
//        </head>
//    <body onload="window.print();window.close()">${printContents}</body>
//      </html>`
//    );
//    popupWin.document.close();
//  }


    /**
     * [printPDF Prints Pdf]
     * @param {String} id
     */
    function printPDF(id){
      html2canvas(document.getElementById(id), {
        onrendered: function (canvas) {
          var data = canvas.toDataURL('image/jpeg', 1.0);
          var docDefinition = {
            // compress: false,
            // a string or { width: number, height: number }
            pageSize: 'A4',
            // by default we use portrait, you can change it to landscape if you wish
            pageOrientation: 'portrait',
            pages: 6,

            // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
            pageMargins: [ 40, 60, 40, 60 ],
            alignment: 'justify',
            content: [{
              image: data,
              width: 500
            }]

             };
            pdfMake.createPdf(docDefinition).print();
        }
    });
    }

    function getPurchaseOrderPDF(purchaseOrderNumber, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'pdfexport/po/' + purchaseOrderNumber,
        method: "GET",
        params:params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // Prints DO details in pdf
    function getDeliveryOrderPDF (purchaseOrderNumber) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'pdfexport/do/' + purchaseOrderNumber,
        method: "GET"
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getInvoicePDF(purchaseOrderNumber,params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'pdfexport/invoices/' + purchaseOrderNumber,
        method: "GET",
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getConsolidatedInvoicePDF(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'pdfexport/invoice/consolidated',
        method: "GET",
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getConsolidatedPurchaseOrderPDF(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'pdfexport/purchase-order/consolidated',
        method: "GET",
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getConsolidatedGrnPDF(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'pdfexport/grnpdf/consolidated',
        method: "GET",
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


    function getGoodReceiptNotePDF(purchaseOrderNumber,params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'pdfexport/grs/' + purchaseOrderNumber,
        method: "GET",
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getCreditNotePDF(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'pdfexport/creditnote/',
        method: "GET",
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAdHocCreditNotePDF(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'pdfexport/adhoccreditnote/',
        method: "GET",
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getGoodsReturnNoticePDF(goodsReturnNumber) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'pdfexport/goods-return-notice/' + goodsReturnNumber,
        method: "GET"
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function printStockReportsPDF(businessReportRequestDTO, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'stock-details/export',
        method: "POST",
        data: businessReportRequestDTO,
        params:params,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function printMassDOPDF(data, doPdfSort ) {
      console.log('Dattde11',data)
      console.log('doPdfSort11',doPdfSort)
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'delivery-orders/export',
        method: "POST",
        params:doPdfSort,
        data:data,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function printMassPOPDF(data, doPdfSort ) {
      console.log('Dattde',data)
      console.log('doPdfSort',doPdfSort)
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'massPrintPo/pdfExport',
        method: "POST",
        params:doPdfSort,
        data:data,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getInvoiceSummaryPDF(invoiceId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/pdfexport/invoices/' + invoiceId,
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getPoSummaryPDF(poId) {
      console.log('po summary pdf', poId);
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/pdfexport/purchase-orders/' + poId,
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
