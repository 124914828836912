/*
 * @Author: Abhay
 * @Date: 2020-12-18 11:47:49
 * @Filename: ezypayment.admin.nfrnds-account-report-statement.controller.js
 * @Last Modified by: Abhay
 * @Last Modified time: 2020-12-18 11:33:03
 */


(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .controller('ManageBnplController', ManageBnplController);

    ManageBnplController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', 'toastr', 'pagingParams', 'UTCDateService', 'NotificationService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'BuyerBusinessService', 'ManageBuyerService', 'EzypaymentAdminStatementOfAccountService', 'BrandManagementService', 'ManageSupplierService', '$window', 'EXPORT_CONSTANTS', 'CSVExportService', 'EzypaymentAdminManageMerchantDetailsService', '$stateParams','ManageBnplService', 'Auth','$mdDialog'];

    function ManageBnplController($scope, $rootScope, $q, $filter, $state, toastr, pagingParams, UTCDateService, NotificationService, GLOBAL_CONSTANTS, paginationConstants, BuyerBusinessService, ManageBuyerService, EzypaymentAdminStatementOfAccountService, BrandManagementService, ManageSupplierService, $window, EXPORT_CONSTANTS, CSVExportService, EzypaymentAdminManageMerchantDetailsService, $stateParams, ManageBnplService,Auth,$mdDialog) {
        var vm = this;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.todaysDate = new Date();
        vm.yesterday = new Date(vm.todaysDate.getTime());
        vm.yesterday.setDate(vm.todaysDate.getDate() - 1);
        vm.isEditable=false;
        vm.loadAll = loadAll;
        vm.query = {
            limit: paginationConstants.itemsPerPage,
            page: 1
        };
        vm.checkValue=checkValue;
        vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
        vm.limitOptions = paginationConstants.limitOptions;
        vm.filter = {};
        vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
        vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
        vm.configured_admin_bnpl_pass = GLOBAL_CONSTANTS.CONFIGURED_ADMIN_BNPL_PASS;
        vm.statementOfAccountReportCSVHeader = EXPORT_CONSTANTS.CSV.nfrndsStatementOfAccountReportCSVHeader;
        vm.selectAll = selectAll;
        vm.deselectAll = deselectAll;
        vm.loadDropdowns = loadDropdowns;
        vm.getAllBuyersMappedToPayment = getAllBuyersMappedToPayment;
        vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
        vm.getStatementOfAccountReportCSV =getStatementOfAccountReportCSV;
        vm.userRole = Auth.getUserRole().ugly;
        vm.selectedBnpl=null;
        vm.cancelEditBnpl=cancelEditBnpl;
        vm.loadAll();
        vm.cancel=cancel;
        vm.showAdminBnplPasswordModal=showAdminBnplPasswordModal;
        vm.checkPassword=checkPassword;
        vm.sha256 = sha256;
        vm.confirmBnpl=confirmBnpl;
        /**
        * [selectAllInvoices select all Pending Process Orders]
        */

       vm.showAdminBnplPasswordModal();
        function showAdminBnplPasswordModal() {
            // reset previous data if any
            vm.details = {};
            $mdDialog.show({
                templateUrl: 'app/core/common-templates/bnpl-pass.html',
                controller: function() {
                    return vm;
                },
                controllerAs: 'vm',
                escapeToClose: false,
                // targetEvent: event,
                clickOutsideToClose: false
            });
        }
        $scope.editBnpl=function(id)
        {
            vm.loanPaid=0;
            vm.statementOfAccountReports.forEach(function(element) {
                if(element.brandBnplDetailsDTOs[0].id==id){
                    vm.selectedBnpl= element ;
                    $scope.selectedBnpl= vm.selectedBnpl;
                } });
                vm.totalLoanPaid=vm.selectedBnpl.brandBnplDetailsDTOs[0]
                .loanPaid;
               vm.bnplStatus= vm.selectedBnpl.bnplStatus;
                $mdDialog.show({
                    templateUrl: 'app/core/common-templates/edit-bnpl.html',
                    controller: function () {
                        return vm;
                      },
                      controllerAs: 'vm',
                      targetEvent: event,
                    clickOutsideToClose: true
                  });

        }
        function cancel() {

            $mdDialog.cancel();
            $state.go('admin.dashboard',{reload: false, initialActive: true})
          }

          function cancelEditBnpl() {

            $mdDialog.cancel();
          }
          function checkPassword(details) {
            if (vm.sha256(details.password) == vm.configured_admin_bnpl_pass) {
                $mdDialog.cancel();
                sessionStorage.isSetAmendPassword = JSON.stringify('true');
            } else {
                toastr.error('Invalid Password!', 'Authentication Failed');
            }
        }
        function getStatementOfAccountReportCSV() {
            var deferred = $q.defer();
            vm.selectedStatementOfAccountReports = _.filter(vm.statementOfAccountReports, _.matches({ 'selected': true }));
            if (!vm.statementOfAccountReports) {
                NotificationService.simpleErrorToast({
                    title: 'global.table.noRecordsFound',
                    message: 'alertMessage.SEARCH_AND_SUBMIT'
                });
                return deferred.reject;
            } else {
                var getStatementOfAccountReportCSVData = (vm.selectedStatementOfAccountReports.length < 1) ? vm.statementOfAccountReports : vm.selectedStatementOfAccountReports;
                return CSVExportService.getStatementOfAccountReportNfrndsCSV(getStatementOfAccountReportCSVData, vm.filter.listView);
            }
        }
        vm.loanPaid=0;
        function checkValue()
        {
           
           var cb=+vm.selectedBnpl.brandBnplDetailsDTOs[0].creditBalance;
           var ca=+vm.selectedBnpl.brandBnplDetailsDTOs[0].creditAllocated;
           var cu=+vm.selectedBnpl.brandBnplDetailsDTOs[0].creditUtilized;
          if(cb+vm.loanPaid>ca)  {
            vm.loanPaid=0;
          }
          if(vm.loanPaid>cu)  {
            vm.loanPaid=0;
          }
        }
        function confirmBnpl(payload)
        {
            ManageBnplService.confirmBnpl( {
                "id": vm.selectedBnpl.brandBnplDetailsDTOs[0].id,
                "businessServiceRate": vm.selectedBnpl.brandBnplDetailsDTOs[0]
                .businessServiceRate,
                "mdr": vm.selectedBnpl.brandBnplDetailsDTOs[0]
                .mdr,
                "creditAllowed": vm.selectedBnpl.brandBnplDetailsDTOs[0].creditAllocated,
                "creditUtilized": vm.selectedBnpl.brandBnplDetailsDTOs[0].creditUtilized,
                "creditBalance": vm.selectedBnpl.brandBnplDetailsDTOs[0].creditBalance,
                "bnplStatus": vm.selectedBnpl.bnplStatus,


                "loanPaid":vm.loanPaid,
                "name":vm.selectedBnpl.name,
                "email":vm.selectedBnpl.email,
               "reasonForRejection":vm.selectedBnpl.brandBnplDetailsDTOs[0].reasonForRejection,
               "bnplPartner":vm.selectedBnpl.brandBnplDetailsDTOs[0].bnplPartner,
               "tenor":vm.selectedBnpl.brandBnplDetailsDTOs[0].tenor
                }).then(function (response) {
                    toastr.success('successfully!', 'BNPL detail updated');
                       vm.cancelEditBnpl();
                       vm.loadAll();
                    }, function (error) {
                        NotificationService.error({
                            title: 'BNPL detail',
                            error: error
                        });
                    });
        }

  function sha256(ascii) {
            function rightRotate(value, amount) {
                return (value >>> amount) | (value << (32 - amount));
            };

            var mathPow = Math.pow;
            var maxWord = mathPow(2, 32);
            var lengthProperty = 'length'
            var i, j; // Used as a counter across the whole file
            var result = ''

            var words = [];
            var asciiBitLength = ascii[lengthProperty] * 8;

            //* caching results is optional - remove/add slash from front of this line to toggle
            // Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
            // (we actually calculate the first 64, but extra values are just ignored)
            var hash = sha256.h = sha256.h || [];
            // Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
            var k = sha256.k = sha256.k || [];
            var primeCounter = k[lengthProperty];
            /*/
            var hash = [], k = [];
            var primeCounter = 0;
            //*/

            var isComposite = {};
            for (var candidate = 2; primeCounter < 64; candidate++) {
                if (!isComposite[candidate]) {
                    for (i = 0; i < 313; i += candidate) {
                        isComposite[i] = candidate;
                    }
                    hash[primeCounter] = (mathPow(candidate, .5) * maxWord) | 0;
                    k[primeCounter++] = (mathPow(candidate, 1 / 3) * maxWord) | 0;
                }
            }

            ascii += '\x80' // Append Ƈ' bit (plus zero padding)
            while (ascii[lengthProperty] % 64 - 56) ascii += '\x00' // More zero padding
            for (i = 0; i < ascii[lengthProperty]; i++) {
                j = ascii.charCodeAt(i);
                if (j >> 8) return; // ASCII check: only accept characters in range 0-255
                words[i >> 2] |= j << ((3 - i) % 4) * 8;
            }
            words[words[lengthProperty]] = ((asciiBitLength / maxWord) | 0);
            words[words[lengthProperty]] = (asciiBitLength)

            // process each chunk
            for (j = 0; j < words[lengthProperty];) {
                var w = words.slice(j, j += 16); // The message is expanded into 64 words as part of the iteration
                var oldHash = hash;
                // This is now the undefinedworking hash", often labelled as variables a...g
                // (we have to truncate as well, otherwise extra entries at the end accumulate
                hash = hash.slice(0, 8);

                for (i = 0; i < 64; i++) {
                    var i2 = i + j;
                    // Expand the message into 64 words
                    // Used below if
                    var w15 = w[i - 15],
                        w2 = w[i - 2];

                    // Iterate
                    var a = hash[0],
                        e = hash[4];
                    var temp1 = hash[7] +
                        (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) // S1
                        +
                        ((e & hash[5]) ^ ((~e) & hash[6])) // ch
                        +
                        k[i]
                        // Expand the message schedule if needed
                        +
                        (w[i] = (i < 16) ? w[i] : (
                            w[i - 16] +
                            (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15 >>> 3)) // s0
                            +
                            w[i - 7] +
                            (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2 >>> 10)) // s1
                        ) | 0);
                    // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
                    var temp2 = (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) // S0
                        +
                        ((a & hash[1]) ^ (a & hash[2]) ^ (hash[1] & hash[2])); // maj

                    hash = [(temp1 + temp2) | 0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
                    hash[4] = (hash[4] + temp1) | 0;
                }

                for (i = 0; i < 8; i++) {
                    hash[i] = (hash[i] + oldHash[i]) | 0;
                }
            }

            for (i = 0; i < 8; i++) {
                for (j = 3; j + 1; j--) {
                    var b = (hash[i] >> (j * 8)) & 255;
                    result += ((b < 16) ? 0 : '') + b.toString(16);
                }
            }
            return result;
        };
        /**
        * [loadAll Load All statement of account report]
        * @param  {[string]} search [search text]
        */
        function loadAll(search) {
            if (!search) {
                vm.searchText = '';
            }
            var a=(!vm.filter.fromDate ? "" : UTCDateService.utcFromDate(vm.filter.fromDate));
            var b=(!vm.filter.toDate ? UTCDateService.utcToDate(vm.todaysDate) : UTCDateService.utcToDate(vm.filter.toDate));
            ManageBnplService.getAllBnplUser({
        "buyerIds":!vm.filter.buyerIdList ? [] : vm.filter.buyerIdList,
        "brandIds":!vm.filter.brandIdList ? [] : vm.filter.brandIdList,
        "uen":vm.filter.uen,
        "requestFromDate":a,
        "requestToDate":b,
        "bnplStatus":vm.filter.Status,
        page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort()
}).then(function (response) {
                console.log(response);
                vm.totalItems =response.data.totalElements;
                vm.statementOfAccountReports = response.data.content;
                $scope.manageBnplUsers=response.data.content;
                console.log(vm.statementOfAccountReports);
            }, function (error) {
                NotificationService.error({
                    title: 'Statement of Account Report',
                    error: error
                });
            });
        }

        /**
        * [sort to sort by PO date]
        */
        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            return result;
        }



        /**
        * [selectAll Selects all from the list]
        * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
        */
        function selectAll(dropdown) {
            switch (dropdown) {
                case 'BUYERS':
                    vm.filter.buyerIdList = _.map(vm.buyers, _.property('id'));
                    break;
                case 'BRANDS':
                    vm.filter.brandIdList = _.map(vm.brands, _.property('id'));
                    break;
                case 'SUPPLIERS':
                    vm.filter.supplierIdList = _.map(vm.suppliers, _.property('id'));
                    break;
            }
        }

        /**
         * [deselectAll Deselect all from the list]
         * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
         */
        function deselectAll(dropdown) {
            switch (dropdown) {
                case 'BUYERS':
                    vm.filter.buyerIdList = [];
                    break;
                case 'BRANDS':
                    vm.filter.brandIdList = [];
                    break;
                case 'SUPPLIERS':
                    vm.filter.supplierIdList = [];
                    break;
            }
        }

        /**
        * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
        * @param {Array} buyerIdList
        */
        function getAllBrandsOfBuyerList(buyerIdList) {
            if (!buyerIdList || buyerIdList.length === 0) return false;
            var params = {
                page: 0,
                size: paginationConstants.maxLimit,
                query: '',
                buyerId: buyerIdList,
                sort: sort()
            };
            BrandManagementService.getAllBrandsOfBuyerList(params)
                .then(function (response) {
                    console.log(response);
                    vm.brands = response;
                }, function (error) {
                    NotificationService.error({
                        title: 'Brand Listing',
                        error: error
                    });
                });
        }

        /**
        * [getAllBuyers Gets all buyers mapped with the payment model]
        */
        function getAllBuyersMappedToPayment() {
            if (vm.buyers) { return false }
            ManageBuyerService.getAllAdminBNPLBuyersWithUserDetails({
                page: 0,
                size: paginationConstants.maxLimit,
                search: '',
                sort: sort(),
                isAdminDashboard:vm.userRole==='ROLE_ADMIN' && $rootScope.redirectionApiFromDashboard==true?true:false
            }).then(function (response) {

                vm.buyers = response.data.content;
            }, function (error) {
                NotificationService.error({
                    title: 'Buyer Listing',
                    error: error
                });
            });
        }

        /**
        * [loadDropdowns The function executes when the dropdown closes]
        */
       vm.getAllBuyersMappedToPayment()
        function loadDropdowns() {
            vm.selectAll('BUYERS');
           // vm.getAllBuyersBusinessOutlets(vm.filter.buyerIdList);
            vm.getAllBrandsOfBuyerList(vm.filter.buyerIdList)
        }
}
})();
