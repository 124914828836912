/**
* @Author: Ajay
* @Date:   21-Apr-2017 11:37 +05:30
* @Project: Ezyprocure
* @Filename: buyer.invoice-report.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-06-24T14:11:48+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerInvoiceReportController', BuyerInvoiceReportController);

  BuyerInvoiceReportController.$inject = ['$scope', '$rootScope', '$filter', '$q', '$state', '$stateParams', 'pagingParams', 'toastr', '$mdDialog', 'BuyerDataService', 'UTCDateService', 'PurchaseOrderService', 'BuyerBusinessUserMapService', 'ItemGroupService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'BuyerBuyNowService', 'CSVExportService', 'EXPORT_CONSTANTS', 'NotificationService', 'GLOBAL_CONFIG_CONSTANTS', 'InvoiceService', 'Auth','UtilService'];

  function BuyerInvoiceReportController($scope, $rootScope, $filter, $q, $state, $stateParams, pagingParams, toastr, $mdDialog, BuyerDataService, UTCDateService, PurchaseOrderService, BuyerBusinessUserMapService, ItemGroupService, GLOBAL_CONSTANTS, paginationConstants, BuyerBuyNowService, CSVExportService, EXPORT_CONSTANTS, NotificationService, GLOBAL_CONFIG_CONSTANTS, InvoiceService, Auth,UtilService) {
    var vm = this;
    vm.init = init;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.getAllAllocatedSuppliersForBusiness = getAllAllocatedSuppliersForBusiness;
    vm.filter = {};
    vm.loadItemGroups = loadItemGroups;
    vm.todaysDate = new Date();
    vm.prevSelectedOutletId= 0;
    vm.getBatchPOListWithDetails = getBatchPOListWithDetails;
    vm.getBatchGEOInvoiceCSVData = getBatchGEOInvoiceCSVData;
    vm.geoInvoiceHeader = EXPORT_CONSTANTS.CSV.geoInvoiceHeader;
    vm.setConsolidatedInvoiceFilters  = setConsolidatedInvoiceFilters;
    vm.loadAll = loadAll;
    vm.validateDateRange = validateDateRange;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptionsWithAll;
    vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
    vm.invStatusClass = GLOBAL_CONFIG_CONSTANTS.INVOICE_STATUS.LABEL_CLASS;
    //Header
    //Function Declaration
    vm.getInvoiceReportCSVData = getInvoiceReportCSVData;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');
    //filter reset
    vm.resetFilters = resetFilters;
    vm.loadAllIfSpecificFiltersFilled = loadAllIfSpecificFiltersFilled;
    $scope.dateLocale = {
      formatDate: function(date) {
        var m = moment(date);
        return m.isValid() ? m.format('DD/MM/YYYY') : '';
      }
    };
    vm.filter.deliveryFromDate = null;
    vm.filter.deliveryToDate = null;
    vm.filter.grnFromDate = null;
    vm.filter.grnToDate = null;
    vm.filter.invoiceFromDate = null;
    vm.filter.invoiceToDate = null;
    function init(){
      if(vm.accessForPriceDisplay)
      vm.InvoiceReportHeader = EXPORT_CONSTANTS.CSV.BuyerInvoiceReport;
      else {
        vm.InvoiceReportHeader = _.without(EXPORT_CONSTANTS.CSV.BuyerInvoiceReport, 'Invoice Amount');
      }
    }


    //Getting CSV DATA
    function getInvoiceReportCSVData(){
      var deferred = $q.defer();
      if (!vm.poItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getBuyerInvoiceReportCSV(vm.poItems);
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
    */
   function getAllBuyerBusinessOutlets() {
    if(vm.businessOutlets)
      return false;
    vm.businessOutlets = null;
    BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser().
    then(function(response){
      vm.businessOutlets = response;
    },function(error){
    });
  }


  /**
  * [getAllAllocatedSuppliersForBusiness Get all allocated suppliers of buyer business]
  */
  function getAllAllocatedSuppliersForBusiness() {
    if(vm.supplierList && vm.prevSelectedOutletId === vm.filter.businessOutletId)
      return false;
    vm.prevSelectedOutletId = vm.filter.businessOutletId;
    var params = {'businessId': !vm.filter.businessOutletId ? '' : vm.filter.businessOutletId};
    BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function(response){
      vm.supplierList = $filter('orderBy')(response.data, 'companyName');
    },function(error){
    });
  }

    function loadItemGroups() {
      if(vm.itemGroups)
        return false;
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }

    /**
    * [loadAll Get all Purchase Order Details]
    */
    function loadAll() {
      var loadAllIfSpecificFiltersFilled = vm.loadAllIfSpecificFiltersFilled();
      if (loadAllIfSpecificFiltersFilled){
      if(vm.filter.listView === "Individual"){
        getIndividualPODetails();
      }
    }
    }


    function validateDateRange(numberOfDays, title, filterAvailable) {
    var poDateSelected = UtilService.checkDateRangeExists(vm.filter.poFromDate, vm.filter.poToDate, filterAvailable);
    var deliveryDateSelected = UtilService.checkDateRangeExists(vm.filter.deliveryFromDate, vm.filter.deliveryToDate, filterAvailable);
    var grnDateSelected = UtilService.checkDateRangeExists(vm.filter.grnFromDate, vm.filter.grnToDate, filterAvailable);
    var invoiceSelected = UtilService.checkDateRangeExists(vm.filter.invoiceFromDate, vm.filter.invoiceToDate, filterAvailable);
    
    if (!poDateSelected && !deliveryDateSelected && !grnDateSelected && !invoiceSelected) {
      toastr.error('Please select valid date ranges!', title);
      return false;
    }
    var poValid = UtilService.checkDateRangeValidity(vm.filter.poFromDate, vm.filter.poToDate, numberOfDays, title,poDateSelected);
    var deliveryValid = UtilService.checkDateRangeValidity(vm.filter.deliveryFromDate, vm.filter.deliveryToDate, numberOfDays, title,deliveryDateSelected);
    var grnValid = UtilService.checkDateRangeValidity(vm.filter.grnFromDate, vm.filter.grnToDate, numberOfDays, title,grnDateSelected);
    var invoiceValid = UtilService.checkDateRangeValidity(vm.filter.invoiceFromDate, vm.filter.invoiceToDate, numberOfDays, title,invoiceSelected);
    
    if ((poDateSelected && !poValid) ||(deliveryDateSelected && !deliveryValid) || (grnDateSelected && !grnValid) || (invoiceSelected && !invoiceValid)) {
      return false;
    }     
    return true;
  }

    /**
    * [getIndividualPODetails  gets PO details for each PO Number]
    */
    function getIndividualPODetails() {
      PurchaseOrderService.getAllPurchaseOrders({
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        purchaseOrderStatus: '',
        invoiceStatus: 'PROCESSED',
        invoiceNumber: !vm.filter.invoiceNumber ? '' : vm.filter.invoiceNumber,
        businessOutletId: !vm.filter.businessOutletId ? '': vm.filter.businessOutletId,
        businessOutletName: !vm.filter.businessOutletName ? '' : vm.filter.businessOutletName,
        //If dates are selected, they are converted to UTC format
        poFromDate: !vm.filter.poFromDate ? '' : UTCDateService.utcFromDate(vm.filter.poFromDate),
        poToDate: !vm.filter.poToDate ? '' : UTCDateService.utcToDate(vm.filter.poToDate),
        invoiceFromDate: !vm.filter.invoiceFromDate ? '' : UTCDateService.utcFromDate(vm.filter.invoiceFromDate),
        invoiceToDate: !vm.filter.invoiceToDate ? '' : UTCDateService.utcToDate(vm.filter.invoiceToDate),
        deliveryFromDate: !vm.filter.deliveryFromDate ? '' : UTCDateService.utcFromDate(vm.filter.deliveryFromDate),
        deliveryToDate: !vm.filter.deliveryToDate ? '' : UTCDateService.utcToDate(vm.filter.deliveryToDate),
        grnFromDate : !vm.filter.grnFromDate ? '' : UTCDateService.utcFromDate(vm.filter.grnFromDate),
        grnToDate : !vm.filter.grnToDate ? '' : UTCDateService.utcToDate(vm.filter.grnToDate),
        supplierId: !vm.filter.supplierId ? '': vm.filter.supplierId,
        itemGroupId: !vm.filter.itemGroupId ? '' :vm.filter.itemGroupId,
        buyerIdList: '',
        debitNoteStatus: '',
        grnNumber: '',
        buyerName: '',
        creditNoteStatus: '',
        supplierVisibilityType: '',
        query: '',
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        xeroExported: '',
        module : 0
      }).then(function(response){
        vm.poItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      },function(error){
        NotificationService.error({
          title: 'Fetching Individual PO details',
          error: error
        });
      });
    }

    /**
    * [getBatchPOListWithDetails  gets list of PO with details]
    */
    function getBatchPOListWithDetails() {
      var deferred = $q.defer();
      PurchaseOrderService.getDetailedDataOfPurchaseOrders({
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        purchaseOrderStatus: '',
        invoiceStatus: 'PROCESSED',
        invoiceNumber: !vm.filter.invoiceNumber ? '' : vm.filter.invoiceNumber,
        businessOutletId: !vm.filter.businessOutletId ? '': vm.filter.businessOutletId,
        businessOutletName: !vm.filter.businessOutletName ? '' : vm.filter.businessOutletName,
        //If dates are selected, they are converted to UTC format
        poFromDate: !vm.filter.poFromDate ? '' : UTCDateService.utcFromDate(vm.filter.poFromDate),
        poToDate: !vm.filter.poToDate ? '' : UTCDateService.utcToDate(vm.filter.poToDate),
        invoiceFromDate: !vm.filter.invoiceFromDate ? '' : UTCDateService.utcFromDate(vm.filter.invoiceFromDate),
        invoiceToDate: !vm.filter.invoiceToDate ? '' : UTCDateService.utcToDate(vm.filter.invoiceToDate),
        deliveryFromDate: !vm.filter.deliveryFromDate ? '' : UTCDateService.utcFromDate(vm.filter.deliveryFromDate),
        deliveryToDate: !vm.filter.deliveryToDate ? '' : UTCDateService.utcToDate(vm.filter.deliveryToDate),
        grnFromDate : !vm.filter.grnFromDate ? '' : UTCDateService.utcFromDate(vm.filter.grnFromDate),
        grnToDate : !vm.filter.grnToDate ? '' : UTCDateService.utcToDate(vm.filter.grnToDate),
        supplierId: !vm.filter.supplierId ? '': vm.filter.supplierId,
        itemGroupId: !vm.filter.itemGroupId ? '' :vm.filter.itemGroupId,
        debitNoteStatus: '',
        grnNumber: '',
        buyerName: '',
        creditNoteStatus: '',
        query: '',
      }).then(function(response){
        vm.batchPOItems = response.data;
        deferred.resolve(response.data);
      },function(error){
        NotificationService.error({
          title: 'Fetching Batch PO List with details',
          error: error
        });
        deferred.reject(error);
      });
      return deferred.promise;
    }

    //Get batch GEO
    function getBatchGEOInvoiceCSVData() {
      var deferred = $q.defer();
      vm.getBatchPOListWithDetails().
      then(function(response){
        var batchGEO = CSVExportService.getBatchGEOInvoiceCSV(response);
        deferred.resolve(batchGEO);
      },function(error){
        NotificationService.error({
          title: 'Fetching Batch GEO Invoice CSV Data',
          error: error
        });
        deferred.reject(error);
      });
      return deferred.promise;
    }


    function setConsolidatedInvoiceFilters() {
      InvoiceService.setConsolidatedInvoiceFilters(vm.filter);
      $state.go('buyer.reports.invoice-report.consolidated-details',{
        'fromDate': vm.filter.fromDate,
        'toDate': vm.filter.toDate,
        'deliveryFromDate': vm.filter.deliveryFromDate,
        'deliveryToDate': vm.filter.deliveryToDate,
        'invoiceFromDate': vm.filter.invoiceFromDate,
        'invoiceToDate': vm.filter.invoiceToDate
      }, {
        reload: false
        });
    }

    // filter reset
    function resetFilters() {
        vm.filter.invoiceNumber = '';
        vm.filter.itemGroupId = '';
        vm.filter.businessOutletId = '';
        vm.filter.supplierId = '';
        vm.filter.listView = vm.listViews[0];
        vm.filter.poNumber = '';
        vm.filter.poFromDate = vm.todaysDate;
        vm.filter.poToDate = vm.todaysDate;
        vm.filter.deliveryFromDate = null;
        vm.filter.deliveryToDate = null;
        vm.filter.grnFromDate = null;
        vm.filter.grnToDate = null;
        vm.filter.invoiceFromDate = null;
        vm.filter.invoiceToDate = null;
        vm.poItems = [];
    }

    function loadAllIfSpecificFiltersFilled() {
      var validationResult = false;
      var filtersToCheck = ['invoiceNumber','itemGroupId', 'businessOutletId', 'supplierId','poNumber'];
      var filterAvailable = UtilService.areSpecificFiltersFilled(filtersToCheck,vm.filter);
      var numberOfDays = GLOBAL_CONSTANTS.REPORTS_DATE_VALIDATION;
      var title = "Invoice Report";
      validationResult = validateDateRange(numberOfDays, title, filterAvailable);
      return validationResult;
    }

  }
})();
