/**
 * @Author: Fenn
 * @Date:   05-Feb-2020 19:49 +05:30
 * @Filename: register.service.js
 */


(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .factory('SubscriptionPlan', SubscriptionPlan);

  SubscriptionPlan.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS', 'AuthServerProvider'];

  function SubscriptionPlan($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS, AuthServerProvider) {

    var service = {
      fetchSubscriptionPlans: fetchSubscriptionPlans,
      fetchUserPlans: fetchUserPlans,
      fetchCards: fetchCards,
      applyPromo: applyPromo,
      selectPlan: selectPlan,
      getAdhocuser: getAdhocuser,
      defaultCardUpdate: defaultCardUpdate,
      deleteCard: deleteCard,
      getMerchantId: getMerchantId,
      postCardDetails: postCardDetails,
      addPlanAndPayment: addPlanAndPayment,
      getAmountByOutlets: getAmountByOutlets,
      updateOutletsAndPay: updateOutletsAndPay,
      fetchSubscriptionLicenceRenewalAlert:fetchSubscriptionLicenceRenewalAlert,
      sendEmailToCS:sendEmailToCS
    };

    return service;

    function fetchSubscriptionPlans(country, outlets) {
      var cbAuthToken = AuthServerProvider.getCbToken();
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion +
        RESOURCE_URL_CONSTANTS.FETCH_PLANS + country + '/outlets/' + outlets,
        {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        })
        .then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    function fetchCards(userId) {
      var cbAuthToken = AuthServerProvider.getCbToken();
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion +
        RESOURCE_URL_CONSTANTS.LISTCARDS + userId, {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        })
        .then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    function fetchUserPlans(userId) {
      var cbAuthToken = AuthServerProvider.getCbToken();
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.FETCH_USER_PLANS + userId, {
        headers: { Authorization: 'Bearer ' + cbAuthToken }
      })
        .then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    function getAdhocuser(id) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http({
        url: API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.GETADHOCSUPPLIERUSER + id,
        method: "POST",
        responseType: "blob",
        headers: { Authorization: 'Bearer ' + cbAuthToken }
      })
        // $http.post(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.GETADHOCSUPPLIERUSER + id)
        .then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    function applyPromo(promo, outlets, country, userId) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.get(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion +
        RESOURCE_URL_CONSTANTS.APPLY_PROMO + promo + '/outlets/' + outlets + '/country/' + country + '/userId/' + userId,
        {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        })
        .then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    function defaultCardUpdate(compId, tokenId) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.put(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion +
        RESOURCE_URL_CONSTANTS.DEFAULTCARDS + '?companyId=' + compId + '&tokenId=' + tokenId, {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        })
        .then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    function deleteCard(id) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.delete(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion +
        RESOURCE_URL_CONSTANTS.DELETECARDS + '?tokenId=' + id, {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        })
        .then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }



    function selectPlan(plans) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.post(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.SELECT_PLANS, plans, {
        headers: { Authorization: 'Bearer ' + cbAuthToken }
      })
        .then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    function getMerchantId(country) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.get(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.GET_MERCHANTID + country,
      {
        headers: { Authorization: 'Bearer ' + cbAuthToken }
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function postCardDetails(requestBody, userId) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.post(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.POST_CARD_DETAILS + userId,
      requestBody, {
        headers: { Authorization: 'Bearer ' + cbAuthToken }
      })
        .then(function(data) {
          deferred.resolve(data.data);
        }).catch(function(message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    function addPlanAndPayment(requestBody) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.post(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + 'user-plans/multiple/payment', requestBody,
      {
        headers: { Authorization: 'Bearer ' + cbAuthToken }
      })
        .then(function(data) {
          deferred.resolve(data.data);
        }).catch(function(message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    function getAmountByOutlets(params) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.get(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion +
      RESOURCE_URL_CONSTANTS.OUTLETS_AMOUNT, {
        params: params,
        headers: { Authorization: 'Bearer ' + cbAuthToken }
      })
        .then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    function updateOutletsAndPay(request) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.post(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion +
        RESOURCE_URL_CONSTANTS.UPDATE_OUTLETS, request,
        {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        })
        .then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    function fetchSubscriptionLicenceRenewalAlert(id, type) {
      var deferred = $q.defer();
      const urlParams={
        id:id,
        type:type
      }
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'subscription/details',
        method: "GET",
        params: urlParams,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function sendEmailToCS(emailDTO){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'subscription/email',
        data: emailDTO,
        method: "PUT",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
