/**
 * @Author: Ajay
 * @Date:   04-Dec-2017 10:19 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.item.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 06-Dec-2017 10:44 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerItemService', BuyerItemService);

  BuyerItemService.$inject = ['$http', '$q', 'API_CONSTANTS'];

  function BuyerItemService($http, $q, API_CONSTANTS) {

    var buyerItemService = {
      getAllBuyerItems: getAllBuyerItems,
      createBuyerItem: createBuyerItem,
      getBuyerItem : getBuyerItem,
      updateBuyerItem : updateBuyerItem,
      deleteBuyerItem: deleteBuyerItem,
      itemBulkUploadCSV: itemBulkUploadCSV,
      bulkDeleteBuyerItems: bulkDeleteBuyerItems,
      searchAllItems: searchAllItems,
      searchAdhocItems: searchAdhocItems,
      getBuyerAdhocItemsById:getBuyerAdhocItemsById
    };

    return buyerItemService;

    /* --  BuyerItem CRUID operations  -- */

    /**
     * [getAllBuyerItems Get All buyer items]
     */
    function getAllBuyerItems(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/items/',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [createBuyerItem create a new Buyer Item]
     * @param  {[type]} buyerItem [buyerItemDTO]
     */
    function createBuyerItem(buyerItem) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'adhoc-buyer/items/', buyerItem).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getBuyerItem Get buyer item details using buyerItemId]
     * @param  {[type]} buyerItemId [buyerItemId]
     */
    function getBuyerItem(buyerItemId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer/items/' + buyerItemId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [updateBuyerItem Update Buyer Item]
     * @param  {[type]} buyerItem [description]
     */
    function updateBuyerItem(buyerItem, itemBuyerDTOObj) {
      var deferred = $q.defer();
      var params = {};
      if (itemBuyerDTOObj.buyerItemName) {
        params['buyerItemName'] = itemBuyerDTOObj.buyerItemName;
      }
      if (itemBuyerDTOObj.buyerItemCode) {
        params['buyerItemCode'] = itemBuyerDTOObj.buyerItemCode;
      }
      if (itemBuyerDTOObj.buyerItemGroup) {
        params['buyerItemGroupName'] = itemBuyerDTOObj.buyerItemGroup;
      }
      params['itemApprovalNeeded'] = itemBuyerDTOObj.itemApprovalNeeded
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'adhoc-buyer/items/',
        method: "PUT",
        params: params,
        data: buyerItem
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


    /**
     * [deleteBuyerItem Soft Delete Buyer Item using buyerItemId]
     * @param  {[type]} buyerItemId [description]
     */
    function deleteBuyerItem(buyerItemId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer-adhoc/items/' + buyerItemId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Bulk Upload Buyer Item using CSV
    function itemBulkUploadCSV(uploadData) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'adhoc/items/upload', uploadData).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [bulkDeleteBuyerItems Bulk Delete Buyer Items using itemIdList]
     * @param  {[type]} itemIdList [itemIdList]
     */
    function bulkDeleteBuyerItems(itemIdList) {
      var bulkDeleteItemsDTO = {
        "itemIdList": itemIdList
      };
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'adhoc/items/bulk/',
        method: "PUT",
        data: bulkDeleteItemsDTO,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get all supplier items which are mapped to a buyer business outlets. Search query as parameter.
    function searchAllItems (searchFilter){
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'items/buyer-search', searchFilter)
      .then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get all adhoc items which are mapped to a buyer business outlets. Search query as parameter.
    function searchAdhocItems (searchFilter){
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer/adhoc/items/search', searchFilter)
      .then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getBuyerAdhocItem Get buyer item details using buyerItemId]
     * @param  {[type]} buyerItemId [buyerItemId]
     */
     function getBuyerAdhocItemsById(buyerItemId) {
        var deferred = $q.defer();
        $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'adhoc-items/items/' + buyerItemId).then(function(data) {
          deferred.resolve(data.data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

  }
})();
