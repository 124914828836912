/**
* @Author: Mufsir
* @Date:   03-Jan-2017 11:40 +05:30
* @Project: Ezyprocure
* @Filename: admin.buyer-supplier-mapping.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 09-Nov-2017 11:11 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('admin.manage-bnpl', {
      parent: 'admin',
      url: '/manage-bnpl',
      data: {
        authorities: ['ROLE_ADMIN'],
        pageTitle: 'ezyprocureApp.manage-bnpl.home.title'
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/bnpl/manage-bnpl/manage-bnpl.html',
          controller: 'ManageBnplController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('manage-bnpl');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          return $translate.refresh();
        }]
      }
    })
    .state('admin.manage-bnpl.edit', {
      parent: 'admin.manage-bnpl',
      url: '/edit/:id',
      data: {
        authorities: ['ROLE_ADMIN'],
        pageTitle: 'ezyprocureApp.manage-bnpl.home.title'
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/bnpl/manage-bnpl/edit/manage-bnpl-edit.html',
          controller: 'ManageBnplEditController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('manage-bnpl');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          return $translate.refresh();
        }]
      }
    })
    
  }

})();
