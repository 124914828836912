/**
 * @Author: Ajay
 * @Date:   27-Dec-2017 16:11 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.items-mapped-from-supplier.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 22-May-2018 09:51 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('buyer.tools.items-mapped-from-supplier', {
      parent: 'buyer.tools',
      url: '/items-mapped-from-supplier',
      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/tools/items-mapped-from-supplier/buyer.items-mapped-from-supplier.html',
          controller: 'BuyerItemsMappedFromSupplierController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,desc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
        return true;
      }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          $translatePartialLoader.addPart('itemsForApproval');
          $translatePartialLoader.addPart('items');

          return $translate.refresh();
        }]
      }
    });
  }

})();
