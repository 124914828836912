/*
 * @Author: Lovin
 * @Date: 2019-06-21 11:50:55
 * @Project: Ezyprocure
 * @Filename: admin.uen-report.controller.js
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2020-05-14 12:16:58
 */


(function() {
    'use strict';

    angular
    .module('ezyprocureApp')
    .controller('UENReportController', UENReportController);

    UENReportController.$inject = ['$scope', '$rootScope', '$state', '$mdDialog', 'pagingParams', '$q', 'paginationConstants', 'GLOBAL_CONSTANTS', 'EXPORT_CONSTANTS', 'toastr', 'CSVExportService', 'NotificationService','$filter','UENReportService','UTCDateService', 'AuditLogReportService', 'UTCDateService'];

    function UENReportController($scope, $rootScope, $state, $mdDialog, pagingParams, $q, paginationConstants, GLOBAL_CONSTANTS, EXPORT_CONSTANTS, toastr, CSVExportService, NotificationService, $filter, UENReportService, UTCDateService) {
      var vm = this;
      vm.todaysDate = new Date();
      vm.tomorrow = new Date();
      vm.tomorrow.setDate(vm.tomorrow.getDate() + 1);
      vm.tomorrow = UTCDateService.utcToDate(vm.tomorrow);
      vm.predicate = pagingParams.predicate;
      vm.reverse = pagingParams.ascending;
      vm.loadAll = loadAll;
      vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
      vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
      vm.filter = {};

      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
      vm.limitOptions = paginationConstants.limitOptions;
      vm.getUENReport = getUENReport;
      vm.getUENReportConsolidated = getUENReportConsolidated;
      vm.getUenConsolidatedCSV = getUenConsolidatedCSV;
      vm.UenConsolidatedCSVHeader = EXPORT_CONSTANTS.CSV.UenConsolidatedCSVHeader;
      vm.showStatisticReportPopup = showStatisticReportPopup;
      vm.submit = submit;
      vm.openPeppolConfirmationPopup = openPeppolConfirmationPopup;
      vm.sendToOpenPeppol = sendToOpenPeppol;
      vm.statisticsfilter = {};
      vm.cancelDialog = cancelDialog;
      vm.openStatisticsReportPopup = openStatisticsReportPopup;
      function sort() {
        var result = ['name' + ',' + (vm.reverse ? 'asc' : 'desc')];
        return result;
      }

    // Get all logs for UEN 
      function loadAll(view) {
        view == 'Individual' ? getUENReport() : getUENReportConsolidated();
      }
        
    // get uen reeport consloidated
    function getUENReportConsolidated() {
      UENReportService.getUENReportConsolidated({
        fromDate: vm.filter.fromDate? UTCDateService.utcFromDate(vm.filter.fromDate):'',
        toDate: vm.filter.toDate? UTCDateService.utcFromDate(vm.filter.toDate):'',
        identifier: vm.filter.uenNumber ? vm.filter.uenNumber : '',
        page: vm.query.page - 1,
        size: vm.query.limit,
        reportType: 'Consolidated'
      }).then(function(response) {
        vm.uenConsolidate = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      }, function(error) {
        NotificationService.error({
          title: 'Audit Log',
          error: error
        });
      });
    }

    // get UEN report for listView
    function getUENReport() {
      UENReportService.getUENReport({
        fromDate: vm.filter.fromDate? UTCDateService.utcFromDate(vm.filter.fromDate):'',
        toDate: vm.filter.toDate? UTCDateService.utcFromDate(vm.filter.toDate):'',
        role :vm.filter.accountType,
        uen: vm.filter.uenNumber,
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort()
      }).then(function(response) {
        vm.uenLogs = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      }, function(error) {
        NotificationService.error({
          title: 'Audit Log',
          error: error
        });
      });
    }

    //download uen consolidataed csv
    function getUenConsolidatedCSV(){
      var deferred = $q.defer();
      if(vm.filter.listView != 'Consolidated'){
        toastr.error('CSV Export Only For Consolidated!', 'CSV Export');
      
          return deferred.promise;
      } 
      if(vm.uenConsolidate)
      var csvData =  CSVExportService.getUenConsolidatedCSV(vm.uenConsolidate);

      return csvData;
    }
      // Create links and trigger click for each URL
      function downloadFile(url, fileName) {
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.href = url;
        link.download = fileName;        
        window.open(link,'_blank');
      }
      function showStatisticReportPopup(reportType, action) {
        vm.reportType = reportType;
        vm.statisticsfilter.reportType = reportType;
        vm.submit(action);
      }

      function submit(action) {
        var title = vm.statisticsfilter.reportType === 'enduser' ? 'End User Statistics Report' : 'Transaction Statistics Report';
        var deferred = $q.defer();
        if (vm.statisticsfilter.fromDate === null && vm.statisticsfilter.toDate === null) {
          return toastr.error('Please choose from date and to date', title);
        }
        if (vm.statisticsfilter.fromDate.getMonth() !== vm.statisticsfilter.toDate.getMonth() ||
          vm.statisticsfilter.fromDate.getFullYear() !== vm.statisticsfilter.toDate.getFullYear()) {
          return toastr.error('From date and to date should be within the same month', title);
        }
        if(action === 'download'){
          UENReportService.getUenStatisticsReport({
            fromDate: vm.statisticsfilter.fromDate ? UTCDateService.utcFromDate(vm.statisticsfilter.fromDate) : '',
            toDate: vm.statisticsfilter.toDate ? UTCDateService.utcToDate(vm.statisticsfilter.toDate) : '',
            reportType: vm.statisticsfilter.reportType.toUpperCase(),
            identifier: '',
            receiver: '',
            sendTo: 'OPENPEPPOL'
          })
            .then(function (response) {
              var dt = response.data;
            
              if (vm.statisticsfilter.reportType === 'enduser') {
                if(dt.endUserStatisticsReportURL)
                {
                  downloadFile(dt.endUserStatisticsReportURL, 'EndUserStatisticsReport.xml');
                }
                else{
                  toastr.error('Download failed', title);
                }             
              }
             else if (vm.statisticsfilter.reportType === 'transaction') {
                if(dt.transactionStatisticsReportURL)
                {
                  downloadFile(dt.transactionStatisticsReportURL, 'TransactionStatisticsReport.xml');
                }
                else{
                  toastr.error('Download failed', title);
                }               
              }
              vm.cancelDialog();
              vm.statisticsfilter = {};
            }, function (error) {
              NotificationService.error({
                title: title,
                error: 'Download failed to OpenPeppol'
              });
              deferred.reject(error);
            });
          return deferred.promise;
        }else if(action === 'sendTo'){
          UENReportService.sendUenStatisticsReportToOpenPeppol({
            fromDate: vm.statisticsfilter.fromDate ? UTCDateService.utcFromDate(vm.statisticsfilter.fromDate) : '',
            toDate: vm.statisticsfilter.toDate ? UTCDateService.utcToDate(vm.statisticsfilter.toDate) : '',
            reportType: vm.statisticsfilter.reportType.toUpperCase(),
            identifier: '',
            receiver: '',
            sendTo: 'OPENPEPPOL'
          })
            .then(function (response) {
              var dt = response.data;
              if(vm.statisticsfilter.reportType.toUpperCase()==='ENDUSER'){
                if (dt.endUserReportStatus) {
                  toastr.success('Transmission successful to OpenPeppol!', title);
                }else{
                  toastr.error('Transmission failed to OpenPeppol', title);
                }
              }
              if(vm.statisticsfilter.reportType.toUpperCase()==='TRANSACTION'){
                if(dt.transactionReportStatus) {
                  toastr.success('Transmission successful to OpenPeppol!', title);
                }else{
                  toastr.error('Transmission failed to OpenPeppol', title);
                }
              }
              vm.cancelDialog();
              vm.statisticsfilter = {};
            }, function (error) {
              NotificationService.error({
                title: title,
                error: 'Transmission failed to OpenPeppol'
              });
              deferred.reject(error);
            });
          return deferred.promise;
        }
      }

      function openPeppolConfirmationPopup(message, ev) {
        var confirm = $mdDialog.confirm()
          .title(message)
          .textContent('Do you want to send the report to OpenPeppol ?')
          .ariaLabel('Confirm action')
          .targetEvent(ev)
          .ok('Yes')
          .cancel('No');

        $mdDialog.show(confirm).then(function () {
          sendToOpenPeppol();
        }, function () {
          vm.statisticsfilter = {};
        });
      }

      function sendToOpenPeppol() {
        var title = vm.statisticsfilter.reportType === 'enduser' ? 'End User Statistics Report' : 'Transaction Statistics Report';
        UENReportService.getUenStatisticsReport({
          fromDate: vm.statisticsfilter.fromDate ? UTCDateService.utcFromDate(vm.statisticsfilter.fromDate) : '',
          toDate: vm.statisticsfilter.toDate ? UTCDateService.utcToDate(vm.statisticsfilter.toDate) : '',
          sendTo: 'OPENPEPPOL',
          reportType: vm.reportType.toUpperCase()
        }).then(function (response) {
          vm.statisticsfilter = {};
          var dt = response.data;
          if (dt.endUserReportStatus || dt.transactionReportStatus) {
            NotificationService.success({
              title: title,
              message: 'Transfer successful to OpenPeppol !'
            });
          }
          if (!dt.endUserReportStatus || !dt.transactionReportStatus) {
            toastr.error('Transfer failed to OpenPeppol ', title);
            vm.statisticsfilter = {};
          }
        }, function (error) {
          NotificationService.error({
            title: title,
            error: 'Transfer failed to OpenPeppol'
          });
          vm.statisticsfilter = {};
          deferred.reject(error);
        });
      }

      function cancelDialog() {
        $mdDialog.cancel();
      }

      function openStatisticsReportPopup(action) {
        vm.action = action;
         $mdDialog
          .show({
            templateUrl: 'app/ezyap-modules/admin-modules/reports/uen-report/admin.reports.statisctics-report.html',
            controller: function () {
              return vm;
            },
            controllerAs: 'vm',
            targetEvent: event,
            clickOutsideToClose: true
          });
      }
}
    

  })();
