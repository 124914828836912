/**
* @Author: JayalekshmiR
* @Date:   2018-09-17T15:37:01+05:30
* @Filename: ezypayment.buyer.payee.state.js
 * @Last modified by:   JayalekshmiR
 * @Last modified time: 2018-09-17T15:37:59+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('ezypayment.buyer.payee', {
      parent: 'ezypayment.buyer',
      url: '/payee',
      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/buyer-modules/manage-payee/ezypayment.buyer.payee-list.html',
          controller: 'PayeeListController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,desc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('supplier');
          return $translate.refresh();
        }]
      }
    })
    .state('ezypayment.buyer.payee-custom', {
        parent: 'ezypayment.buyer',
        url: '/payee/:isCustom',
        data: {
          authorities: ['ROLE_BUYER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/ezypayment-modules/buyer-modules/manage-payee/ezypayment.buyer.payee-list.html',
            controller: 'PayeeListController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,desc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('supplier');
            return $translate.refresh();
          }]
        }
    })
    .state('ezypayment.buyer.payee.add', {
      parent: 'ezypayment.buyer.payee',
      url: '/add',

      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/buyer-modules/manage-payee/add/ezypayment.buyer.payee.add.html',
          controller: 'AddPayeeController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('paymentTerms');
          $translatePartialLoader.addPart('paymentModel');
          $translatePartialLoader.addPart('epdApprovalRequest');
          $translatePartialLoader.addPart('bank');
          $translatePartialLoader.addPart('buyerBusiness');
          $translatePartialLoader.addPart('brandSupplierPaymentModelMapping');
          $translatePartialLoader.addPart('countryCurrency');
          return $translate.refresh();
        }]
      }
    })
    .state('ezypayment.buyer.payee.edit', {
      parent: 'ezypayment.buyer.payee',
      url: '/edit/:supplierId',

      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/buyer-modules/manage-payee/edit/ezypayment.buyer.payee.edit.html',
          controller: 'EditPayeeController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('paymentTerms');
          $translatePartialLoader.addPart('paymentTypes');
          $translatePartialLoader.addPart('bank');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
    .state('ezypayment.buyer.payee.bulk-upload', {
      parent: 'ezypayment.buyer.payee',
      url: '/upload',

      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/buyer-modules/manage-payee/bulk-upload/ezypayment.buyer.payee.bulk-upload.html',
          controller: 'PayeeBulkUploadController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('paymentTerms');
          $translatePartialLoader.addPart('bank');
          return $translate.refresh();
        }]
      }
    });
  }

})();
