/*
 * @Author: Neethu 
 * @Date: 2023-09-11 10:00:30 
 * @Project: Ezyprocure
 * @Filename: ezyap.upload-po.service.js
 * @Last Modified by: Neethu S
 * @Last Modified time: 2023-09-13 13:03:53
 */

(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('UploadPoService', UploadPoService);

  UploadPoService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function UploadPoService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var UploadPoService = {
      poUploadCSV: poUploadCSV
    };
    return UploadPoService;

    /**
     * [Upload the po csv file]
     * @param {Object} file         [CSV file to be uploaded]
     * @param {Integer} supplierInfoId [Supplier Information Id]
     */
    function poUploadCSV(file, supplierInfoId) {
      var deferred = $q.defer();
      var fd = new FormData();
      fd.append('file', file);
      $http.post(API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/purchase-orders/upload/', fd, {
        transformRequest: angular.identity,
        headers: { 'Content-Type': undefined },
        params: supplierInfoId
      })
        .then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }
  }
})();