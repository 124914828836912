/**
* @Author: Ajay
* @Date:   28-Mar-2017 12:35 +05:30
* @Project: Ezyprocure
* @Filename: buyer.email.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 10-Nov-2017 10:46 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerEmailController', BuyerEmailController);

  BuyerEmailController.$inject = ['$scope', '$state', '$stateParams', 'NotificationService', 'BuyerDataService', 'BuyerEmailService', 'BuyerSupplierMappingService', 'GLOBAL_CONSTANTS','toastr'];

  function BuyerEmailController($scope, $state, $stateParams, NotificationService, BuyerDataService, BuyerEmailService, BuyerSupplierMappingService, GLOBAL_CONSTANTS,toastr) {

    var vm = this;
    vm.init = init;
    vm.getAllBuyerMappedSuppliers = getAllBuyerMappedSuppliers;
    vm.populateSelectedEmails = populateSelectedEmails;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.sendEmail = sendEmail;

    vm.subjectLimit = GLOBAL_CONSTANTS.emailConfiguration.SUBJECT_SIZE_LIMIT;
    vm.bodyLimit = GLOBAL_CONSTANTS.emailConfiguration.BODY_SIZE_LIMIT;
    vm.ezyapFlag = false;
    vm.isGreetingDisabled = true;
    vm.getAllEzyapBuyerSuppliersMapped = getAllEzyapBuyerSuppliersMapped;
    vm.locationEzyap = GLOBAL_CONSTANTS.emailOrigin.LOCATION_EZYAP;
    vm.locationEzyproc = GLOBAL_CONSTANTS.emailOrigin.LOCATION_EZYPROCURE;
    vm.validateEmail=validateEmail;
    /**
     * [getAllBuyerMappedSuppliers Get all supplier mapped buyers]
     * @return {[list]} [List of buyers]
     */
    function getAllBuyerMappedSuppliers() {
      if (vm.suppliers) return false;
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      BuyerSupplierMappingService.getAllBuyerMappedSupplierList(buyerId).then(function(response) {
        vm.suppliers = response.data;
      }, function(error) {
      });
    }


    /**
     * [init Load initial data]
     */
    function init() {
      vm.buyerName  = BuyerDataService.getBuyerProfile().buyerUser.buyer.companyName;
      vm.emailDTO = {
        recipients: [],
        subject: '',
        body: ''
      };
      // Get the current URL
      const currentURL = window.location.href;

      // Check if the URL contains the substring "ezyap"
      if (currentURL.includes("/ezyap/")) {
        vm.ezyapFlag = true;
        vm.emailDTO.greeting = 'Hi There,\n';
        vm.emailDTO.subject = 'e-Doc Enablement';
        // Your email content
        const emailContent = '1.We are now able to receive the ePurchase Order and the eInvoice Response over the Peppol Network.\n2.Also, able to send and receive Invoice Response via InvoiceNow with the response statuses like Message Acknowledgment, In Progress, Under Query, Accepted, Rejected, Partially Paid, Paid.\n So, Please send these e-Docs to us via InvoiceNow. \n';
        vm.emailDTO.body = emailContent;        
        vm.emailDTO.source = vm.locationEzyap;
      }else{
        vm.emailDTO.source = vm.locationEzyproc;
      }

    }

    /**
     * [selectAll Select all buyers from the list and create separate string array of emails]
     */
    function selectAll() {
      vm.supplierEmailList = _.map(vm.suppliers, _.property('supplierId'));
    }

    /**
     * [deselectAll Deselect all suppliers from the list]
     */
    function deselectAll() {
      vm.supplierEmailList = [];
    }

    /**
     * [populateSelectedEmails Populate selected emails and union it to md-chips]
     */
    function populateSelectedEmails() {
      if(vm.ezyapFlag){
       vm.emailDTO.recipients = [];
       _.filter(vm.suppliers, function(supplier) {
        if(supplier.id == vm.supplierEmailList){
          vm.emailDTO.recipients.push(supplier.email);
          vm.greetingCompanyName = supplier.companyName;
          vm.emailDTO.greeting = 'Hi'+' '+vm.greetingCompanyName+',\n';
        }
      });
      } else {        
        var filteredSuppliers = _.filter(vm.suppliers, function (supplier) {
          return vm.supplierEmailList.includes(supplier.supplierId);
        });
        const uniqueRecipients = new Set();
        filteredSuppliers.forEach(function (supplier) {
          uniqueRecipients.add(supplier.email);
        });
        vm.emailDTO.recipients = Array.from(uniqueRecipients);
      }
    }

    /**
     * [sendEmail Send email to recipients]
     */
   
    function sendEmail() {
     if( vm.emailDTO.recipients.length<1)
     {
      toastr.error('Add recipients!', 'Please Add any recipients');
      return ;
     }
   
      BuyerEmailService.sendEmailToSupplier(vm.emailDTO).then(function(response) {
        NotificationService.success({
          title: 'Buyer - Email to Supplier',
          message: 'Email sent successfully'
        });
      }, function(error) {
        NotificationService.error({
          title: 'Buyer - Email to Supplier',
          error: error
        });
      });
    }

    /**
     * [getAllMappedBuyersOrSuppliers Get all buyer/suppliers mapped]
     * @return {[list]} [List of buyers/suppliers]
     */
    function getAllEzyapBuyerSuppliersMapped() {
      if (vm.suppliers) return false;
      BuyerSupplierMappingService.getAllEzyapBuyerSuppliersMappedList().then(function(response) {
        vm.suppliers = response.data;
      }, function(error) {
      });
    }
    function validateEmail(x) {
      if (event.which === 13) {
          var emailValue = x;
          var reg = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
          if (!reg.test(emailValue)) {                                        
            var index =  vm.emailDTO.recipients.indexOf(emailValue);
            if (index !== -1) {
              toastr.error('Invalid Email!', 'Removed '+emailValue+' from recipients');
              vm.emailDTO.recipients.splice(index, 1);
            }    
        
          }
      };

};

  }
})();
