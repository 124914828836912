/**
* @Author: Ajay
* @Date:   17-Jan-2017 17:22 +05:30
* @Project: Ezyprocure
* @Filename: admin.dashboard.service.js
 * @Last modified by:   sneha
 * @Last modified time: 10-Jan-2018 17:23 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('AdminDashboardService', AdminDashboardService);

  AdminDashboardService.$inject = ['$http', '$q', 'API_CONSTANTS'];

  function AdminDashboardService($http, $q, API_CONSTANTS) {

    var adminDashboardService = {
      getAdminDashboardCounter: getAdminDashboardCounter,
      getTotalTransactionVolume: getTotalTransactionVolume,
      getPaymentTransactionVolume: getPaymentTransactionVolume,
      getAdminDashboardCounterBasedOnFilter: getAdminDashboardCounterBasedOnFilter
    };

    return adminDashboardService;

    /* --  Admin Dashboard APIs  -- */

    //Get Dashboard Counter values
    function getAdminDashboardCounter() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin/dashboard',
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getTotalTransactionVolume(filter){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin/dashboard/transaction-volume?filter='+filter,
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getPaymentTransactionVolume(filter){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin/dashboard/payment-transaction?filter='+filter,
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAdminDashboardCounterBasedOnFilter(filter) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin/dashboard/new?filter='+filter,
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
