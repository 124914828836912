/*
 * @Author: Ajay 
 * @Date: 2019-02-11 11:36:38 
 * @Project: Ezyprocure
 * @Filename: ezyap.peppol-invoices-summary.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-10-24 10:40:39
 */
(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapPeppolInvoicesSummaryController', EzyapPeppolInvoicesSummaryController);

  EzyapPeppolInvoicesSummaryController.$inject = ['$q', 'NotificationService', 'UTCDateService', 'GLOBAL_CONSTANTS', 'EzyapGetInvoicesService', 'paginationConstants', 'pagingParams', 'EXPORT_CONSTANTS', 'CSVExportService', '$state', 'ExtenalPeppolInvoicesService', 'Auth'];

  function EzyapPeppolInvoicesSummaryController($q, NotificationService, UTCDateService, GLOBAL_CONSTANTS, EzyapGetInvoicesService, paginationConstants, pagingParams, EXPORT_CONSTANTS, CSVExportService, $state, ExtenalPeppolInvoicesService, Auth) {

    var vm = this;
    vm.getAllInvoices = getAllInvoices;
    vm.init = init;
    vm.todaysDate = new Date();
    vm.type = $state.params.type;
    vm.peppolTransmissionStatus = !vm.type ? GLOBAL_CONSTANTS.PEPPOL_TRANSMISSION_STATUSES: ['RECEIVED', 'SENT', 'FAILED'];
    vm.filter={};
    vm.filter.peppolTransmissionStatus= vm.peppolTransmissionStatus[0];
    vm.filter.fromDate = vm.todaysDate;
    vm.filter.toDate = vm.todaysDate;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.sort = sort;
    vm.selectAllInvoices = selectAllInvoices;
    vm.sendAllInvoices = sendAllInvoices;
    vm.invoiceCSVHeader = EXPORT_CONSTANTS.CSV.peppolInvoiceCSVHeader;
    vm.getInvoicesCSVData = getInvoicesCSVData;
    vm.getAllSelectedInvoices=getAllSelectedInvoices; 
    vm.downloadAllInvoices=downloadAllInvoices;
    vm.userRole = Auth.getUserRole();
    vm.updateExportFlag = updateExportFlag;
    vm.getInvoiceIdList = getInvoiceIdList;
    // vm.peppolInvoiceStatus = [{ data: 'Message acknowledgement', value: 'AB' }, { data: 'Accepted', value: 'AP' }, { data: 'Rejected', value: 'RE' }, { data: 'In process', value: 'IP' }, { data: 'Under query', value: 'UQ' }, { data: 'Conditionally accepted', value: 'CA' }, { data: 'Paid', value: 'PD' }];
    
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function init() {
      vm.getAllInvoices();
    }

    /**
    * [selectAllInvoices select all Pending Process Orders]
    */
    function selectAllInvoices() {
      vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
      _.map(vm.invoiceDetails, function(invoice) {
        invoice.selected = vm.selectAll;
      });
    }

    /**
     * [getAllSelectedInvoices Get all selected invoices]
     */
    function getAllSelectedInvoices(){
      var selectedInvoices = _.filter(vm.invoiceDetails, function(invoice) {
        return invoice.selected === true;
      });
     return selectedInvoices;
    }
    /**
     * [getSelectedJobs Returns selected Jobs Ids]
     */
    function getSelectedInvoices() {
     var selectedInvoices = vm.getAllSelectedInvoices();
     var selectedInvoiceIds =_.map(selectedInvoices, _.property('id'));
      return selectedInvoiceIds;
    }

    /**
     * [getInvoicesCSVData Downloads CSV]
     */
    function getInvoicesCSVData() {
      var deferred = $q.defer();
      vm.selectedInvoices = vm.getAllSelectedInvoices();
      if (vm.invoiceDetails.length == 0) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      else if (vm.selectedInvoices.length > 0) {
        return CSVExportService.getPeppolInvoiceCSV(vm.selectedInvoices);
      } else {
        return CSVExportService.getPeppolInvoiceCSV(vm.invoiceDetails);
      }
    }

    /**
     * [send invoices which are selected]
     */
    function sendAllInvoices() {
      var selectedInvoiceIds = getSelectedInvoices();
      if (selectedInvoiceIds.length < 1) {
        NotificationService.simpleErrorToast({
          title: 'global.menu.entities.invoice',
          message: 'global.pleaseSelectedOneFromTheList'
        });
      } else {
        ExtenalPeppolInvoicesService.sendAllInvoice(selectedInvoiceIds)
        .then(function(response){
          vm.getAllInvoices();
          NotificationService.success({
            title: 'global.sendInvoice',
            message: 'alertMessage.INVOICE_SEND_SUCCESSFULLY'
          });
        },function(error){
          NotificationService.error({
            title: 'global.sendInvoice',
            error: error
          });
        });
      }
    }

    /**
     * [get all invoices]
     */
    function getAllInvoices() {
      vm.selectAll=false;
      EzyapGetInvoicesService.getAllInvoices({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: vm.sort(),
        startDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        endDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        transmissionStatus: !vm.filter.peppolTransmissionStatus ? '' : vm.filter.peppolTransmissionStatus
      }).
        then(function (response) {
          vm.invoiceDetails = response.data;
          vm.totalItems = response.headers('X-Total-Count');
        }, function (error) {
          NotificationService.error({
            title: 'global.menu.entities.invoice',
            error: error
          });
        });
    } 

    /**
     * [updateExportFlag Update the export flag]
     * @param {Idlist} invoiceIdList 
     */
    function updateExportFlag(invoiceIdList){
      ExtenalPeppolInvoicesService.updateExportFlag(invoiceIdList)
        .then(function(response){
        },function(error){
      });
    }

    function getInvoiceIdList(selectedInvoices){
      var invoiceIdList = _.map(selectedInvoices, _.property('id'));
     return invoiceIdList;
    }

    /**
     * 
     * @param {String} uri [uploaded url]
     * @param {String} fileName [the name of the file downloaded file]
     */
    function downloadAllInvoices(fileName) {
      vm.selectedInvoices=vm.getAllSelectedInvoices();
      if(vm.selectedInvoices.length>0){
        vm.invoices=vm.selectedInvoices;
        var selectedInvoiceIds = getSelectedInvoices();
        vm.updateExportFlag(selectedInvoiceIds);
        vm.getAllInvoices();
      }
      else if(vm.invoiceDetails.length>0){
        vm.invoices=vm.invoiceDetails;
        var selectedInvoiceIds = getSelectedInvoices();
        vm.updateExportFlag(selectedInvoiceIds);
        vm.getAllInvoices();
      }
      else{
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });   
      }
      _.forEach(vm.invoices,function(invoices){
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.download = 'invoice details_'+invoices.id;
        window.open(invoices.uploadedUrl,'_blank');
      })
    }
  }
})();
