/*
 * @Author: Neethu S
 * @Date: 2023-08-17
 * @Project: Ezyprocure
 * @Filename: ezyap.create-po.serice.js
 */

(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('CreateAdhocPoService', CreateAdhocPoService);

  CreateAdhocPoService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function CreateAdhocPoService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var CreateAdhocPoService = {
      createAdhocPo: createAdhocPo,
      getAllUOMs: getAllUOMs,
      confirmAndSendAdhocPo: confirmAndSendAdhocPo,
      getBusinessUnit: getBusinessUnit,
      getPaymentTerms: getPaymentTerms,
      getSupplierInformation: getSupplierInformation,
      getSupplierDeliveryDetails: getSupplierDeliveryDetails,
      getSupplierDeliveryDetailsById: getSupplierDeliveryDetailsById
    };
    return CreateAdhocPoService;

    function getPaymentTerms() {
      var deferred = $q.defer();
      $http.get(RESOURCE_URL_CONSTANTS.JSON_DATA + 'paymentTerms.json').
        then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    function getBusinessUnit() {
      var deferred = $q.defer();
      $http.get(RESOURCE_URL_CONSTANTS.JSON_DATA + 'businessUnit.json').
        then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    //create adhoc PO manually
    function createAdhocPo(adhocPoDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/purchase-orders/adhoc/',
        method: "PUT",
        data: adhocPoDTO
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //get all peppol uoms
    function getAllUOMs(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + '/api/unit-codes',
        method: "GET",
        params: params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    // create and send invoice
    function confirmAndSendAdhocPo(adhocInvoiceDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/purchase-orders/adhoc/upload',
        method: "PUT",
        data: adhocInvoiceDTO
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //get Supplier Details 
  
   function getSupplierInformation() {
    var deferred = $q.defer();
    $http({
      url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/supplier-information',
      method: "GET",
    }).then(function (data) {
      deferred.resolve(data);
    }).catch(function (message) {
      deferred.reject(message);
    });
    return deferred.promise;
  }

   /**
       * [get supplier information]
       * @param {String} params [search text] 
       */
   function getSupplierDeliveryDetails(params) {
    var deferred = $q.defer();
    $http({
      url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/supplier-delivery-details' ,
      method: "GET",
      params: params
    }).then(function (data) {
      deferred.resolve(data);
    }).catch(function (message) {
      deferred.reject(message);
    });
    return deferred.promise;
  }

  /**
       * [get individual delivery information by id]
       * @param {Integer} id [delivery information id]
       */
  function getSupplierDeliveryDetailsById(id) {
    var deferred = $q.defer();
    $http({
      url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/supplier-delivery-details/' + id,
      method: "GET"
    }).then(function (data) {
      deferred.resolve(data);
    }).catch(function (message) {
      deferred.reject(message);
    });
    return deferred.promise;
  }

  }
})();
