/*
 * @Author: Jayalekshmi
 * @Date: 2018-07-23 11:47:49
 * @Filename: ezypayment.admin.statement-of-account-report.contoller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-09-19 11:33:03
 */


(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentAdminStatementOfAccountReport', EzypaymentAdminStatementOfAccountReport);

  EzypaymentAdminStatementOfAccountReport.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', 'toastr','pagingParams', 'UTCDateService', 'NotificationService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'BuyerBusinessService', 'ManageBuyerService', 'EzypaymentAdminStatementOfAccountService', 'BrandManagementService', 'ManageSupplierService', '$window', 'EXPORT_CONSTANTS', 'CSVExportService', 'EzypaymentAdminManageMerchantDetailsService', '$stateParams', '$mdDialog', 'PurchaseOrderService', 'Auth', 'UtilService'];

  function EzypaymentAdminStatementOfAccountReport($scope, $rootScope, $q, $filter, $state, toastr, pagingParams, UTCDateService, NotificationService, GLOBAL_CONSTANTS, paginationConstants, BuyerBusinessService, ManageBuyerService, EzypaymentAdminStatementOfAccountService, BrandManagementService, ManageSupplierService, $window, EXPORT_CONSTANTS, CSVExportService, EzypaymentAdminManageMerchantDetailsService, $stateParams, $mdDialog, PurchaseOrderService, Auth, UtilService) {

    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.loadAll = loadAll;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.selectedRow = {};
    vm.cancelPopup = cancelPopup;
    vm.splitWord = splitWord;
    vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.limitOptions = paginationConstants.limitOptionsWithAll;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
    vm.getAllBuyersBusinessOutlets = getAllBuyersBusinessOutlets;
    vm.loadDropdowns = loadDropdowns;
    vm.getAllBuyersMappedToPayment = getAllBuyersMappedToPayment;
    vm.showOtpPrompt = showOtpPrompt;
    vm.getAllSuppliersMappedToPayment = getAllSuppliersMappedToPayment;
    vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
    vm.filter = {};
    vm.selectAllInvoices = selectAllInvoices;
    vm.generateGiro = generateGiro;
    vm.getStatementOfAccountReportCSV = getStatementOfAccountReportCSV;
    vm.statementOfAccountReportCSVHeader = EXPORT_CONSTANTS.CSV.statementOfAccountReportCSVHeader;
    vm.statementOfAccountReportCSVHeaderTt = EXPORT_CONSTANTS.CSV.statementOfAccountReportCSVHeaderTt;
    vm.readGiroResponseFiles = readGiroResponseFiles;
    vm.getAllCountryCurrencies = getAllCountryCurrencies;
    vm.chooseCurrency = $stateParams.currency;
    vm.calculateSum=calculateSum;
    vm.getAllMerchantList = getAllMerchantList;
    vm.userRole = Auth.getUserRole().ugly;
    vm.validateDateRange = validateDateRange;
    vm.loadAllIfSpecificFiltersFilled = loadAllIfSpecificFiltersFilled;
    vm.checkInternalExternalInvoices = checkInternalExternalInvoices;
    function splitWord(value){
      return value.split('_')[0];
    }

    function getStatementOfAccountReportCSV(){
      var deferred = $q.defer();
      vm.selectedStatementOfAccountReports = _.filter(vm.statementOfAccountReports, _.matches({ 'selected': true }));
      if (!vm.statementOfAccountReports) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.reject;
      }else {
        var getStatementOfAccountReportCSVData = (vm.selectedStatementOfAccountReports.length <1)? vm.statementOfAccountReports: vm.selectedStatementOfAccountReports;
        return CSVExportService.getStatementOfAccountReportCSV(getStatementOfAccountReportCSVData, vm.filter.listView, vm.filter.currency);
      }
    }

    function cancelPopup() {
        $mdDialog.cancel();
    }

    function showOtpPrompt(index) {
        PurchaseOrderService.getPurchaseOrderByPaymentId(index.paymentIds, {
            deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
          }).then(function(response){
            vm.selectedRow = response.data;
            // show prompt
            $mdDialog.show({
              templateUrl: 'app/core/common-templates/statement-view-popup.tmpl.html',
              controller: function () {
                return vm;
              },
              controllerAs: 'vm',
              targetEvent: event,
              clickOutsideToClose: true
            });
          },function(error){
            NotificationService.error({
              title: 'Credit Note',
              error: error
            });
          });
      }

    /**
     * [calculateSum shows the sum of key in List]
     */
     function calculateSum(statementOfAccountReportsList, key){
        statementOfAccountReportsList =  _.map(statementOfAccountReportsList, function(elements){
            elements[key] = Number($filter('decimalNumber')(elements[key], 2));
              return elements;
          });
       var sum=_.sumBy(statementOfAccountReportsList, key)
         return sum;
     }

   /**
    * [generateGiro creates GIRO files]
    * @param {Array} supplierIdList
    */
   function generateGiro(uploadType){
    vm.supplierIdList = _.map(_.filter(vm.statementOfAccountReports, _.matches({ 'selected': true })), _.property('supplierId'));
    if(!vm.supplierIdList || vm.supplierIdList.length<1){
      toastr.error('Please select atleast one statement', 'No Statement Selected');
      return false;
    }
    var checkSelectedInvoices = vm.checkInternalExternalInvoices();
    if(!checkSelectedInvoices){
      return;
    }

    vm.brandIdList = _.map(_.filter(vm.statementOfAccountReports, _.matches({ 'selected': true })), _.property('brandId'));

    vm.referenceIdList = _.map(_.filter(vm.statementOfAccountReports, _.matches({ 'selected': true })), _.property('transactionId'));
    var supplierIdList = {
      brandIdList: !vm.brandIdList ? []: String(vm.brandIdList),
      currency: !vm.filter.currency ? '' : vm.filter.currency,
      supplierId: !vm.supplierIdList? [] : String(vm.supplierIdList),
      fromDate: !vm.filter.fromDate ? UTCDateService.utcFromDate(vm.todaysDate) : UTCDateService.utcFromDate(vm.filter.fromDate),
      toDate: !vm.filter.toDate ? UTCDateService.utcToDate(vm.todaysDate) : UTCDateService.utcToDate(vm.filter.toDate),
      uploadHostToHost:uploadType,
      fast:vm.fast
    }
    if (vm.defaultBrandId) {
    supplierIdList.defaultBrandId = vm.defaultBrandId;
    EzypaymentAdminStatementOfAccountService.extCreateGiroFile(supplierIdList)
    .then(function(response){
      if(response){
        _.each(response, function(url){
            $window.open(url);
        });
      }
      NotificationService.success({
        title: 'Generate GIRO file',
        message: 'GIRO file is successfully generated'
      });
      vm.loadAll();
    },function(error){
      if(error.status == 503) {
        NotificationService.error({
          title: 'File upload failed into UOB server',
          error: error
        });
      }
      else {
        NotificationService.error({
          title: 'Could not generate GIRO',
          error: error
        });
      }
    });    
    vm.defaultBrandId = '';
    }else{
      EzypaymentAdminStatementOfAccountService.createGiroFile(supplierIdList)
      .then(function(response){
        if(response){
          _.each(response, function(url){
              $window.open(url);
          });
        }
        NotificationService.success({
          title: 'Generate GIRO file',
          message: 'GIRO file is successfully generated'
        });
        vm.loadAll();
      },function(error){
        if(error.status == 503) {
          NotificationService.error({
            title: 'File upload failed into UOB server',
            error: error
          });
        }
        else {
          NotificationService.error({
            title: 'Could not generate GIRO',
            error: error
          });
        }
      });
    }    
   }

    /**
    * [selectAllInvoices select all Pending Process Orders]
    */
    function selectAllInvoices() {
      vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
      _.map(vm.statementOfAccountReports, function(statementOfAccountReport) {
        statementOfAccountReport.selected = vm.selectAll;
      });
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
     * [getAllBuyers Gets all buyers mapped with the payment model]
     */
    function getAllBuyersMappedToPayment(){
      if(vm.buyers){ return false}
      ManageBuyerService.getAllAdminBuyersWithUserDetails({
        page: 0,
        size: paginationConstants.maxLimit,
        search: '',
        sort: sort(),
        isAdminDashboard:vm.userRole==='ROLE_ADMIN' && $rootScope.redirectionApiFromDashboard==true?true:false
      }).then(function(response) {
        vm.buyers = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Buyer Listing',
          error: error
        });
      });
    }

    function getAllMerchantList() {
      if(!vm.filter.gatewayType) {
        return false;
      };
      EzypaymentAdminStatementOfAccountService.getAllMerchantList({
        gateWayType: vm.filter.gatewayType
      }).then(function(response) {
        vm.merchantIdList = [];
        if(Array.isArray(response.data)) {
          response.data.forEach(function(merchantId) {
            vm.merchantIdList.push({"merchantId" : merchantId});
          });
        }
      }, function(error) {
        NotificationService.error({
          title: 'MID Listing',
          error: error
        });
      });
    }

    function getAllSuppliersMappedToPayment(){
      if(vm.suppliers){return false}
      ManageSupplierService.getAllAdminSuppliersWithUserDetails({
        page: 0,
        size: paginationConstants.maxLimit,
        search: '',
        visibilityType: 'ALLOCATED',
        sort: sort(),
        isAdminDashboard:vm.userRole==='ROLE_ADMIN' && $rootScope.redirectionApiFromDashboard==true?true:false,
        filter:vm.userRole==='ROLE_ADMIN' && $rootScope.redirectionApiFromDashboard==true?$rootScope.dashboardFilter:"All"
      }).then(function(response){
        vm.suppliers = response.data;
      },function(error){
        NotificationService.error({
          title: 'Supplier Listing',
          error: error
        });
      });
    }

    /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
      case 'BUYERS':
        vm.filter.buyerIdList = _.map(vm.buyers, _.property('id'));
        break;
      case 'BRANDS':
        vm.filter.brandIdList = _.map(vm.brands, _.property('id'));
        break;
      case 'SUPPLIERS':
        vm.filter.supplierIdList = _.map(vm.suppliers, _.property('id'));
        break;
      }
    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
      case 'BUYERS':
        vm.filter.buyerIdList = [];
        break;
      case 'BRANDS':
        vm.filter.brandIdList = [];
        break;
      case 'SUPPLIERS':
        vm.filter.supplierIdList = [];
        break;
      }
    }

    /**
    * [getAllBuyersBusinessOutlets gets all businessoutlets for selected buyers]
    * @param {List} buyerIdList
    */
    function getAllBuyersBusinessOutlets(buyerIdList) {
      if (!buyerIdList || buyerIdList.length === 0) return false;
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        groupBy: 'buyer'
      };
      BuyerBusinessService.getAllBuyersBusinessOutlets(buyerIdList, params).then(function(response) {
        vm.buyerBusinessOutlets = response.data;
        vm.filter.businessOutletIdList = [];
      }, function(error) {
        NotificationService.error({
          title: 'Business Outlet Listing',
          error: error
        });
      });
    }
    /**
     * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
     * @param {Array} buyerIdList
     */
    function getAllBrandsOfBuyerList(buyerIdList){
      if (!buyerIdList || buyerIdList.length === 0) return false;
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        query:'',
        buyerId: buyerIdList,
        sort: sort()
      };

      BrandManagementService.getAllBrandsOfBuyerList(params)
      .then(function(response){
        vm.brands = response;
      },function(error){
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

    /**
     * [Read Giro Response Files]
     */
    function readGiroResponseFiles(){
      var defaultBrandId = {};
      if (vm.defaultBrandId) {
        defaultBrandId = {
          defaultBrandId :vm.defaultBrandId
        }
      EzypaymentAdminStatementOfAccountService.extReadGiroResponseFiles(defaultBrandId)
      .then(function(response){
        NotificationService.success({
          title: 'global.buttons.readGiroResponseFiles',
          message: 'alertMessage.COMPLETED_SUCCESSFULLY'
        });
      },function(error){
        NotificationService.error({
          title: 'global.buttons.readGiroResponseFiles',
          error: error
        });
      });
      vm.defaultBrandId = '';
      }else{
        EzypaymentAdminStatementOfAccountService.readGiroResponseFiles()
        .then(function(response){
          NotificationService.success({
            title: 'global.buttons.readGiroResponseFiles',
            message: 'alertMessage.COMPLETED_SUCCESSFULLY'
          });
        },function(error){
          NotificationService.error({
            title: 'global.buttons.readGiroResponseFiles',
            error: error
          });
        });
      }      
    }

    /**
    * [loadDropdowns The function executes when the dropdown closes]
    */
    function loadDropdowns(){
      vm.getAllBuyersBusinessOutlets(vm.filter.buyerIdList);
      vm.getAllBrandsOfBuyerList(vm.filter.buyerIdList)
    }

    /**
    * [loadAll Load All statement of account report]
    * @param  {[string]} search [search text]
    */
    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      var loadAllIfSpecificFiltersFilled = vm.loadAllIfSpecificFiltersFilled();
      if (loadAllIfSpecificFiltersFilled){        
      EzypaymentAdminStatementOfAccountService.getAllStatementOfAccountReports({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText,
        currency: vm.filter.currency,
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        invoiceNumber: !vm.filter.invoiceNumber ? '': vm.filter.invoiceNumber,
        buyerIdList: !vm.filter.buyerIdList ? []: vm.filter.buyerIdList,
        supplierIdList: !vm.filter.supplierIdList ? []: vm.filter.supplierIdList,
        brandIdList: !vm.filter.brandIdList ? []: vm.filter.brandIdList,
        businessOutletIdList: !vm.filter.businessOutletIdList ? []: vm.filter.businessOutletIdList,
        //If dates are selected, they are converted to UTC format
        reportName: !vm.filter.listView ? '': vm.filter.listView,
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        paymentGiroStatus: (vm.filter.giroStatus === 'ALL') ? '' : vm.filter.giroStatus,
        accountType:(vm.accountType==='ALL')?'':vm.accountType,
       issuer:(vm.issuer==='ALL')?'':vm.issuer,
       merchantId: vm.filter.listView === 'Individual' ? vm.filter.merchantId : "",
       gateWay: vm.filter.listView === 'Individual' ? vm.filter.gatewayType : ""
       }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.statementOfAccountReports = response.data;
         for (var i = 0; i < vm.statementOfAccountReports.length; i++) {
           vm.statementOfAccountReports[i].reallyPresent = i % 2 === 0;
         }
      },function(error){
        NotificationService.error({
          title: 'Statement of Account Report',
          error: error
        });
      });
      }
    }

    /**
     * [getAllCountryCurrencies gets all country currencies]
     */
    function getAllCountryCurrencies(){
      EzypaymentAdminManageMerchantDetailsService.getAllCountryCurrencies().then(function(response){
        vm.currencies = response.data;
        if(vm.chooseCurrency == "USD"){
          vm.filter.currency = "USD";
        }
        else{
        vm.filter.currency=vm.currencies[0].currency;
      }
      vm.loadAll();
      },function(error){
      });
    }

    vm.getAllCountryCurrencies();

    function validateDateRange(numberOfDays, title, filterAvailable) {
    var dateSelected = UtilService.checkDateRangeExists(vm.filter.fromDate, vm.filter.toDate, filterAvailable);
    if (!dateSelected) {
      toastr.error('Please select valid date ranges!', title);
      return false;
    }
    var dateValid = UtilService.checkDateRangeValidity(vm.filter.fromDate, vm.filter.toDate, numberOfDays, title, dateSelected);
    if (dateSelected && !dateValid) {
      return false;
    }     
    return true;
  }

  function loadAllIfSpecificFiltersFilled() {
    var validationResult = false;
    var filtersToCheck = ['poNumber','invoiceNumber','buyerIdList','supplierIdList','brandIdList','giroStatus','merchantId'];
    var filterAvailable = UtilService.areSpecificFiltersFilled(filtersToCheck,vm.filter);
    var numberOfDays = GLOBAL_CONSTANTS.REPORTS_DATE_VALIDATION;
    var title = "Statement of Account Report";      
    validationResult = validateDateRange(numberOfDays, title, filterAvailable);
    return validationResult;
  }

  function checkInternalExternalInvoices(){
    var trueCount = 0, falseCount = 0, externalInvoice = false, internalInvoice = false;    
    vm.supplierList = _.filter(vm.statementOfAccountReports, _.matches({ 'selected': true }));
    
    for(var i=0;i<vm.supplierList.length;i++){
      if (vm.supplierList[i].externalPayment === true) {
        trueCount++;
        externalInvoice = true;
      } else {
        falseCount++;
        internalInvoice = true;
      }
    }

    if(!vm.defaultBrandId){
      if(externalInvoice){
        toastr.error('Please select internal invoices only!', 'Statement of Account Report');
        return false;
      }     
    }else{
      if(internalInvoice){
        toastr.error('Please select external invoices only!', 'Statement of Account Report');
        return false;
      }
    }
    return true;
  }
  }
})();
