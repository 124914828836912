/**
 * @Author: Ajay
 * @Date:   15-May-2018 13:04 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.purchase-approval-request.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 23-May-2018 16:10 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerPurchaseApprovalRequestService', BuyerPurchaseApprovalRequestService);

  BuyerPurchaseApprovalRequestService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function BuyerPurchaseApprovalRequestService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var buyerPurchaseApprovalRequestService = {
      getAllPurchaseApprovalRequest: getAllPurchaseApprovalRequest,
      getPurchaseApprovalRequest: getPurchaseApprovalRequest,
      approvePurchaseApprovalRequest: approvePurchaseApprovalRequest,
      rejectPurchaseApprovalRequest: rejectPurchaseApprovalRequest,
      deletePurchaseApprovalRequest: deletePurchaseApprovalRequest,
      deletePurchaseApprovalRequestbyId: deletePurchaseApprovalRequestbyId,
      updatePurchaseApprovalRequestDetails: updatePurchaseApprovalRequestDetails,
      addToPurchaseApprovalRequest: addToPurchaseApprovalRequest,
      mergePurchaseApprovalRequest: mergePurchaseApprovalRequest,
      getAllApprovalListById : getAllApprovalListById,
      approveOrRejectAllPrRequest: approveOrRejectAllPrRequest,
      getAllBuyerBusinessOutlets: getAllBuyerBusinessOutlets,
      getAlternateSupplierView: getAlternateSupplierView,
      chooseAlternateSupplierView: chooseAlternateSupplierView,
      changeAlternateSupplier: changeAlternateSupplier,
      getDeliveryConditionsForPRApproval: getDeliveryConditionsForPRApproval,
      validateApprovalRequest: validateApprovalRequest,
      getMyPrPurchaseApprovalRequest: getMyPrPurchaseApprovalRequest
    };

    return buyerPurchaseApprovalRequestService;


    function getAllBuyerBusinessOutlets(buyerId,params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer/'+buyerId+'/businessOutlets',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //approve or reject all purchase approval requests
    function approveOrRejectAllPrRequest(purchaseApprovalDTO, ids){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_APPROVAL_REQUESTS_RESOURCE + 'bulk',
        method: "POST",
        params:purchaseApprovalDTO,
        data:ids
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get All Purchase under the selected buyer or item
    function getAllPurchaseApprovalRequest(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_APPROVAL_REQUESTS_RESOURCE,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get Purchase Approval Request details by approval Request Number
    function getPurchaseApprovalRequest(approvalRequestNumber, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_APPROVAL_REQUESTS_RESOURCE + approvalRequestNumber,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Approve Purchase Approval Request to Accepted/Rejected by approval Request Number
    function approvePurchaseApprovalRequest(approvalRequestNumber, ids) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_APPROVAL_REQUESTS_RESOURCE + approvalRequestNumber + '/approve',
        method: "PUT",
        data: ids
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Reject Purchase Approval Request to Accepted/Rejected by approval Request Number
    function rejectPurchaseApprovalRequest(approvalRequestNumber,data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_APPROVAL_REQUESTS_RESOURCE + approvalRequestNumber +'/reject',
        method: "PUT",
        data:data
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Delete Purchase Approval Request details by approval Request Number
    function deletePurchaseApprovalRequest(approvalRequestNumber) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_APPROVAL_REQUESTS_RESOURCE + approvalRequestNumber,
        method: "DELETE",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function deletePurchaseApprovalRequestbyId(approvalId,data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_APPROVAL_REQUESTS_RESOURCE + 'deletion/' + approvalId,
        method:'PUT',
        data:data
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [setUpdateWeight_grn Save Good receiving Orders]
    * @return {[string]}                [Success and error messages]
    */
    function updatePurchaseApprovalRequestDetails(purchaseApprovalRequest) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_APPROVAL_REQUESTS_RESOURCE + 'updation', purchaseApprovalRequest).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function addToPurchaseApprovalRequest(cartItem, approvalRequestNumber) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_APPROVAL_REQUESTS_RESOURCE + 'buy-now/' + approvalRequestNumber,
        method: "POST",
        data: cartItem,
      }).then(function(response) {
        deferred.resolve(response);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * passes requestNumber for which the Purchase Requests have to be merged
     * @param {string} approvalNumberList
     */
    function mergePurchaseApprovalRequest(approvalNumberList) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_APPROVAL_REQUESTS_RESOURCE + 'merge',
        method: "POST",
        params: approvalNumberList
      }).then(function(response) {
        deferred.resolve(response);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

     //Get all approval list under the selected buyer or item
     function getAllApprovalListById(approvalRequestNumber) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_APPROVAL_REQUESTS_RESOURCE + approvalRequestNumber+'/approver-list/v2',
        method: "GET"
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAlternateSupplierView(params){
        var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_APPROVAL_REQUESTS_RESOURCE+'alternate-supplier-view',
        method: "GET",
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function chooseAlternateSupplierView(params){
        var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_APPROVAL_REQUESTS_RESOURCE+'alternate-supplier-choose',
        method: "POST",
        data: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function changeAlternateSupplier(params){
        var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/change-supplier',
        method: "PUT",
        data: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    // get specific delivery condition mapped to buyer the mapped suppliers
    function getDeliveryConditionsForPRApproval(idlist) {
        var deferred = $q.defer();
        $http({
            url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'delivery-conditions/buyer-and-supplier-delivery-conditions?approvalRequestNumber=' + idlist,
            method: "GET"
        }).then(function(data) {
            deferred.resolve(data);
        }).catch(function(message) {
            deferred.reject(message);
        });
        return deferred.promise;
    }

    function validateApprovalRequest(params){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_APPROVAL_REQUESTS_RESOURCE + 'validate-pr',
        method: "POST",
        params:params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get My Pr Purchase under the selected buyer or item
    function getMyPrPurchaseApprovalRequest(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MYPR_PURCHASE_APPROVAL_REQUESTS_RESOURCE,
        method: "GET",
        params: params,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
