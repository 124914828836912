/**
* @Author: Ajay
* @Date:   27-Mar-2017 13:16 +05:30
* @Project: Ezyprocure
* @Filename: manage-buyers.service.js
* @Last modified by:   Mufsir
* @Last modified time: 07-Apr-2017 05:31 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('ManageBuyerService', ManageBuyerService);

  ManageBuyerService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS', 'AuthServerProvider'];

  function ManageBuyerService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS, AuthServerProvider) {

    var manageBuyerService = {
      getAllAdminBuyers: getAllAdminBuyers,
      getAllAdminBuyersWithUserDetails: getAllAdminBuyersWithUserDetails,
      getAllAdminBNPLBuyersWithUserDetails:getAllAdminBNPLBuyersWithUserDetails,
      getAdminBuyer: getAdminBuyer,
      createAdminBuyer: createAdminBuyer,
      updateAdminBuyer: updateAdminBuyer,
      updateBuyerActivatedKey: updateBuyerActivatedKey,
      deleteAdminBuyer: deleteAdminBuyer,
      buyerBulkUploadCSV: buyerBulkUploadCSV,
      getBuyerDetails:getBuyerDetails,
      getAllocatedBuyerDetails:getAllocatedBuyerDetails
    };

    return manageBuyerService;

    /* --  AdminBuyer CRUID operations  -- */

    //Get All Admin buyers
    function getAllAdminBuyers() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/all',
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get All Admin buyers with User Details
    function getAllAdminBuyersWithUserDetails(urlParams) {
      if (!urlParams) {
        urlParams = {};
        urlParams.search = '';
      }
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers',
        method: "GET",
        params: urlParams,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
//BNPL Buyer
function getAllAdminBNPLBuyersWithUserDetails(urlParams) {
  if (!urlParams) {
    urlParams = {};
    urlParams.search = '';
  }
  var deferred = $q.defer();
  $http({
    url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'bnpl/buyers',
    method: "GET",
    params: urlParams,
  }).then(function(data) {
    deferred.resolve(data);
  }).catch(function(message) {
    deferred.reject(message);
  });
  return deferred.promise;
}



    //Get buyer details using buyer id
    function getAdminBuyer(buyerId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/' + buyerId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create a new Admin buyer
    function createAdminBuyer(buyer) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers', buyer).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update Admin buyer
    function updateAdminBuyer(buyer) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/', buyer).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //update Admin Buyer Activated Key
    function updateBuyerActivatedKey(buyerId, isActivate) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/'+buyerId+'/'+isActivate).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Soft Delete Admin buyer using buyerId
    function deleteAdminBuyer(buyerId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/' + buyerId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function buyerBulkUploadCSV(uploadData) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/upload', uploadData).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getBuyerDetails(buyerId) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.get(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_GETBUYER + buyerId,
      {
        headers: { Authorization: 'Bearer ' + cbAuthToken }
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    } 
    function getAllocatedBuyerDetails(buyerId) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.get(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_GETBUYERDETAILS + buyerId,
      {
        headers: { Authorization: 'Bearer ' + cbAuthToken }
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
