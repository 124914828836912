/*
 * @Author:Neethu
 * @Date: 2323-08-23 14:24:00
 * @Project: Ezyprocure
 * @Filename: ezyap.supplier-information.service.js
 */


(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('ManageSupplierInfoService', ManageSupplierInfoService);

  ManageSupplierInfoService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function ManageSupplierInfoService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var ManageSupplierInfoService = {
      searchAllPeppolUsers: searchAllPeppolUsers,
      addSupplierInformation: addSupplierInformation,
      getSupplierInformation: getSupplierInformation,
      getSupplierInformationById: getSupplierInformationById,
      deleteSupplierInformationById: deleteSupplierInformationById,
      editSupplierInformation: editSupplierInformation
    };
    return ManageSupplierInfoService;

    /**
     * [searchAllPeppolUsers Searchs all peppol users]
     */
    function searchAllPeppolUsers(searchQuery){
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/supplier-information/all',
          method: 'GET',
          params: searchQuery
        }).then(function(data){
          deferred.resolve(data);
        }).catch(function(message){
          deferred.reject(message)
        });
        return deferred.promise;
    }

     /**
     * [add supplier information]
     * @param {Object} data [supplier information data]
     */
     function addSupplierInformation(data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/supplier-information',
        method: "POST",
        data: data
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [edit supplier information]
     * @param {Object} data [supplier information data]
     */
    function editSupplierInformation(data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/supplier-information',
        method: "PUT",
        data: data
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [get supplier information]
     * @param {String} params [search text]
     */
    function getSupplierInformation(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/supplier-information',
        method: "GET",
        params: params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [get individual supplier information by id]
     * @param {Integer} id [supplier information id]
     */
    function getSupplierInformationById(id) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/supplier-information/' + id,
        method: "GET"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [delete supplier information by id ]
     * @param {Integer} id [supplier information id]
     */
    function deleteSupplierInformationById(id) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/supplier-information/' + id,
        method: "DELETE",
        params: id
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
