/**
 * @Author: Ajay
 * @Date:   20-Dec-2016 19:05 +05:30
 * @Project: Ezyprocure
 * @Filename: admin.dashboard.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 16-Jan-2018 10:25 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('AdminDashboardController', AdminDashboardController);

  AdminDashboardController.$inject = ['$state', '$stateParams', 'Auth', 'AdminDashboardService', 'GLOBAL_CONSTANTS', 'NotificationService', '$rootScope'];

  function AdminDashboardController($state, $stateParams, Auth, AdminDashboardService, GLOBAL_CONSTANTS, NotificationService, $rootScope) {
    var vm = this;
    vm.getAdminDashboardCounter = getAdminDashboardCounter;
    vm.refreshInterval = GLOBAL_CONSTANTS.DASHBOARD_REFRESH_INTERVAL;
    vm.getTotalTransactionVolume = getTotalTransactionVolume;
    vm.getPaymentTransactionVolume = getPaymentTransactionVolume;
    //Dashboard counter refresh timeout
    vm.incrementInterval = incrementInterval;
    vm.initializeIntervalCount = initializeIntervalCount;
    vm.refreshDashboardCounts = refreshDashboardCounts;
    vm.refreshTimeout = GLOBAL_CONSTANTS.DASHBOARD_REFRESH_TIMEOUT;
    vm.initialRefreshInterval = GLOBAL_CONSTANTS.DASHBOARD_REFRESH_INTERVAL;
    vm.refreshIntervalIncrement = GLOBAL_CONSTANTS.DASHBOARD_INTERVAL_INCREMENT;
    vm.pauseRefreshFlag = false;
    vm.clickCounter = clickCounter;
    vm.filterYear = sessionStorage.getItem('filterYear')!==null?sessionStorage.getItem('filterYear'):'2024';
    sessionStorage.setItem('filterYear',vm.filterYear); 
    vm.getAdminDashboardCounterBasedOnFilter = getAdminDashboardCounterBasedOnFilter;    
    vm.filterYears = ['2020','2021','2022','2023','2024','All'];
    vm.goToPurchaseOrders = goToPurchaseOrders;

    function getAdminDashboardCounter() {
      AdminDashboardService.getAdminDashboardCounter().then(function(response) {
        vm.adminDashboard = response.data;
        vm.adminDashboard.totalBuyers = 1780;
        vm.adminDashboard.totalSuppliers = 4294;
      }, function(error) {
        NotificationService.error({
          title: 'Admin Dashboard',
          error: error
        });
      });
    }

    /** force refresh counts */
    function refreshDashboardCounts() {
      getAdminDashboardCounter();
      initializeIntervalCount();
    }

    /** increment refresh interval on successfull refresh */
    function incrementInterval() {
      if(vm.refreshInterval == vm.initialRefreshInterval) {
        vm.refreshInterval += vm.refreshIntervalIncrement;
      }
      else if(vm.refreshInterval < vm.refreshTimeout) {
        vm.refreshInterval += vm.refreshIntervalIncrement;
      }
      else if (vm.refreshInterval >= vm.refreshTimeout) {
        vm.pauseRefreshFlag = true;
      }
    }

    function initializeIntervalCount() {
      vm.refreshInterval = vm.initialRefreshInterval;
      vm.pauseRefreshFlag = false;
    }

    function getTotalTransactionVolume() {
      AdminDashboardService.getTotalTransactionVolume(vm.filterYear).then(function(response) {
        vm.transactionVolume = response.data;
        sessionStorage.setItem('transactionVolume',JSON.stringify(vm.transactionVolume));
      })
      .catch(function(error) {
        NotificationService.error({
          title: 'Total Transaction Volume',
          error: error
        });
      })
      .finally(function(){
       vm.transactionLoadingFlag = false;
       });
    }

    function getPaymentTransactionVolume(){
      AdminDashboardService.getPaymentTransactionVolume(vm.filterYear).then(function(response){
        vm.paymentTransactionVolume = response.data;
        sessionStorage.setItem('paymentTransactionVolume',JSON.stringify(vm.paymentTransactionVolume));
      })
     .catch(function(error) {
       NotificationService.error({
         title: 'Payment Transaction Volume',
         error: error
       });
      })
     .finally(function(){
      vm.paymentLoadingFlag = false;
      });
    }

    /**
    * [To set in api whether call is coming from navbar or dashboard]
    */
    function clickCounter(data){
        if (vm.filterYear === '2024' ||vm.filterYear === '2023' || vm.filterYear === '2022' || vm.filterYear === 'All') {
          $rootScope.redirectionApiFromDashboard = true;
          $rootScope.dashboardFilter = vm.filterYear;
          if (data === 'Total Suppliers') {
            $state.go('admin.suppliers', { visibilityType: 'ALLOCATED' });
          }
          if (data === 'Total Invoices') {
            $state.go('admin.invoices');
          }
         }
     }

    function getAdminDashboardCounterBasedOnFilter(){
      var storedFilterYear = sessionStorage.getItem('filterYear');
      var storedAdminDashboard = JSON.parse(sessionStorage.getItem('adminDashboard'));
      var storedPaymentTransactionVolume = JSON.parse(sessionStorage.getItem('paymentTransactionVolume'));
      var storedTransactionVolume = JSON.parse(sessionStorage.getItem('transactionVolume'));
      
      if((storedFilterYear === vm.filterYear) && storedAdminDashboard && storedPaymentTransactionVolume && storedTransactionVolume){
        initializeDashboardCounters();
        vm.adminDashboard = storedAdminDashboard;
        vm.paymentTransactionVolume = storedPaymentTransactionVolume;
        vm.transactionVolume = storedTransactionVolume;
      } else {
        resetDashboardCounters();
        sessionStorage.clear();
        AdminDashboardService.getAdminDashboardCounterBasedOnFilter(vm.filterYear).then(function (response) {
          vm.adminDashboard = response.data;          
          sessionStorage.setItem('filterYear',vm.filterYear);
          sessionStorage.setItem('adminDashboard',JSON.stringify(vm.adminDashboard));
        })
        .catch(function (error) {
          NotificationService.error({
            title: 'Dashboard filter is having some issue',
            error: error
          });
        })
        .finally(function(){
         vm.loadingFlag = false;
         });
        vm.getPaymentTransactionVolume();
        vm.getTotalTransactionVolume();
      }
    }
    
    function goToPurchaseOrders(){
    if (vm.filterYear === '2024' || vm.filterYear === '2023' || vm.filterYear === '2022' || vm.filterYear === 'All') {
      $rootScope.redirectionApiFromDashboard = true;
      $rootScope.dashboardFilter = vm.filterYear;
      $state.go('admin.purchase-orders'); // Perform the navigation to "admin.purchase-orders" state
     }
    };
    
    function resetDashboardCounters(){
        vm.adminDashboard = {};
        vm.paymentTransactionVolume = {};
        vm.transactionVolume = {};
        vm.loadingFlag = true;
        vm.paymentLoadingFlag = true;
        vm.transactionLoadingFlag = true;
    }
    function initializeDashboardCounters(){
      vm.loadingFlag = false;
      vm.paymentLoadingFlag = false;
      vm.transactionLoadingFlag = false;
    }    

    initializeIntervalCount();

    getAdminDashboardCounterBasedOnFilter();
  }
})();
