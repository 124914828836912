/**
* @Author: Atul
* @Date:   25-July-2023 16:15 +05:30
* @Project: Ezyprocure
* @Filename: bnpl.buyer.bnpl.due-payment-summary.controller.js
* @Last modified by:   Atul
* @Last modified time:  
*/
(function() {'use strict';
angular.module('ezyprocureApp').controller('BNPLPayPandingController', BNPLPayPandingController);
BNPLPayPandingController.$inject = 
['$mdDialog', '$state','BrandManagementService','BuyerBuyNowService','BuyerDataService','BnplBuyerDashboardService','BuyerOCRService','paginationConstants','$stateParams','BnplDuePaymentInvoicesService','Auth','NotificationService','toastr','UTCDateService'];

  function BNPLPayPandingController($mdDialog, $state,BrandManagementService,BuyerBuyNowService,BuyerDataService,BnplBuyerDashboardService,BuyerOCRService,paginationConstants,$stateParams,BnplDuePaymentInvoicesService,Auth,NotificationService,toastr,UTCDateService) {
    var vm = this;
    var popup=this;
    vm.filter = {invoiceType:'INTERNAL',paymentMethod:'BNPL',brandIds:0};
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.buyerDashboardCount=
    {
      "creditAllocated":0,
      "creditUtilized":0,
      "creditBalance":0,
      "bsfAmount":0,
      "selectedAmount":0,
      "totalBsfAmount":0
    };
    vm.isCustom = $stateParams.isCustom == 'custom';
    vm.accessForOcr = Auth.hasPermission("accessForOcr");
    vm.payDisable=true;
    vm.getBnplBuyer=getBnplBuyer;
    vm.getBnplCountByBrandId=getBnplCountByBrandId;
    vm.getAllAllocatedSuppliersForBusiness=getAllAllocatedSuppliersForBusiness;
    vm.selectAll=selectAll;
    vm.deselectAll=deselectAll;
    vm.getInvoices=getInvoices;
    vm.getSelectedPOs=getSelectedPOs;
    vm.getInternalInvoices = getInternalInvoices;
    vm.checkSelectedAmount=checkSelectedAmount;
    vm.showDuePopup=showDuePopup;
    vm.checkPaymentModel=checkPaymentModel;
    vm.getSelectedPOIds=getSelectedPOIds;
    vm.cancel=cancel;
    vm.checkAllRows=checkAllRows;
    vm.showNoteTicker = true;
    vm.hideNotes=hideNotes;
    vm.getBnplBuyer();

    function getBnplBuyer() 
    {
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      BnplBuyerDashboardService.getBnplStatus(buyerId).then(
          function (response) {
              var brandBnplDetailsDTOs = response.data.brandBnplDetailsDTOs;
              vm.brandsWithTokenMapped = brandBnplDetailsDTOs;
          },
          function (error) {
              NotificationService.error({
                  title: "BNPL Dashboard",
                  error: error,
              });
          }
      );
    }
    function getBnplCountByBrandId() 
    {
      var brandFlag=vm.filter.brandIds==0?false:true;
      var dd=brandFlag? _.find(vm.brandsWithTokenMapped, ['brandId',vm.filter.brandIds]):vm.buyerDashboardCount;
       vm.buyerDashboardCount.creditAllocated=dd.creditAllocated!=null?dd.creditAllocated:0;
      vm.buyerDashboardCount.creditUtilized=dd.creditUtilized!=null?dd.creditUtilized:0;
      vm.buyerDashboardCount.creditBalance=dd.creditBalance!=null?dd.creditBalance:0;
      vm.buyerDashboardCount.loanPaid=dd.loanPaid!=null?dd.loanPaid:0;
      vm.buyerDashboardCount.businessServiceRate=dd.businessServiceRate!=null?dd.businessServiceRate:0;
      checkSelectedAmount(null);
    }
    function getAllAllocatedSuppliersForBusiness() {
      var params = {
      businessId: !vm.filter.businessOutletIds?'':vm.filter.businessOutletIds,
      brandIds: !vm.filter.brandIds?'': vm.filter.brandIds,
      };
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function(response){
        vm.supplierList = response.data;
      },function(error){
        NotificationService.error({
          title: 'Fetching Allocated Suppliers',
          error: error
        });
      });
    }
    function selectAll(dropdown) {
      switch (dropdown) {
        case 'SUPPLIERS':
        vm.filter.supplierIds = _.map(vm.supplierList, _.property('id'));
        break;
        case 'OUTLETS':
        vm.filter.businessOutletIds = _.map(vm.businessOutlets, _.property('id'));
        break;
        case 'BRANDS':
        vm.filter.brandIds = _.map(vm.brandsWithTokenMapped, _.property('id'));
        break;
      }
    }
    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'SUPPLIERS':
        vm.filter.supplierIds = [];
        break;
        case 'OUTLETS':
        vm.filter.businessOutletIds = [];
        break;
        case 'BRANDS':
        vm.filter.brandIds = [];
        break;
      }
    }

    function getInvoices(){
      (vm.filter.invoiceType === 'INTERNAL') ? getInternalInvoices() : getExternalInvoices();
    }
    function getInternalInvoices() {
      if(   
        !vm.filter.brandIds>0){
          return
        }
      
      BuyerOCRService.getAllPurchaseOrders({
        page: vm.query.page - 1,
        size: vm.query.limit,
        query: !vm.filter.poNumber ? '': vm.filter.poNumber,
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        ocrInvoiceStatus: !vm.accessForOcr ? '' : 'ACCEPTED',
        ocrAcceptanceStatus: !vm.accessForOcr ? '' : 'ACCEPTED',
        invoiceStatus: !vm.accessForOcr ? 'PROCESSED' : '',
        geoExported: '',
        businessOutletIds: !vm.filter.businessOutletIds ? '' : vm.filter.businessOutletIds,
        brandIds: !vm.filter.brandIds ? '' : String(vm.filter.brandIds),
        supplierId: !vm.filter.supplierIds ? '' : String(vm.filter.supplierIds),
        paymentStatus: "'UNPAID','FAILED','VOID','CANCELLED'",
        kycStatus: 'SUCCESSFUL',
        module: 'payment',
        paymentMethod: "BNPL",
        sort: sort(),
      }).then(function(response){
        vm.invoices = response.data;
        vm.totalItems = response.headers('X-Total-Count');
        checkSelectedAmount(null);
      },function(error) {
        NotificationService.error({
          title: 'Fetching Invoices',
          error: error
        });
      });
    }
    function getExternalInvoices()
    {

    }
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }
    function getSelectedPOs(poItems) {
      var selectedPOs = _.filter(poItems, function(poItem) {
        return poItem.selected === true;
      });
      return selectedPOs;
    }
    function checkSelectedAmount(invoice)
    { 
     
      vm.buyerDashboardCount.selectedAmount=0;
      vm.buyerDashboardCount.bsfAmount =0;
      vm.buyerDashboardCount.totalBsfAmount=0;
      var selectedPOs =  vm.getSelectedPOs(vm.invoices);
       selectedPOs.forEach(function(invoice){
        var amount;
        var flag;
        var bsfAmount;
        var BsfValueAmount;
        try {
          amount=invoice.purchaseOrder.ocrInvoiceAmount?invoice.purchaseOrder.ocrInvoiceAmount:invoice.purchaseOrder.invoice.grandTotal;
          bsfAmount =(amount*vm.buyerDashboardCount.businessServiceRate)/100;
          BsfValueAmount=amount+bsfAmount;
          flag=vm.buyerDashboardCount.totalBsfAmount+BsfValueAmount>=vm.buyerDashboardCount.creditBalance?false:true;
         
        } catch (error) {
          flag=false;
          invoice.selected=false;
          vm.errorHeading='Amount Missing';
          vm.errorMsg='Invoice Not Selected';
          if(vm.selectAllRows){

          }
          else
          {
            vm.showDuePopup();
          }
          
        }

        if(invoice.selected){
          if(flag){
            vm.buyerDashboardCount.selectedAmount= flag?+vm.buyerDashboardCount.selectedAmount+amount:vm.buyerDashboardCount.selectedAmount;
            vm.buyerDashboardCount.bsfAmount= flag?vm.buyerDashboardCount.bsfAmount+bsfAmount:vm.buyerDashboardCount.bsfAmount;
            vm.buyerDashboardCount.totalBsfAmount= flag?vm.buyerDashboardCount.totalBsfAmount+BsfValueAmount:vm.buyerDashboardCount.totalBsfAmount;
            
          }
          else{
            invoice.selected=false;
            vm.errorHeading='Exceeds credit balance';
            vm.errorMsg='Invoice Not Selected!';
            if(!vm.selectAllRows){
              vm.showDuePopup();
            }

          }
        }



      });
      vm.buyerDashboardCount.selectedAmount=  vm.buyerDashboardCount.selectedAmount.toFixed(2);
      vm.buyerDashboardCount.bsfAmount =vm.buyerDashboardCount.bsfAmount.toFixed(2);
      vm.buyerDashboardCount.totalBsfAmount=  vm.buyerDashboardCount.totalBsfAmount.toFixed(2);
      selectedPOs =  vm.getSelectedPOs(vm.invoices);
      vm.payDisable=selectedPOs.length>0?false:true;
      if(vm.payDisable==false && vm.buyerDashboardCount.totalBsfAmount<=300)
      {
        vm.payDisable=true;
      }
      if(vm.selectAllRows){
        if(selectedPOs.length!=vm.invoices.length)
        {
          vm.selectAllRows=false;
          vm.errorHeading='Exceeds credit balance';
          vm.errorMsg='Some of Invoices Not Selected!';
          vm.showDuePopup();
        }
      }
    }
    vm.selectAllRows=false;
    function checkAllRows() {
     
      angular.forEach(vm.invoices, function (item) {
        item.selected = vm.selectAllRows;
      });
      checkSelectedAmount();
    }


    function showDuePopup()
    {
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/duepayment.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }
    function cancel() {
      $mdDialog.cancel();
    }
     /**
     * [checkPaymentModel Decides whether to goto details page or show popup]
     */
     function checkPaymentModel() {
      getDuePaymentSummary();
  }

  function getDuePaymentSummary(){
    var selectedPOs = vm.getSelectedPOs(vm.invoices);
    var brandIds = [];
    selectedPOs.forEach(function (elem) {
     
      brandIds.push(elem.purchaseOrder.processOrder.buyerBusiness.brandId)
  })
    var selectedPOIds = vm.getSelectedPOIds(selectedPOs);
    if(selectedPOIds.length > 0){
      var purposeObj = _.filter(vm.purposes, function(code) {
          return code.id === vm.purposeCode;
        })[0];
      BnplDuePaymentInvoicesService.setSelectedPOIds(selectedPOIds);
      BnplDuePaymentInvoicesService.setInvoiceType(vm.filter.invoiceType);
      BnplDuePaymentInvoicesService.setIsCustom(vm.isCustom);
      BnplDuePaymentInvoicesService.setPurpose(popup.purposeCode);
      BnplDuePaymentInvoicesService.setPurposeObj(purposeObj);
      BnplDuePaymentInvoicesService.setCard(popup.brandTokens);
      BnplDuePaymentInvoicesService.setBrandIds(brandIds);
      BnplDuePaymentInvoicesService.setSelectedPO(selectedPOs);
      $state.go('bnpl.buyer.due-payment-summary.details');
    } else {
      toastr.error('Please select invoices to pay.', 'Fetching Invoice Details');
    }
  }
  function getSelectedPOIds(selectedPOs) {
    var purchaseOrderIdList = (vm.filter.invoiceType === 'INTERNAL') ? _.map(selectedPOs, _.property('purchaseOrder.id')) : _.map(selectedPOs, _.property('id'));
    return purchaseOrderIdList;
  }
  function hideNotes() {
    vm.showNoteTicker = !vm.showNoteTicker;
  }
     
  }
})();
