/**
* @Author: Elsa
* @Date:   10-Mar-2017 12:51 +05:30
* @Project: Ezyprocure
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:39 +05:30
*/

(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierGoodReceiptNoteController', SupplierGoodReceiptNoteController);

  SupplierGoodReceiptNoteController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog', 'UTCDateService', 'NotificationService', 'pagingParams', 'PurchaseOrderService', 'ItemGroupService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'CSVExportService', 'EXPORT_CONSTANTS', 'SupplierDataService', 'BuyerSupplierMappingService', 'BuyerBusinessService', 'UtilService'];

  function SupplierGoodReceiptNoteController($scope, $rootScope, $q, $filter, $state, $stateParams, toastr, $mdDialog, UTCDateService, NotificationService, pagingParams, PurchaseOrderService, ItemGroupService, GLOBAL_CONSTANTS, paginationConstants, CSVExportService, EXPORT_CONSTANTS, SupplierDataService, BuyerSupplierMappingService, BuyerBusinessService, UtilService) {
    var vm = this;
    vm.todaysDate = new Date();
    vm.loadAll = loadAll;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.receivingStatuses = GLOBAL_CONSTANTS.RECEIVING_STATUSES;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.loadItemGroups=loadItemGroups;
    vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.limitOptions = paginationConstants.limitOptionsWithAll;
    //Header
    vm.goodsReceiptNoticeReport = EXPORT_CONSTANTS.CSV.SupplierGoodsReceiptNoticeReport;
    //Function Declaration
    vm.getGoodsReceiptNote = getGoodsReceiptNote;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.getAllBuyersBusinessOutlets = getAllBuyersBusinessOutlets;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.filter = {
      buyerIdList: [],
      businessIdList: []
    };
    vm.resetFilters = resetFilters;
    $scope.dateLocale = {
      formatDate: function(date) {
        var m = moment(date);
        return m.isValid() ? m.format('DD/MM/YYYY') : '';
      }
    };
    vm.filter.deliveryFromDate = null;
    vm.filter.deliveryToDate = null;
    vm.filter.poFromDate = null;
    vm.filter.poToDate = null;
    vm.validateDateRange = validateDateRange;
    vm.loadAllIfSpecificFiltersFilled = loadAllIfSpecificFiltersFilled;
      //Get all buyers mapped to the supplier
      function getAllSupplierMappedBuyers() {
        vm.filter.businessIdList = [];
        vm.bussinessOutlets = [];
        var params = {
          page: 0,
          size: paginationConstants.maxLimit,
        };
        if (vm.buyers)
          return false;
        var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
        BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function (response) {
          vm.buyers = response.data;
        }, function (error) {
        });
      }

      //get all business outlets of the selected buyer
      function getAllBuyersBusinessOutlets() {
        if (!vm.filter.buyerIdList) {
          return false;
        }
        if (vm.filter.businessIdList > 0) {
          return false;
        }
        var params = {
          page: 0,
          size: paginationConstants.maxLimit,
          groupBy: 'buyer'
        };
        BuyerBusinessService.getAllBuyersBusinessOutlets(vm.filter.buyerIdList, params).then(function (response) {
          vm.buyerBusinessOutlets = response.data;
          vm.bussinessOutlets = [];
          _.forEach(vm.buyerBusinessOutlets, function (buyer) {
            _.forEach(buyer.businessDTOList, function (outlets) {
              vm.bussinessOutlets.push(outlets);
            });
          });
        }, function (error) {
        });
      }

      function selectAll(dropdown) {
        switch (dropdown) {
          case 'BUYERS':
            vm.filter.buyerIdList = _.map(vm.buyers, _.property('id'));
            break;
          case 'BUSINESSOUTLETS':
            var filteredArray = [];
            if (vm.businessOutletSearchTerm) {
              // Only select values in business outlets id if they match vm.businessOutletSearchTerm
              filteredArray = _.filter(vm.bussinessOutlets, function (item) {
                return _.includes(item.businessOutletName.toLowerCase(), vm.businessOutletSearchTerm.businessOutletName.toLowerCase());
              });
            }
            vm.filter.businessIdList = _.map(filteredArray.length !== 0 ? filteredArray : vm.bussinessOutlets, _.property('businessId'));
            break;
        }
      }

      /**
       * [deselectAll Deselect all from the list]
       * @param  {[type]} dropdown [Active dropdown - outlets or brands]
       */
      function deselectAll(dropdown) {
        switch (dropdown) {
          case 'BUYERS':
            vm.filter.buyerIdList = [];
            break;
          case 'BUSINESSOUTLETS':
            vm.filter.businessIdList = [];
            break;
        }
      }


    //Getting CSV DATA
    function getGoodsReceiptNote(){
      var deferred = $q.defer();
      if (!vm.goodReceivingNotes) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getSupplierGoodsReceiptNoteReportCSV(vm.goodReceivingNotes);
    }
    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadItemGroups() {
      if(vm.itemGroups)
        return false;
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }

    /**
    * [loadAll Get all GRN]
    */
    function loadAll() {
      var loadAllIfSpecificFiltersFilled = vm.loadAllIfSpecificFiltersFilled();
      if (loadAllIfSpecificFiltersFilled && vm.filter.listView === "Individual"){
        PurchaseOrderService.getGRNDetails({
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort(),
          invoiceStatus: vm.filter.receivingStatus !== "ALL" ? vm.filter.receivingStatus : '' ,
          businessOutletName: !vm.filter.businessOutletName ? '' : vm.filter.businessOutletName,
          businessOutletId: vm.filter.businessIdList.length == 0 ?  '' : vm.filter.businessIdList,
          poFromDate: !vm.filter.poFromDate ? '' : UTCDateService.utcFromDate(vm.filter.poFromDate),
          poToDate: !vm.filter.poToDate ? '' : UTCDateService.utcToDate(vm.filter.poToDate),
          deliveryFromDate: !vm.filter.deliveryFromDate ? '' : UTCDateService.utcFromDate(vm.filter.deliveryFromDate),
          deliveryToDate: !vm.filter.deliveryToDate ? '' : UTCDateService.utcToDate(vm.filter.deliveryToDate),
          grnFromDate : !vm.filter.grnFromDate ? '' : UTCDateService.utcFromDate(vm.filter.grnFromDate),
          grnToDate : !vm.filter.grnToDate ? '' : UTCDateService.utcToDate(vm.filter.grnToDate),
          poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
          supplierId: '',
          itemGroupId: !vm.filter.itemGroupId ? '' : vm.filter.itemGroupId,
          buyerIdList: vm.filter.buyerIdList.length == 0 ? '': vm.filter.buyerIdList
        }).then(function(response) {
          vm.goodReceivingNotes = response.data;
          vm.totalItems = response.headers('X-Total-Count');
        }, function(error) {
          NotificationService.error({
            title: 'GRN Details',
            error: error
          });
        });
      }
    }

    function resetFilters() {
        vm.filter = {};
        vm.filter.receivingStatus = vm.receivingStatuses[0];
        vm.filter.businessIdList = [];
        vm.filter.buyerIdList = [];
        vm.filter.listView = vm.listViews[0];
        vm.filter.poFromDate = null;
        vm.filter.poToDate = null;
        vm.filter.deliveryFromDate = null;
        vm.filter.deliveryToDate = null;
        vm.filter.grnFromDate = vm.todaysDate;
        vm.filter.grnToDate = vm.todaysDate;
        vm.goodReceivingNotes = [];
        vm.query = {
          limit: paginationConstants.itemsPerPage,
          page: 1
        };
    }

    function validateDateRange(numberOfDays, title, filterAvailable) {
      var poDateSelected = UtilService.checkDateRangeExists(vm.filter.poFromDate, vm.filter.poToDate, filterAvailable);
      var deliveryDateSelected = UtilService.checkDateRangeExists(vm.filter.deliveryFromDate, vm.filter.deliveryToDate, filterAvailable);
      var grnDateSelected = UtilService.checkDateRangeExists(vm.filter.grnFromDate, vm.filter.grnToDate, filterAvailable);
      
      if (!poDateSelected && !deliveryDateSelected && !grnDateSelected) {
        toastr.error('Please select valid date ranges!', title);
        return false;
      }
      var poValid = UtilService.checkDateRangeValidity(vm.filter.poFromDate, vm.filter.poToDate, numberOfDays, title, poDateSelected);
      var deliveryValid = UtilService.checkDateRangeValidity(vm.filter.deliveryFromDate, vm.filter.deliveryToDate, numberOfDays, title, deliveryDateSelected);
      var grnValid = UtilService.checkDateRangeValidity(vm.filter.grnFromDate, vm.filter.grnToDate, numberOfDays, title, grnDateSelected);
      if ((poDateSelected && !poValid) ||(deliveryDateSelected && !deliveryValid) || (grnDateSelected && !grnValid)) {
        return false;
      }     
      return true;
    }

  function loadAllIfSpecificFiltersFilled() {
    var validationResult = false;
    var filtersToCheck = ['poNumber','buyerIdList', 'businessIdList', 'itemGroupId'];
    var filterAvailable = UtilService.areSpecificFiltersFilled(filtersToCheck,vm.filter);
    var numberOfDays = GLOBAL_CONSTANTS.REPORTS_DATE_VALIDATION;
    var title = "Good Receipt Notes";      
    validationResult = validateDateRange(numberOfDays, title, filterAvailable);
    return validationResult;
  }

  }
})();
