/*
 * @Author: Lovin C 
 * @Date: 2021-09-13 13:18:29 
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-09-14 13:35:04
 */


(function () {
    'use strict';

    angular.module('ezyprocureApp').controller('AdminCustomPaymentsController', AdminCustomPaymentsController);

    AdminCustomPaymentsController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$mdDialog', '$timeout', 'toastr', 'pagingParams', 'UTCDateService', 'NotificationService', 'GLOBAL_CONSTANTS', 'paginationConstants', '$window', 'EzypaymentAdminToolsCompanyListService', 'EXPORT_CONSTANTS', '$stateParams', 'AdminCustomPaymentsService', 'BrandManagementService', 'ManageBuyerService', 'ManageSupplierService', 'Auth'];

    function AdminCustomPaymentsController($scope, $rootScope, $q, $filter, $state, $mdDialog, $timeout, toastr, pagingParams, UTCDateService, NotificationService, GLOBAL_CONSTANTS, paginationConstants, $window, EzypaymentAdminToolsCompanyListService, EXPORT_CONSTANTS, $stateParams, AdminCustomPaymentsService, BrandManagementService, ManageBuyerService, ManageSupplierService, Auth) {
        var vm = this;
        vm.predicate = pagingParams.predicate;
        vm.reverse = true;
        vm.loadAll = loadAll;
        vm.query = {
            limit: paginationConstants.itemsPerPage,
            page: 1
        };
        vm.getAllBuyersMappedToPayment = getAllBuyersMappedToPayment;
        vm.getAllSuppliersMappedToPayment = getAllSuppliersMappedToPayment;
        vm.selectAll = selectAll;
        vm.deselectAll = deselectAll;
        vm.limitOptions = paginationConstants.limitOptions;
        vm.visibilityType = $stateParams.visibilityType ? $stateParams.visibilityType : 'ALLOCATED';
        vm.showConfirm = showConfirm;
        vm.filter = {};
        vm.customPaymentsList = [];
        vm.invoiceApprove = invoiceApprove;
        vm.invoiceReject = invoiceReject;
        vm.downloadDoc = downloadDoc;
        vm.promptRejectRemark = promptRejectRemark;
        vm.cancel = cancel;
        vm.proceedToRejectInvoice = proceedToRejectInvoice;
        vm.checkAllRows = checkAllRows;
        vm.handleRowSelection = handleRowSelection;
        // 2FA changes
        vm.otp = undefined;
        vm.isOtpValid = true;
        vm.counterMilliSeconds = undefined;
        vm.counterSeconds = undefined;
        vm.counterMinutes = undefined;
        vm.validateOTP = validateOTP;
        vm.resendOTP = resendOTP;
        vm.confirmAndPay = confirmAndPay;
        vm.userRole = Auth.getUserRole().ugly;
        vm.loadAll();

        function checkAllRows() {
            vm.customPaymentsList.forEach(function (item) {
                item.selected = vm.selectAllRows;
            });
        }

        function handleRowSelection() {
            vm.selectAllRows = true;
            vm.customPaymentsList.forEach(function (item) {
                if(!item.selected) {
                    vm.selectAllRows = false;
                }
            });
        }

        function confirmAndPay(otp) {
            invoiceApprove(otp);
        }

        function proceedToRejectInvoice() {
            $mdDialog.hide(vm.rejectRemarks);
        }

        function promptRejectRemark(event) {
            vm.rejectRemarks = '';
            var selectedInvocies = getSelectedInvoices(vm.customPaymentsList);
            if(selectedInvocies.length === 0) {
                toastr.error('Please select atleast one invoice', 'Rejection');
                return;
            }
            $mdDialog.show({
                controller: function () {
                    return vm;
                },
                templateUrl: 'app/ezypayment-modules/admin-modules/tools/custom-payments/ezypayment.custom-payments.reject-dialog.tmpl.html',
                parent: angular.element(document.body),
                targetEvent: event,
                clickOutsideToClose: true,
                fullscreen: true, // Only for -xs, -sm breakpoints.
                controllerAs: 'vm'
            }).then(function (remarks) {
                invoiceReject(remarks);
            }, function () {

            });
        }

        function downloadDoc(url) {
            var a = document.createElement('a');
            a.setAttribute('href', url);
            a.setAttribute('download', undefined);
            a.setAttribute('target', '_blank');
            a.click();
        }

        function invoiceApprove(otp) {
            var selectedInvocies = getSelectedInvoices(vm.customPaymentsList);
            if(selectedInvocies.length === 0) {
                toastr.error('Please select atleast one invoice', 'Approval');
                return;
            }
            var paymentIds = _.map(selectedInvocies, _.property('paymentId'));
            AdminCustomPaymentsService.approveInvoices({ 'paymentIds': paymentIds }, otp).then(function (response) {
                if (response.headers('authorization-otp') == 'OTP_VERIFICATION_REQUIRED') {
                    showOtpPrompt();
                }
                else if (GLOBAL_CONSTANTS.OTP_EXCEPTIONS.includes(response.headers('authorization-otp'))) {
                    toastr.error('Invalid OTP', 'AUTHENTICATION FAILED');
                }
                else {
                    toastr.success('Approved successfully', 'Invoice Payment');
                    $mdDialog.hide();
                    vm.loadAll();
                }

            },
                function (error) {
                    NotificationService.error({
                        title: 'Invoice Payment',
                        error: error
                    });
                });
        }

        function cancel() {
            $mdDialog.cancel();
        }

        function invoiceReject(remarks) {
            var selectedInvocies = getSelectedInvoices(vm.customPaymentsList);
            if(selectedInvocies.length === 0) {
                toastr.error('Please select atleast one invoice', 'Rejection');
                return;
            }
            var paymentIds = _.map(selectedInvocies, _.property('paymentId'));
            var params = {
                paymentIds: paymentIds,
                remarks: remarks
            };
            AdminCustomPaymentsService.rejectInvoices(params).then(function (response) {
                toastr.success('Rejected successfully', 'Invoice Payment');
                vm.loadAll();
            },
                function (error) {
                    NotificationService.error({
                        title: 'Invoice Payment',
                        error: error
                    });
                });
        }

        function getSelectedInvoices(invData) {
            return _.filter(invData, 'selected');
        }

        function getAllBuyersMappedToPayment() {
            if (vm.buyers) { return false }
            ManageBuyerService.getAllAdminBuyersWithUserDetails({
                page: 0,
                size: paginationConstants.maxLimit,
                search: '',
                sort: sort(),
                isAdminDashboard:vm.userRole==='ROLE_ADMIN' && $rootScope.redirectionApiFromDashboard==true?true:false
            }).then(function (response) {
                vm.buyers = response.data;
            }, function (error) {
                NotificationService.error({
                    title: 'Buyer Listing',
                    error: error
                });
            });
        }

        function getAllSuppliersMappedToPayment() {
            if (vm.suppliers) { return false }
            ManageSupplierService.getAllAdminSuppliersWithUserDetails({
                page: 0,
                size: paginationConstants.maxLimit,
                search: '',
                visibilityType: 'ALLOCATED',
                sort: sort(),
                isAdminDashboard:vm.userRole==='ROLE_ADMIN' && $rootScope.redirectionApiFromDashboard==true?true:false,
                filter:vm.userRole==='ROLE_ADMIN' && $rootScope.redirectionApiFromDashboard==true?$rootScope.dashboardFilter:"All"
            }).then(function (response) {
                vm.suppliers = response.data;
            }, function (error) {
                NotificationService.error({
                    title: 'Supplier Listing',
                    error: error
                });
            });
        }

        function selectAll(dropdown) {
            switch (dropdown) {
                case 'BUYERS':
                    vm.filter.buyerIdList = _.map(vm.buyers, _.property('id'));
                    break;
                case 'SUPPLIERS':
                    vm.filter.supplierIdList = _.map(vm.suppliers, _.property('id'));
                    break;
            }
        }

        function deselectAll(dropdown) {
            switch (dropdown) {
                case 'BUYERS':
                    vm.filter.buyerIdList = [];
                    break;
                case 'SUPPLIERS':
                    vm.filter.supplierIdList = [];
                    break;
            }
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            return result;
        }

        function loadAll() {
            AdminCustomPaymentsService.customPaymentsList({
                fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
                toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
                supplierIds: vm.filter.supplierIdList ? vm.filter.supplierIdList : '',
                buyerIds: vm.filter.buyerIdList ? vm.filter.buyerIdList : '',
                page: vm.query.page - 1,
                size: vm.query.limit,
                sort: sort()
            }).then(function (response) {
                vm.customPaymentsList = response.data;
                vm.totalItems = response.headers('X-Total-Count');
            }, function (error) {
                NotificationService.error({
                    title: 'Admin Custom Payments',
                    error: error
                });
            });
        }

        function showConfirm(ev, supplier) {
            var confirm = $mdDialog.confirm()
                .title('Are you sure you want to delete?')
                .textContent('This action could not be reverted back.')
                .targetEvent(ev)
                .ok('Delete')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function () {
                deleteAdminSupplier(supplier);
            }, function () { });
        }

        function deleteAdminSupplier(supplierId) {
            BuyerSupplierMappingService.deleteBSMapping({ supplierId: supplierId, buyerId: vm.suppliers[0].buyer.id }).then(function (response) {
                NotificationService.success({
                    title: 'Admin Supplier',
                    message: 'alertMessage.DELETED_SUCCESSFULLY'
                });
                vm.loadAll();
            }, function (error) {
                NotificationService.error({
                    title: 'Admin Supplier Deletion',
                    error: error
                });
            });
        }


        // timer for otp resend
        function counter() {
            vm.stopped = $timeout(function () {
                vm.counterMilliSeconds -= 1000;
                if (vm.counterMilliSeconds) {
                    var seconds = vm.counterMilliSeconds / 1000;
                    vm.counterMinutes = Math.floor(seconds / 60);
                    if (vm.counterMinutes > 0) {
                        vm.counterSeconds = seconds - (vm.counterMinutes * 60);
                    }
                    else {
                        vm.counterSeconds = seconds;
                    }
                    counter();
                }
                else {
                    vm.counterSeconds = 0;
                    vm.counterMinutes = 0;
                    stopCounter();
                }
            }, 1000);
        };

        function stopCounter() {
            vm.isOtpValid = false;
            $timeout.cancel(vm.stopped);
        }

        function showOtpPrompt() {
            // clear otp
            vm.otp = undefined;
            vm.isOtpValid = true;
            vm.counterSeconds = 0;
            vm.counterMinutes = 0;
            vm.counterMilliSeconds = GLOBAL_CONSTANTS.OTP_EXPIRY_MINUTES * 60 * 1000;
            counter();
            // show prompt
            $mdDialog.show({
                templateUrl: 'app/core/common-templates/otp-templates/otp-capture.tmpl.html',
                controller: function () {
                    return vm;
                },
                controllerAs: 'vm',
                targetEvent: event,
                clickOutsideToClose: true
            });
        }

        function validateOTP() {
            if (!vm.otp) {
                toastr.error('Invalid OTP!', 'Please check the OTP entered');
                return;
            }

            if (vm.otp && (vm.otp.length < 5 || vm.otp.length > 8)) {
                toastr.error('Invalid OTP!', 'Please check the OTP entered');
                return;
            }
            confirmAndPay(vm.otp);
        }

        function resendOTP() {
            confirmAndPay();
        }
    }
})();

