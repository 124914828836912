/**
* @Author: Elsa
* @Date:   04-Apr-2017 11:44 +05:30
* @Project: Ezyprocure
* @Filename: supplier.purchase-order.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-01-22T15:21:31+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierPurchaseOrderController', SupplierPurchaseOrderController);

  SupplierPurchaseOrderController.$inject = ['$scope', '$rootScope','$q', '$filter', '$state', '$stateParams', 'pagingParams', 'toastr', '$mdDialog', 'UTCDateService', 'NotificationService', 'PurchaseOrderService', 'ItemGroupService', 'GLOBAL_CONSTANTS', 'paginationConstants','CSVExportService', 'EXPORT_CONSTANTS','CallbackResourceService','XeroResourceService', 'GLOBAL_CONFIG_CONSTANTS', 'PDFExportService', 'BuyerBusinessService', 'BuyerSupplierMappingService', 'SupplierDataService','Auth','UtilService'];

  function SupplierPurchaseOrderController($scope, $rootScope,$q, $filter, $state, $stateParams, pagingParams, toastr, $mdDialog, UTCDateService, NotificationService, PurchaseOrderService, ItemGroupService, GLOBAL_CONSTANTS, paginationConstants, CSVExportService, EXPORT_CONSTANTS,CallbackResourceService,XeroResourceService, GLOBAL_CONFIG_CONSTANTS, PDFExportService, BuyerBusinessService, BuyerSupplierMappingService, SupplierDataService,Auth, UtilService ) {
    var vm = this;
    vm.loadItemGroups=loadItemGroups;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;

    vm.todaysDate = new Date();
    vm.loadAll = loadAll;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.poStatuses = GLOBAL_CONSTANTS.PO_STATUSES;
    vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.limitOptions = paginationConstants.limitOptionsWithAll;
    vm.poStatusClass = GLOBAL_CONFIG_CONSTANTS.PURCHASE_ORDER_STATUS.LABEL_CLASS;

    //CSV Header
    vm.purchaseOrderHeader = EXPORT_CONSTANTS.CSV.supplierPOHeader;
    // XERO Integration
    vm.connectToXero = connectToXero;
    vm.disconnectToXero = disconnectToXero;
    vm.exportToXero = exportToXero;
    vm.getTokenExpiry = getTokenExpiry;
    vm.fetchExportToXeroList = fetchExportToXeroList;
    vm.checkAll = checkAll;
    vm.getSelectedPOs = getSelectedPOs;
    vm.getSelectedPOIds = getSelectedPOIds;
    vm.validateExportXero = validateExportXero;
    vm.updateXeroExportFlag = updateXeroExportFlag;
    vm.selectAll = false;
    vm.getSelectedPOItems = getSelectedPOItems;
    vm.getSelectedPrintPOItems = getSelectedPrintPOItems;
    vm.getMassDeliveryOrderPDF = getMassDeliveryOrderPDF;
    vm.getMassPurchaseOrderPDF = getMassPurchaseOrderPDF;
    vm.getOraganization = getOraganization;
    vm.closeModal = closeModal;

    //CSV Function Declaration
    vm.getPurchaseOrderCSVData = getPurchaseOrderCSVData;
    vm.getSelectedPONumbers = getSelectedPONumbers;
    vm.getAllExportPurchaseOrderDetails = getAllExportPurchaseOrderDetails;
    //consolidated View
    vm.navigateToConsolidatedView = navigateToConsolidatedView;

    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.getAllBuyersBusinessOutlets = getAllBuyersBusinessOutlets;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.filter = {
      buyerIdList: [],
      businessIdList: []
    };
    vm.exportToEzyfinance = exportToEzyfinance;
    $scope.dateLocale = {
      formatDate: function(date) {
        var m = moment(date);
        return m.isValid() ? m.format('DD/MM/YYYY') : '';
      }
    };
    vm.filter.deliveryFromDate = null;
    vm.filter.deliveryToDate = null;
    vm.validateDateRange = validateDateRange;
    vm.loadAllIfSpecificFiltersFilled = loadAllIfSpecificFiltersFilled;
    function exportToEzyfinance(){
      var selectedPos = getSelectedPOs(vm.poItems);
      var poDto = {
        poNumList: [],
        ExternalApi : 'EZPSG'
      };
      var data = {};
      _.forEach(selectedPos, function(po){
        data = {
          "key":po.purchaseOrder.purchaseOrderNumber,
          "value":po.purchaseOrder.processOrder.deliveryDate
        }
        poDto.poNumList.push(data);
      });
      PurchaseOrderService.exportToEzyfinance(poDto).then(function(response){
        NotificationService.success({
          title: response.data.status,
          message: response.data.message
        });
      },
      function(error){
        NotificationService.simpleErrorToast({
          title: global.exportPurchaseOrders,
          message: error
        });
      });
    }

    vm.resetFilters = resetFilters;

      //Get all buyers mapped to the supplier
      function getAllSupplierMappedBuyers() {
        vm.filter.businessIdList = [];
        vm.bussinessOutlets = [];
        var params = {
          page: 0,
          size: paginationConstants.maxLimit,
        };
        if (vm.buyers)
          return false;
        var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
        BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function (response) {
          vm.buyers = response.data;
        }, function (error) {
        });
      }

      //get all business outlets of the selected buyer
      function getAllBuyersBusinessOutlets() {
        if (!vm.filter.buyerIdList) {
          return false;
        }
        if (vm.filter.businessIdList > 0) {
          return false;
        }
        var params = {
          page: 0,
          size: paginationConstants.maxLimit,
          groupBy: 'buyer'
        };
        BuyerBusinessService.getAllBuyersBusinessOutlets(vm.filter.buyerIdList, params).then(function (response) {
          vm.buyerBusinessOutlets = response.data;
          vm.bussinessOutlets = [];
          _.forEach(vm.buyerBusinessOutlets, function (buyer) {
            _.forEach(buyer.businessDTOList, function (outlets) {
              vm.bussinessOutlets.push(outlets);
            });
          });
        }, function (error) {
        });
      }

      function selectAll(dropdown) {
        switch (dropdown) {
          case 'BUYERS':
            vm.filter.buyerIdList = _.map(vm.buyers, _.property('id'));
            break;
          case 'BUSINESSOUTLETS':
            vm.filter.businessIdList = _.map(vm.bussinessOutlets, _.property('businessId'));
            break;
        }
      }

      /**
       * [deselectAll Deselect all from the list]
       * @param  {[type]} dropdown [Active dropdown - outlets or brands]
       */
      function deselectAll(dropdown) {
        switch (dropdown) {
          case 'BUYERS':
            vm.filter.buyerIdList = [];
            break;
          case 'BUSINESSOUTLETS':
            vm.filter.businessIdList = [];
            break;
        }
      }


    //Getting CSV DATA
    function getPurchaseOrderCSVData() {
      if (vm.accessForPriceDisplay)
        vm.purchaseOrderHeader = EXPORT_CONSTANTS.CSV.supplierPOHeader;
      else {
        vm.purchaseOrderHeader = _.without(EXPORT_CONSTANTS.CSV.supplierPOHeader, 'unitPrice', 'totalAmount', 'currency');
      }
      var deferred = $q.defer();
      vm.getAllExportPurchaseOrderDetails().then(function (response) {
        if (_.isEmpty(response)) {
          NotificationService.simpleErrorToast({
            title: 'global.exportPurchaseOrders',
            message: 'global.table.NO_RECORDS_FOUND'
          });
          return deferred.promise;
        } else {
          var exportPO = CSVExportService.getSupplierPOReportCSV(response);
          deferred.resolve(exportPO);
        }
      }, function (error) {
        NotificationService.simpleErrorToast({
          title: 'global.exportPurchaseOrders',
          message: error
        });
        deferred.reject(error);
      });
      return deferred.promise;
    }
    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function getAllExportPurchaseOrderDetails(){
      var deferred = $q.defer();
      vm.purchaseOrderNumberList = [];
      var selectedPOs = vm.getSelectedPOs(vm.poItems);
      vm.purchaseOrderNumberList = vm.getSelectedPONumbers(selectedPOs);
      vm.exportPORequestDTO = {
          poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
          purchaseOrderStatus: vm.filter.poStatus ,
          invoiceStatus: '',
          invoiceNumber: '',
          businessOutletId: !vm.filter.businessOutletId ? '' : vm.filter.businessOutletId ,
          businessOutletName: '' ,
          //If dates are selected, they are converted to UTC format
          poFromDate: !vm.filter.poFromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.poFromDate)),
          poToDate: !vm.filter.poToDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.poToDate)),
          deliveryFromDate: !vm.filter.deliveryFromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.deliveryFromDate)),
          deliveryToDate: !vm.filter.deliveryToDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.deliveryToDate)),
          grnFromDate : '',
          grnToDate : '',
          query: !vm.filter.itemName ? '' : vm.filter.itemName,
          supplierId: !vm.filter.supplierId ? '': vm.filter.supplierId,
          itemGroupId: !vm.filter.itemGroupId ? '' :vm.filter.itemGroupId,
          debitNoteStatus: '',
          grnNumber: '',
          buyerName: '',
          creditNoteStatus: '',
          supplierVisibilityType: vm.filter.supplierVisibilityType ? vm.filter.supplierVisibilityType : '',
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort(),
          xeroExported: vm.filter.xeroExported ? vm.filter.xeroExported : '',
          module : 0
      };
      if(vm.purchaseOrderNumberList.length > 0) {
        PurchaseOrderService.getPOExportListing(vm.exportPORequestDTO, vm.purchaseOrderNumberList, 'batch').then(function(response){
          deferred.resolve(response.data);
        },function(error){
          NotificationService.simpleErrorToast({
            title: 'global.fetchingCSVExportDetails',
            error: error
          });
          deferred.reject(error);
        });
        return deferred.promise;
      }
      else if (_.isEmpty(vm.purchaseOrderNumberList) && selectedPOs.length === 0) {
        PurchaseOrderService.getPOExportListing(vm.exportPORequestDTO, [],'all').then(function(response){
          deferred.resolve(response.data);
        },function(error){
          NotificationService.simpleErrorToast({
            title: 'global.fetchingCSVExportDetails',
            error: error
          });
          deferred.reject(error);
        });
        return deferred.promise;
      }
      else {
        return deferred.promise;
      }
    }

    function loadItemGroups() {
      if(vm.itemGroups)
        return false;
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }

    /**
    * [loadAll Get all Purchase Order Details]
    */
    function loadAll() {
    var loadAllIfSpecificFiltersFilled = vm.loadAllIfSpecificFiltersFilled();
      if (loadAllIfSpecificFiltersFilled && vm.filter.listView === "Individual"){
        getIndividualPODetails();
      }
    }

    /**
    * [getIndividualPODetails  gets PO details for each PO Number]
    */
    function getIndividualPODetails() {
      PurchaseOrderService.getAllPurchaseOrders({
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        purchaseOrderStatus: vm.filter.poStatus,
        invoiceStatus: '',
        invoiceNumber: '',
        businessOutletId: vm.filter.businessIdList.length == 0 ?  '' : vm.filter.businessIdList,
        businessOutletName: !vm.filter.businessOutletName ? '' : vm.filter.businessOutletName,
        //If dates are selected, they are converted to UTC format
        poFromDate: !vm.filter.poFromDate ? '' : UTCDateService.utcFromDate(vm.filter.poFromDate),
        poToDate: !vm.filter.poToDate ? '' : UTCDateService.utcToDate(vm.filter.poToDate),
        deliveryFromDate: !vm.filter.deliveryFromDate ? '' : UTCDateService.utcFromDate(vm.filter.deliveryFromDate),
        deliveryToDate: !vm.filter.deliveryToDate ? '' : UTCDateService.utcToDate(vm.filter.deliveryToDate),
        grnFromDate : '',
        grnToDate : '',
        query: !vm.filter.itemName ? '' : vm.filter.itemName,
        supplierId: '',
        itemGroupId: !vm.filter.itemGroupId ? '' : vm.filter.itemGroupId,
        buyerIdList: vm.filter.buyerIdList.length == 0 ? '' : vm.filter.buyerIdList,
        debitNoteStatus: '',
        grnNumber: '',
        buyerName: '',
        creditNoteStatus: '',
        supplierVisibilityType: '',
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        xeroExported: vm.filter.xeroExported ? vm.filter.xeroExported : '',
        module : 1
      }).then(function(response){
        vm.poItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      },function(error){
        NotificationService.error({
          title: 'Purchase Order Details',
          error: error
        });
      });
    }
  /**
   * [navigateToConsolidatedView creates an object vm.purchaseOrderRequestDTO to use as a request in consolidated view]
  */
    function navigateToConsolidatedView(){
      vm.purchaseOrderRequestDTO={
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        purchaseOrderStatus: vm.filter.poStatus,
        invoiceStatus: '',
        invoiceNumber: '',
        businessOutletId: vm.filter.businessIdList ? vm.filter.businessIdList : '',
        businessOutletName: !vm.filter.businessOutletName ? '' : vm.filter.businessOutletName,
        //If dates are selected, they are converted to UTC format
        poFromDate: !vm.filter.poFromDate ? '' : UTCDateService.utcFromDate(vm.filter.poFromDate),
        poToDate: !vm.filter.poToDate ? '' : UTCDateService.utcToDate(vm.filter.poToDate),
        deliveryFromDate: !vm.filter.deliveryFromDate ? '' : UTCDateService.utcFromDate(vm.filter.deliveryFromDate),
        deliveryToDate: !vm.filter.deliveryToDate ? '' : UTCDateService.utcToDate(vm.filter.deliveryToDate),
        query: !vm.filter.itemName ? '' : vm.filter.itemName,
        supplierId: '',
        itemGroupId: !vm.filter.itemGroupId ? '' : vm.filter.itemGroupId,
        debitNoteStatus: '',
        grnNumber: '',
        buyerName: '',
        buyerIdList: vm.filter.buyerIdList ? vm.filter.buyerIdList : '',
        creditNoteStatus: '',
        supplierVisibilityType: '',
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        xeroExported: vm.filter.xeroExported ? vm.filter.xeroExported : '',
        module : 1
      };
      var canRedirect = PurchaseOrderService.setPurchaseOrderRequestParams(vm.purchaseOrderRequestDTO);
       if(canRedirect == true){
         $state.go('supplier.reports.purchase-order.consolidated-details', {
         reload: false
        });
      }
    }

    function checkAll() {
      vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
      angular.forEach(vm.poItems, function(poItem) {
        poItem.purchaseOrder.selected = vm.selectAll;
      });
    }

    function connectToXero() {
      CallbackResourceService.redirectUrl({
        module : 1,
      }).then(function(response){
      },function(error){
        NotificationService.error({
          title: 'global.buttons.connectXERO',
          error: error
        });
      });
    }

    function disconnectToXero() {
      vm.organisationName = "";
      CallbackResourceService.disconnectToXero().then(function(response){
        vm.xero = response.xero;
        NotificationService.success({
          title: response.data.status,
          message: response.data.message
        });
      },function(error){
        NotificationService.error({
          title: 'global.buttons.disconnectXERO',
          error: error
        });
      });
    }

    function validateExportXero(poItems) {
      var validSelection = true;
      var validStatus = ['PENDING','PROCESSED'];
      _.forEach(poItems, function(po, key) {
        if(validSelection){
          if(validStatus.indexOf(po.purchaseOrder.purchaseOrderStatus) === -1){
            validSelection = false;
            NotificationService.simpleErrorToast({
              title: 'global.buttons.exportXERO',
              message: 'Only Pending and Processed POs can be exported to XERO'
            });
          }
        }
      });
      return validSelection;
    }

    function getSelectedPOs(poItems) {
      var selectedPOs = _.filter(poItems, function(po) {
        return po.purchaseOrder.selected === true;
      });

      return selectedPOs;
    }

    function getSelectedPONumbers(selectedPOs) {
      var purchaseOrderNumberList = _.map(selectedPOs, _.property('purchaseOrder.purchaseOrderNumber'));
      return purchaseOrderNumberList;
    }

    function getSelectedPOIds(selectedPOs) {
      var purchaseOrderIdList = _.map(selectedPOs, _.property('purchaseOrder.id'));
      return purchaseOrderIdList;
    }

    function fetchExportToXeroList() {
      var purchaseOrderIdList = [];
      var selectedPOs = vm.getSelectedPOs(vm.poItems);
      if(vm.validateExportXero(selectedPOs)){
        purchaseOrderIdList = vm.getSelectedPOIds(selectedPOs);
      }
      return purchaseOrderIdList;
    }

    function exportToXero() {
      var purchaseOrderIdList = fetchExportToXeroList();

      if(angular.isDefined(vm.poItems) && purchaseOrderIdList.length > 0){
        XeroResourceService.exportToXero({
          purchaseOrderStatus : vm.filter.poStatus ,
          poNumber : !vm.filter.poNumber ? '' : vm.filter.poNumber,
          fromDate : !vm.filter.poFromDate ? !vm.filter.deliveryFromDate ? (!vm.filter.grnFromDate ? '' :UTCDateService.utcFromDate(vm.filter.grnFromDate)) : UTCDateService.utcFromDate(vm.filter.deliveryFromDate) : UTCDateService.utcFromDate(vm.filter.poFromDate),
          toDate : !vm.filter.poToDate ? !vm.filter.deliveryToDate ? (!vm.filter.grnToDate ? '' :UTCDateService.utcToDate(vm.filter.grnToDate)) : UTCDateService.utcToDate(vm.filter.deliveryToDate) : UTCDateService.utcToDate(vm.filter.poToDate),
          businessOutletName : !vm.filter.businessOutletName ? '' : vm.filter.businessOutletName,
          itemName : '',
          supplierId : !vm.filter.supplierId ? '': vm.filter.supplierId,
          itemGroupId : !vm.filter.itemGroupId ? '' :vm.filter.itemGroupId,
          buyerName : '',
          poExported : '',
          page : 0,
          query : '',
          size : 20,
          sort : 'id,desc',
          purchaseOrderIdList : (purchaseOrderIdList.length === 0) ? [''] : purchaseOrderIdList,
          supplierVisibilityType: vm.filter.supplierVisibilityType ? vm.filter.supplierVisibilityType : '',
          invoiceStart : '',
          invoiceEnd : '',
          invoiceNumber : '',
          module : 1,
        }).then(function(response){
          vm.message = response.headers('X-ezyprocureApp-alert')
          NotificationService.success({
            title: 'global.buttons.exportXERO',
            message: vm.message
          });
          vm.updateXeroExportFlag(purchaseOrderIdList);
          vm.selectAll = false;
          setTimeout(function(){
            vm.loadAll();
          },60000);
        },function(error){
          NotificationService.error({
            title: 'global.buttons.exportXERO',
            error: error
          });
          getTokenExpiry();
          disableConnectToXeroBtn();
        });
      } else {
        NotificationService.simpleErrorToast({
          title: 'global.buttons.exportXERO',
          message: 'alertMessage.SEARCH_AND_SELECT'
        });
      }
    }
    function updateXeroExportFlag(purchaseOrderIdList) {
      _.forEach(vm.poItems, function(po, key) {
        if(_.includes(purchaseOrderIdList, po.purchaseOrder.id)){
          vm.poItems[key].purchaseOrder.xeroExported = undefined;
        }
      });
    }

    function getTokenExpiry() {

      CallbackResourceService.getTokenExpiry(
      ).then(function(response){
        vm.xero = response;
      },function(error){
        NotificationService.error({
          title: 'global.buttons.connectXERO',
          error: error
        });
      });
    }

    function disableConnectToXeroBtn() {

      CallbackResourceService.getAuthTimeout(
      ).then(function(response){
        vm.xero = response;
        vm.organisationName = vm.xero.errors;
        if(window.location.href.indexOf("?") > -1) {
          var code = window.location.href.split('code=')[1].split('&scope=')[0];
          var scope = window.location.href.split('scope=')[1].split('&session_state=')[0];
          var session_state = window.location.href.split('session_state=')[1].split('&state')[0];
          var state = window.location.href.split('state=')[1];
          CallbackResourceService.xeroCallback({ code: code, scope: scope, sessionState: session_state,state: state })
            .then(function(response){
            vm.xero = response;
            vm.organisationdata = response.data.data;
              if(vm.organisationdata.length == 1){
                vm.tenantId = vm.organisationdata[0].tenantId;
                vm.getOraganization();
              } else {
                $mdDialog.show({
                  templateUrl: 'app/core/common-templates/xero-multiple-organization.html',
                  controller: function() {
                    return vm;
                  },
                  controllerAs: 'vm',
                  targetEvent: event,
                  clickOutsideToClose: true
                });
              }
              if(!vm.organisationName){
                vm.organisationName = vm.xero.errors;
                }
             code = null;
             scope = null;
             session_state=null;
             state=null;
          }, function(error){
            vm.xero = CallbackResourceService.getXeroConnectionParams();
            NotificationService.error({
              title: 'global.buttons.connectXERO',
              error: error
            });
          });
        }
      },function(error){
        NotificationService.error({
          title: 'global.buttons.connectXERO',
          error: error
        });
      });
    }

    disableConnectToXeroBtn();

    function getOraganization(){
      closeModal();
      var params ={
        tenantId: vm.tenantId
      };
      CallbackResourceService.xeroConnect(params).then(function(response){
        vm.xero = response;
        vm.organisationName = vm.xero.data.responseList[0].reason;
        NotificationService.success({
          title: 'global.buttons.connectXERO',
          message: 'alertMessage.XERO.SUCCESS'
        });
      }, function(error){
        NotificationService.error({
          title: 'global.buttons.connectXERO',
          error: error
        });
      });
     }

    function closeModal() {
      $mdDialog.cancel();
    }

    // get selected process orders
    function getSelectedPOItems(poItems){
      //Fetch all ids and purchaseOrderNumber Selected PO where selectedPOs.selected = true
      var selectedPOs = vm.getSelectedPOs(poItems);
      vm.deliveryOrder = _.map(selectedPOs,  function(processOrderItem){
        return {
          'deliveryDateList': [processOrderItem.purchaseOrder.processOrder.deliveryDate],
          'purchaseOrderNumber': processOrderItem.purchaseOrder.purchaseOrderNumber
        };
      });
    }

    /**
     * [downloadURI downloads the PDF]
     * @param {String} uri
     * @param {String} name
     */
    function downloadURI(uri, name)
    {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = name;
      link.href = uri;
      link.click();
    }

    /**
     * [getMassDeliveryOrderPDF get the url of PDF to download]
     */
    function getMassDeliveryOrderPDF() {
      var date = new Date();
      var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
      vm.getSelectedPOItems(vm.poItems);
      if(vm.deliveryOrder.length>0){
        PDFExportService.printMassDOPDF(vm.deliveryOrder)
         .then(function(response){
          downloadURI(response.data.message, "PDF_" + dateString);
        },function(error) {
          NotificationService.error({
            title: 'alertMessage.MASS_PRINT_DO',
            error: error
          });
        });
      }
      else{
        NotificationService.simpleErrorToast({
          title: 'alertMessage.MASS_PRINT_DO',
          message: 'global.pleaseSelectedOneFromTheList'
        });
      }
    }

    function getMassPurchaseOrderPDF() {
      var date = new Date();
      var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
      vm.getSelectedPrintPOItems(vm.poItems);
      if(vm.poOrderList.length>0){
        vm.pageParams = {
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort(),
        }
        PDFExportService.printMassPOPDF(vm.poOrderList,vm.pageParams)
         .then(function(response){
          downloadURI(response.data.message, "PDF_" + dateString);
        },function(error) {
          NotificationService.error({
            title: 'alertMessage.MASS_PRINT_PO',
            error: error
          });
        });
      }
      else{
        NotificationService.simpleErrorToast({
          title: 'alertMessage.MASS_PRINT_PO',
          message: 'global.pleaseSelectedOneFromTheList'
        });
      }
    }

        // get selected process orders
        function getSelectedPrintPOItems(poItems){
          //Fetch all ids and purchaseOrderNumber Selected PO where selectedPOs.selected = true
          var selectedPOs = vm.getSelectedPOs(poItems);
          vm.poOrderList = _.map(selectedPOs,  function(processOrderItem){
            return {
              'deliveryDate': processOrderItem.purchaseOrder.processOrder.deliveryDate,
              'poNumber': processOrderItem.purchaseOrder.purchaseOrderNumber
            };
          });
        }

    function resetFilters() {
        vm.filter = {};
        vm.filter.poStatus = vm.poStatuses[0];
        vm.filter.poFromDate = vm.todaysDate;
        vm.filter.poToDate = vm.todaysDate;
        vm.filter.deliveryFromDate = null;
        vm.filter.deliveryToDate = null;
        vm.filter.businessIdList = [];
        vm.filter.buyerIdList = [];
        vm.filter.listView = vm.listViews[0];
        vm.poItems = [];
        vm.query = {
          limit: paginationConstants.itemsPerPage,
          page: 1
        };
    }

  function validateDateRange(numberOfDays, title, filterAvailable) {
    var poDateSelected = UtilService.checkDateRangeExists(vm.filter.poFromDate, vm.filter.poToDate, filterAvailable);
    var deliveryDateSelected = UtilService.checkDateRangeExists(vm.filter.deliveryFromDate, vm.filter.deliveryToDate, filterAvailable);
    if (!poDateSelected && !deliveryDateSelected) {
      toastr.error('Please select valid date ranges!', title);
      return false;
    }
    var poValid = UtilService.checkDateRangeValidity(vm.filter.poFromDate, vm.filter.poToDate, numberOfDays, title,poDateSelected);
    var deliveryValid = UtilService.checkDateRangeValidity(vm.filter.deliveryFromDate, vm.filter.deliveryToDate, numberOfDays, title,deliveryDateSelected);
    if ((poDateSelected && !poValid) ||(deliveryDateSelected && !deliveryValid)) {
      return false;
    }     
    return true;
  }

  function loadAllIfSpecificFiltersFilled() {
    var validationResult = false;
    var filtersToCheck = ['poNumber','buyerIdList','businessIdList','itemGroupId', 'itemName', 'xeroExported'];
    var filterAvailable = UtilService.areSpecificFiltersFilled(filtersToCheck,vm.filter);
    var numberOfDays = GLOBAL_CONSTANTS.REPORTS_DATE_VALIDATION;
    var title = "Purchase Order";      
    validationResult = validateDateRange(numberOfDays, title, filterAvailable);
    return validationResult;
  }
  }
})();
