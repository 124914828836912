/**
 * @Author: Ajay
 * @Date:   28-Jun-2017 11:59 +05:30
 * @Project: Ezyprocure
 * @Filename: admin.purchase-orders.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 09-Nov-2017 11:34 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('AdminPurchaseOrdersController', AdminPurchaseOrdersController);

  AdminPurchaseOrdersController.$inject = ['$scope', '$rootScope', '$state', 'PurchaseOrderService', 'pagingParams', 'paginationConstants', 'NotificationService', 'GLOBAL_CONFIG_CONSTANTS', 'GLOBAL_CONSTANTS'];

  function AdminPurchaseOrdersController($scope, $rootScope, $state, PurchaseOrderService, pagingParams, paginationConstants, NotificationService, GLOBAL_CONFIG_CONSTANTS, GLOBAL_CONSTANTS) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.loadAll = loadAll;
    vm.poStatusClass = GLOBAL_CONFIG_CONSTANTS.PURCHASE_ORDER_STATUS.LABEL_CLASS;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      PurchaseOrderService.getAllPurchaseOrdersForAdmin({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText,
        filter: $rootScope.redirectionApiFromDashboard==true?$rootScope.dashboardFilter:"All"
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.purchaseOrders = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Purchase Order Listing',
          error: error
        });
      });
    }

    vm.loadAll();

  }


})();
