/**
 * @Author: Ajay
 * @Date:   18-Jul-2018 15:19 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.manage-erp-item.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 08-Aug-2018 13:12 +05:30
 */



(function() {

  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ManageERPItemController', ManageERPItemController);

  ManageERPItemController.$inject = ['BuyerBusinessUserMapService','$mdDialog','$scope', '$rootScope', '$filter', '$state', 'toastr', '$stateParams', '$q', 'pagingParams', 'NotificationService', 'BuyerItemsForApprovalService', 'ItemGroupService', 'BuyerReceivingOrderService', 'paginationConstants', 'ItemBuyerMappingService', 'BuyerDataService', 'CSVExportService', 'EXPORT_CONSTANTS', '$window', 'GLOBAL_CONSTANTS'];

  function ManageERPItemController(BuyerBusinessUserMapService,$mdDialog,$scope, $rootScope,$filter, $state, toastr, $stateParams, $q, pagingParams, NotificationService, BuyerItemsForApprovalService, ItemGroupService, BuyerReceivingOrderService, paginationConstants, ItemBuyerMappingService, BuyerDataService, CSVExportService, EXPORT_CONSTANTS, $window, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.getItemBuyerMappingByItemId = getItemBuyerMappingByItemId;
    vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
    vm.accessForEzyinventory = BuyerDataService.getBuyerProfile().accessDTO.accessForEzyinventory;
    vm.getBuyerERPCSVData = getBuyerERPCSVData;
    vm.checkSelectedItems = checkSelectedItems;
    vm.loadAll = loadAll;
    vm.selectAllSupplier = selectAllSupplier;
    vm.deselectAllSupplier = deselectAllSupplier;
    vm.checkAll = checkAll;
    vm.disableItemExport = disableItemExport;
    vm.itemListQuery = {};
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.supplierItemMappingHeader = EXPORT_CONSTANTS.CSV.SupplierItemMappingData;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.todaysDate = new Date();
    vm.filter = {};
    vm.limitOptions = paginationConstants.limitOptions;
    vm.getSupplierItemMappingCSVData = getSupplierItemMappingCSVData;
    vm.itemIds = [];
    vm.accessForBarcodeSearch = BuyerDataService.getBuyerProfile().accessDTO.accessForBarcodeSearch;
    vm.exportToInventory = exportToInventory;
    vm.exportedToInventoryItems = [];
    vm.updatedFlagItems = [];
    vm.ezyprocureItemsId = [];
    vm.itemFilter = null;
    vm.itemFilterList = {'All Items' : null, 'Exported': true, 'Not Exported': false};
    vm.ignoreBuyingUpdate = ignoreBuyingUpdate;

    vm.replicateItemsHeader = EXPORT_CONSTANTS.CSV.ReplicateItemsHeader;
    vm.getReplicateItemsCSVData = getReplicateItemsCSVData;
    vm.replicateitemselectedCheckbox = replicateitemselectedCheckbox;
    vm.replicateItems = [];

    vm.accessForBuyerItemCodeFeatures = BuyerDataService.getBuyerProfile().accessDTO.accessForBuyerItemCodeFeatures;
    vm.defaultSupplierSelectedCheckbox = defaultSupplierSelectedCheckbox;
    vm.defaultSupplierCheckPopUp = defaultSupplierCheckPopUp;
    vm.changeDefaultSupplier = changeDefaultSupplier;
    vm.cancelPopUp = cancelPopUp;

    //get supplier item mapping data
    function getSupplierItemMappingCSVData(){
        var temp = getSelectedItemMappingIdList(vm.itemBuyerMappings);
          var deferred = $q.defer();
          return CSVExportService.getSupplierItemMappingCSV(temp);
        }
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    // Load All gl mappings for logged in buyer
    function loadAll() {
    vm.replicateItems = [];
    vm.selectAll=false;
      ItemBuyerMappingService.getAllItemBuyerMappings({
        page: vm.query.page - 1,
        size: vm.query.limit,
        buyerId: vm.buyerId,
        supplierIdList: !vm.filter.supplierIdList ? '': vm.filter.supplierIdList,
        query: !vm.filter.query ? '': vm.filter.query,
        isInvCreated: vm.itemFilter,
        buyerItemCode: !vm.filter.buyerItemcode ? '': vm.filter.buyerItemcode,
        sort: sort()
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.itemBuyerMappings = response.data;
      },function(error){
        NotificationService.error({
          title: 'Item Buyer Mapping Listing',
          error: error
        });
      });

      if (!vm.accessForEzyinventory) {
        if (!BuyerDataService.isAdminBuyer()) {
          BuyerReceivingOrderService.checkEzyinventoryAccess().then(function (response) {
            vm.accessForEzyinventory = response.data.accessForEzyinventory;
          }, function (error) {
          });
        }
      }
    }

    vm.loadAll();

    function checkAll() {
      angular.forEach(vm.itemBuyerMappings, function (item) {
        if (!item.invCreated && item.subCategoryId)
          item.selected = vm.selectAll;
      });
    }

    //check any item selected or not
    function disableItemExport() {
      var enableItemExport = false;
      if (vm.itemBuyerMappings && vm.itemBuyerMappings.length) {
        enableItemExport = vm.itemBuyerMappings.some(function (item) {
          return item.selected;
        });
      }
      return !enableItemExport;
    }

    //Getting CSV DATA
    function getBuyerERPCSVData() {
      var deferred = $q.defer();
      vm.buyerERPCSVDataHeader = EXPORT_CONSTANTS.CSV.buyerERPCSVData;
      ItemBuyerMappingService.getAllItemBuyerMappingCsv({
        page: 0,
        size: paginationConstants.maxLimit,
        buyerId: vm.buyerId,
        supplierIdList: !vm.filter.supplierIdList ? '': vm.filter.supplierIdList,
        query: !vm.filter.query ? '': vm.filter.query,
        buyerItemCode: !vm.filter.buyerItemcode ? '': vm.filter.buyerItemcode,
        sort: sort()
      }).then(function(response){
        if(_.isEmpty(response.data)){
          toastr.error('No records found', 'Export Buyer ERP Error!');
          return deferred.promise;
        }else {
          var buyerERPCSV = CSVExportService.getBuyerERPCSVDataCSV(response.data);
          deferred.resolve(buyerERPCSV);
        }
      },function(error){
        NotificationService.error({
          title: 'Export CSV',
          error: error
        });
        deferred.reject(error);
      });
      return deferred.promise;
    }

    function getItemBuyerMappingByItemId() {
      if(vm.supplierList)
        return false;
      BuyerItemsForApprovalService.getItemBuyerMappingByItemId().then(function(response) {
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
      }, function(error) {
      });
    }

    /**
    * [selectAllSupplier Selects all from the list]
    * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups, itemGroups ]
    */
    function selectAllSupplier(dropdown) {
      switch (dropdown) {
      case 'SUPPLIERS':
        vm.filter.supplierIdList = _.map(vm.supplierList, _.property('id'));
        break;
      }
    }

    /**
    * [deselectAllSupplier Deselect all from the list]
    * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups, itemGroups ]
    */
    function deselectAllSupplier(dropdown) {
      switch (dropdown) {
      case 'SUPPLIERS':
        vm.filter.supplierIdList = [];
        break;
      }
    }

    //check item has selected or not
    function checkSelectedItems() {
      var noOfItems = [];
      noOfItems = _.filter(itemBuyerMappings, {
        'selected': true
      });
      itemIds = _.map(noOfItems, _.property('id'));
      return itemIds.length;
    }

    function getAllBuyerBusinessOutlets() {
      vm.businessOutlets = null;
      BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser().
        then(function (response) {
          vm.businessOutlets = response;
          //if only one business outlet set it as default
          if (vm.businessOutlets.length === 1) {
            vm.itemListQuery.businessId = vm.businessOutlets[0].id;
          }
        }, function (error) {
        });
    }

    function getSelectedItemMappingIdList(itemBuyerMappings) {
      var itemIds = [];
      var selectedItems = [];
      //Fetch all ids of supplierItem in vm.supplierItems where supplierItem.selected = true
      selectedItems = _.filter(itemBuyerMappings, {
        'selected': true
      });
      itemIds = _.map(selectedItems, _.property('id'));
      return selectedItems;
    }

    function exportToInventory(ev) {
      var selectedItems = vm.itemBuyerMappings.filter(function(item) {
        return item.selected;
      });

      var itemsNotInInventory = selectedItems.filter(function(item) {
        return !item.invCreated && item.subCategoryId;
      });

      if(itemsNotInInventory.length && (selectedItems.length != itemsNotInInventory.length)) {
        showConfirmDialog(itemsNotInInventory, ev);
      }
      else if(!itemsNotInInventory.length) {
        NotificationService.success({
          title: 'Nothing to Export',
          message: 'alertMessage.COMPLETED_SUCCESSFULLY'
        });
      }
      else {
        exportAndUpdateItemList(itemsNotInInventory);
      }
    }

    function exportAndUpdateItemList(itemsNotInInventory) {
      ItemBuyerMappingService.exportToInventory(itemsNotInInventory).then(function (response) {
        var itemsId = _.map(response.data, _.property('ezyprocureItemId'));
        ItemBuyerMappingService.updateFlagForERPExport(itemsId).then(function (response) {
          loadAll();
        }, function (error) {
          NotificationService.error({
            title: 'Failed to update item',
            error: error
          });
        });
        NotificationService.success({
          title: 'Export to Ezyinventory',
          message: 'alertMessage.COMPLETED_SUCCESSFULLY'
        });

      }, function (error) {
        NotificationService.error({
          title: 'Failed to Export Item',
          error: error
        });
      });
    }

    function showConfirmDialog(itemsNotInInventory, ev) {
      var confirm = $mdDialog.confirm()
        .clickOutsideToClose(false)
        .title('Export conditions mismatch for some items!')
        .textContent('These items will be excluded from export!')
        .ariaLabel('Alert Dialog')
        .ok('Proceed')
        .cancel('Cancel')
        .targetEvent(ev);

      $mdDialog.show(confirm).then(function () {
        exportAndUpdateItemList(itemsNotInInventory);
      },
        function () {
        }
      );

    }

    function ignoreBuyingUpdate(itemBuyerMapping){
          ItemBuyerMappingService.updateItemBuyerMapping(itemBuyerMapping).then(function(response) {
            $scope.$emit('ezyprocureApp:buyerItemUpdate', response);
            NotificationService.success({
              title: 'Item',
              message: 'alertMessage.UPDATED_SUCCESSFULLY'
            });
            $state.go('buyer.manage-erp-item', {
              reload: false
            });
          }, function(error) {
            NotificationService.error({
              title: 'ERP Item Updation',
              error: error
            });
          });
    }
    function getReplicateItemsCSVData(){
      return CSVExportService.getReplicateItemsCSV(vm.replicateItems);
    }

    function replicateitemselectedCheckbox(itemBuyerMapping,$index){
        if(itemBuyerMapping.replicateitemselected == true){
            vm.replicateItems.push(itemBuyerMapping)
        }
        else{
            vm.replicateItems =  _.filter(vm.replicateItems,function(ritem){
                return ritem.id!=itemBuyerMapping.id
            });
        }
        vm.replicateItems.sort(function(a, b) {
            return b.id - a.id;
        });
    }

    function defaultSupplierSelectedCheckbox(itemBuyerMapping,$index){
            vm.mappingId =  itemBuyerMapping.id;
            vm.buyerItemCodeData = itemBuyerMapping.buyerItemCode;
            vm.checkedItemSupplierId = itemBuyerMapping.items.supplier.id;
            vm.changeSupplierDTO = {
                buyerItemCode: vm.buyerItemCodeData,
                defaultSupplier: itemBuyerMapping.defaultSupplier ,
                index: $index
            }
            vm.defaultSupplierCheckPopUp()
    }

    function defaultSupplierCheckPopUp(event){

        vm.changeSupplierbtn  = true;
        $mdDialog.show({
        templateUrl: 'app/core/common-templates/default-supplier-view.tmpl.html',
        controller: function() {
            return vm
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
        });
    }

    function changeDefaultSupplier(){
        ItemBuyerMappingService.changeDefaultSupplier(vm.changeSupplierDTO,vm.mappingId).then(function(response) {
            vm.changeDefaultSupplierItemData = response.data;
            if(vm.changeSupplierDTO.defaultSupplier){
              /**
               * updateItemPriceForDefaultSupplier() will function only for below buyerId 
               * v2 : buyer id= 1967 (MM buyer)
               * dev & staging :  buyer id= 581 (paymentbyr1)
               */
              if($window.location.origin === "https://v2.ezyprocure.com" && vm.buyerId === GLOBAL_CONSTANTS.MM_PROD_BUYERID){
                updateItemPriceForDefaultSupplier();
              }
              if(($window.location.origin === "https://dev.ezyprocure.com" ||$window.location.origin === "https://staging.ezyprocure.com") && vm.buyerId === GLOBAL_CONSTANTS.MM_TEST_BUYERID ){
                updateItemPriceForDefaultSupplier();
              }
            }
            $mdDialog.hide();
            vm.loadAll();
           }, function(error) {
            $mdDialog.hide();
            vm.itemBuyerMappings[vm.changeSupplierDTO.index].defaultSupplier = false;
               NotificationService.error({
                error: error,
              })
          });
    }

    function cancelPopUp(){
        $mdDialog.hide();
    }

    function updateItemPriceForDefaultSupplier(){
        ItemBuyerMappingService.updateItemPriceForDefaultSupplier(vm.buyerId,vm.checkedItemSupplierId).then(function(response) {
            $mdDialog.hide();
            vm.loadAll();
           }, function(error) {
               NotificationService.error({
                error: error,
              })
          });
    }

  }
})();
