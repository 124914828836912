/**
* @Author: Ajay
* @Date:   07-Mar-2017 15:17 +05:30
* @Project: Ezyprocure
* @Filename: supplier.email.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 10-Nov-2017 11:21 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierEmailController', SupplierEmailController);

  SupplierEmailController.$inject = ['$scope', '$state', '$stateParams', 'NotificationService', 'SupplierEmailService', 'BuyerSupplierMappingService', 'SupplierDataService', 'GLOBAL_CONSTANTS','toastr'];

  function SupplierEmailController($scope, $state, $stateParams, NotificationService, SupplierEmailService, BuyerSupplierMappingService, SupplierDataService, GLOBAL_CONSTANTS,toastr) {

    var vm = this;
    vm.init = init;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.populateSelectedEmails = populateSelectedEmails;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.sendEmail = sendEmail;

    vm.subjectLimit = GLOBAL_CONSTANTS.emailConfiguration.SUBJECT_SIZE_LIMIT;
    vm.bodyLimit = GLOBAL_CONSTANTS.emailConfiguration.BODY_SIZE_LIMIT;
    vm.ezyapFlag = false;
    vm.isGreetingDisabled = true;
    vm.getAllEzyapBuyerSuppliersMapped = getAllEzyapBuyerSuppliersMapped;
    vm.locationEzyap = GLOBAL_CONSTANTS.emailOrigin.LOCATION_EZYAP;
    vm.locationEzyproc = GLOBAL_CONSTANTS.emailOrigin.LOCATION_EZYPROCURE;
    vm.validateEmail=validateEmail;
    /**
     * [getAllSupplierMappedBuyers Get all supplier mapped buyers]
     * @return {[list]} [List of buyers]
     */
    function getAllSupplierMappedBuyers() {
      if (vm.buyers) return false;
      var supplierId = vm.currentSupplierProfile.supplier.id;
      BuyerSupplierMappingService.getAllSupplierMappedBuyerList(supplierId).then(function(response) {
        vm.buyers = response.data;
      }, function(error) {
      });
    }


    /**
     * [init Load initial data]
     */
    function init() {
      vm.currentSupplierProfile = SupplierDataService.getSupplierProfile();
      vm.supplierName = vm.currentSupplierProfile.supplier.companyName;
      vm.emailDTO = {
        recipients: [],
        subject: '',
        body: ''
      };
      // Get the current URL
      const currentURL = window.location.href;

      // Check if the URL contains the substring "ezyap"
      if (currentURL.includes("/ezyap/")) {
        vm.ezyapFlag = true;
        vm.emailDTO.greeting = 'Hi There,\n';
        vm.emailDTO.subject = 'e-Doc Enablement';
        // Your email content
        const emailContent = '1.We are now able to receive the ePurchase Order and the eInvoice Response over the Peppol Network.\n2.Also, able to send and receive Invoice Response via InvoiceNow with the response statuses like Message Acknowledgment, In Progress, Under Query, Accepted, Rejected, Partially Paid, Paid.\n So, Please send these e-Docs to us via InvoiceNow. \n';
         vm.emailDTO.body = emailContent;
        vm.emailDTO.source = vm.locationEzyap;
      }else{
        vm.emailDTO.source = vm.locationEzyproc;
      }
    }

    /**
     * [selectAll Select all buyers from the list and create separate string array of emails]
     */
    function selectAll() {
      vm.buyerEmailList = _.map(vm.buyers, _.property('buyerId'));
    }

    /**
     * [deselectAll Deselect all buyers from the list]
     */
    function deselectAll() {
      vm.buyerEmailList = [];
    }

    /**
     * [populateSelectedEmails Populate selected emails and union it to md-chips]
     */
    function populateSelectedEmails() {
      if(vm.ezyapFlag){
        vm.emailDTO.recipients = [];
        _.filter(vm.buyers, function(buyer) {
          if(buyer.id == vm.buyerEmailList){
            vm.emailDTO.recipients.push(buyer.email);
            vm.greetingCompanyName = buyer.companyName;
            vm.emailDTO.greeting = 'Hi'+' '+vm.greetingCompanyName+',\n';
          }
        });
       }else{
        var filteredBuyers = _.filter(vm.buyers, function (buyer) {
          return vm.buyerEmailList.includes(buyer.buyerId);
        });
        const uniqueRecipients = new Set();
        filteredBuyers.forEach(function (buyer) {
          uniqueRecipients.add(buyer.email);
        });
        vm.emailDTO.recipients = Array.from(uniqueRecipients);
       }      
    }

    /**
     * [sendEmail Send email to recipients]
     */
    function sendEmail() {
      if( vm.emailDTO.recipients.length<1)
      {
       toastr.error('Add recipients!', 'Please Add any recipients');
       return ;
      }
      SupplierEmailService.sendEmailToBuyer(vm.emailDTO).then(function(response) {
        NotificationService.success({
          title: 'Supplier - Email to Buyer',
          message: 'alertMessage.SENT_SUCCESSFULLY'
        });
      }, function(error) {
        NotificationService.error({
          title: 'Supplier - Email to Buyer',
          error: error
        });
      });
    }

        /**
     * [getAllMappedBuyersOrSuppliers Get all buyer/suppliers mapped]
     * @return {[list]} [List of buyers/suppliers]
     */
        function getAllEzyapBuyerSuppliersMapped() {
          if (vm.buyers) return false;
          BuyerSupplierMappingService.getAllEzyapBuyerSuppliersMappedList().then(function(response) {
            vm.buyers = response.data;
          }, function(error) {
          });
        }

        function validateEmail(x) {
          if (event.which === 13) {
              var emailValue = x;
              var reg = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
              if (!reg.test(emailValue)) {                                        
                var index =  vm.emailDTO.recipients.indexOf(emailValue);
                if (index !== -1) {
                  toastr.error('Invalid Email!', 'Removed '+emailValue+' from recipients');
                  vm.emailDTO.recipients.splice(index, 1);
                }    
            
              }
          };
    
    };

  }
})();
