/**
* @Author: Atul
* @Date:   11-July-2023 15:12 +05:30
* @Project: Ezyprocure
* @Filename: buyer.dashboard.service.js
 * @Last modified by:   Atul
 * @Last modified time: 11-July-2023 10:26 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BnplBuyerDashboardService', BNPLBuyerNavbarController);

  BNPLBuyerNavbarController.$inject = ['$http', '$q', 'API_CONSTANTS','RESOURCE_URL_CONSTANTS'];
  function BNPLBuyerNavbarController($http, $q, API_CONSTANTS,RESOURCE_URL_CONSTANTS) {

    var BNPLBuyerNavbarController = {
      getBnplBuyerDashboardCount: getBnplBuyerDashboardCount,
      getBnplStatus:getBnplStatus,
      activateBNPL:activateBNPL,
      getBnplBrandStatus:getBnplBrandStatus
    };

    return BNPLBuyerNavbarController;
function getBnplStatus(buyerId)
{
  var deferred = $q.defer();
  $http({
    url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'bnpl/buyer/'+buyerId,
    method: "GET",
  }).then(function(data) {
    deferred.resolve(data);
  }).catch(function(message) {
    deferred.reject(message);
  });
  return deferred.promise;
}
function getBnplBrandStatus(brandId)
{
  var deferred = $q.defer();
  $http({
    url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'bnpl/brand/'+brandId,
    method: "GET",
  }).then(function(data) {
    deferred.resolve(data);
  }).catch(function(message) {
    deferred.reject(message);
  });
  return deferred.promise;
}
function activateBNPL(userData)
{
  var deferred = $q.defer();
  $http({
    url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BNPL + 'activate',
    method: "POST",
    data: userData
  }).then(function(data) {
    deferred.resolve(data);
  }).catch(function(message) {
    deferred.reject(message);
  });
  return deferred.promise;
}
    function getBnplBuyerDashboardCount(buyerId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'bnpl/dashboard/'+buyerId,
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    function getBnplBuyer(buyerId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'bnpl/dashboard/'+buyerId,
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
  
})();
