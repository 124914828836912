/**
 * @Author: Mufsir
 * @Date:   08-Dec-2016 19:49 +05:30
 * @Project: Ezyprocure
 * @Filename: auth.jwt.service.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-11-04T15:08:51+05:30
 */



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .factory('AuthServerProvider', AuthServerProvider);

  AuthServerProvider.$inject = ['$http', 'Principal', '$localStorage', '$sessionStorage', '$cookies', '$q', 'API_CONSTANTS', 'MobileUserService', 'GLOBAL_CONSTANTS'];

  function AuthServerProvider($http, Principal, $localStorage, $sessionStorage, $cookies, $q, API_CONSTANTS, MobileUserService, GLOBAL_CONSTANTS) {
    var service = {
      getToken: getToken,
      login: login,
      loginWithToken: loginWithToken,
      storeAuthenticationToken: storeAuthenticationToken,
      logout: logout,
      clearStorageData: clearStorageData,
      clearPushToken: clearPushToken,
      restoreDeviceTokenForMobile: restoreDeviceTokenForMobile,
      storeCbToken: storeCbToken,
      getCbToken: getCbToken
    };

    return service;

    // fetch access token from sessionStorage/localStorage
    function getToken() {
      return $localStorage.authenticationToken || $sessionStorage.authenticationToken;
    }

    // login with username and password
    function login(credentials) {
      var deferred = $q.defer();
      var userData = {
        username: credentials.username,
        password: credentials.password,
        rememberMe: credentials.rememberMe,
        id_token: credentials.idToken
      };

      var deferred = $q.defer();

      $http.post('api/' + API_CONSTANTS.baseVersion + 'authenticate', userData).then(authenticateSuccess, authenticateFailure);

      return deferred.promise;
      // if authentication is success store jwt token
      function authenticateSuccess(data, status, headers) {
        var bearerToken = data.headers('Authorization');
        if (angular.isDefined(bearerToken) && bearerToken.slice(0, 7) === 'Bearer ') {
          var jwt = bearerToken.slice(7, bearerToken.length);
          service.storeAuthenticationToken(jwt, credentials.rememberMe);

          // Saving values to track the timestamp for validating the subscription licence expiration popup
          const currentTime = new Date().getTime();
          sessionStorage.setItem('expirationPopupTimestamp', currentTime);
          sessionStorage.setItem('showExpirationPopup', 'true');
          checkCbHealth().then(function () {
            loginToCB(userData)
              .then(function () {
                deferred.resolve(jwt);
              })
              .catch(function () {
                Principal.setHasCbPlatform(false);
                deferred.resolve(jwt);
              })
          }).catch(function () {
            Principal.setHasCbPlatform(false);
            deferred.resolve(jwt);
          });
        } else {
          deferred.reject(data);
        }
      }

      // handle authentication failure
      function authenticateFailure(data, status, headers) {
        deferred.reject(data);
      }
    }

    function loginToCB(data) {
      var deferred = $q.defer();
      // Login to crossborder platforms
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'Crossborder/authenticate', data)
        .success(function (data, status, headers) {
          if (data && data.id_token) {
            var bearerToken = data.id_token;
            if (angular.isDefined(bearerToken) && bearerToken !== 'Bad credentials') {
              service.storeCbToken(bearerToken);
              deferred.resolve(bearerToken);
            } else if (bearerToken == 'Bad credentials') {
              Principal.setHasCbPlatform(false);
              deferred.resolve()
            }
          } else {
            deferred.resolve();
          }
        })
        .error(function () {
          deferred.reject();
        })
        .catch(function (error) {
          deferred.reject();
        });
      return deferred.promise;
    }
    /*
    * logoutServicecall call logout api
    */
    function logoutServiceCall() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + '/api' + API_CONSTANTS.baseVersion + '/logout',
        method: "POST"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function loginWithToken(jwt, rememberMe) {
      var deferred = $q.defer();

      if (angular.isDefined(jwt)) {
        this.storeAuthenticationToken(jwt, rememberMe);
        deferred.resolve(jwt);
      } else {
        deferred.reject();
      }

      return deferred.promise;
    }

    function storeAuthenticationToken(jwt, rememberMe) {
      $localStorage.authenticationToken = jwt;
      $cookies.put('rememberMe', rememberMe, GLOBAL_CONSTANTS.COOKIE_OPTIONS);
    }

    /**
     * [restoreDeviceTokenForMobile Fix to restore deviceToken for mobile apps on logout]
     * From mobile, deviceToken is sent only once when the app is opened
     * When user logs out from the app and relogin as a new user, there is no interaction b/w mobile app and web app
     * So we sets deviceToken even after user logouts
     */
    function restoreDeviceTokenForMobile(deviceToken) {
      MobileUserService.setDeviceToken(deviceToken);
    }

    function clearStorageData(deviceToken) {
      $localStorage.$reset();
      $sessionStorage.$reset();
      $cookies.remove('rememberMe');
      restoreDeviceTokenForMobile(deviceToken);
    }

    function clearPushToken(deviceToken) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'notifications/' + deviceToken).then(function (data) {
        clearStorageData(deviceToken);
        deferred.resolve(data);
      }).catch(function (message) {
        clearStorageData(deviceToken);
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [logout method for calling logout api]
    * @return {[type]} [description]
    */
    function logout() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'logout',
        method: "POST"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function storeCbToken(jwt) {
      if (jwt && jwt !== 'Bad credentials') {
        Principal.setHasCbPlatform(true);
      } else {
        Principal.setHasCbPlatform(false);
      }
      $localStorage.cbAuthenticationToken = jwt;
    }

    function getCbToken() {
      return $localStorage.cbAuthenticationToken || $sessionStorage.cbAuthenticationToken;
    }

    function checkCbHealth() {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + 'health', {
        responseType: 'blob'
      })
        .then(function (data) {
          Principal.setHasCbPlatform(true);
          deferred.resolve(data);
        }).catch(function (message) {
          Principal.setHasCbPlatform(false);
          deferred.reject(message);
        });
      return deferred.promise;
    }

  }
})();
