/**
* @Author: Lovin
* @Date:   2018-11-17T13:23:29+05:30
* @Filename: ezypayment.buyer.payee.edit.controller.js
 * @Last modified by:   Lovin
 * @Last modified time: 2018-09-17T15:41:20+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EditPayeeController', EditPayeeController);

  EditPayeeController.$inject = ['$scope', '$state', 'pagingParams', 'NotificationService', 'paginationConstants','ManageSupplierService', 'Auth', 'GLOBAL_CONSTANTS', '$stateParams', 'BankService', 'BuyerDataService', 'BrandManagementService', 'EZYAP_CONSTANTS'];

  function EditPayeeController($scope,  $state, pagingParams, NotificationService, paginationConstants,ManageSupplierService,  Auth, GLOBAL_CONSTANTS, $stateParams, BankService, BuyerDataService, BrandManagementService, EZYAP_CONSTANTS) {

    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.translatedError = {};
    vm.translatedError.responseList = [];
    vm.reverse = true;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.supplier = {};
    vm.paymentTerms = {};
    vm.paymentTerms.BANK_TRANSFER = {};
    vm.userRole = Auth.getUserRole();
    vm.visibilityType = $stateParams.visibilityType ? $stateParams.visibilityType : 'ALLOCATED';
    vm.getSupplierdata = getSupplierdata;
    vm.supplierId = $stateParams.supplierId;
    vm.save = save;
    vm.customDutyTypes = GLOBAL_CONSTANTS.CUSTOM_DUTY_TYPE;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.countryCodes = EZYAP_CONSTANTS.ezyap.countryCodes;
    vm.countryCodeChanged = countryCodeChanged;
    /**
    * [getSupplierData get supplier data by id]
    */
    function getSupplierdata() {
      ManageSupplierService.getAdminSupplier(vm.supplierId).
        then(function(response) {
          if (response) {
            vm.supplier = response;
            vm.countryCodeChanged();
          }
        }, function(error) {
        });
    }

    /**
    * [save update payee details]
    */
    function save(){
      ManageSupplierService.updateAdminSupplier(vm.supplier).then(function(response) {
        $scope.$emit('ezyprocureApp:supplierUpdate', response);
        NotificationService.success({
          title: 'Add Payee',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        $state.go('ezypayment.buyer.payee', {
          reload: true
        });
      }, function(error) {
        NotificationService.error({
          title: 'Add Payee Updation',
          error: error
        });
      });
    }

    function countryCodeChanged() {
      const countryCode = vm.supplier.countryCode;

      const country = _.find(vm.countryCodes, function(data) {
        return data.countryCode === countryCode;
      });
      if (country) {
        vm.selectedCountry = country.countryName;
      } else {
        console.log("Country not found");
      }
    }
        
  }
})();
